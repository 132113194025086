<div class="tracking-details mb-12" *ngIf="transportJourneys?.portToPort; let p">
  <h3>Tracking Details</h3>
  <div class="row">
    <div class="col">
      <label class="label">Origin</label>
      <span class="pl-4 text-bold">{{ p.originPort || 'N/A' }}</span>
    </div>

    <div class="col">
      <label class="label">Origin airport</label>
      <span class="pl-4 text-bold">{{ p.originPortId || 'N/A' }}</span>
    </div>

    <div class="col">
      <label class="label">Destination airport</label>
      <span class="pl-4 text-bold">{{ p.destinationPortId || 'N/A' }}</span>
    </div>

    <div class="col">
      <label class="label">Destination</label>
      <span class="pl-4 text-bold">{{ p.destinationPort || 'N/A'}}</span>
    </div>
  </div>

  <div class="mt-20 tracking-details">
    <div class="row">
      <div class="col-9">
        <label class="label inline-block">Origin ATD</label>
        <span class="pl-4 text-bold">{{ p.portAtd }}</span>
      </div>

      <div class="col-3">
        <label class="label inline-block">Flight ATD</label>
        <span class="pl-4 text-bold">{{ p.hubAtd }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <label class="label inline-block">Flight ATA</label>
        <span class="pl-4 text-bold">{{ p.hubAta }}</span>
      </div>

      <div class="col-3">
        <label class="label inline-block">Destination {{p.portAta ? 'ATA' : 'ETA'}}</label>
        <span class="pl-4 text-bold">{{ p.portAta || p.portEta }}</span>
      </div>
    </div>
  </div>
</div>