import { Injectable } from '@angular/core';
import { ShippingFilters, FilterType } from '@dp/types/shipment_calendar.model';
import { StaticDataService } from './static-data.service';
import { StaticData } from './static-data.model';
import { User } from 'app/auth/user.model';
import { AuthService } from 'app/auth/auth.service';
import moment from 'moment';
import 'moment-timezone';
import { Router } from '@angular/router';
import { MIGRATE_ROUTES, ROUTE_PATH } from 'app/route.constants';
import { Utility } from '@dp/utilities';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  staticData: StaticData;
  user: User;
  utc: string;
  monthDatePattern: string;

  constructor(private dataService: StaticDataService, private authService: AuthService, private router: Router) {
    this.staticData = this.dataService.getStaticDataDirect();
    this.authService.currentUser.subscribe((user) => {
      this.reset();
      this.user = user;
      // any number of non-word, 2-4 character Y, and any number of non word
      // https://regex101.com/r/CFIYX6/1

      this.monthDatePattern = this.user?.datePattern?.replace(/\W?Y{2,4}\W?/gm, '');
    });
  }

  //todo: move this call back to the component
  public getFilterValue(filters: Array<ShippingFilters>, type: FilterType): string {
    if (!filters) {
      return null;
    }
    let found = filters.find((filter: ShippingFilters) => {
      return filter.type == type;
    });

    if (!found) {
      return null;
    } else {
      return found.value;
    }
  }

  // private getUTC(): string | number {
  //   if (!this.utc) {
  //     let timezone = this.user.timezone || moment.tz.guess();
  //     let displayText = this.staticData.timezones[timezone];
  //     this.utc = displayText.replace(/[^\d.-]/g, '') || 0;
  //   }
  //   return this.utc;
  // }

  private isToday(isoString): boolean {
    return moment().isSame(moment(isoString), 'day');
  }

  //todo: get rid of isToday, use isTodayNew
  private isTodayNew(mo: moment.Moment, timezone: string): boolean {
    return moment().tz(timezone).isSame(mo, 'day');
  }

  //special way to show 'date,time'
  //if it is same day, do not show date
  public isoStringToLocalDateTimeStringShort(isoString: string): string {
    // console.log(`timezone ${isoString}, ${moment(isoString).utcOffset(this.getUTC()).format('LT')}`);
    if (this.isToday(isoString)) {
      // return moment(isoString).utcOffset(this.getUTC()).format('LT');
      return moment(isoString).tz(this.user.timezone).format('LT');
    } else {
      let mo = moment(isoString).tz(this.user.timezone);

      return mo.format('L').replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '') + ', ' + mo.format('LT');
    }
  }

  public isoStringToLocalDateTimeStringShort2(isoString: string, timezone: string): string {
    let mo = moment(isoString).tz(timezone);
    let result = '';
    if (this.isTodayNew(mo, timezone)) {
      result = mo.format(this.user.timePattern);
    } else {
      result = mo.format(this.monthDatePattern) + ', ' + mo.format(this.user.timePattern);
    }
    //console.log(`mike, ${isoString} -> ${result}`);
    return result;
  }

  public getClockDisplay(): string {
    let mo = moment().tz(this.user.timezone);
    return mo.format('L').replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '') + ', ' + mo.format('LTS');
  }

  //'2020-08-18T23:46:33.696Z' => '18/08/2020 16:46:33'
  public isoStringToLocalDateTimeString(isoString: string): string {
    if (!isoString) {
      return '';
    }
    let mo = moment(isoString).tz(this.user.timezone);
    return mo.format(this.user.datePattern) + ' ' + mo.format(this.user.timePattern);
  }

  public getDateWithPattern(date: Date | string) {
    if (!this.user.datePattern) {
      return date;
    }
    if (typeof date === 'string') {
      return moment(date, 'YYYY-MM-DD').format(this.user.datePattern);
    } else {
      return moment(date).format(this.user.datePattern);
    }
  }

  // getStandardDate and getDateWithPattern reverse the values.
  public getStandardDate(date: string) {
    return moment(date, this.user.datePattern).format('YYYY-MM-DD');
  }

  public reset() {
    this.utc = null;
  }

  // navigate to container-shipments page, with shipmentNumber
  navigateToShipmentsPage(shipmentNumber, toTracking = false, tabLabel = 'Tracking') {
    const queryParams = {
      shipmentNumber,
      toTracking,
    };
    if (tabLabel !== 'Tracking') {
      queryParams['tabLabel'] = tabLabel;
    }
    this.router.navigate(['/ocean-shipments/tracking'], { queryParams });
  }

  // navigate to air-shipments page, with shipmentNumber
  navigateToAirShipmentsPage(shipmentNumber) {
    this.router.navigate(['/air-shipments/tracking'], {
      queryParams: {
        shipmentNumber,
      },
    });
  }

  isOceanCardViewEnabled(): boolean {
    // if (environment.environmentType === 'prod' || environment.environmentType === 'staging') {
    //   return [100000077, 100000010].includes(this.user.organizationId);
    // } else if (environment.environmentType === 'qa' || environment.environmentType === 'local') {
    //   return true;
    // }
    return true;
  }

  navigateToTrackingByGroupPage(searchValue, groupBy = 'referenceNumber') {
    this.router.navigate(['/ocean-shipments/tracking-by-group'], {
      queryParams: {
        groupBy,
        searchValue,
      },
    });
  }

  // generic way to reload the current page, this would reset the page state.
  reloadPage(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  migrateRouteHandler(
    route: string,
    otherInfo?: { shipmentId?: number; params?: { referer?: string; tab?: string; saveView?: string; q?: string; status?: string } }
  ): string {
    const { shipmentId, params } = otherInfo || {};
    if (params?.q && [ROUTE_PATH.OCEAN_TRACKING].includes(route)) {
      params.status = 'ALL';
    }
    const urlQueryParams = new URLSearchParams(params).toString();
    switch (route) {
      case ROUTE_PATH.OCEAN_TRACKING: {
        if (!this.isOceanCardViewEnabled() || this.user.shipmentPageViewType === 'LIST') {
          return route;
        }
        Utility.redirectTo(
          `${MIGRATE_ROUTES.OCEAN_TRACKING}${shipmentId ? `/${shipmentId}` : ''}${urlQueryParams ? `?${urlQueryParams}` : ''}`
        );
        return '';
      }
      case ROUTE_PATH.VESSEL_SCHEDULE: {
        Utility.redirectTo(MIGRATE_ROUTES.VESSEL_SCHEDULE);
        return '';
      }
      case ROUTE_PATH.FLIGHT_SCHEDULE: {
        Utility.redirectTo(MIGRATE_ROUTES.FLIGHT_SCHEDULE);
        return '';
      }
      case ROUTE_PATH.LOCATIONS: {
        Utility.redirectTo(`${MIGRATE_ROUTES.LOCATIONS}`);
        return '';
      }
      case ROUTE_PATH.STORAGE_COST: {
        Utility.redirectTo(`${MIGRATE_ROUTES.STORAGE_COST}`);
        return '';
      }
      case ROUTE_PATH.ADD_SINGLE_ROAD_SHIPMENT: {
        Utility.redirectTo(`${MIGRATE_ROUTES.ADD_SINGLE_ROAD_SHIPMENT}`);
        return '';
      }
      default:
        return route;
    }
  }
}
