<span type="button" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</span>
<h3 class="title">
  {{ data.step.title }} ({{ data.step.data.length }})
</h3>
<div mat-dialog-content class="box">
  <div class="table-wrapper">
    <div class="padding-wrapper" #scrollable>
      <table mat-table #table [dataSource]="railStationTalbe.rows" class="uds-table colored-header">
        <ng-container *ngFor="let col of railStationTalbe.header; let i = index ">
          <ng-container matColumnDef={{col}}>
            <th mat-header-cell *matHeaderCellDef>
              {{railStationTalbe.header[i]}}
            </th>
            <td mat-cell *matCellDef="let element; let row = index;">
              {{element[i].data}}
            </td>
          </ng-container>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="railStationTalbe.header"></tr>
        <tr mat-row *matRowDef="let row; columns: railStationTalbe.header;"></tr>
      </table>
    </div>
  </div>
</div>
