import { Component } from '@angular/core';
import { SvgMap } from '../svg/uds-svg-map';

@Component({
  selector: 'dp-cargoes-features',
  templateUrl: './cargoes-features.component.html',
  styleUrls: ['./cargoes-features.component.scss'],
})
export class CargoesFeaturesComponent {
  SvgMap = SvgMap;
  constructor() {}
}
