import { ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';

export function requiredByOtherFieldValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent) return null;

    let f = control.parent as UntypedFormGroup;
    let anyControlHasValue = Object.keys(f.controls).find((key) => f.controls[key] !== control && (f.controls[key].value || f.controls[key].value === 0));
    if (!anyControlHasValue) {
      control.setErrors(null);
      return null;
    }
    control.setErrors(control.value ? null : { required: true });
    control.markAsTouched();
    return control.value? null : {required: true};
  };
}
