import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { PolicyTypes } from 'app/documents/documents.model';
import { UsersService } from 'app/settings/users/users.service';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-tcs-dialog',
  templateUrl: './tcs-dialog.component.html',
  styleUrls: ['./tcs-dialog.component.scss'],
})
export class TcsDialogComponent implements OnInit {
  docName: string = PolicyTypes.TERMS_OF_SERVICE;
  pdfSrc: any;
  isBusy = false;

  constructor(
    public dialogRef: MatDialogRef<TcsDialogComponent>,
    private authService: AuthService,
    private usersService: UsersService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    this.pdfSrc = {
      url: environment.rootUrl + 'files/policy?type=' + this.docName,
      httpHeaders: { 'x-dpw-apikey': environment.apiValue },
    };
  }

  agreeToTcs(flag = true): void {
    this.isBusy = true;

    this.updateUserAcceptedTcs(flag).subscribe(
      (updatedUser) => {
        // console.log('updatedUser: ', updatedUser);
        this.authService.userUpdated(updatedUser);
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this.uiService.showSnackbar('Unable to save the change. Please try again', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });
      },
      () => (this.isBusy = false)
    );
  }

  private updateUserAcceptedTcs(flag: boolean) {
    const currentUser = this.authService.currentUserValue;
    return this.usersService.editUserDebounce(
      {
        userAcceptedTcs: flag,
      },
      currentUser.userCode
    );
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
