import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { SuperUploadResult } from '@dp/types/containers.model';
import { MultiLinesSnackbarComponent } from '@dp/components/';
import { SvgMap } from './components/svg/uds-svg-map';
import { CustomSnackbarComponent } from '@dp/components/custom-snackbar/custom-snackbar.component';
import { environment } from 'environments/environment';

@Injectable()
export class UIService {
  remindMeClosed = false;
  isHandsetChanged = new BehaviorSubject<boolean>(false);

  constructor(private snackbar: MatSnackBar, private dialog: MatDialog) {}

  showSnackbar(message: string, action: string, config: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbar.open(message, action, { verticalPosition: 'top', ...config });
  }
  showMultiLinesSnackbar(messages: string[], duration: number, panelClass: string) {
    this.snackbar.openFromComponent(MultiLinesSnackbarComponent, {
      verticalPosition: 'top',
      data: messages,
      panelClass: panelClass,
      duration: duration,
    });
  }
  showSuccessSnackbar(message: string, config?) {
    const { duration, panelClass } = config || {};
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      verticalPosition: 'top',
      data: { message, icon: SvgMap.whiteTick },
      panelClass: panelClass || 'success',
      duration: duration || environment.snackBarDuration.success,
    });
  }

  showFailedSnackbar(message: string, config?) {
    const { duration, panelClass } = config || {};
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      verticalPosition: 'top',
      data: { message, icon: SvgMap.f_exception },
      panelClass: panelClass || 'failed',
      duration: duration || environment.snackBarDuration.error,
    });
  }

  openDialog(message: SuperUploadResult) {}
}
