import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dp-video-dlg',
  templateUrl: './video-dlg.component.html',
  styleUrls: ['./video-dlg.component.scss'],
})
export class VideoDlgComponent implements OnInit {
  safeUrl: any;
  constructor(private dialogRef: MatDialogRef<VideoDlgComponent>, private _sanitizer: DomSanitizer) {

    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/433229585?autoplay=1`);
    // vimeo demo sample
    // this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https:player.vimeo.com/video/347119375?color=ef2200&byline=0&portrait=0&autoplay=1`);
  }

  ngOnInit(): void {}

  close() {
    console.log("VideoDlgComponent.close; ");
    this.dialogRef.close();
  }
}
