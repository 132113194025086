import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UsersService } from '../users/users.service';
import { LogService } from '@dp/services/log.service';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { User, STATES } from 'app/auth/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MdePopoverTrigger } from '@material-extended/mde';
// import { faUsersMedical as falUsersMedical } from '@fortawesome/pro-light-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { finalize, delay } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import moment from 'moment';

@Component({
  selector: 'dp-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  animations: dpAnimations,
})
export class OrganizationComponent implements OnInit {
  loadingUsers = false;
  pendingUsers: User[];
  currentUsers: User[];
  users: User[];
  isAdmin: boolean;
  pageLoadingZoneRef?: ProgressRef;

  @ViewChild('pageLoadingZone', { static: true }) pageLoadingZone?: ElementRef;

  constructor(
    private usersService: UsersService,
    private log: LogService,
    private uiService: UIService,
    private library: FaIconLibrary,
    private authService: AuthService,
    private progressService: ProgressService
  ) {
    // this.library.addIcons(falUsersMedical);
  }

  ngOnInit() {

    this.isAdmin = this.authService.currentUserValue.isAdmin;
  }




}
