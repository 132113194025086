<div *ngIf="vm$ | async as vm">
  <div class="heading-sub-regular mt-40">Customize Notification Definitions</div>
  <div class="body-regular mt-12 mb-20">
    Configure the notification settings tailored for your business needs.
  </div>
  <div class="heading-sub-small mb-12">
    Container Storage Period in Ports
  </div>

  <div class="box">

    <ng-container *ngIf="getNotificationMetric(vm, 'CN_EXTENDED_POO_STORAGE') as metrics">
      <div fxLayout="row">
        <div class="box-title w-200">Origin Port</div>
        <div class="box-title w-200">Shipping Line</div>
      </div>
      <!-- <div class="d-flex justify-content-between align-items-center">
        <button mat-button color="primary" (click)="currentMode = 'ADD_POO'" *ngIf="isAdmin">
          <mat-icon>add</mat-icon>
          <span>Customize a new port</span>
        </button>
      </div> -->

      <div *ngIf="currentMode === 'ADD_POO'">
        <dp-notification-metric-form-add [(currentMode)]="currentMode" [formData]="createDefaultFormData('CN_EXTENDED_POO_STORAGE')"
          [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}" (onDispatch)="onDispatch($event)">
        </dp-notification-metric-form-add>
        <mat-divider></mat-divider>
      </div>

      <ng-container *ngFor="let item of metrics.notificationMetrics; let i = index">
        <div *ngIf="!item.id || !deletedIds.has(item.id)">
          <mat-divider *ngIf="i !== 0"></mat-divider>
          <dp-notification-metric-form [(currentMode)]="currentMode" [formData]="item"
            [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}" (onDispatch)="onDispatch($event)">
          </dp-notification-metric-form>
        </div>
      </ng-container>

      <button mat-flat-button class="easy-button secondary" *ngIf="isAdmin" (click)="openCustomizePortDlg('ADD_POO', metrics)">CUSTOMIZE NEW CONFIGURATION</button>

      <mat-divider></mat-divider>
    </ng-container>

    <br>

    <ng-container *ngIf="getNotificationMetric(vm, 'CN_EXTENDED_POD_STORAGE') as metrics">
      <div class="box-title">Destination Port</div>
      <!-- <div class="d-flex justify-content-between align-items-center">
        <button mat-button color="primary" (click)="currentMode = 'ADD_POD'" *ngIf="isAdmin">
          <mat-icon>add</mat-icon>
          <span>Customize a new port</span>
        </button>
      </div> -->

      <div *ngIf="currentMode === 'ADD_POD'">
        <dp-notification-metric-form-add [(currentMode)]="currentMode" [formData]="createDefaultFormData('CN_EXTENDED_POD_STORAGE')"
          [options]="{notificationCode: metrics.notificationCode, ports: ports}" (onDispatch)="onDispatch($event)">
        </dp-notification-metric-form-add>
        <mat-divider></mat-divider>
      </div>

      <ng-container *ngFor="let item of metrics.notificationMetrics; let i = index">
        <div *ngIf="!item.id ||  !deletedIds.has(item.id)">
          <mat-divider *ngIf="i!==0"></mat-divider>
          <dp-notification-metric-form [(currentMode)]="currentMode" [formData]="item" (onDispatch)="onDispatch($event)"
            [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}">
          </dp-notification-metric-form>
        </div>
      </ng-container>
      <button mat-flat-button class="easy-button secondary" *ngIf="isAdmin" (click)="openCustomizePortDlg('ADD_POD', metrics)">CUSTOMIZE NEW CONFIGURATION</button>

    </ng-container>

  </div>

  <section *ngIf="getNotificationMetric(vm, 'ARRIVING_SOON') as metrics">
    <div class="heading-sub-small mt-40 mb-12">Hours Before Vessel Arrive at Destination Port</div>
    <div class="box easy-form">
      <mat-form-field appearance="outline" class="w-60">
        <mat-label>Hours</mat-label>
        <input matInput type="number" min="1" max="100" [value]="metrics.notificationMetrics[0].toleranceSecs / 3600"
          (change)="toleranceHoursChange($event, metrics.notificationMetrics[0])" [readonly]="!isAdmin">
      </mat-form-field>
    </div>
  </section>
</div>