<div fxLayout="row" fxLayoutAlign="space-between center">
  <div class="eventTitle">
    {{getTitle()}}
  </div>
  <div class="eventTime">
    {{notificationEvent.timeLabel}}
  </div>
</div>
<div [ngSwitch]="notificationEvent.notificationCode" class="description">
  <div *ngSwitchCase="'VESSEL_DEPARTURE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have departed their original port.' : ' has departed its original port.'}}
  </div>
  <div *ngSwitchCase="'ARRIVING_SOON'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of arrival is within 48 hours.
  </div>
  <div *ngSwitchCase="'RS_POD_RECEIVED'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    proof of delivery document has been received.
  </div>
  <div *ngSwitchCase="'VESSEL_ARRIVAL'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived to their destination port.' : ' has arrived to its destination port.'}}
  </div>
  <div *ngSwitchCase="'VESSEL_ARRIVAL_AIS'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived to their destination port as per AIS.' : ' has arrived to its destination port as per AIS.'}}
  </div>
  <div *ngSwitchCase="'CONTAINER_PICKED'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have been picked up at their origin location.' : ' has been picked up at its origin
    location.'}}
  </div>
  <div *ngSwitchCase="'CONTAINER_GATE_IN'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived at their origin port.' : ' has arrived at its origin port.'}}
  </div>
  <div *ngSwitchCase="'CONTAINER_GATE_OUT'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have departed from their destination port.' : ' has departed from its destination
    port.'}}
  </div>
  <div *ngSwitchCase="'CONTAINER_DELIVERED'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have been delivered to their destination location.' : ' has been delivered to its
    destination location.'}}
  </div>
  <div *ngSwitchCase="'OS_ARRIVED_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived early.' : ' has arrived early.'}}
  </div>
  <div *ngSwitchCase="'OS_ETD_CHANGE_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of departure changed to an earlier value.
  </div>
  <div *ngSwitchCase="'OS_ETD_CHANGE_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of departure changed to an later value.
  </div>
  <div *ngSwitchCase="'OS_ETA_CHANGE_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of arrival changed to a later value.
  </div>
  <div *ngSwitchCase="'CN_EXTENDED_POD_STORAGE'">
    Container<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    remained in destination port for too long after getting discharged from the vessel.
  </div>
  <div *ngSwitchCase="'CN_EXTENDED_POO_STORAGE'">
    Container<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    remained in origin port for too long after gating in.
  </div>
  <div *ngSwitchCase="'CN_EXTENDED_TRANSSHIPMENT_PORT_STORAGE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    remained in transshipment port for too long.
  </div>
  <div *ngSwitchCase="'OS_POD_CHANGE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have changed their destination ports.' : ' has changed its destination port.'}}
  </div>
  <div *ngSwitchCase="notificationCodes.AIR_CONTAINER_PICKED">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? 'have been picked up at their origin location.' : 'has been picked up at its origin
    location.'}}
  </div>
  <div *ngSwitchCase="notificationCodes.AIR_CONTAINER_GATE_IN">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? 'have arrived at their origin airport.' : 'has arrived at its origin airport.'}}
  </div>
  <div *ngSwitchCase="notificationCodes.AIR_CONTAINER_GATE_OUT">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? 'have' : 'has'}} been received from flight.
  </div>
  <div *ngSwitchCase="notificationCodes.AIR_CONTAINER_DELIVERED">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? 'have been delivered to their destination location.' : 'has been delivered to its
    destination location.'}}
  </div>
  <div *ngSwitchCase="notificationCodes.PLANE_ARRIVAL">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? 'have' : 'has'}} arrived at the destination airport.
  </div>
  <div *ngSwitchCase="notificationCodes.PLANE_DEPARTURE">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    departed from {{notificationEvent.notificationData.length > 1? 'their' : 'its'}} origin airport.
  </div>

  <!-- exceptions -->
  <div *ngSwitchCase="'OS_PROMISED_ETA_EARLIER_THAN_ESTIMATED_ETA_MISMATCH'">
    <ng-container *ngIf="notificationEvent.notificationData?.length">
      Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'OS_PROMISED_ETA_LATER_THAN_ESTIMATED_ETA_MISMATCH'">
    <ng-container *ngIf="notificationEvent.notificationData?.length">
      Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  
  <div *ngSwitchCase="'OS_PROMISED_ETD_EARLIER_THAN_ESTIMATED_ETD_MISMATCH'">
    <ng-container *ngIf="notificationEvent.notificationData?.length">
      Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'OS_PROMISED_ETD_LATER_THAN_ESTIMATED_ETD_MISMATCH'">
    <ng-container *ngIf="notificationEvent.notificationData?.length">
      Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  
  <div *ngSwitchCase="'TEMPERATURE_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{getBreachMessage(notificationEvent.notificationData[0].sensorName,notificationEvent.notificationData.length)}}
  </div>
  <div *ngSwitchCase="'PRODUCT_TEMPERATURE_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{getBreachMessage(notificationEvent.notificationData[0].sensorName,notificationEvent.notificationData.length)}}
  </div>
  <div *ngSwitchCase="'SHOCK_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{getBreachMessage(notificationEvent.notificationData[0].sensorName,notificationEvent.notificationData.length)}}
  </div>
  <div *ngSwitchCase="'LIGHT_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{getBreachMessage(notificationEvent.notificationData[0].sensorName,notificationEvent.notificationData.length)}}
  </div>
  <div *ngSwitchCase="'HUMIDITY_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{getBreachMessage(notificationEvent.notificationData[0].sensorName,notificationEvent.notificationData.length)}}
  </div>
  <div *ngSwitchCase="'OS_MISSED_DEPARTURE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have not departed yet, despite their estimated time of departure being passed.' : '
    has not departed yet, despite its estimated time of departure being passed.'}}
  </div>
  <div *ngSwitchCase="'OS_MISSED_ARRIVAL'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have not arrived yet.' : ' has not arrived yet.'}}
  </div>
  <div *ngSwitchCase="'OS_DEPARTED_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have departed late.' : ' has departed late.'}}
  </div>
  <div *ngSwitchCase="'OS_ARRIVED_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived late.' : ' has arrived late.'}}
  </div>
  <div *ngSwitchCase="'OS_ETA_CHANGE_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of arrival changed to an earlier value.
  </div>
  <div *ngSwitchCase="'RS_CREATED'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have been created.' : ' has been created.'}}
  </div>
  <div *ngSwitchCase="'RS_PICKED_AT_ORIGIN'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have been picked up at their origin locations.' : ' has been picked up at its origin
    location.'}}
  </div>
  <div *ngSwitchCase="'RS_DELIVERED'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have been delivered to their destination locations.' : ' has been delivered to its
    destination location.'}}
  </div>
  <div *ngSwitchCase="'OS_PREDICTED_ETA_ESTIMATED_ETA_MISMATCH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    predicted time of arrival varying from the ETA.
  </div>
  <div *ngSwitchCase="'OS_VESSEL_CHANGE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    vessel was changed.
  </div>
  <div *ngSwitchCase="'AS_PARTIAL_DELIVERY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    partially delivered.
  </div>
  <div *ngSwitchCase="'AS_ARRIVED_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived early.' : ' has arrived early.'}}
  </div>
  <div *ngSwitchCase="'AS_ETA_CHANGE_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of arrival changed to a later value.
  </div>
  <div *ngSwitchCase="'AS_ETA_CHANGE_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of arrival changed to an earlier value.
  </div>
  <div *ngSwitchCase="'AS_MISSED_ARRIVAL'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have not arrived yet.' : ' has not arrived yet.'}}
  </div>
  <div *ngSwitchCase="'AS_ARRIVED_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have arrived late.' : ' has arrived late.'}}
  </div>
  <div *ngSwitchCase="'AS_DEPARTED_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have departed late.' : ' has departed late.'}}
  </div>
  <div *ngSwitchCase="'AS_ETD_CHANGE_LATE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of departure changed to a later value.
  </div>
  <div *ngSwitchCase="'AS_ETD_CHANGE_EARLY'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    estimate time of departure changed to an earlier value.
  </div>
  <div *ngSwitchCase="'AS_MISSED_DEPARTURE'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' have not departed yet, despite their estimated time of departure being passed.' : '
    has not departed yet, despite its estimated time of departure being passed.'}}
  </div>

  <!-- Others tab -->
  <div *ngSwitchCase="'SHIPMENTS_SHARED_BY_PARTNER'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{(notificationEvent.notificationData.length > 1? ' have been shared by ' : ' has been shared by ') +
    notificationEvent.notificationData[0].sharedByOrganizationName}}
  </div>
  <div *ngSwitchCase="'DOCUMENT_UPLOADED'">
    New documents have been uploaded for shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
  </div>
  <div *ngSwitchCase="'USER_JOIN_ORG_REQ'">
    <ng-container *ngIf="notificationEvent.notificationData[0] as item">
      <div *ngIf="['VERIFIED', 'ACCEPTED'].includes(item.action)">
        {{item.userName + '(' + item.userEmail + ')' }} was accepted to join your organization.
      </div>
      <div *ngIf="item.action === 'DECLINED'">
        {{item.userName + '(' + item.userEmail + ')' }} was declined to join your organization.
      </div>
      <div *ngIf="!item.action">
        <p>
          {{item.userName + '(' + item.userEmail + ')' }} has requested to join your organization.
        </p>
        <div align="right">
          <button mat-button class="medium lightgray" [dpBusy]="item.decline" (click)="action('decline', item, 'ORG_REQ')">
            Decline
          </button>
          <button mat-button class="primary medium ml-20" [dpBusy]="item.accept" (click)="action('accept', item, 'ORG_REQ')">
            Accept
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngSwitchCase="'NEW_SHIPMENTS_FOUND'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{notificationEvent.notificationData.length > 1? ' were successfully tracked for the first time.' : ' was successfully tracked for the
    first time.'}}
  </div>
  <div *ngSwitchCase="'FILE_UPLOAD_SUMMARY'">
    {{buildUploadResult()}}
  </div>
  <div *ngSwitchCase="'PARTNERSHIP_REQ'">
    <ng-container *ngIf="notificationEvent.notificationData[0] as item">
      <div *ngIf="['VERIFIED', 'ACCEPTED', 'ACTIVE'].includes(item.action)">
        You now have an active partnership with {{item.orgName}} company.
      </div>
      <div *ngIf="item.action === 'DECLINED'">
        We have rejected a partnership request from company {{item.orgName}}.
      </div>
      <div *ngIf="item.action === 'resent'">
        The organization {{item.orgName}} has resent you their invitation to join as its partner.
      </div>
      <div *ngIf="!item.action">
        <p>
          {{item.orgName}} company has invited you to join as its partner.
        </p>
        <div align="right">
          <button mat-button class="medium lightgray" [dpBusy]="item.decline" (click)="action('decline', item,'PARTNERSHIP_REQ')">
            Decline
          </button>
          <button mat-button class="primary medium ml-20" [dpBusy]="item.accept" (click)="action('accept', item,'PARTNERSHIP_REQ')">
            Accept
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngSwitchCase="'NOTE_ADDED'">
    <ng-container *ngIf="notificationEvent.notificationData?.length > 0 && notificationEvent.notificationData[0] as userNote">
      <span class="text-bold">{{ userNote.noteCreatedBy }}</span> added a new note on {{userNote.orderNumber !== null ? 'order':'shipment'}} <dp-item-list
        [notificationEvent]="notificationEvent"></dp-item-list> :
      "{{ userNote.noteText | truncate:[50] }}"
    </ng-container>
    <ng-container *ngIf="!notificationEvent.notificationData?.length">
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'ORDER_NOTE_USER_TAGGED'">
    <ng-container *ngIf="notificationEvent.notificationData?.length > 0 && notificationEvent.notificationData[0] as userNote">
      <span class="text-bold">{{ userNote.noteCreatedBy }}</span> tagged you on order <dp-item-list
        [notificationEvent]="notificationEvent"></dp-item-list> :
      "{{ userNote.noteText | truncate:[50] }}"
    </ng-container>
    <ng-container *ngIf="!notificationEvent.notificationData?.length">
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'SHIPMENT_NOTE_USER_TAGGED'">
    <ng-container *ngIf="notificationEvent.notificationData?.length > 0 && notificationEvent.notificationData[0] as userNote">
      <span class="text-bold">{{ userNote.noteCreatedBy }}</span> tagged you on shipment <dp-item-list
        [notificationEvent]="notificationEvent"></dp-item-list> :
      "{{ userNote.noteText | truncate:[50] }}"
    </ng-container>
    <ng-container *ngIf="!notificationEvent.notificationData?.length">
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'ORDER_NOTE_EDITED'">
    <ng-container *ngIf="notificationEvent.notificationData && notificationEvent.notificationData[0] as userNote">
      <span class="text-bold">{{ userNote.noteCreatedBy }}</span> edited the comment you were mentioned in for order <dp-item-list
        [notificationEvent]="notificationEvent"></dp-item-list> :
      "{{ userNote.noteText | truncate:[50] }}"
    </ng-container>
    <ng-container *ngIf="!notificationEvent.notificationData?.length">
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'SHIPMENT_NOTE_EDITED'">
    <ng-container *ngIf="notificationEvent.notificationData?.length > 0 && notificationEvent.notificationData[0] as userNote">
      <span class="text-bold">{{ userNote.noteCreatedBy }}</span> edited the comment you were mentioned in for shipment <dp-item-list
        [notificationEvent]="notificationEvent"></dp-item-list> :
      "{{ userNote.noteText | truncate:[50] }}"
    </ng-container>
    <ng-container *ngIf="!notificationEvent.notificationData?.length">
      {{ notificationEvent.description }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'VESSEL_CROSSED_GEOFENCE_AREA'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{
      notificationEvent.notificationData.length > 1
        ? ' are detected to have crossed the geofence and are arriving at the port(s).'
        : ' is detected to have crossed the geofence and is arriving at the port.'
    }}
  </div>
  <div *ngSwitchCase="'SENSOR_BREACH'">
    Shipment<dp-item-list [notificationEvent]="notificationEvent"></dp-item-list>
    {{
      notificationEvent.notificationData.length > 1
        ? ' have encountered data breach for installed device.'
        : ' has encountered data breach for installed device.'
    }}
  </div>
  <div *ngSwitchDefault>
    Everything else case, {{notificationEvent.notificationCode}}
  </div>

</div>
