import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Params } from '@angular/router';
import { Pagination } from '@dp/types';
import { CSVDialogData, ConsentStatus, DeleteShipmentResponse, ShipmentType, Vessel } from 'app/shared/shared.model';
import { csvDlgData, DownloadShipmentsMode } from '@dp/components/csv-dlg/csv-dlg.model';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { ProductSubscribed } from '../../@dp/types/dp-model';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ShipmentsBaseApiService {
  shipmentV2 = environment.rootUrl + environment.urls.shipmentV2; //shipmentV2
  shipmentV2_API;

  constructor(protected http: HttpClient, private shipmentType: ShipmentType) {
    this.shipmentV2_API = {
      shipmentsURL: `${this.shipmentV2}/shipments`,
      getShipmentsURL: `${this.shipmentV2}/shipments?shipmentType=${this.shipmentType}`,
      shipmentFilterURL: `${this.shipmentV2}/filterDropDown?shipmentType=${this.shipmentType}`,
      getShipmentDetailURL: (id) => {
        return `${this.shipmentV2}/shipments/${id}/detail`;
      },
      getShipmentMapURL: (id) => {
        return `${this.shipmentV2}/shipments/${id}/map`;
      },
      getTagSuggestionsURL: () => {
        return `${environment.rootUrl}shipmentTags`;
      },
      getProductSuggestionsURL: () => `${environment.rootUrl}products`,
      //https://dev.azure.com/dpwhotfsonline/DTLP/_wiki/wikis/DTLP.wiki/474/road-container-shipment-page-reports-POD-download-dialogs?anchor=get-shipments-pod-files
      getShipmentsPodFilesURL: `${this.shipmentV2}/shipments/podFiles?shipmentType=${this.shipmentType}`,
      getManualMilestonesURL: (id) => {
        return `${this.shipmentV2}/shipments/${id}/milestones`;
      },
    };
  }

  getShipments(params, pagination?: Pagination): Observable<any> {
    if (pagination) {
      params._page = pagination.currentPage;
      params._limit = pagination.pageSize;
    }
    if (params._sort === 'flag') {
      params._sort = 'flagged';
    }
    if (this.shipmentType === ShipmentType.TRUCK_SHIPMENT && params._sort === 'bookingDate') {
      params._sort = 'created_at';
    }
    // params = new HttpParams().set('_page', pagination.toString()).set('_limit', pagination.pageSize.toString());
    return this.http.get<any[]>(this.shipmentV2_API.getShipmentsURL, { observe: 'response', params }).pipe(
      map((res) => {
        return {
          totalContainers: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
          containers: res.body,
        };
      }),
      catchError(this.handleError)
    );
  }

  getVessels(q: string): Observable<Vessel[]> {
    if (!q) {
      return of(null);
    }
    const url = environment.rootUrl + 'vessels/searchInfo?q=' + q;
    return this.http.get<Vessel[]>(url);
  }

  isVesselUnique(vessel: Vessel): Observable<ValidationErrors | null> {
    const url = environment.rootUrl + 'vessels/checkInfo?q=' + vessel.id;
    return this.http.get<ValidationErrors | null>(url).pipe(map((res) => (!res?.['valid'] ? { entityExists: true } : null)));
  }

  // GET: /filterDropDown?shipmentType=INTERMODAL_SHIPMENT
  getFilterDropdownValue(): Observable<any> {
    return this.http.get<any>(this.shipmentV2_API.shipmentFilterURL);
  }

  updateFlag(shipmentId: number, data: object): Observable<any> {
    return this.http.put(this.shipmentV2_API.shipmentsURL + '/' + shipmentId, data);
  }

  bulkUpdateFlag(request) {
    return this.http.put(this.shipmentV2_API.shipmentsURL, request);
  }
  toggleSubscription(payload: Object): Observable<any> {
    return this.http.put<any>(environment.rootUrl + 'notification/subscribe/shipment', payload);
  }

  bulkDeleteShipments(request): Observable<DeleteShipmentResponse> {
    const httpOptions = {
      headers: null,
      body: request,
    };
    return this.http.delete<DeleteShipmentResponse>(this.shipmentV2_API.shipmentsURL, httpOptions);
  }
  changeStatus(requestBody) {
    const changeStatusUrl = environment.rootUrl + 'shipmentV2/changeStatus';
    return this.http.put<any>(changeStatusUrl, requestBody).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  getDetail(shipmentId: string): Observable<any> {
    return this.http.get(this.shipmentV2_API.getShipmentDetailURL(shipmentId)).pipe(
      map((result) => {
        // result['uploadType'] = 'VESSEL_NUMBER'; //TODO: XXX
        if (result['shipmentTags']) {
          const tags = result['shipmentTags'].map((item) => {
            return {
              id: item.tagId,
              name: item.name,
            };
          });
          result['shipmentTags'] = tags;
        }
        return result;
      })
    );
  }

  getMap(shipmentId: string): Observable<MapSpace.ShipmentMap> {
    return this.http.get<MapSpace.ShipmentMap>(this.shipmentV2_API.getShipmentMapURL(shipmentId));
  }

  getProductSuggestions(): Observable<ProductSubscribed[]> {
    return this.http.get(this.shipmentV2_API.getProductSuggestionsURL()).pipe(map((result) => (result['data'] ? result['data'] : result)));
  }

  // export trucks
  exportTrucksCSV(queryParams?: Params): Observable<BlobPart> {
    let params = {
      maxPage: 'true', //*required
      _page: '', // no page, _limit
      _limit: '',
    };
    params = { ...queryParams, ...params }; //uncomment to filter container
    return this.http.get(this.shipmentV2_API.getShipmentsURL, { responseType: 'blob', params });
  }

  // private paramsSetup(dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode, dlgValues: csvDlgData) {
  //   let url = this.isPod(dlgData, downloadMode) ? this.shipmentV2_API.getShipmentsPodFilesURL : this.shipmentV2_API.getShipmentsURL;
  //   let defaultParams: Object = {
  //     _page: '',
  //     _limit: '',
  //   };

  //   let dlgParams = this.convertDlgValueToParams(dlgValues, dlgData);

  //   let params: Params = { ...dlgParams, ...dlgData.filters, ...defaultParams };

  //   if (downloadMode === DownloadShipmentsMode.singleFile) {
  //     params = { ...params, maxPage: true }; //*required to export as csv file instead of json
  //   } else {
  //     delete params.isProductDataRequired;
  //     delete params.exportFormat;
  //   }

  //   delete params.mode;
  //   return { url, params };
  // }

  // getPodDownloadUrl(dlgValues: csvDlgData, dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode): string {
  //   let { url, params }: { url: string; params: Params } = this.paramsSetup(dlgData, downloadMode, dlgValues);
  //   return this.shipmentV2_API.getShipmentsPodFilesURL + '&' + new HttpParams({ fromObject: params }).toString();
  // }

  shouldSkip(dlgValues: csvDlgData, dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode) {
    //pod dialog should skip csv
    if (dlgData.mode === 'pod' && downloadMode === DownloadShipmentsMode.singleFile) return true;

    //report dialog
    if (dlgData.mode === 'reports') {
      if (dlgData.shipmentType === ShipmentType.INTERMODAL_SHIPMENT) {
        return downloadMode === DownloadShipmentsMode.zip;
      } else {
        //truck report
        if (!dlgValues.includePODZipFile && downloadMode === DownloadShipmentsMode.zip) {
          return true;
        }
      }
    }

    return false;
  }

  deleteTag(shipmentId: number, tagId: number) {
    return this.http.delete(environment.rootUrl + `shipmentTags/${shipmentId}/tags/${tagId}`);
  }

  updateShipment(payload: Object): Observable<any> {
    return this.http.put(environment.rootUrl + `shipmentV2/edit`, payload);
  }

  reportShipmentError(payload: Object, commentOnly: boolean): Observable<Object> {
    const url = environment.rootUrl + (commentOnly ? environment.urls.reportErrorPublic : environment.urls.reportErrorPrivate);
    return this.http.post<Object>(url, payload);
  }

  checkConsentStatus(shipmentId: string): Observable<ConsentStatus> {
    return this.http.get<ConsentStatus>(environment.rootUrl + `shipmentV2/checkConsent/${shipmentId}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param httpError
   * @param result - optional value to return as the observable result
   */
  protected handleError(err?) {
    console.error('error:', err);
    // Let the app keep running by returning an empty result.
    const empty = {
      totalContainers: 0,
      containers: [],
      loading: false,
    };
    return of(empty);
  }

 
}
