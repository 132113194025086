<div class="page-title">
  Manage Users
</div>

<button class="user-button"
  style="margin:1rem; margin-bottom: 0rem;"
  (click)="inviteUsersClicked()">
  <div fxLayout="row"
    fxLayoutAlign="center center">
    <mat-icon>add_circle_outline</mat-icon>
    <div style="margin-left: .5rem;">Invite Users</div>
  </div>
</button>

<mat-card class='content-area'>

  <div> Users </div>

  <ng-container *ngFor="let theUser of allUsers">

    <mat-card *ngIf="theUser.state !== 'NEW' "
      class='user-panel'>
      <div fxLayout="row">
        <div class="profile-icon">
          {{ theUser.initials }}
        </div>

        <div>
          <div class="user-panel-item">
            <div style="width: 20rem; display: inline-block;">
              <a role="button"
                style="cursor: pointer;"
                (click)="showUserProfile(theUser)">
                {{ theUser.firstName +' '+ theUser.lastName }}
              </a>
            </div>

            <span *ngIf="theUser.isAdmin"
              style="margin-left: 2rem;"> Admin </span>
          </div>

          <div class="user-panel-item">
            Email: {{ theUser.userEmail }}
          </div>
        </div>

        <div style="margin-left: auto;">
          <button class="user-button"
            (click)="removeUserClicked(theUser, removeYesNoPrompt)">
            Remove
          </button>
        </div>
      </div>
    </mat-card>
  </ng-container>
</mat-card>


<mat-card class='content-area'>

  <div> Invited </div>

  <ng-container *ngFor="let theUser of allUsers">
    <mat-card *ngIf="theUser.state === 'NEW' "
      class='user-panel'>
      <div fxLayout="row">
        <div class="profile-icon">
        </div>

        <div>
          <div class="user-panel-item"
            style="margin-top: 1rem;">
            Email: {{ theUser.userEmail }}
          </div>
        </div>

        <div *ngIf="theUser.moreThanDayOld"
          style="margin-left: auto;">
          <button style="border-radius: .4rem; width: 10rem; height:3rem; background-color: lightblue; margin-top: .5rem;"
            (click)="resendClicked(theUser)">
            Resend invitation
          </button>
        </div>
      </div>
    </mat-card>
  </ng-container>
</mat-card>


<ng-template #removeYesNoPrompt
  let-data>

  <div style="margin:1rem;">
    <div> Are you sure you want to remove this user? </div>
  </div>

  <button mat-button
    style="width: 49%;"
    (click)="removeYesClicked(data.user)">
    Yes
  </button>

  <button mat-button
    style="width: 49%;"
    (click)="removeNoClicked()">
    No
  </button>

</ng-template>
