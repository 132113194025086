<div class="detail" *ngIf="payload; let data">
  
  <div class="row">
    <div class="col" *ngIf="data.transportJourneys.roadTransport.truckNumber">
      <label class="label">Vehicle #</label>
      <p class="text-bold">{{ data.transportJourneys.roadTransport.truckNumber }}</p>
    </div>
    <div class="col" *ngIf="data.transportJourneys.roadTransport.driverPhone">
      <label class="label">Driver Phone</label>
      <p class="text-bold">{{ data.transportJourneys.roadTransport.driverPhone }}</p>
    </div>
    <div class="col" *ngIf="data.status">
      <label class="label">Status</label>
      <p class="text-bold">{{ data.status }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col" *ngIf="data.referenceNumber">
      <label class="label">Shipment reference</label>
      <p class="text-bold">{{data.referenceNumber || '' }}</p>
    </div>
    <div class="col" *ngIf="data.transportJourneys.roadTransport.carrier">
      <label class="label">Carrier</label>
      <p class="text-bold">{{ data.transportJourneys.roadTransport.carrier }}</p>
    </div>
    <div class="col" *ngIf="data.emissions?.co2e?.value let v">
      <label class="label">Carbon Emissions</label>
      <p>
        <span class="value">{{ v | number : '1.0-2' }} {{data.emissions.co2e.unit || '' }}</span>
        <span>
          <dp-svg [src]="udsIconMap.infoExclamation" w="16" placement="bottom"
            tooltip="Total Port to Port GHG emission basis GLEC framework Default factors"></dp-svg>
        </span>
      </p>
    </div>
  </div>

</div>