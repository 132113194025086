import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { Note, NoteUser } from './shipment-note.component';

interface ShipmentNoteRequest {
  shipmentNoteId?: string;
  text: string;
  usersTagged: string[];
}

export interface NoteState {
  notes: Note[];
  editingNoteId: number;
}

export const _userNotesStateEmpty: NoteState = {
  notes: [],
  editingNoteId: null,
};


@Injectable({
  providedIn: 'root',
})
export class ShipmentNotesService {
  store: BehaviorSubject<NoteState> = new BehaviorSubject(_userNotesStateEmpty);
  state$ = this.store.asObservable();

  get currentState() {
    return this.store.getValue();
  }

  updateState(partial: object) {
    const newState = { ...this.currentState, ...partial };
    this.store.next(newState);
  }

  productsUrl = environment.rootUrl + 'orders';
  updateProductsUrl = environment.rootUrl + 'orders/edit';
  deleteProductsUrl = environment.rootUrl + 'orders/delete';

  constructor(private http: HttpClient) {}

  addNoteUrl(shipmentId: number, isShipmentPage:boolean) {
    const path = isShipmentPage ? 'shipmentV2/shipments' : 'order';
    return environment.rootUrl + `${path}/${shipmentId}/addNote`;
  }
  editNoteUrl(shipmentId: number, isShipmentPage:boolean) {
    const path = isShipmentPage ? 'shipmentV2/shipments' : 'order';
    return environment.rootUrl + `${path}/${shipmentId}/editNote`;
  }
  deleteNoteUrl(shipmentId: number, isShipmentPage:boolean) {
    const path = isShipmentPage ? 'shipmentV2/shipments' : 'order';
    return environment.rootUrl + `${path}/${shipmentId}/deleteNote`;
  }

  usersTaggingListUrl(shipmentId: number, isShipmentPage:boolean) {
    const path = isShipmentPage ? 'shipmentV2/shipments' : 'order';
    return environment.rootUrl + `${path}/${shipmentId}/usersTaggingList`;
  }

  /*
  Get users tagged list
  @param: shipmentId, request
  response: { userDetails: NoteUser[] }
  }*/
  getUsersTaggingList(shipmenId: number, isShipmentPage: boolean): Observable<NoteUser[]> {
    return this.http.get<{ userDetails: NoteUser[] }>(this.usersTaggingListUrl(shipmenId, isShipmentPage)).pipe(map((data) => data.userDetails));
  }

  /*
  @param: shipmentId, request
  }*/
  addShipmentNote(shipmenId: number, request: ShipmentNoteRequest, isShipmentPage: boolean): Observable<any> {
    return this.http.post(this.addNoteUrl(shipmenId, isShipmentPage), request);
  }

  /*
  @param: shipmentId, request
  */
  editShipmentNote(shipmenId: number, request: ShipmentNoteRequest, isShipmentPage: boolean): Observable<any> {
    if(isShipmentPage){
      return this.http.post(this.editNoteUrl(shipmenId, isShipmentPage), request);
    } else {
      return this.http.put(this.editNoteUrl(shipmenId, isShipmentPage), request);
    }
  }

  /*
  @param: shipmentId
  */
  deleteShipmentNote(shipmenId: number, shipmentNoteId: number, isShipmentPage: boolean) {
    if(isShipmentPage){
      return this.http.post(this.deleteNoteUrl(shipmenId, isShipmentPage), { shipmentNoteId });
    } else {
      const httpOptions = {
        headers: null,
        body: { 'orderNoteId': shipmentNoteId },
      };
      return this.http.delete(this.deleteNoteUrl(shipmenId, isShipmentPage), httpOptions);
    }
  }


}