import { Component, OnInit, Input } from '@angular/core';
import { LogoComponent } from '@dp/components/logo/logo.component';

@Component({
  selector: 'dp-tracking-result-mbl-bk',
  templateUrl: './tracking-result-mbl-bk.component.html',
  styleUrls: ['./tracking-result-mbl-bk.component.scss'],
})
export class TrackingResultMblBkComponent implements OnInit {
  @Input() payload;
  @Input() isBusy = false;

  shipmentInfo: any = {};

  ngOnInit(): void {
    if (this.payload.length > 0) {
      this.shipmentInfo = this.getShipmentInfo(this.payload[0], this.payload.length);
    }
  }

  getShipmentInfo(container, numOfContainers) {
    let shipmentInfo = {
      blNumber: container.blNumber,
      bkNumber: container.bkNumber,
      numOfContainers: numOfContainers,
    };

    if (container.transportJourneys.portToPort) {
      shipmentInfo = {
        ...shipmentInfo,
        ...this.getPortInfo(container.transportJourneys.portToPort),
      };
    }


    return shipmentInfo;
  }

  getPortInfo(port) {
    return {
      carrier: port.carrier,
      originHub: port.originHub,
      destinationHub: port.destinationHub,
      ata: port.portAta,
      atd: port.portAtd,
      eta: port.portEta,
      etd: port.portEtd,
    };
  }
}
