import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, NavigationEnd } from '@angular/router';
import { ROUTE_PATH_ACCESS } from 'app/user-access.constants';
import { UserAccessService } from './user-access.service';

@Injectable()
export class AccessPermissionGuard implements CanActivate {
  constructor(private router: Router, private userAccessService: UserAccessService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot | NavigationEnd) {
    const path = state.url;
    const accessKey = ROUTE_PATH_ACCESS[path];
    if (!accessKey || this.userAccessService.hasAccess(accessKey)) {
      return true;
    } else {
      this.router.navigate(['/welcome']);
    }
  }
}
