<div class='gs-container'>
  <div class="easy-form search-container no-hint">
    <mat-form-field appearance="outline" class='select-type filter-group fixed-width-xxl'>
      <mat-select class='select-filter' [value]='selectedSearchType' (selectionChange)="onSearchTypeSelectionChange($event)">
        <mat-option *ngFor="let option of searchType" [value]='option.key' class='global-search'>{{option.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="input-container">
      <dp-svg [src]="SvgMap.search" class="mr-8 ml-16" w='21'></dp-svg>
      <input
        matInput
        #search
        type='search'
        placeholder="Search"
        class='search-box'
        (click)="openMenu()"
        (ngModelChange)="onSearchKeyChange($event)"
        [ngModel]="searchKey"
        [matMenuTriggerFor]="searchMenu"
        #searchMenuTrigger="matMenuTrigger"
      />
      <mat-spinner diameter="15" class='spinner' *ngIf='facade.currentState.loading'></mat-spinner>
      <button *ngIf='!facade.currentState.loading' class='cancel' color="primary" mat-button [style.visibility]="searchKey.length ? 'visible': 'hidden'" matSuffix mat-icon-button aria-label="Clear"
              (click)="cancelClicked()">
        <mat-icon class="no-hover-effect">close</mat-icon>
      </button>
    </div>
    <mat-menu #searchMenu="matMenu" class='result' yPosition="below" xPosition="after">
      <div mat-menu-item *ngFor="let result of searchResult" (click)='navigateTo(result)'>
        <span class='search-value'>{{result.entityValue.substring(0, 13)}}{{result.entityValue.length > 13 ? '...' : ''}}</span>
        <span class='text'>&nbsp;in</span>
        <span class='entity-type'>&nbsp;{{getEntityType(result.entityType)}}</span>
      </div>
      <div mat-menu-item *ngIf='searchKey.length > 2 && searchResult.length === 0' [ngClass]="{'empty': searchKey.length > 2 && searchResult.length === 0}" disabled>
        <div class='main'>We couldn't find any matches!</div>
        <div class='sub'>Make sure value entered is precise</div>
      </div>
    </mat-menu>

  </div>
</div>
