import { Component, Input } from '@angular/core';
import { Address } from './address.model';

@Component({
  selector: 'dp-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address: Address;
  @Input() addressType: 'Origin' | 'Destination';
  constructor() {
  }
}
