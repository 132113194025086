<div fxLayout="column" class="fullHeight" fxFlexFill *ngIf="vm$ | async as vm">
  <div class="align-right mt-20 mb-24">
    <button mat-flat-button class="easy-button" (click)="createOrEditTeam(null)">CREATE NEW TEAM</button>
  </div>
  <ng-container *ngIf="!vm.loading">
    <div class="table-wrapper dp-white" fxFlex #scrollable dpPerfectScrollbar *ngIf="vm.items.length">
      <table mat-table #tableZone multiTemplateDataRows [dataSource]="vm.items"  class="uds-table alternate-rows colored-header">
        <ng-container matColumnDef="teamName">
          <th mat-header-cell *matHeaderCellDef  start="desc" style="width: 450px">Team Name</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.teamName"></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="teamDescription">
          <th mat-header-cell *matHeaderCellDef  style="width: 450px">Team Description</th>
          <td mat-cell *matCellDef="let element">
            <span [innerHTML]="element.teamDescription"></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalUsers">
          <th mat-header-cell *matHeaderCellDef  style="width: 450px">Total Members</th>
          <td mat-cell *matCellDef="let element">
            <span class="totalUsers" [innerHTML]="element.totalUsers"></span>
            <a class="ml-8 totalUsers" (click)="toggleMember(element)">{{element === vm.selectedItem? 'HIDE' : 'SHOW'}}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef  style="width: 450px">Created By</th>
          <td mat-cell *matCellDef="let element">
            <p [innerHTML]="element.createdByUserEmail"></p>
            <p>{{element.createdAt | localizedDate:'local-date-time' }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedBy">
          <th mat-header-cell *matHeaderCellDef  style="width: 450px">Last Updated By</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.updatedByUserEmail">
              <p [innerHTML]="element.updatedByUserEmail"></p>
              <p>{{element.updatedAt | localizedDate:'local-date-time' }}</p>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 100px"></th>
          <td mat-cell *matCellDef="let element">
            <span class="uds-icon">
              <a class="uds-icon-link" (click)="createOrEditTeam(element)" [tooltip]="'Modify this team'">
                <dp-svg [src]="SvgMap.edit" w="20"></dp-svg>
              </a>
              <a class="ml-8 uds-icon-link" (click)="deleteTeam(element)" [tooltip]="'Remove this team'">
                <dp-svg [src]="SvgMap.delete" w="20"></dp-svg>
              </a>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="members">
          <td mat-cell *matCellDef="let element; let i" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element===vm.selectedItem ? 'expanded' : 'collapsed'">
              <div class="element-members">
                <dp-team-users *ngIf="element===vm.selectedItem" [team]="element"></dp-team-users>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
          [class.expanded-row]="expandedElements.has(element)"
          [ngClass]="{'highlighted': vm.selectedItem && vm.selectedItem.id === element.id }"></tr>
        <tr mat-row *matRowDef="let row; columns: ['members']" class="members-row"></tr>
      </table>
    </div>
    <mat-paginator #paginator class="dp-white pl-40 pr-40 uds-margin-right uds-paginator" *ngIf="vm.items?.length > 0" [length]="vm.pagination.total"
      [pageSize]="vm.pagination?.pageSize" showFirstLastButtons fxFlex="none" [pageIndex]="vm.pagination?.currentPage"
      [pageSizeOptions]="vm.pagination?.pageSizeOptions" (page)='handlePageChange($event)'></mat-paginator>
    <div class="no-teams" *ngIf="vm.items.length === 0">
      <dp-no-result [src]="SvgMap.user_3" title="No teams created" p1="The teams you create using the" p2="“Create New Team” button, will be shown here."></dp-no-result>
    </div>
  </ng-container>

  <ng-container *ngIf="vm.loading">
    <div fxFlex #contentZone>
    </div>
  </ng-container>



</div>