import { Pipe, PipeTransform } from '@angular/core';

export enum SHIPMENT_STATUS {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  NEW = 'NEW', // backend sometimes provide NEW or PENDING, interchangeably
  PENDING = 'PENDING',
  UNTRACKABLE = 'UNTRACKABLE',
}

// @param: status: ACTIVE, COMPLETED, NEW, UNTRACKABLE
@Pipe({ name: 'shipmentStatus' })
export class ShipmentStatusPipe implements PipeTransform {
  transform(status: string): string {
    if (!status) {
      console.warn('invalid shipment status');
      return status;
    }

    let statusString = '';
    switch (status) {
      case SHIPMENT_STATUS.ACTIVE:
        statusString = 'Active';
        break;
      case SHIPMENT_STATUS.COMPLETED:
        statusString = 'Completed';
        break;
      case SHIPMENT_STATUS.NEW:
        statusString = 'Pending';
        break;
      case SHIPMENT_STATUS.PENDING:
        statusString = 'Pending';
        break;
      case SHIPMENT_STATUS.UNTRACKABLE:
        statusString = 'Untrackable';
        break;
      default:
        statusString = '';
    }

    return `<span class="${statusString}">${statusString}</span>`;
  }
}
