<div #ContainerRef class="contentBlock" *ngIf="sensorData">
  <div class="mt-12" fxLayout="row" fxLayoutAlign="center">
    <div class="tempDescription" fxFlex="30">
      <dp-data-point [record]="sensorData.recordsHistory.at(-1)" [leadingText]="''" [sensorType]="sensorName" [uom]="sensorData.uom"
        [maxThresholdValue]="sensorData.maxThresholdValue" [minThresholdValue]="sensorData.minThresholdValue"></dp-data-point>
    </div>
    <div class="tempDescription" fxFlex="30">
      <dp-data-range [sensorType]="sensorName" [uom]="sensorData.uom" [minThresholdValue]="sensorData.minThresholdValue"
        [maxThresholdValue]="sensorData.maxThresholdValue"></dp-data-range>
    </div>
    <div class="showLogBlock" (click)="showingLog = !showingLog" fxFlex="40">
      {{(showingLog? 'Hide ':'See ') + sensorName}} log
      <dp-svg [src]="showingLog ? SvgMap.arrowUp : SvgMap.arrowDown" w="16"></dp-svg>
    </div>
  </div>
  <div class="outer" *ngIf="showingLog && results">
    <div class="inner mr-8">
      <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="results"
        [yAxisTicks]="yAxisTicks" [yScaleMin]="yScaleMin" [trimXAxisTicks]="trimXAxisTicks" [yScaleMax]="yScaleMax"
        [showRefLines]="showRefLines" [referenceLines]="referenceLines" [roundDomains]="true" [xAxisTickFormatting]="dateFormatter"
        [rotateXAxisTicks]="true" [wrapTicks]="true" (select)="onSelect($event)">
        <ng-template #tooltipTemplate let-model="model">
          <div class="mt-12">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span class="label text-capitalize">{{sensorName}}</span>
              <span class="property {{showStatus(model.value)}}">{{Utility.getSensorText(model.value, sensorData.uom, sensorName)}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span class="label">Date & time</span>
              <span class="property">{{model.name}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="model.lat">
              <div class="label">Lat/Long</div>
              <div class="property">{{model.lat}}, {{model.long}}</div>
            </div>
          </div>
          <p class="tip">Click to copy data to the clipboard</p>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
          <div fxLayout="row" fxFlexFill>
            <dl fxFlex="55">
              <dt class="text-capitalize">{{sensorName}}</dt>
              <dd class="{{showStatus(model[0].value)}}">{{Utility.getSensorText(model[0].value, sensorData.uom, sensorName)}}</dd>
            </dl>
            <dl fxFlex="45">
              <dt>Date & time</dt>
              <dd class="ml-0">{{model[0].name}}</dd>
            </dl>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
  
  
  
</div>