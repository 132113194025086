import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BingMapsService } from 'app/shared/bing-maps.service';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { ShipmentMapService } from '../shipment-map.service';

@Component({
  selector: 'dp-shipment-bing-maps',
  templateUrl: './shipment-bing-maps.component.html',
  styleUrls: ['./shipment-bing-maps.component.scss'],
})
export class ShipmentBingMapsComponent implements OnChanges, OnDestroy {
  @Input() shipmentDetail: MapSpace.ShipmentMap;
  @Input() trackingType = '';
  @Input() maxZoom = 12;
  @Input() minZoom = 2;

  constructor(private shipmentMapService: ShipmentMapService, private bms: BingMapsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentDetail.previousValue !== changes.shipmentDetail.currentValue) {
      this.bms.clear();
      this.loadBingMaps();
    }
  }

  loadBingMaps() {
    this.bms.loadMap('#bingMapTracking', {
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    });

    const { routes } = this.shipmentDetail || {};
    routes && this.routesPolylineHandler(routes);

    const locations = this.getLocations();
    this.mapPushpinsAndInfoboxHandler(locations);
    // Set bounds to Bing Maps just wide enough to display all locations
    this.bms.setView({
      bounds: this.bms.locationRect(locations.map((loc) => loc.point)),
    });
  }

  mapPushpinsAndInfoboxHandler(locations: MapSpace.MapLocations[]) {
    locations.forEach((loc) => {
      const { point, icon, infoBoxData } = loc;
      const pushpin = this.bms.pushpin(point, {
        icon,
        anchor: this.bms.point(10, 7),
      });
      pushpin.metadata = infoBoxData;
      this.infoBoxHandler(pushpin, infoBoxData?.visible);
      this.bms.pushEntities(pushpin);
    });
  }

  getLocations() {
    const { journeyStops, shipmentLocation } = this.shipmentDetail || {};
    const locations: MapSpace.MapLocations[] =
      journeyStops?.map((stop) => ({
        point: this.bms.location(stop.lat, stop.lon),
        icon: this.shipmentMapService.getStopIcon(stop).url,
        infoBoxData: {
          title: stop.name,
          imgUrl: this.shipmentMapService.getLabelIcon(stop, this.trackingType),
          visible: stop?.displayNamePermanently,
        },
      })) || [];

    shipmentLocation?.lat &&
      shipmentLocation?.lon &&
      locations.push({
        point: this.bms.location(shipmentLocation.lat, shipmentLocation.lon),
        icon: this.shipmentMapService.currentLocation.url,
        infoBoxData: {
          title: this.shipmentMapService.getLocationLabel(shipmentLocation.locationLabel, shipmentLocation.locationTimestamp),
        },
      });
    return locations;
  }

  routesPolylineHandler(routes) {
    for (const key in routes) {
      routes[key]?.['pastRoute']?.length && this.pastRoutePolyline(routes[key]?.['pastRoute']);
      routes[key]?.['futureRoute']?.length && this.futureRoutePolyline(routes[key]?.['futureRoute']);
      routes[key]?.['missingRoute']?.length && this.missingRoutePolyline(routes[key]?.['missingRoute']);
    }
  }

  pastRoutePolyline(routes) {
    routes.forEach((route) => {
      const pastRoutes = route?.map((item) => this.bms.location(item.lat, item.lon));
      pastRoutes?.length &&
        this.bms.pushEntities(
          this.bms.polyline(pastRoutes, {
            strokeColor: '#073964',
            strokeThickness: 3,
          })
        );
    });
  }

  futureRoutePolyline(routes) {
    routes.forEach((route) => {
      const futureRoutes = route?.map((item) => this.bms.location(item.lat, item.lon));
      futureRoutes.length &&
        this.bms.pushEntities(
          this.bms.polyline(futureRoutes, {
            strokeColor: '#1976d2',
            strokeThickness: 3,
            strokeDashArray: [2, 2],
          })
        );
    });
  }

  missingRoutePolyline(routes) {
    routes.forEach((item) => {
      const missingRoutes = [];
      if (item.length === 2) {
        item?.forEach((val) => {
          missingRoutes.push(this.bms.location(val.lat, val.lon));
        });
      } else {
        [0, 1].forEach((key) => {
          const points = item.slice(key, key + 2);
          points.forEach((val) => {
            missingRoutes.push(this.bms.location(val.lat, val.lon));
          });
        });
      }
      if (missingRoutes.length) {
        const infobox = this.bms.setCustomInfobox(
          this.bms.map.getCenter(),
          `<span style="color: #FFA000;">This route hasn't been identified yet</span>`
        );
        this.bms.infoboxCloseHandler(infobox);
        const missingRoutesPolyline = this.bms.polyline(missingRoutes, {
          strokeColor: '#FFA000',
          strokeThickness: 3,
          strokeDashArray: [2, 2],
        });
        this.bms.pushEntities(missingRoutesPolyline);
        this.bms.addEventListener(missingRoutesPolyline, 'click', (e) => {
          infobox.setOptions({
            location: e.location,
            visible: true,
          });
        });
      }
    });
  }

  infoBoxHandler(pushpin, visible = false) {
    const content = `${pushpin.metadata.imgUrl ? `<img src="${pushpin.metadata.imgUrl}"/>` : ''}${pushpin.metadata.title}`;
    const infobox = this.bms.setCustomInfobox(pushpin.getLocation(), content, visible);
    this.bms.addEventListener(pushpin, 'click', () => {
      infobox.setOptions({ visible: true });
    });
    this.bms.infoboxCloseHandler(infobox);
  }

  ngOnDestroy(): void {
    this.bms.clear();
  }
}
