import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CrossFieldErrorMatcher } from '@dp/types';
import { PasswordStrengthValidator } from '@dp/validators/password-strength.validator';
import { UsersService } from '../../users/users.service';
import { finalize } from 'rxjs/operators';
import { UIService } from '../../../shared/ui.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { User } from 'app/auth/user.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'dp-edit-password-dlg',
  templateUrl: './edit-password-dlg.component.html',
  styleUrls: ['./edit-password-dlg.component.scss'],
})
export class EditPasswordDlg implements OnInit {
  @HostBinding('class.easy-dialog')
  f: UntypedFormGroup;
  isBusy = false;
  user: User;
  errorMatcher = new CrossFieldErrorMatcher();
  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private uiService: UIService,
    private dialogRef: MatDialogRef<EditPasswordDlg>
  ) {}

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
    this.f = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required, Validators.pattern(environment.regex.passwordValidation)]],
        password: ['', [Validators.required, PasswordStrengthValidator]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.passwordValidator,
      }
    );
  }
  passwordValidator(form: UntypedFormGroup) {
    let result = {};
    if (!form.touched || form.get('password').status === 'DISABLED') {
      return result;
    }
    if (
      form.get('password').value.length > 0 &&
      form.get('confirmPassword').value.length > 0 &&
      form.get('password').value !== form.get('confirmPassword').value
    ) {
      result = { ...result, passwordsDoNotMatch: true };
    }
    if (form.get('password').value === form.get('oldPassword').value) {
      result = { ...result, sameAsOld: true };
    }

    return result;
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = control.hasError('required')
      ? 'You must enter a value'
      : control.hasError('email')
      ? 'This is not a valid email address'
      : control.hasError('pattern')
      ? 'This is not a valid phone number'
      : control.hasError('emailExists')
      ? 'This email has registered with another user'
      : control.hasError('passwordsDoNotMatch')
      ? 'Passwords do not match'
      : control.hasError('sameAsOld')
      ? 'This new password is the same as your old password'
      : control.hasError('passwordStrength')
      ? control.errors['passwordStrength']
      : '';
    return msg;
  }

  oldPwdErrorMessage(control: UntypedFormControl) {
    return control.hasError('required') ? 'You must enter a value' : control.hasError('pattern') ? 'Incorrect password' : '';
  }

  submit() {
    this.isBusy = true;
    let oldPassword = this.f.get('oldPassword').value;
    let newPassword = this.f.get('password').value;
    this.usersService
      .changePasswordRequest(oldPassword, newPassword, this.user.userCode)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        () => {
          this.uiService.showSnackbar('Your password has been updated.', null, {
            duration: 3000,
            panelClass: 'accent',
          });
          this.dialogRef.close();
        },
        () => {
          this.uiService.showSnackbar('The current password you provided is not correct.', null, {
            duration: 3000,
            panelClass: 'warn',
          });
        }
      );
  }
}
