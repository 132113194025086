<span type="button" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<div mat-dialog-title>Edit Password</div>
<form [formGroup]="f" class="easy-form" mat-dialog-content (ngSubmit)="submit()" fxLayout="column" fxLayoutAlign="start center">
  <mat-form-field appearance="outline">
    <mat-label>Current password</mat-label>
    <input matInput type="password" formControlName="oldPassword" name="oldPassword" required autocomplete="current-password" />
    <mat-error *ngIf="f.controls['oldPassword'].invalid"> {{ oldPwdErrorMessage(f.controls['oldPassword']) }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>New password</mat-label>
    <input matInput type="password" formControlName="password" name="password" autocomplete="off" required />
    <mat-error *ngIf="f.controls['password'].invalid"> {{ getErrorMessage(f.controls['password']) }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Confirm new password</mat-label>
    <input matInput type="password" formControlName="confirmPassword" required autocomplete="new-password"
      [errorStateMatcher]="errorMatcher" />
    <mat-error *ngIf="f.controls['confirmPassword'].invalid">
      {{ getErrorMessage(f.controls['confirmPassword']) }}
    </mat-error>
    <mat-error *ngIf="f.hasError('passwordsDoNotMatch')"> Passwords do not match! </mat-error>
  </mat-form-field>

  <mat-error *ngIf="f.hasError('sameAsOld')"> Can not use the same password! </mat-error>

  <div class="dlg-row mt-20">
    <button type="submit" style="margin: 0 auto; display: block;" [dpBusy]="isBusy" [disabled]="!f.valid || f.pristine" mat-flat-button class="easy-button medium">
      RESET PASSWORD
    </button>
  </div>

  <!-- <div class="easy-list mt-20" fxLayout="row" fxLayoutGap="40px">
    <div fxLayout="column">
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">Cannot Use Old Password1</span>
      </div>
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">At least 8 & Max 16 Character</span>
      </div>
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">One Numeric Value</span>
      </div>
    </div>
    <div fxLayout="column">
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">One Lowercase Alphabet</span>
      </div>
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">One Uppercase Alphabet</span>
      </div>
      <div class="easy-list-item" fxFlex="50">
        <mat-icon class="easy-list-icon">done</mat-icon>
        <span class="easy-list-line">One Special Character - @!#&*</span>
      </div>
    </div>
  </div> -->
</form>