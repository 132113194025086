import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { StaticData } from '@dp/services/static-data.model';
import { StaticDataService } from '@dp/services/static-data.service';
import { ShippingLane } from '@dp/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressService } from 'app/shared';
import { TrackingService } from 'app/tracking/tracking.service';
import { environment } from 'environments/environment';
import { ReplaySubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UIService } from '../../../shared/ui.service';
import { atLeastOne } from '../../../../@dp/validators/dp-atLeastOne-validator';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { MatTableDataSource } from '@angular/material/table';
@UntilDestroy()
@Component({
  selector: 'dp-shipping-lane',
  templateUrl: './shipping-lane.component.html',
  styleUrls: ['./shipping-lane.component.scss'],
})
export class ShippingLaneComponent implements AfterViewInit {
  SvgMap = SvgMap;
  addingMode = false;
  isBusy = false;
  initializing = true;
  f: UntypedFormGroup;
  staticData: StaticData;
  carriers: string[] = [];
  ports: string[] = [];
  columnsToDisplay = ['carrier', 'origin', 'destination', 'delete'];
  tableColumnMap = {
    carrier: 'Carrier',
    origin: 'From',
    destination: 'To'
  }
  dataSource: MatTableDataSource<ShippingLane> = null;
  protected _onDestroy = new Subject<void>();

  public carrierFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public fromFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public toFilterCtrl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  @ViewChild('fromSelect', { static: true }) fromSelect: MatSelect;
  @ViewChild('toSelect', { static: true }) toSelect: MatSelect;
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredFromPorts: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredToPorts: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @Input() set shippingLanesSubscribed(data: ShippingLane[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  @Output() shippingLanesSubscribedChange = new EventEmitter<ShippingLane[]>();

  @ViewChild('contentZone') contentZone?: ElementRef;

  constructor(
    private progressService: ProgressService,
    private formBuilder: UntypedFormBuilder,
    private staticDataService: StaticDataService,
    private trackingService: TrackingService,
    private uiService: UIService
  ) {
    this.staticData = this.staticDataService.getStaticDataDirect();
  }

  ngAfterViewInit(): void {
    this.f = this.formBuilder.group(
      {
        carrier: [''],
        from: [''],
        to: [''],
      },
      { validator: atLeastOne(Validators.required, ['carrier', 'from', 'to']) }
    );

    this.carriers = this.staticData['carriers']
      .filter((carrier) => carrier.shipment_type !== MapSpace.ShipmentTypes.AIR_SHIPMENT)
      .map((carrier) => carrier.carrier_name);
    this.filteredCarriers.next(this.carriers.slice());
    // listen for search field value changes
    this.carrierFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCarriers();
    });

    let progress = this.progressService.showProgress(this.contentZone);
    this.initializing = true;

    this.trackingService
      .getLandPorts()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          // this.isLoading = false;
          this.progressService.detach(progress);
          this.initializing = false;
        })
      )
      .subscribe(
        (ports) => {
          this.ports = [
            ...this.staticData.ports.map((port) => `${port.port}, ${port.country_code}(${port.location_code})`),
            ...ports,
          ].sort();

          this.filteredCarriers.next(this.carriers);
          this.filteredFromPorts.next(null);
          this.filteredToPorts.next(null);
        },
        (err) => {
          let msg = 'Something went wrong. Please try again later.';
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  getError(name): boolean {
    return this.f.errors?.[name] && (this.f.controls['from'].touched || this.f.controls['to'].touched);
  }
  deleteCarrier(e: PointerEvent) {
    e.stopPropagation();
    this.f.get('carrier').reset();
  }

  onSubmit() {
    const from = this.f.get('from').value;
    const to = this.f.get('to').value;
    let carrier = this.f.get('carrier').value;
    let portOriginCode = this.getPortCode(from);
    let portDestinationCode = this.getPortCode(to);
    let payload: Object;

    if (this.isInvalidCase()) {
      this.uiService.showSnackbar(`You cannot select a combination of port and land locations`, null, {
        duration: environment.snackBarDuration.error,
        panelClass: 'warn',
      });
      return;
    } else if (!from && !to) {
      payload = {
        subscribed: true,
        shippingLanes: [
          {
            carrier,
            portOriginCode: '',
            portDestinationCode: '',
          },
        ],
      };
    } else if (portOriginCode || portDestinationCode) {
      payload = {
        subscribed: true,
        shippingLanes: [
          {
            carrier,
            ...(!!portOriginCode && { portOriginCode }),
            ...(!!portDestinationCode && { portDestinationCode }),
          },
        ],
      };
    } else if (!portOriginCode && !portDestinationCode) {
      payload = {
        subscribed: true,
        shippingLanes: [
          {
            carrier,
            landOrigin: from,
            landDestination: to,
          },
        ],
      };
    }

    this.isBusy = true;
    this.trackingService
      .addLane(payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isBusy = false;
          //todo: add result to the list
        })
      )
      .subscribe(
        (res) => {
          const shippingLane = res?.['shippingLanesSubscribed']?.[0];
          if (shippingLane && 'notificationSubscriptionId' in shippingLane) {
            this.dataSource.data = [...(this.dataSource.data || []), shippingLane];
            this.shippingLanesSubscribedChange.emit(this.dataSource.data);
          } else {
            let msg = 'This shipping lane is already in the list!';
            this.uiService.showSnackbar(msg, null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
          this.reset();
        },
        (err) => {
          let msg = 'Something went wrong. Please try again later.';
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  reset() {
    this.f.get('carrier').reset();
    this.f.get('from').reset();
    this.f.get('to').reset();
    this.filterCarriers();
    this.onSearchFrom('');
    this.onSearchTo('');
  }

  isInvalidCase(): boolean {
    const from = this.f.get('from').value;
    const to = this.f.get('to').value;
    let portOriginCode = this.getPortCode(from);
    let portDestinationCode = this.getPortCode(to);

    const res = (!!portOriginCode && !!to && !portDestinationCode) || (!portOriginCode && !!from && !!portDestinationCode);
    return res;
  }

  getPortCode(port: string): string {
    if (port?.includes('(')) {
      return port.substring(port.lastIndexOf('(') + 1, port.lastIndexOf(')'));
    } else {
      return '';
    }
  }

  getLocationSvg(port: string): string {
    if (!port) return null;

    return port?.includes('(') ? 'assets/svg/shipping_location_port.svg' : 'assets/svg/shipping_location_land.svg';
  }

  removeShippingLane(shippingLane: ShippingLane) {
    let progress = this.progressService.showProgress(this.contentZone);
    this.trackingService
      .deleteLane(shippingLane.notificationSubscriptionId)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.progressService.detach(progress))
      )
      .subscribe(
        (res) => {
          this.dataSource.data = this.dataSource.data.filter((lane) => lane.notificationSubscriptionId !== shippingLane.notificationSubscriptionId);
          this.shippingLanesSubscribedChange.emit(this.dataSource.data);
        },
        (err) => {}
      );
  }

  onSearchFrom(search) {
    const filteredItems = !search ? null : this.ports.filter((port) => port.search(new RegExp(search, 'i')) > -1);
    this.filteredFromPorts.next(filteredItems);
  }
  onSearchTo(search) {
    const filteredItems = !search ? null : this.ports.filter((port) => port.search(new RegExp(search, 'i')) > -1);
    this.filteredToPorts.next(filteredItems);
  }
  protected filterCarriers() {
    // get the search keyword
    let search = this.carrierFilterCtrl.value;
    const filteredCarriers = search
      ? this.carriers.filter((carrier) => carrier.search(new RegExp(search, 'i')) > -1)
      : this.carriers.slice();
    this.filteredCarriers.next(filteredCarriers);
  }
}
