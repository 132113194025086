<div class="title">Shipping Lanes</div>
<div class="subTitle">
  Get notified with specific carrier, origin, destination, port of origin, port of destination, and shipping lanes.
</div>
<div #contentZone class="contentZone">
  <div *ngIf="initializing"></div>

  <ng-container *ngIf="!initializing">
    <!-- <div *ngIf="!dataSource || !dataSource.data.length" class="flex">
      <div>
        <img style="margin: 20px auto; width: 30px; display: block" src="assets/images/svg/failed.svg" />
        <div class="no-data">No shipping lane subscribed yet!</div>
      </div>
    </div> -->

    <table
      mat-table
      [dataSource]="dataSource"
      *ngIf="dataSource?.data?.length || addingMode"
      multiTemplateDataRows
      matSort
      class="uds-table colored-header"
    >
      <ng-container *ngFor="let item of columnsToDisplay">
        <ng-container matColumnDef="{{ item }}">
          <th mat-header-cell *matHeaderCellDef>{{ tableColumnMap[item] }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="item !== 'delete'">
              <dp-svg w="21" h="15" [src]="element.type === 'INTERMODAL_SHIPMENT' ? 'assets/svg/shipping_location_port.svg' : 'assets/svg/shipping_location_land.svg'" *ngIf="element[item] && (item === 'origin' || item === 'destination')"></dp-svg>
              {{ element[item] }}
            </span>
            <button *ngIf="item === 'delete'" mat-icon-button (click)="removeShippingLane(element)">
              <dp-svg [src]="SvgMap.delete"></dp-svg>
            </button>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>

    <form [formGroup]="f" [hidden]="!addingMode" autocomplete="doNotAutoComplete" class="easy-form no-hint">
      <div class="fields" fxLayout="row">
        <mat-form-field appearance="outline" class="form-group carrier-field">
          <mat-label color="accent">Carrier</mat-label>
          <mat-select formControlName="carrier" placeholder="Carrier" #carrierSelect [disableOptionCentering]="true">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="carrierFilterCtrl"
                placeholderLabel="Search..."
                noEntriesFoundLabel="'no matching carrier found'"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">{{ carrier }}</mat-option>
          </mat-select>
          <button mat-button matSuffix aria-label="Clear" (click)="deleteCarrier($event)" class="deleteCarrier">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-group">
          <input
            matInput
            placeholder="From"
            aria-label="From"
            [matAutocomplete]="fromPort"
            formControlName="from"
            (keyup)="onSearchFrom($event.target.value)"
            name="off"
            autocomplete="off"
          />
          <!-- <mat-error>From and To entries at least one field is required!</mat-error> -->
          <mat-autocomplete #fromPort="matAutocomplete">
            <mat-option *ngFor="let port of filteredFromPorts | async" [value]="port">
              <img style="vertical-align: baseline; margin-right: 12px" [src]="getLocationSvg(port)" /><span>{{ port }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-group">
          <input
            matInput
            placeholder="To"
            aria-label="To"
            [matAutocomplete]="toPort"
            formControlName="to"
            (keyup)="onSearchTo($event.target.value)"
          />
          <mat-autocomplete #toPort="matAutocomplete">
            <mat-option *ngFor="let port of filteredToPorts | async" [value]="port">
              <img style="vertical-align: baseline; margin-right: 12px" [src]="getLocationSvg(port)" /><span>{{ port }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="btn-wrapper">
          <div class="save-btn" [class.disabled]="f.invalid" (click)="!f.invalid && onSubmit()">
            <button mat-icon-button [disabled]="f.invalid">
              <dp-svg [src]="SvgMap.blueTick"></dp-svg>
            </button>
            Save
          </div>
          <button mat-icon-button (click)="addingMode = false">
            <dp-svg [src]="SvgMap.delete"></dp-svg>
          </button>
        </div>
      </div>
      <!-- <mat-error style="font-size: 11px;" *ngIf="getError('atLeastOne')">From and To entries at least one field is required!</mat-error> -->
    </form>

    <button [disabled]="addingMode" mat-flat-button class="easy-button secondary medium add-shipping-btn" (click)="addingMode = !addingMode">
      {{dataSource?.data?.length ? 'Add another shipping lane' : 'Add a shipping lane'}}
    </button>
  </ng-container>
</div>
