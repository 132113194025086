import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvgMap } from 'app/shared';

@Component({
  selector: 'dp-rail-station-dialog',
  templateUrl: './rail-station-dialog.component.html',
  styleUrls: ['./rail-station-dialog.component.scss']
})
export class RailStationDialogComponent {
  SvgMap = SvgMap;
  railStationTalbe = null;
  constructor(
    private dialogRef: MatDialogRef<RailStationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    this.railStationTalbe = this.buildRailStationTable(this.data.step.data);
  }

  buildRailStationTable(data) {
    return {
      header: ['S No.', 'Stations Name', 'Crossed At', 'Duration'],
      rows: this.getRailData(data),
    }
  }

  getRailData(data) {
    return data.map((step, index) => {
      if (step.locationActions[0].duration === '1 hour ') {
        step.locationActions[0].duration = '1 hr';
      }
      const [datePart, timePart] = step.date.split(' ');
      const newDate= datePart + ' | ' + timePart;
      return [
        {
          data: (index + 1).toString() + '.',
        },
        {
          data: step.address,
        },
        {
          data: newDate,
        },
        {
          data: step.locationActions[0].duration,
        },
      ];
    });
  }
}
