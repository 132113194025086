import { DateAgoPipe } from '@dp/pipes/date-ago/date-ago.pipe';

export interface INotificationItem {
  code?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  isEnabled?: boolean;
  forAllShipments?: boolean;
  isBusy?: boolean;
  key?: string;
  allowChange?: boolean;
}

export interface NotificationEventShipment {
  shipmentId: string;
  containerId: string;
}

export enum NotificationCodes {
  VESSEL_DEPARTURE = 'VESSEL_DEPARTURE',
  ARRIVING_SOON = 'ARRIVING_SOON',
  // DESTINATION_CUSTOMS= 'DESTINATION_CUSTOMS',
  // TERMINAL_RELEASE= 'TERMINAL_RELEASE',
  VESSEL_ARRIVAL = 'VESSEL_ARRIVAL',
  CONTAINER_PICKED = 'CONTAINER_PICKED',
  CONTAINER_GATE_IN = 'CONTAINER_GATE_IN',
  CONTAINER_GATE_OUT = 'CONTAINER_GATE_OUT',
  CONTAINER_DELIVERED = 'CONTAINER_DELIVERED',
  //Road Shipment notification codes
  RS_CREATED = 'RS_CREATED',
  RS_PICKED_AT_ORIGIN = 'RS_PICKED_AT_ORIGIN',
  RS_OUT_FOR_DELIVERY = 'RS_OUT_FOR_DELIVERY',
  RS_DELIVERED = 'RS_DELIVERED',
  RS_POD_RECEIVED = 'RS_POD_RECEIVED',

  OS_ETD_CHANGE_LATE = 'OS_ETD_CHANGE_LATE', // ETD changed to a later date
  OS_ETD_CHANGE_EARLY = 'OS_ETD_CHANGE_EARLY', // ETD changed to an earlier date
  OS_DEPARTED_LATE = 'OS_DEPARTED_LATE', // ATD is larger than ETD
  OS_MISSED_DEPARTURE = 'OS_MISSED_DEPARTURE', // Current time is greater than ETD, ATD is null
  OS_ETA_CHANGE_LATE = 'OS_ETA_CHANGE_LATE', // ETA changed to a later date
  OS_ETA_CHANGE_EARLY = 'OS_ETA_CHANGE_EARLY', // ETA changed to an earlier date
  OS_ARRIVED_EARLY = 'OS_ARRIVED_EARLY', // ATA is smaller than ETA
  OS_ARRIVED_LATE = 'OS_ARRIVED_LATE', // ATA is larger than ETA
  OS_MISSED_ARRIVAL = 'OS_MISSED_ARRIVAL', // Current time is greater than ETA, ATA is null
  OS_POD_CHANGE = 'OS_POD_CHANGE', // Destination port changed
  CN_EXTENDED_POO_STORAGE = 'CN_EXTENDED_POO_STORAGE', // Container was stored in port for too long
  CN_EXTENDED_POD_STORAGE = 'CN_EXTENDED_POD_STORAGE', // Container was stored in port for too long
  FILE_UPLOAD_SUMMARY = 'FILE_UPLOAD_SUMMARY',
  NEW_SHIPMENTS_FOUND = 'NEW_SHIPMENTS_FOUND',
  AIR_CONTAINER_PICKED = 'AIR_CONTAINER_PICKED',
  AIR_CONTAINER_GATE_IN = 'AIR_CONTAINER_GATE_IN',
  PLANE_DEPARTURE = 'PLANE_DEPARTURE',
  PLANE_ARRIVAL = 'PLANE_ARRIVAL',
  AIR_CONTAINER_GATE_OUT = 'AIR_CONTAINER_GATE_OUT',
  AIR_CONTAINER_DELIVERED = 'AIR_CONTAINER_DELIVERED',
  VESSEL_CROSSED_GEOFENCE_AREA = 'VESSEL_CROSSED_GEOFENCE_AREA',
  SYSTEM_DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
}

export enum NotificationTypes {
  EVENT = 'event',
  EXCEPTION = 'exception',
  SYSTEM = 'system',
}
export interface NotificationData {
  containerNumber: string;
  referenceNumber: string;
  shipmentNumber: string;
  shipmentId: number;
  shippingMode: string;
}

export interface NotificationEvent {
  notificationCode?: NotificationCodes;
  description: string;
  title: string;
  notificationType: NotificationTypes;
  isRead: boolean;
  notificationInsertionTime: string;
  notificationData: NotificationData[];
  notificationIds?: number[];
  shipmentType: 'intermodal' | 'land' | 'air' | 'outsourced_logistics';

  //calculated properties
  timeLabel?: string;
}
export interface NotificationAccount {
  id?: number;
  read?: boolean;
  date: string;
  type: 'newuser-join' | 'partner-invite' | 'shipments-shared' | 'invoice-ready';
  // calculated items below
  title?: string;
  detail?: string;
  timeLabel?: string;
  invoice?: string;
}
export interface NotificationSystem {
  id?: number;
  read?: boolean;
  date: string;
  type: 'release';
  // calculated items below
  title?: string;
  detail?: string;
  timeLabel?: string;
  invoice?: string;
}

export interface NotificationCount {
  notification_code: NotificationCodes; //todo: change to "ARRIVING_SOON" | "OS_ARRIVED_LATE" ...
  notificationType: 'EVENT' | 'EXCEPTION';
  notification_insertion_time: string;
  count: number;
}

export interface NotificationCounter {
  event: number;
  exception: number;
  system: number;
}

export interface SubscribeResult {
  subscribed: boolean;
}
