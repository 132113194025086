import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'app/shared';
import { environment } from '../../../environments/environment';
import { CompanySettingService } from '../organization/company-setting/company-setting.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'dp-add-partner-dialog',
  templateUrl: './add-partner-dialog.component.html',
  styleUrls: ['./add-partner-dialog.component.scss'],
})
export class AddPartnerDialogComponent implements OnInit {
  @ViewChild('contentZone', { static: true }) contentZone?: ElementRef;
  companyId = '';
  addPartner;
  confirmBtnBusy = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPartnerDialogComponent>,
    private companySettingService: CompanySettingService,
    private uiService: UIService,
    private authService: AuthService,
  ) {
    this.addPartner = data.addPartner;
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.createPartner(this.companyId);
  }

  createPartner(organizationNumber) {
    this.confirmBtnBusy = true;
    this.companySettingService.createPartnership(organizationNumber)
      .subscribe((response: any) => {
        const companyAdded = response[0];
        if (companyAdded.status === 'error') {
          this.uiService.showSnackbar(companyAdded.description, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
          this.confirmBtnBusy = false;
        } else {
          delete companyAdded.description;
          this.data.partners.push(companyAdded);
          this.updatePartnershipAction();
          this.confirmBtnBusy = false;
          this.dialogRef.close(true);
        }
      }, error => {
        this.confirmBtnBusy = false;
        if (error.status === 400) {
          this.uiService.showSnackbar(`Company Id - ${organizationNumber} not found`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        } else {
          this.uiService.showSnackbar(`Failed to send invite to partner. Please try again later.`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      });
  }


  updatePartnershipAction(): string {
    return this.data.partners.map(partner => {
      switch (partner.status.toLowerCase()) {
        case 'pending': {
          partner.actionLabel = 'Resend';
          break;
        }
        case 'disabled': {
          partner.actionLabel = 'Enable';
          break;
        }
        case 'active': {
          partner.actionLabel = 'Disable';
          break;
        }
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(true);
  }

  /*getPartners(): Observable<any> {
    return this.partnersService.getPartners();
  }*/
}
