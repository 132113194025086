<div style="line-height: 28px;text-align: center;" class="accent">
  <span [hidden]="webUpdates.length === 1">
    <span>{{paginator?.pageIndex + 1}} of {{webUpdates.length}}</span>
    <mat-paginator [pageSize]="1" [hidePageSize]="true" [length]="webUpdates.length" (page)="navigateTo($event)"></mat-paginator>
  </span>
  <div class="center" style="display: inline-block;">
    <span class="data">{{data.content}}</span>
  </div>
  <div class="action" *ngIf="data.isDismissAvailable || data.isRemindMeLaterAvailable">
    <button mat-raised-button class="primary-800 medium ml-20" [dpBusy]="isDismissBusy" (click)="btnClicked(ButtonTypes.Dismiss)" *ngIf="data.isDismissAvailable" [disabled]="data.actionFinished">Dismiss</button>
    <button mat-raised-button class="primary-800 medium ml-20" [dpBusy]="isRemindMeBusy" (click)="btnClicked(ButtonTypes.RemindMe)" *ngIf="data.isRemindMeLaterAvailable" [disabled]="data.actionFinished">Remind me later</button>
  </div>
  <div class="action" *ngIf="!data.isDismissAvailable && !data.isRemindMeLaterAvailable && !data.actionFinished">
    <countdown [config]="{leftTime: leftTime, format: 's'}" (event)="countdownFinished($event)"></countdown><span>s</span>
  </div>
</div>