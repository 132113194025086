import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductSubscribed } from '@dp/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'app/navigation/header/notification/notification.service';
import { OceanShipmentsApiService } from 'app/ocean-shipments/ocean-shipments-api-service';
import { ProgressService, UIService } from 'app/shared';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { environment } from 'environments/environment';
import { finalize, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MdePopover, MdePopoverTrigger } from '@material-extended/mde';
import { MatSelect } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'dp-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  SvgMap = SvgMap;
  products: ProductSubscribed[] = [];
  productToAdd = null;
  noProduct = false;
  isBusy = false;
  addingMode = false;
  initializing = true;
  columnsToDisplay = ['productCode', 'description', 'hsCode', 'delete'];
  tableColumnMap = {
    productCode: 'Product #',
    description: 'Product Description',
    hsCode: 'HS Code',
  };
  dataSource: MatTableDataSource<ProductSubscribed> = null;
  @Input() set productsSubscribed(data: ProductSubscribed[]) {
    this.dataSource = new MatTableDataSource(data);
  }
  @Output() productsSubscribedChange = new EventEmitter<ProductSubscribed[]>();
  @ViewChild('contentZone') contentZone?: ElementRef;
  public productFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  public filteredProducts: ReplaySubject<ProductSubscribed[]> = new ReplaySubject<ProductSubscribed[]>(1);
  popoverTrigger: MdePopoverTrigger;
  @ViewChild(MdePopover, { static: true }) exceptionPopover: MdePopover;
  productNoOrCode: string;
  @ViewChild('productList') productList: MatSelect;

  constructor(
    private apiService: OceanShipmentsApiService,
    private notificationService: NotificationService,
    private uiService: UIService,
    private progressService: ProgressService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    let progress = this.progressService.showProgress(this.contentZone);
    this.apiService
      .getProductSuggestions()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          // this.isLoading = false;
          this.progressService.detach(progress);
          this.initializing = false;
        })
      )
      .subscribe(
        (res) => {
          this.products = res;
          if (!this.products?.length) {
            this.noProduct = true;
          }
          this.productListSetup();
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
      this.productFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterProducts();
      });
  }

  productListSetup() {
    this.products = this.products.filter((product) => {
      return !this.dataSource?.data?.find((item) => item.id === product.id);
    });
  }
  protected filterProducts() {
    // get the search keyword
    const search = this.productFilterCtrl.value;
    const filteredProducts = search
      ? this.products.filter((product) => {
        return (product.productNumber ? product.productNumber?.search(new RegExp(search, 'i')) > -1 : product.productCode?.search(new RegExp(search, 'i')) > -1)
      })
      : this.products;
    this.filteredProducts.next(filteredProducts);
  }
  subscribedAllProducts() {
    return this.dataSource?.data?.length > 0 && (!this.products || this.products.length === 0);
  }
  removeProduct(product: ProductSubscribed) {
    let progress = this.progressService.showProgress(this.contentZone);
    this.notificationService
      .removeProduct(product)
      .pipe(
        finalize(() => {
          this.progressService.detach(progress);
        })
      )
      .subscribe(
        (res) => {
          this.dataSource.data = this.dataSource?.data?.filter((item) => item.id !== product.id);
          // this.tagsSubscribedChange.emit(this.tagsSubscribed);
          if (!this.products) this.products = [];
          this.products.push(product);
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
    this.dataSource.data = this.dataSource?.data?.filter((p) => p.id !== product.id);
  }

  addProduct() {
    this.isBusy = true;
    this.notificationService
      .addProduct(this.productToAdd)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (res) => {
          const { id, HSCode, productDescription, productNumber, productCode, description, hsCode } = this.productToAdd;
          this.dataSource.data = [
            ...(this.dataSource.data || []),
            {
              id,
              productCode: productNumber || productCode,
              description: productDescription || description,
              hsCode: HSCode || hsCode,
            },
          ];
          this.productsSubscribedChange.emit(this.dataSource.data);
          this.products = this.products.filter((p) => p.id !== this.productToAdd.id);
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  setFirstItemSelected() {
    this.filteredProducts.next(this.products);
  }
  
  openPopover(target: MdePopoverTrigger, productNoOrCode: string, element) {
    this.popoverTrigger = target;
    this.productNoOrCode = element.offsetWidth < element.scrollWidth ? productNoOrCode : '';
    this.popoverTrigger.openPopover();
  }
  
  closePopover(target: MdePopoverTrigger) {
    target.closePopover();
  }

  toggleAddMode() {
    this.addingMode = !this.addingMode;
  }

  onAddProduct() {
    this.toggleAddMode();
    this.productList.open();
  }

  onProductSelection() {
    this.addProduct();
    this.toggleAddMode();
  }
}
