<section id="add-containers-dlg" class="advancedMode page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="uds-page-title" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
    {{getTitle()}}
  </div>

  <div class="content p-20 dp-white" fxLayout="column" fxLayoutAlign="start center">
    <div>
      <ng-container *ngIf="shipmentType === ShipmentType.INTERMODAL_SHIPMENT; else elseAdd">
        <div class="mt-40 add-text grey-color">
          Choose how you want to add your shipments
        </div>
        <p class="add-text mt-8">
          <b>Do You Know?</b>&nbsp;<span>Adding Carrier Name increases the Tracking Percentage by upto 50%</span>
        </p>
      </ng-container>
      <ng-template #elseAdd>
        <div class="heading-sub-regular mt-40 mb-40">
          Choose how you want to add your shipments
        </div>
      </ng-template>
      <div class="mt-40 mb-40">
        <button mat-flat-button class="easy-button mr-60" (click)="logEvent('ADD SHIPMENT ONE BY ONE');gotoAddShipmentOneByOne()" *ngIf="!isRoadShipment()">ADD SHIPMENT ONE BY ONE</button>
        <button mat-flat-button class="easy-button mr-60" *ngIf="isRoadShipment()" (click)="logEvent('ADD SINGLE SHIPMENT');gotoAddShipmentOneByOne()">ADD SINGLE SHIPMENT</button>
        <button mat-flat-button class="easy-button mr-60" (click)="logEvent('UPLOAD SHIPMENTS IN BULK');globalFileInput.click()">UPLOAD
          SHIPMENTS IN BULK</button>

        <ng-container *ngIf="shipmentType === ShipmentType.INTERMODAL_SHIPMENT">
          <button mat-flat-button class="easy-button mr-60"
            (click)="logEvent('UPDATE EXISTING SHIPMENTS');bulkUpdateFileInput.click()">UPDATE EXISTING SHIPMENTS</button>
        </ng-container>

        <ng-container *ngIf="shipmentType === ShipmentType.TRUCK_SHIPMENT">
          <button mat-flat-button class="easy-button mr-60"
            (click)="logEvent('UPDATE EXISTING SHIPMENTS');globalFileInputForRoad.click()">UPDATE
            EXISTING SHIPMENTS</button>
        </ng-container>

        <ng-container
          *ngIf="shipmentType === ShipmentType.INTERMODAL_SHIPMENT || shipmentType === ShipmentType.TRUCK_SHIPMENT || shipmentType === ShipmentType.AIR_SHIPMENT">
          <button mat-flat-button class="easy-button" (click)="logEvent('UPLOAD MILESTONES IN BULK');milestonesFileInput.click()">Add /
            Update
            Milestones</button>
        </ng-container>
      </div>

      <input #globalFileInputForRoad type="file" accept=".csv,.xlsx" id="globalFileSelect"
        (change)="handleFileInput($event.target.files, 'ROAD_BULK_UPDATE')" style="display: none;" />

      <input #globalFileInput type="file" accept=".csv,.xlsx" id="globalFileSelect" (change)="handleFileInput($event.target.files)"
        style="display: none;" />

      <input #bulkUpdateFileInput type="file" accept=".csv,.xlsx" id="globalFileSelect"
        (change)="handleBulkUpdateFileInput($event.target.files)" style="display: none;" />
      <input #milestonesFileInput type="file" accept=".csv,.xlsx" id="milestonesFileSelect"
        (change)="handleMilestonesFileInput($event.target.files)" (click)="$event.target.value = ''" style="display: none;" />
    </div>
  </div>

</section>