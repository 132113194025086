import { Component, OnInit, Input, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { StaticDataService } from '@dp/services/static-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MapSpace } from 'app/shipments2/shipments3.model';

export interface IFormData {
  id?: number;
  port: string;
  carrier: string;
  portCode: string;
  carrierScac: string;
  notificationCode: 'CN_EXTENDED_POO_STORAGE' | 'CN_EXTENDED_POD_STORAGE' | 'ARRIVING_SOON';
  defaultToleranceSecs: number;
  mediumSeverityToleranceSecs: number;
  highSeverityToleranceSecs: number;
  demurrageFreeDays: number;
  detentionFreeDays: number;
}

interface IOption {
  mode: 'CREATE' | 'UPDATE' | 'DELETE' | 'CLEAR' | '';
  notificationCode: string;
  ports: any[];
  carriers: any[];
}

export interface AddPortDialogData {
  currentMode: 'ADD_POO' | 'ADD_POD' | '';
  options: IOption;
  formData;
  IFormData;
  isDemurrageDetentionEnabled?: boolean;
  isDemurrageDetentionCostEnabled?: boolean;
}
@Component({
  selector: 'dp-notification-metric-form-add',
  templateUrl: './metric-form-add.component.html',
  styleUrls: ['./metric-form-add.component.scss'],
})
export class MetricFormAddComponent implements OnInit {
  @Input() currentMode: 'ADD_POO' | 'ADD_POD' | '';
  @Output() currentModeChange = new EventEmitter();

  // option configuration
  private _options: IOption = {
    mode: '',
    notificationCode: '',
    ports: [],
    carriers: [],
  };

  formGroup = this.fb.group(
    {
      id: [null],
      port: [null],
      carrier: [null],
      portCode: ['', [Validators.required]],
      carrierScac: [''],
      notificationCode: ['', [Validators.required]],
      toleranceSecs: [null],
      mediumSeverityToleranceSecs: [null, [Validators.min(1), Validators.max(60)]],
      highSeverityToleranceSecs: [null, [Validators.min(1), Validators.max(60)]],
      demurrageFreeDays: [null, [Validators.min(1)]],
      detentionFreeDays: [null, [Validators.min(1)]],
    },
    { validators: this.checkMediumHighValue }
  );

  /**
   *
   */

  checkMediumHighValue(c: AbstractControl) {
    const medium = c.get('mediumSeverityToleranceSecs').value;
    const high = c.get('highSeverityToleranceSecs').value;
    if ((medium && !high) || (high && !medium))
      return {
        invalidRange: true,
      };
    if (c.get('demurrageFreeDays').value || c.get('detentionFreeDays').value) {
      return null;
    }
    if (!medium && !high && !c.get('demurrageFreeDays').value && !c.get('detentionFreeDays').value)
      return {
        emptyForm: true,
      };
    if (medium >= high)
      return {
        invalidRange: true,
      };
  }

  get portCodeCtrl() {
    return this.formGroup.get('portCode');
  }

  onOptionChanged(portCode: string) {
    this.portCodeCtrl.setValue(portCode);
  }

  @Input()
  get options() {
    return this._options;
  }
  // set options(config: Partial<IOption>) {
  //   this._options = { ...this._options, ...config };
  // }

  formData: IFormData;
  // @Output() onDispatch = new EventEmitter();

  get mediumSeverityToleranceSecs() {
    return this.formGroup.get('mediumSeverityToleranceSecs').value;
  }

  get highSeverityToleranceSecs() {
    return this.formGroup.get('highSeverityToleranceSecs').value;
  }

  f: UntypedFormGroup;
  carriers: string[] = [];
  ports: string[] = [];
  protected _onDestroy = new Subject<void>();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  public carrierFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  constructor(
    private fb: UntypedFormBuilder,
    private staticDataService: StaticDataService,
    @Inject(MAT_DIALOG_DATA) public data: AddPortDialogData,
    private dialogRef: MatDialogRef<MetricFormAddComponent>
  ) {
    const staticData = this.staticDataService.getStaticDataDirect();

    this.ports = staticData.ports.map((item) => {
      return `${item.port}, ${item.country_code} (${item.location_code})`;
    });
    // this.filteredCarriers.next(this.carriers);
    this.carriers = staticData['carriers']
      .filter((carrier) => carrier.shipment_type === MapSpace.ShipmentTypes.INTERMODAL_SHIPMENT)
      .map((carrier) => carrier.carrier_name);
    this.filteredCarriers.next(this.carriers.slice());

    // listen for search field value changes
    this.carrierFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCarriers();
    });

    this.formData = data.formData;
    this._options = { ...this._options, ...data.options };
  }

  ngOnInit(): void {
    if (this.formData) this.formGroup.patchValue(this.formData);
  }

  getCarrierScac() {
    const staticData = this.staticDataService.getStaticDataDirect();
    const scac = staticData['carriers']
      .filter((carrier) => carrier.carrier_name === this.formGroup.value.carrier)
      .map((carrier) => carrier.carrier_scac);
    this.formGroup.value.carrierScac = scac.length > 0 ? scac[0] : null;
  }
  dispatchAction(action: string) {
    this.getCarrierScac();
    console.log('action: ', action);
    let emitObject = {
      options: { ...this.options, mode: action },
      data: this.formGroup.value,
    };
    // this.onDispatch.emit(emitObject);
    this.dialogRef.close(emitObject);
  }

  // For CN_EXTENDED_POO_STORAGE and CN_EXTENDED_POD_STORAGE code
  isFormValid() {
    return this.formGroup.valid;
  }

  isDefaultMetric(formGroup): boolean {
    return !formGroup.value.id && !formGroup.value.portCode;
  }

  get mediumSeverityToleranceSecsCtrl() {
    return this.formGroup.get('mediumSeverityToleranceSecs');
  }

  get highSeverityToleranceSecsCtrl() {
    return this.formGroup.get('highSeverityToleranceSecs');
  }
  get demurrageFreeDaysCtrl() {
    return this.formGroup.get('demurrageFreeDays');
  }
  get detentionFreeDaysCtrl() {
    return this.formGroup.get('detentionFreeDays');
  }
  deleteCarrier(e: PointerEvent) {
    e.stopPropagation();
    this.formGroup.get('carrier').reset();
  }
  protected filterCarriers() {
    // get the search keyword
    let search = this.carrierFilterCtrl.value;
    const filteredCarriers = search
      ? this.carriers.filter((carrier) => carrier.search(new RegExp(search, 'i')) > -1)
      : this.carriers.slice();
    this.filteredCarriers.next(filteredCarriers);
  }
}
