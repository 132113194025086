<flx-text-input-autocomplete [textInputElement]="textInputElement" [menuTemplate]="menuTemplate" [triggerCharacter]="triggerCharacter"
  [searchRegexp]="searchRegexp" [closeMenuOnBlur]="closeMenuOnBlur" [getChoiceLabel]="getChoiceLabel" [selectedChoices]="selectedChoices"
  (search)="search.emit($event)" (choiceSelected)="choiceSelected.emit($event)" (choiceRemoved)="choiceRemoved.emit($event)"
  (selectedChoicesChange)="onSelectedChoicesChange($event)" (menuShow)="menuShow.emit()" (menuHide)="menuHide.emit()"
  [menuPosition]="position">
</flx-text-input-autocomplete>

<flx-text-input-highlight [textInputElement]="textInputElement" [textInputValue]="textInputValue" [tags]="selectedCwis"
  [tagCssClass]="tagCssClass" (tagClick)="tagClick.emit($event)" (tagMouseEnter)="tagMouseEnter.emit($event)"
  (tagMouseLeave)="tagMouseLeave.emit($event)">
</flx-text-input-highlight>