import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dp-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
  @Input() src: string;
  @Input() w = 18;
  @Input() h;
}
