import {
  Container,
  PageData,
  ContainerFilters,
  ContainerDetail,
  ContainerEvent,
  ContainerCalendarEvents,
  ContainerAlert,
  ALERT_STATE,
} from '../types/containers.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from 'app/shared/message.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { FILTER_FLAG, TRACKING_STATE } from '@dp/types/dp-model';
import moment from 'moment';
import { IContainerUpload, ContainerUploadDetail } from '../../app/upload-history/models/containers.model';
import { AddContainerByTypes, TemplateFileExtensionTypes } from 'app/navigation/nav.model';
import { IMilestoneUploadResult } from 'app/new-ocean-shipment/ocean-tracking/ocean-tracking.model';

@Injectable({
  providedIn: 'root',
})
export class ContainersService {
  private TemplateSensitivityUrl = environment.rootUrl + environment.urls.template_sensitivity;
  private FilesUploadUrl = environment.rootUrl + environment.urls.files_upload;
  private ContainerTemplateUrl = environment.rootUrl + environment.urls.container_template;
  private milestoneUploadsUrl = environment.rootUrl + environment.urls.milestoneUploads;

  constructor(private http: HttpClient, private messageService: MessageService) {}

  uploadContainerFile(file: FormData): Observable<any> {
    return this.http.post(this.FilesUploadUrl, file);
  }

  checkTemplateSensitivity(file: FormData): Observable<any> {
    return this.http.post(this.TemplateSensitivityUrl, file);
  }

  updateContainerFile(file: FormData): Observable<any> {
    return this.http.put(this.FilesUploadUrl, file);
  }

  //todo: rename downloadTemplateFile2 to  downloadTemplateFile
  downloadTemplateFile2(
    type: AddContainerByTypes,
    fileExtension: TemplateFileExtensionTypes = TemplateFileExtensionTypes.csv
  ): Observable<BlobPart> {
    const template =
      type === AddContainerByTypes.ByContainer
        ? 'CONTAINER_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByMBL
        ? 'UPLOAD_BY_MBL_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByBooking
        ? 'UPLOAD_BY_BOOKING_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByVessel
        ? 'VESSEL_UPLOAD_TEMPLATE'
        : type === AddContainerByTypes.ByAir
        ? 'UPLOAD_BY_AWB_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByRoad
        ? 'VEHICLE_NUMBER_UPLOAD_TEMPLATE'
        : type === AddContainerByTypes.ByBulk
        ? 'BULK_UPDATE_TEMPLATE'
        : type === AddContainerByTypes.ByOL
        ? 'UPLOAD_BY_LOGISTICS_ID_TEMPLATE'
        : type === AddContainerByTypes.ByRoadUpdate
        ? 'ROAD_BULK_UPDATE_TEMPLATE'
        : type === AddContainerByTypes.ByMileStone
        ? 'BULK_MILESTONE_UPDATE_TEMPLATE'
        : '';

    if (!template) {
      return throwError('Wrong parameter');
    }
    const ext = fileExtension === TemplateFileExtensionTypes.csv ? 'csv' : 'xlsx';

    return this.http.get(this.ContainerTemplateUrl + `${template}?extension=${ext}`, { responseType: 'blob' });
  }

  uploadMilestoneFile(file: FormData): Observable<IMilestoneUploadResult> {
    return this.http.put<IMilestoneUploadResult>(this.milestoneUploadsUrl, file);
  }
}
