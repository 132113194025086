import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Vessel } from 'app/shared/shared.model';
import { ShipmentUploadTypes } from '@dp/types';


@Injectable({
  providedIn: 'root',
})
export class DpEntityExistValidator {
  url: string;
  groupUrl: string;
  vesselUrl: string;
  constructor(private http: HttpClient) {
    this.url = environment.rootUrl + environment.urls.containerExist;
    this.groupUrl = environment.rootUrl + environment.urls.groupUploadValidate;
    this.vesselUrl = environment.rootUrl + 'vessels/checkInfo';
  }
  entityExists(entity: string, containerType: ShipmentUploadTypes, mode = '', hbl_number = ''): Observable<Object> {
    if (containerType === ShipmentUploadTypes.CONTAINER) {
      const containerUrl = `${this.url}?container=${entity}&shipping_mode=${mode || ''}&hbl_number=${hbl_number || ''}`;
      return this.http.get<Object>(containerUrl);
    } else if (containerType === ShipmentUploadTypes.VESSEL) {
      const containerUrl = `${this.vesselUrl}?q=${entity}`;
      return this.http.get<Object>(containerUrl);
    } else {
      const params = {
        groupValue: entity,
        groupKey: containerType === ShipmentUploadTypes.BK ? 'bkNumber' : 'blNumber',
      };
      return this.http.get<Object>(this.groupUrl, { params });
    }
  }

  //validate by ContainerType
  entityExistValidator(shipmentUploadType: ShipmentUploadTypes): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      let val = shipmentUploadType === ShipmentUploadTypes.VESSEL ? (control.value as Vessel).id : control.value;
      return this.entityExists(val, shipmentUploadType).pipe(
        map((res) => {
          console.log(res);
          return !res?.['valid'] ? { entityExists: true } : null;
        })
      );
    };
  }
}
