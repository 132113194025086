import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Used for logging Errors to a backend report. Will probably be used for other types of logging as well.
 * We haven't decided which service we want to use to track errors, so we won't send to the backend yet. 
 * @author Charles Skinner <charles.skinner@dpworld.com>
 */
export class LogService {

  constructor() { }

  logError(message: string) {
    console.error(message);

    // TODO: call to backend logging
  }
}
