import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

export enum SearchEntityType {
  CONTAINER_NUMBER = 'Container No.',
  REFERENCE_NUMBER = 'Shipment Reference No.',
  BL_NUMBER = 'MBL / AWB',
  HBL_NUMBER = 'HBL / HABL',
  ORDER_NUMBER = 'Purchase Order'
}

export enum ResponseEntityType {
  INTERMODAL_SHIPMENT = 'Ocean Shipment',
  TRUCK_SHIPMENT = 'Road Shipment',
  AIR_SHIPMENT = 'Air Shipment',
  ORDER = 'Purchase Order'
}

export interface ISearchResult {
  entityId: number;
  entityValue: string;
  isGroup: boolean;
  entityType: ResponseEntityType;
}

export interface IState {
  loading: boolean;
  searchKey: string;
  searchType: string;
  searchResult: ISearchResult[];
}

export const initialState: IState = {
  loading: false,
  searchKey: '',
  searchType: Object.keys(SearchEntityType).find(entityType => entityType === 'ORDER_NUMBER'),
  searchResult: []
};

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchFacade {
  private store = new BehaviorSubject<IState>(initialState);
  private state$ = this.store.asObservable();

  globalSearchApi = `${environment.rootUrl}search?`;

  loading$ = this.state$.pipe(
    map((state) => state.loading),
    distinctUntilChanged()
  );
  searchKey$ = this.state$.pipe(
    map((state) => state.searchKey),
    distinctUntilChanged()
  );
  searchType$ = this.state$.pipe(
    map((state) => state.searchType),
    distinctUntilChanged()
  );
  searchResult$ = this.state$.pipe(
    map((state) => state.searchResult),
    distinctUntilChanged()
  );
  vm$: Observable<IState> = combineLatest([
    this.loading$,
    this.searchKey$,
    this.searchType$,
    this.searchResult$
  ]).pipe(
    map(
      ([
         loading,
         searchKey,
         searchType,
         searchResult
       ]) => {
        return {
          loading,
          searchKey,
          searchType,
          searchResult
        };
      }
    )
  );

  constructor(protected http: HttpClient) {
    combineLatest(this.searchType$, this.searchKey$)
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter(([searchType, searchKey]) => {
          return searchKey?.length > 2;
        }),
        switchMap(([searchType, searckKey]) => {
          this.updateState({loading: true});
          return this.getSearchResult(searchType, searckKey);
        })
      )
      .subscribe(searchResult => {
        this.updateState({ searchResult, loading: false });
      });
  }

  get currentState() {
    return this.store.getValue();
  }

  updateState(partial: object) {
    const newState = { ...this.currentState, ...partial };
    this.store.next(newState);
  }

  getSearchResult(entityType, entityValue): Observable<ISearchResult[]> {
    return this.http.get<ISearchResult[]>(`${this.globalSearchApi}entityType=${entityType}&entityText=${entityValue}`).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

}

