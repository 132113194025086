<div id="signup" class="intro-wrapper inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div class="intro-right-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <form class="intro-right easy-form" [hidden]="waitingForVerify" [formGroup]="f">
      <div class="title">Please enter your work email</div>
      <p class="dp-body-2">Please provide your work email address so that we can configure your company.</p>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-16">
        <mat-form-field appearance="outline" class="emailField w-304">
          <mat-label>Your work email</mat-label>
          <input type="email" matInput placeholder="name@company.com" ngModel name="email" email formControlName="email" required>
          <mat-error *ngIf="f.controls['email'].invalid">{{getErrorMessage(f.controls['email'])}}</mat-error>
        </mat-form-field>
        <!-- <img class="orgLogo" src="//logo.clearbit.com/{{orgDomain}}?size=35" *ngIf="showLogo"> -->
      </div>
      <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'"
        formControlName="recaptcha"></ngx-recaptcha2>

      <button type="submit" [dpBusy]="isFormBusy" mat-flat-button [disabled]="f.invalid" class="w-304 mt-20 easy-button" (click)="submit()">
        Submit
      </button>

      <p class="dp-body-2">Already have an account? <a href="/login">Login here</a>.</p>
    </form>
    <div class="intro-right" *ngIf="waitingForVerify" [@animate]="{value:'*',params:{delay:'100ms',x:'25px'}}">
      <div class="title">Please verify your email</div>
      <p class="dp-body-2">We have sent you an email to confirm your address. Once you verify your email address, you can get started
        with CARGOES Flow!</p>
      <p class="dp-body-2" [hidden]="resendUsed">Didn't receive an email? We can resend it to your email: <b>{{email}}</b></p>

      <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'"
        [(ngModel)]="recaptcha" [hidden]="resendUsed"></ngx-recaptcha2>

      <button mat-flat-button class="submit-button easy-button" [disabled]="!recaptcha" [dpBusy]="resendUserBusy" (click)="resend()"
        [hidden]="resendUsed">Resend</button>

    </div>
  </div>

</div>