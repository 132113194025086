import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { of } from 'rxjs';
import { environment } from 'environments/environment';
import { FileExtensionType } from './models/response';
import { ShipmentType } from 'app/shared/shared.model';
import { TrackContainer } from 'app/welcome/landingPage/track-container';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DpWidgetApiService {
  _reCaptcha: string;
  tracking_URI = environment.rootUrl + 'public_tracking/track';
  carrierName_URI = environment.rootUrl + 'public_tracking/carrier';

  expediteContainers_URI = environment.rootUrl + 'public_tracking/expediteContainers';
  downloadTemplate_URI = environment.rootUrl + 'files/download-template?templateName=EXPEDITE_FORM_TEMPLATE';

  constructor(private http: HttpClient) {}

  get reCaptcha() {
    return this._reCaptcha;
  }

  set reCaptcha(value) {
    this._reCaptcha = value;
  }

  searchTrackingNumber(trackingNumber: string, shipmentType: ShipmentType | string, carrierName?: string, source?: string) {
    let params = new HttpParams().set('trackingNumber', trackingNumber);

    if (shipmentType) params = params.set('shipmentType', shipmentType);
    if (carrierName && carrierName !== 'Any carrier') params = params.set('carrierName', carrierName);
    if (source) params = params.set('source', source);

    return this.http.get(this.tracking_URI, { params });
  }

  searchContainer(trackContainerModel: TrackContainer) {
    return this.http.post(this.tracking_URI, trackContainerModel);
  }

  getCarrierName(trackingNumber: string, shipmentType?: string) {
    trackingNumber = trackingNumber.trim();
    let params = {
      params: {
        trackingNumber,
        shipmentType,
      },
    };
    return this.http.get(this.carrierName_URI, params);
  }

  searchTrackingNumberDetail(shipmentId: string, shipmentGroupUploadId: string, trackingNumber: string) {
    const params = new HttpParams().set('trackingNumber', trackingNumber).set('shipmentId', shipmentId).set('shipmentGroupUploadId', shipmentGroupUploadId);
    return this.http.get(this.tracking_URI, { params });
  }

  submitExpediteForm(formData) {
    // return of({ success: true });
    return this.http.post(this.expediteContainers_URI, formData);
  }

  downloadTemplateFile(fileExtension: FileExtensionType) {
    return this.http.get(this.downloadTemplate_URI + `&extension=${fileExtension}`, { responseType: 'blob' });
  }
}
