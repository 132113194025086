<form class="full-width easy-form">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" required placeholder={{placeholder}}>
    <mat-error *ngIf="control.hasError('required')">This field is required.</mat-error>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelectedChange($event)">
      <mat-option *ngFor="let item of filteredOptions | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>