// all small, useful classes

import { ErrorStateMatcher } from "@angular/material/core";
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

/** Error when the parent is invalid */
export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private errors?: string[]) {}
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && control.touched && form?.invalid && (!this.errors || this.errors.some(error => form.hasError(error)));
  }
}
