import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UtilsService } from '@dp/services/utils.service';
import { Utility } from '@dp/utilities';
import { LineChartComponent } from '@swimlane/ngx-charts';
import { SvgMap } from '../svg/uds-svg-map';
import { SensorData, SensorTypes } from '@dp/types/dp-model';
import { Clipboard } from '@angular/cdk/clipboard';
import { UIService } from 'app/shared/ui.service';

@Component({
  selector: 'dp-temperature-humidity',
  templateUrl: './temperature-humidity.component.html',
  styleUrls: ['./temperature-humidity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemperatureHumidityComponent implements OnInit {
  SvgMap = SvgMap;
  Utility = Utility;
  showingLog = true;
  @ViewChild(LineChartComponent) lineChart: LineChartComponent;

  @Input() sensorData: SensorData;
  @Input() sensorName: SensorTypes;

  results: object[] = null;

  //view: any[] = [550, 300];
  //multi: any[];

  colorScheme = {
    domain: ['#377531', '#F57600', '#3C92EF'],
  };

  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = false;
  xAxisLabel = 'time';
  timeline = false;
  yAxisLabel: string;
  xAxisTicks = [];
  yScaleMin = Number.MAX_VALUE;
  yScaleMax = Number.MIN_VALUE;
  showRefLines = true;
  trimXAxisTicks = false;
  referenceLines = [];
  startAt = '';
  endAt = '';

  constructor(private utils: UtilsService, private clipboard: Clipboard, private uiService: UIService) {}

  ngOnInit(): void {
    this.yAxisLabel = Utility.capitalize(this.sensorName);
    this.parseSensor();
  }

  parseSensor() {
    this.sensorData.lastAbnormalRecord = this.sensorData.recordsHistory.find(
      (record) =>
        (this.sensorData.maxThresholdValue && record.value > this.sensorData.maxThresholdValue) ||
        (this.sensorData.minThresholdValue && record.value < this.sensorData.minThresholdValue)
    );
    const series = this.sensorData.recordsHistory.map((item) => {
      // let name = this.utils.isoStringToLocalDateTimeStringShort(item.recordedAt);
      const name = this.utils.isoStringToLocalDateTimeString(item.recordedAt);
      this.yScaleMin = Math.min(this.yScaleMin, +item.value);
      this.yScaleMax = Math.max(this.yScaleMax, +item.value);
      return {
        name,
        value: item.value,
        lat: item.lat,
        long: item.long,
      };
    });
    this.yScaleMax = Math.max(this.yScaleMax, +this.sensorData.maxThresholdValue);
    this.yScaleMin = Math.min(this.yScaleMin, +this.sensorData.minThresholdValue);
    const margin = (this.yScaleMax - this.yScaleMin) / 10;
    this.yScaleMax += margin;
    this.yScaleMin -= margin;

    //this.xAxisTicks = [series[0].name, series[series.length - 1].name];
    this.startAt = `Start at: ${series[0].name}`;
    this.endAt = `Latest update: ${series[series.length - 1].name}`;
    this.results = [
      {
        name: `${this.sensorName} Log`,
        series,
      },
    ];

    if (this.sensorData.minThresholdValue) {
      this.results.push({
        name: this.getWarningText('min'),
        series: [
          { name: series[0].name, value: this.sensorData.minThresholdValue },
          { name: series[series.length - 1].name, value: this.sensorData.minThresholdValue },
        ],
      });
    }
    if (this.sensorData.maxThresholdValue) {
      this.results.push({
        name: this.getWarningText('max'),
        series: [
          { name: series[0].name, value: this.sensorData.maxThresholdValue },
          { name: series[series.length - 1].name, value: this.sensorData.maxThresholdValue },
        ],
      });
    }

    if (this.sensorData.minThresholdValue) {
      this.referenceLines.push({
        name: Utility.getSensorText(+this.sensorData.minThresholdValue, this.sensorData.uom, this.sensorName),
        value: +this.sensorData.minThresholdValue,
      });
    }
    if (this.sensorData.maxThresholdValue) {
      this.referenceLines.push({
        name: Utility.getSensorText(+this.sensorData.maxThresholdValue, this.sensorData.uom, this.sensorName),
        value: +this.sensorData.maxThresholdValue,
      });
    }
  }
  getWarningText(minOrMax: string): string {
    switch (this.sensorName) {
      case SensorTypes.temperature:
        return minOrMax === 'max' ? 'Too hot' : 'Too cold';
      case SensorTypes.humidity:
        return minOrMax === 'max' ? 'Too humid' : 'Too dry';
      default:
        return minOrMax === 'max' ? 'Over' : 'Below' + ' ' + this.sensorName + ' ' + 'threshold';
    }
  }

  /// val: temperature or humidity
  showStatus(val: number) {
    return val > +this.sensorData.maxThresholdValue ? 'HIGH' : val < +this.sensorData.minThresholdValue ? 'LOW' : 'OK';
  }

  dateFormatter(val: string) {
    // 'this' is the chart control
    const count = this['ticks'].length;
    const max = 5;
    const step = Math.max(Math.floor(count / max), 1);
    const ticks = this['ticks'];
    if (ticks[0] == val || ticks[count - 1] == val) {
      return val;
    }
    for (let i = step; i < count; i = i + step) {
      if (ticks[i] === val) {
        // skip the 2nd last tick if it is too close to the last tick
        if ((step * 0.75) > (count - i)) {
          return '';
        }
        return val;
      }
    }
    return '';
  }
  getTimeFromModel(val: string | { name; value }) {
    return typeof val === 'object' ? val['name'] : val;
  }

  onSelect(data): void {
    const result = {
      time: data.name,
      data: data['value'],
      log: data.series,
    };
    if (data.lat) {
      result['lat_long'] = `(${data.lat}, ${data.long})`;
    }
    const content = JSON.stringify(result);
    this.clipboard.copy(content);
    this.uiService.showSnackbar('Data copied!', null, { duration: 2000, panelClass: 'accent' });
  }

  getRandomItemFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }
}
