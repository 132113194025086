export enum csvDlgExportTypes {
  BOOKING_DATE = 'Booking date',
  ATA_DATE = 'ATA date',
}
export enum csvDlgDateRangeTypes {
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  THIS_QUARTER = 'This quarter',
  LAST_QUARTER = 'Last quarter',
  YEAR_TO_DATE = 'Year to date',
  CUSTOM = 'Custom',
}

export enum csvDlgExportFormats {
  EXCEL = 'Excel',
  CSV = 'csv',
}

export interface csvDlgData {
  fileFormat: csvDlgExportFormats;
  exportType: csvDlgExportTypes;
  dateRange: csvDlgDateRangeTypes;
  isProductDataRequired: boolean;
  isTransshipmentPortDataRequired: boolean;
  uiEnabledColumnsOnly: boolean;
  includePODZipFile: boolean;
  startDate?: Date;
  endDate?: Date;

  //dateRangeStr: string;
}

export enum DownloadShipmentsMode {
  singleFile,
  zip,
  both,
}
