<ng-container *ngIf="logoMode === LogoMode.compact">
  <img class="corp-logo" *ngIf="!loadLogoFailed" [src]="corp_logo"
    (error)="loadLogoFail($event)" alt="logo" />
  <mat-icon svgIcon="site_logo" *ngIf="loadLogoFailed"></mat-icon>
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.wide">
  <img class="corp-logo" *ngIf="!(loadLogoFailed || isOrgLogo)" [src]="corp_logo"
       (error)="loadLogoFail($event)" alt="logo" />
  <ng-container *ngIf="isOrgLogo && isOrgImageLoaded">
    <img [src]="orgImage" class='org-logo' alt="logo" />
  </ng-container>
  <img class="corp-logo" *ngIf="loadLogoFailed && !isOrgLogo" [src]="imageMap.cargoFlowNew" alt="logo" />
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.full">
  <img class="corp-logo-full" *ngIf="!loadLogoFailed" [src]="corp_logo"
    (error)="loadLogoFail($event)" alt="logo" />
  <img class="corp-logo-full" *ngIf="loadLogoFailed" [src]="imageMap.cargoFlowNew" alt="logo" />
</ng-container>
