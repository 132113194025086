import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GlobalSearchFacade, initialState, ResponseEntityType, SearchEntityType } from './global-search.facade';
import { UtilsService } from '@dp/services/utils.service';
import { Router } from '@angular/router';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

@Component({
  selector: 'dp-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  vm$;
  searchType;
  selectedSearchType;
  searchResult = [];
  searchKey = '';
  SvgMap = SvgMap;
  @ViewChild('searchMenuTrigger') searchMenuTrigger: MatMenuTrigger;
  @ViewChild('search') search: ElementRef;

  constructor(public facade: GlobalSearchFacade, private utilsService: UtilsService, private router: Router) {
    this.vm$ = facade.vm$;
    this.searchType = Object.entries(SearchEntityType)
      .map(([key, value]) => {
        return {key, value};
      });
    this.selectedSearchType = facade.currentState.searchType;
    facade.searchResult$.subscribe(searchResult => {
      this.searchResult = searchResult.filter(result => ResponseEntityType[result.entityType] !== ResponseEntityType.TRUCK_SHIPMENT);
      if (this.searchKey?.length > 2) {
        this.openMenu();
      }
    });
  }

  onSearchTypeSelectionChange(event) {
    this.selectedSearchType = event.value;
    this.facade.updateState({searchType: event.value});
  }

  onSearchKeyChange(event) {
    this.searchKey = event;
    this.facade.updateState({searchKey: event});
    if (this.searchKey.length <= 2) {
      this.closeMenu();
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.facade.updateState({...initialState});
  }

  getEntityType(entityType) {
    return ResponseEntityType[entityType];
  }

  openMenu() {
    if (this.searchKey.length > 2) {
      this.searchMenuTrigger.openMenu();
    } else {
      this.closeMenu();
    }
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 0);
  }
  cancelClicked() {
    this.searchKey = '';
    this.facade.updateState({searchKey: '', searchResult: []});
  }

  closeMenu() {
    this.searchMenuTrigger?.closeMenu();
  }

  navigateTo(result) {
    switch (ResponseEntityType[result.entityType]) {
      case ResponseEntityType.ORDER: {
        this.router.navigate(['/purchase-orders'], { queryParams: { q: result.entityValue } });
        break;
      }
      case ResponseEntityType.INTERMODAL_SHIPMENT: {
        if (result.isGroup) {
          let groupBy = '';
          switch (SearchEntityType[this.selectedSearchType]) {
            case SearchEntityType.HBL_NUMBER : {
              groupBy = 'hblNumber';
              break;
            }
            case SearchEntityType.BL_NUMBER : {
              groupBy = 'blNumber';
              break;
            }
            case SearchEntityType.REFERENCE_NUMBER : {
              groupBy = 'referenceNumber';
              break;
            }
          }
          this.utilsService.navigateToTrackingByGroupPage(result.entityValue, groupBy);
        } else {
          this.utilsService.navigateToShipmentsPage(result.entityValue, true);
        }
        break;
      }
      case ResponseEntityType.AIR_SHIPMENT: {
        this.utilsService.navigateToAirShipmentsPage(result.entityValue);
        break;
      }
    }
  }
}
