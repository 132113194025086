import { NgModule } from '@angular/core';
import { DpInnerScrollDirective } from './dp-inner-scroll/dp-inner-scroll.directive';
import { DpPerfectScrollbarDirective } from './dp-perfect-scrollbar/dp-perfect-scrollbar.directive';
import { DpIfOnDomDirective } from './dp-if-on-dom/dp-if-on-dom.directive';
import { DpHesitateDirective } from './dp-hesitate/dp-hesitate.directive';
import { DpBusyButtonDirective } from './dp-busy-button/dp-busy-button.directive';
import { MatLegacyProgressSpinner as MatProgressSpinner, MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DpScrollToBottomDirective } from './dp-scroll-to-bottom/scroll-to-bottom.directive';
import { DpStopClickPropagationDirective } from './dp-stop-click-propagation/stop-click-propagation.directive';
import { FocusableDirective } from './dp-focus-able.directive';
import { IsEllipsisDirective } from './dp-ellipsis/dp-ellipsis.directive';
import { DisableControlDirective } from './dp-disable-control.directive';
import { ClickOutsideDirective } from './dp-click-outside/clickOutside.directive';
import { ShowTooltipIfTruncatedDirective } from './dp-ellipsis/dp-ellipsis-tooltip.directive';
import { TrapScrollDirective } from './dp-trap-scroll.directive';
import { AdDirective } from './ad.directive';

@NgModule({
  declarations: [
    DpBusyButtonDirective,
    DpInnerScrollDirective,
    DpPerfectScrollbarDirective,
    DpIfOnDomDirective,
    DpHesitateDirective,
    DpScrollToBottomDirective,
    IsEllipsisDirective,
    ShowTooltipIfTruncatedDirective,
    DpStopClickPropagationDirective,
    FocusableDirective,
    DisableControlDirective,
    ClickOutsideDirective,
    TrapScrollDirective,
    AdDirective,
  ],
  imports: [MatProgressSpinnerModule],
  exports: [
    DpBusyButtonDirective,
    DpInnerScrollDirective,
    DpPerfectScrollbarDirective,
    DpIfOnDomDirective,
    DpHesitateDirective,
    MatProgressSpinnerModule,
    DpScrollToBottomDirective,
    IsEllipsisDirective,
    ShowTooltipIfTruncatedDirective,
    DpStopClickPropagationDirective,
    FocusableDirective,
    DisableControlDirective,
    ClickOutsideDirective,
    TrapScrollDirective,
    AdDirective,
  ],
  //entryComponents: [MatProgressSpinner],
})
export class DpDirectivesModule {}
