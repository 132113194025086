import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  QueryList,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { DpWidgetToggleDirective } from './widget-toggle.directive';

@Component({
  selector: 'dp-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DpWidgetComponent implements AfterContentInit {
  @HostBinding('class.flipped')
  flipped = false;

  @ContentChildren(DpWidgetToggleDirective, { descendants: true })
  toggleButtons: QueryList<DpWidgetToggleDirective>;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.toggleButtons.forEach(flipButton => {
        this._renderer.listen(flipButton.elementRef.nativeElement, 'click', event => {
          event.preventDefault();
          event.stopPropagation();
          this.toggle();
        });
      });
    });
  }

  toggle(): void {
    this.flipped = !this.flipped;
  }
}
