/*
import { Injectable, NgZone } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { Address } from 'app/network/locations';

declare var google: any;

@Injectable()
export class GMapsService extends GoogleMapsAPIWrapper {
  private geoCoder;
  constructor(_loader: MapsAPILoader, _zone: NgZone) {
    super(_loader, _zone);
    _loader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      //console.log('coder is loaded');
    });
  }

  getLatLan(address: Address) {
    //console.log('Getting Address - ', address);
    const addressStringified = `${address.streetLine1} ${address.streetLine2}, ${address.city}, ${address.stateProv} ${address.postalCode}, ${address.countryCode}`;
    return new Observable(observer => {
      // tslint:disable-next-line: only-arrow-functions
      if (this.geoCoder !== null) {
        this.geoCoder.geocode({ address: addressStringified }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            observer.next(results[0].geometry.location);
            observer.complete();
          } else {
            //console.log('Error - ', results, ' & Status - ', status);
            observer.next({});
            observer.complete();
          }
        });
      } else {
        observer.next({});
      }
    });
  }
}
*/

import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { tap, map, switchMap } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { Location } from './location.model';

declare var google: any;

//todo: deprecate this file
@Injectable()
export class GeocodeService {
  private initStarted = false;
  private geocoder: any;

  constructor(private mapLoader: MapsAPILoader) {}

  private initGeocoder() {
    if (!this.initStarted) {
      this.initStarted = true;
      //console.log('Init geocoder!');
      // this.geocoder = new google.maps.Geocoder();
    }
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.initStarted) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }

    return of(true);
  }

  geocodeAddress(address): Observable<Location> {
    //console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable((observer) => {
          const addressStringified = `${address.streetLine1} ${address.streetLine2}, ${address.city}, ${address.stateProv} ${address.postalCode}, ${address.countryCode}`;

          observer.next({ lat: 0, lng: 0 });
          observer.complete();
          return;
          /*
                    this.geocoder.geocode({ address: addressStringified }, (results, status) => {
                      if (status === google.maps.GeocoderStatus.OK) {
                        //console.log('Geocoding complete!');
          
                        observer.next({
                          lat: results[0].geometry.location.lat(),
                          lng: results[0].geometry.location.lng()
                        });
                      } else {
                        //console.log('Error - ', results, ' & Status - ', status);
                        observer.next({ lat: 0, lng: 0 });
                      }
                      observer.complete();
                    });
          
          */
        });
      })
    ) as Observable<Location>;
  }
}
