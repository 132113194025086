import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export enum DocumentAction {
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  UPDATE = 'UPDATE',
  PREVIEW = 'PREVIEW',
}
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  actionEvent = new EventEmitter();
  loading: BehaviorSubject<string | null> = new BehaviorSubject(null);
  loading$ = this.loading.asObservable();

  // api/ + files/document
  private files_document_url = environment.rootUrl + environment.urls.files_document;

  // for shipmentGroup
  // api/ + shipmentGroup/uploadDocuments/100000005?shipmentType=INTERMODAL_SHIPMENT
  private shipment_group_documents_url = (groupId) => {
    return environment.rootUrl + `shipmentGroup/uploadDocuments/${groupId}?shipmentType=INTERMODAL_SHIPMENT`;
  };

  constructor(private http: HttpClient) {}

  // Get all documents metadata for the shipment
  getShipmentDocuments(entityId, entityType = 'OCEAN_SHIPMENT') {
    return this.http.get(`${this.files_document_url}?entityId=${entityId}&entityType=${entityType}`).pipe(finalize(() => {}));
  }

  /*
  POST: /api/files/document/100000001
  {
    "documentType": string
    "documentReference": string
    "entityId": number (shipmentId)
    "fileName": fileInput
  }
  */
  uploadShipmentDocument(file: FormData, entityId: string | number, groupId: string | number) {
    if (entityId) {
      return this.http.post(this.files_document_url, file);
    } else if (groupId) {
      return this.http.post(this.shipment_group_documents_url(groupId), file);
    }
  }

  // get shipment document by shipmentId
  // GET /api/files/document/100000001
  getShipmentDocument(documentId, documentVersion?: number) {
    this.loading.next(documentId);

    let url = this.files_document_url + '/' + documentId;
    if (documentVersion) {
      url += `?version=${documentVersion}`;
    }
    return this.http.get(url, { responseType: 'blob' }).pipe(
      finalize(() => {
        this.loading.next(null);
      })
    );
  }

  downloadInvoice(id: string): Observable<HttpResponse<Blob>> {
    this.loading.next(id);
    return this.http
      .get<Blob>(environment.rootUrl + `invoices/download/${id}`, {
        responseType: 'blob' as 'json',
        observe: 'response',
        // 'Access-Control-Expose-Headers': 'Content-Disposition',
      })
      .pipe(
        finalize(() => {
          this.loading.next(null);
        })
      );
  }

  getPublicSharedDocument(tokenId: string, documentId) {
    const url = environment.rootUrl + `files/sharedDocument?token=${tokenId}&documentId=${documentId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  // DELETE shipment document by documentId - /api/files/document/100000001
  deleteShipmentDocument(documentId) {
    return this.http.delete(this.files_document_url + '/' + documentId);
  }

  // GET channel
  getFileChannels(): Observable<any> {
    return this.http.get(environment.rootUrl + 'files/channels');
  }

  // GET document revisions
  getDocumentRevisions(documentId: string | number): Observable<any> {
    return this.http.get(environment.rootUrl + `files/document/revisions/${documentId}`);
  }

  /*
  POST: update document - /api/files/document/100000001
  both documentType & documentReference are required
  {
    "documentType": "DOC TYP1",
    "documentReference": "DREF3"
  }
  */
  updateShipmentDocument(documentId, formData) {
    return this.http.post(this.files_document_url + '/' + documentId, formData);
  }
}
