import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate/truncate.pipe';
import { HighlightSearchPipe } from './search-highlight/search-highlight.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { LocalizedDatePipe } from './dates/localized-date.pipe';
import { SecuredUrlPipe } from './securedUrl/securedUrl.pipe';
import { DateAgoPipe } from './date-ago/date-ago.pipe';
import { ShipmentStatusPipe } from './shipment-status/shipment-status.pipe';
import { UploadStatePipe } from './shipment-status/upload-state.pipe';
import { UserMentionSearchHighlightPipe } from './search-highlight/user-mention-highlight.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    HighlightSearchPipe,
    KeysPipe,
    ReplacePipe,
    LocalizedDatePipe,
    SecuredUrlPipe,
    DateAgoPipe,
    ShipmentStatusPipe,
    UploadStatePipe,
    UserMentionSearchHighlightPipe,
  ],
  imports: [],
  exports: [
    TruncatePipe,
    HighlightSearchPipe,
    UserMentionSearchHighlightPipe,
    KeysPipe,
    ReplacePipe,
    LocalizedDatePipe,
    SecuredUrlPipe,
    DateAgoPipe,
    ShipmentStatusPipe,
    UploadStatePipe,
  ],
  providers: [DateAgoPipe, LocalizedDatePipe],
})
export class DpPipesModule {}
