<p class="invite-user-title">Invite Users to Add</p>
<p class="easy-sub-title">You may add up to 10 users at once.</p>

<form [formGroup]="emailForm" autocomplete="off" (ngSubmit)="inviteUsers()" class="easy-form">
  <div formArrayName="emails">
    <div *ngFor="let email of formEmails.controls; let i = index" [formGroupName]="i" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field class="w-260 mt-8" appearance="outline">
        <input matInput formControlName="email" placeholder="Email Id" />
        <mat-error *ngFor="let validation of validationMsgs.email">
          <div *ngIf="email.get('email').hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="user-admin">
        <mat-select formControlName="role" panelClass="no-change">
          <mat-option *ngFor="let role of roles" [value]="role.id">
            {{role.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <dp-svg-icon [src]="SvgMap.uds_delete" tooltip="Remove" [disabled]="formEmails.controls.length === 1" (click)="removeOrClearEmail(i)" class="delete-icon"></dp-svg-icon>
      <div>
        <!-- <button class="float-left" style="margin-left: 5px; margin-right: 5px;" type="button" mat-icon-button color="primary"
          aria-label="Add" (click)="addEmailFormGroup()" tooltip="Add"
          *ngIf="i === formEmails.controls.length - 1 && formEmails.controls.length <= 9">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button mat-icon-button type="button" color="primary" aria-label="Remove/clear" (click)="removeOrClearEmail(i)" tooltip="Remove"
          [disabled]="formEmails.controls.length === 1">

          <mat-icon>highlight_off</mat-icon>
        </button> -->
      </div>

    </div>
  </div>

  <button mat-flat-button class="easy-button medium" type="submit" [dpBusy]="isBusy"
    [disabled]="!emailForm.valid || !hasUser() || emailForm.pristine">SEND INVITATION</button>
  <button mat-flat-button class="easy-button medium ml-20 secondary" (click)="addEmailFormGroup($event)"
    [hidden]="formEmails.controls.length >= 10">ADD ANOTHER USER</button>
</form>
