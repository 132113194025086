import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersTeamsComponent } from '.';
import { AuthGuard } from 'app/auth/auth.guard';
import { Users2Component } from '../users-teams/users/users2.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewSettingsShellComponent } from './new-settings-shell/new-settings-shell.component';
import { CompanySettingComponent } from './organization/company-setting/company-setting.component';
import { AddShipmentComponent } from 'app/settings/add-shipment/add-shipment.component';
import { AddShipmentOneByOneComponent } from 'app/settings/add-shipment/add-shipment-one-by-one.component';
import { AccessPermissionGuard } from 'app/auth/access-permission.guard';
import { TeamsComponent } from '../users-teams/teams/teams.component';

const routes: Routes = [
  {
    path: 'settings',
    component: NewSettingsShellComponent,
    data: { title: 'Settings' },
    canActivate: [AuthGuard],
  },
  {
    path: 'company-settings',
    component: CompanySettingComponent,
    data: { title: 'Company Settings' },
    canActivate: [AuthGuard],
  },
  {
    path: 'add-shipments/:shipmentType',
    component: AddShipmentComponent,
    data: { title: 'ADD AIR SHIPMENTS' },
    canActivate: [AuthGuard, AccessPermissionGuard],
  },
  {
    path: 'add-shipments-one-by-one/:shipmentType',
    component: AddShipmentOneByOneComponent,
    data: { title: 'ADD SHIPMENTS ONE BY ONE' },
    canActivate: [AuthGuard, AccessPermissionGuard],
  },
  // {
  //   path: 'profile',
  //   component: UserProfileComponent,
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: { title: 'Contact-US' },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [],
})
export class SettingsRoutingModule {}
