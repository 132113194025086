import { Component } from "@angular/core";
import { dpAnimations } from "@dp/animations";
import { SvgMap } from "../svg/uds-svg-map";
import { FeedbackBannerService } from "./feedback-banner.service";
import { commonEnvironment } from "environments/environment.common";
import { Utility } from "@dp/utilities";
import { environment } from "environments/environment";

@Component({
  selector: 'dp-feedback-banner',
  templateUrl: './feedback-banner.component.html',
  styleUrls: ['./feedback-banner.component.scss'],
  animations: dpAnimations,
})
export class FeedbackBanner {
  showBanner: boolean;
  SvgMap = SvgMap;

  constructor(private feedbackBannerService: FeedbackBannerService) {}

  ngOnInit() {
    this.feedbackBannerService.state$.subscribe(isBannerVisible => {
      this.showBanner = isBannerVisible;
    });
  }

  onClose() {
    this.feedbackBannerService.updateState(false);
    Utility.generateAndTrackEventData(
      environment.gaEvents.names.buttonClick,
      environment.gaEvents.categories.buttonClick,
      environment.gaEvents.actions.close,
      environment.gaEvents.labels.feedbackBanner
    );
  }

  onNavigate(reviewSite: string) {
    if (reviewSite === 'g2') {
      window.open(commonEnvironment.feedbackUrl.g2, '_blank');
    } else {
      window.open(commonEnvironment.feedbackUrl.gartner, '_blank');
    }
    Utility.generateAndTrackEventData(
      environment.gaEvents.names.buttonClick,
      environment.gaEvents.categories.buttonClick,
      reviewSite === 'g2'
        ? environment.gaEvents.actions.g2Action 
        : environment.gaEvents.actions.gartnerAction,
      environment.gaEvents.labels.feedbackBanner
    );
  }
}