<div class="search-wrapper" [ngClass]="{'mat-elevation-z1': hasBorder}">
  <div class="search">
    <!-- <mat-icon class="search-icon">search</mat-icon> -->
    <dp-svg [src]="SvgMap.search" class="mr-8"></dp-svg>
    <input matInput type="search" [placeholder]="placeholder" [ngModel]="value" (ngModelChange)="onModelChange($event, value)"
      (keyup)="onKeyUp($event)" />

    <button color="primary" *ngIf="value" mat-icon-button aria-label="Clear"
      (click)="clear()">
      <mat-icon class="no-hover-effect">close</mat-icon>
    </button>

    <span mat-icon-button *ngIf='showFilter' class='filter-popup' [class]="!disableFilter ? '' : 'filter-icon'" (click)="openFilterPopup()" [tooltip]="!disableFilter ? 'Open Filters' : ''" placement="bottom">
      <mat-icon svgIcon="filter-icon" w="24"></mat-icon>
      <div class='circle' *ngIf='filterCount'>{{filterCount}}</div>
    </span>
  </div>
</div>
