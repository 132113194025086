import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getCorpLogo, IMAGES_MAP } from 'app/image.constants';

export enum LogoMode {
  compact,
  wide,
  full,
}
@Component({
  selector: 'dp-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnChanges {
  imageMap = IMAGES_MAP;
  corp_logo;
  LogoMode = LogoMode;
  loadLogoFailed = false;
  isOrgLogo = true;
  isOrgImageLoaded = false;
  orgImage;
  @Input() orgCode: string;
  @Input() orgLogo: string;
  @Input() logoMode: LogoMode;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.getLogo();
    this.corp_logo = getCorpLogo(this.orgCode, this.logoMode);
  }

  ngOnChanges() {
    this.getLogo();
  }

  getLogo() {
    if (this.orgLogo) {
      this.http.get(this.orgLogo, { responseType: 'blob' })
        .pipe(map((val) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
        .subscribe(orgSrc => {
          if (orgSrc) {
            this.orgImage = orgSrc;
            this.isOrgLogo = true;
            this.isOrgImageLoaded = true;
          } else {
            this.isOrgLogo = false;
          }
        }, () => {
          this.isOrgLogo = false;
        });
    } else {
      setTimeout(() => {
        this.isOrgLogo = this.orgImage ? true : false;
      }, 3000);
    }
  }

  loadLogoFail() {
    this.loadLogoFailed = true;
  }
}
