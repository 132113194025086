export enum ShipmentType {
  TRUCK_SHIPMENT = 'TRUCK_SHIPMENT',
  INTERMODAL_SHIPMENT = 'INTERMODAL_SHIPMENT',
  AIR_SHIPMENT = 'AIR_SHIPMENT',
  OL_SHIPMENT = 'OL_SHIPMENT',
  INTERMODAL_PLUS_SHIPMENT = 'INTERMODAL_PLUS_SHIPMENT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export enum TruckUpdateType {
  UPDATE_TYPE = 'ROAD_VEHICLE_AND_DRIVER_PHONE_UPDATE',
}

export enum ScheduleType {
  VESSEL_SCHEDULE = 'VESSEL_SCHEDULE',
  FLIGHT_SCHEDULE = 'FLIGHT_SCHEDULE',
}

export interface CSVDialogData {
  mode: 'reports' | 'pod';
  shipmentType: ShipmentType;
  filters: Object;
  params: Object;
  istbg?: boolean;
}

export interface ISchedulesResponse {
  schedules: object[];
  schedulesResponse: object[];
}

export interface ITableColumns {
  createdAt: string;
  shipmentNumber: string;
  containerNumber: string;
  containerId: string;
  trackingState: string;
  referenceNumber: string;
  substatus_1: string;
  is: Object; //is : intermodal_shipment
}

export interface DeleteShipmentResponse {
  deletedShipments: DeleteShipments[];
}

export interface DeleteShipments {
  id: number;
  isDeleted: boolean;
  shipmentNumber: string;
}

export interface ReportErrorData {
  id: number;
  shipmentNumber: string;
  containerNumber: string;
  loadReportShipmentErrorFromTable: boolean;
  carrier: string;
  blNumber: string;
  uploadType: string;
  sharedByOrganizationId: string;
}

export interface ReportErrorPayload {
  errorReportTypeCode: string;
  errorReportRecommendationCode: string;
  userEnteredReportData: UserEnteredReportData;
  shipmentNumber: string;
  shareToken: string;
}

interface UserEnteredReportData {
  description: string;
}

export interface Vessel {
  id: number;
  name: string;
  imo: string;
  mmsi: string;
}
export const exceptionSeverityLevel = {
  2: 'High Severity',
  1: 'Medium Severity',
  0: 'No Exception',
};

export interface AWBCheckResult {
  valid: boolean;
  reason: string;
  carrierName: string;
}
export interface KeyValueItem {
  key: string;
  value: string;
}

export interface Transport {
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  eta?: string;
  ata?: string;
  originalEtd?: string;
  originalEta: string;
  carrier: string;
  serviceProvider?: string;
  truckNumber?: string;
  driverName?: string;
  driverPhone?: string;
  originFullAddress: string;
  originAddressComponents: AddressComponents;
  destinationFullAddress: string;
  destinationAddressComponents: AddressComponents;
  bookingNumber?: string;
  statusSteps: StatusSteps;
  segmentData: SegmentDatum[];
  originPort?: string;
  originPortId?: string;
  originHub?: string;
  destinationPort?: string;
  destinationPortId?: string;
  destinationHub?: string;
  manualAta?: string;
  originHubTerminalName?: string;
  destinationHubTerminalName?: string;
}

interface SegmentDatum {
  pickup: string;
  dropoff: string;
  carrier?: any;
  truckNumber?: string;
  etd: string;
  atd: string;
  eta: string;
  ata?: string;
  pickupFullAddress: string;
  dropoffFullAddress: string;
}

interface StatusSteps {
  steps: Step[];
}

interface Step {
  title: string;
  isCurrent: boolean;
  isFuture: boolean;
  address: string;
  dpwFlag: boolean;
  portActions: PortAction[];
  date: string;
  locationActions?: PortAction[];
}

interface PortAction {
  name: string;
  date: string;
  isCurrent: boolean;
  isFuture: boolean;
  approximatedFlag: boolean;
  duration?: string;
}

export interface AddressComponents {
  streetLine1: string;
  streetLine2: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode?: any;
  countryName: string;
}

export interface ColumnSettingItem {
  key: string; // column name
  isEnabled: boolean;
  allowedChange: boolean;
}

export interface ColumnSetting {
  key: string;
  columns: ColumnSettingItem[];
}
export interface GenericHttpResponse {
  message?: string;
  errorCode?: number;
}

export enum ConsentStatusEnum {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export interface ConsentStatus {
  driverPhone: string;
  carrier?: string;
  consentStatus?: ConsentStatusEnum;
  consentInitiated?: string;
  consentCompleted?: string;
  lastCheckedAt?: string;
}

export interface RoadTransport {
  truckNumber?: string;
  driverPhone?: string;
  carrier?: string;
}

export interface Emissions {
  co2e: {
    value: number;
    unit: string;
  };
}

export interface RoadPayload {
  status?: string;
  referenceNumber?: string;
  transportJourneys: {
    roadTransport: RoadTransport;
  };
  emissions?: Emissions;
}