import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service'; // Adjust the path as necessary
import { Utility } from '@dp/utilities';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  // this is only used for root url
  canActivate(): boolean {
    const isLocalhost = window.location.hostname === 'localhost';
    if (this.authService.isAuth()) {
      this.router.navigate(['/welcome']);
    } else {
      if (isLocalhost) {
        this.router.navigate(['/login']);
      } else {
        Utility.redirectTo('cargoes/flow/welcome');
      }
    }
    return false; // Prevents navigation to the original route
  }
}
