export const commonEnvironment = {
  environmentType: 'local',
  production: false,
  productionHosting: false,
  title: 'CARGOES Flow',
  enableBeta: true, // setting to deal with any 'not-mature' feature
  useMock: false,
  staticData_date: '2023.08.25', //change to current date if staticData is changed!!!
  ga: 'G-WT4L8VD6DP', //prod site
  SSO: true,
  timeStamp: '',
  orgLogosOnTracking: [100000076, 100000986, 100001021],

  heartBeats: {
    containerDeliveryHeartBeat: 6, //every n second
    webUpdateHeartBeat: 6, //seconds
    notificationBeat: 30, //seconds
  },

  feedback_token:
    'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJDdzdMZjdadnJoWnRxYlFra3BlVGlSWUx4V1dGQUNBYkdIWXd2N3hZOEo0In0.eyJleHAiOjE2NTg3MjQxNDcsImlhdCI6MTY1ODcwMjU0NywianRpIjoiYWVjOGZiODgtZDE0YS00OWZiLTgzNjAtYzMwNTQyNWFkNGM3IiwiaXNzIjoiaHR0cDovL2Rldi1sb2dpbi5jYXJnb2VzLmNvbTo4MC9hdXRoL3JlYWxtcy9kdHdvcmxkIiwic3ViIjoiMTlkNjdlNGYtNDgxYi00MzZlLTk5ZjctOWExMjc0MzQ5ZWNkIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiRkwiLCJzZXNzaW9uX3N0YXRlIjoiY2Y3ZjIwYTQtYTczYy00MmM3LThkOTQtNmIyZTk2OTUzY2U4IiwiYWNyIjoiMSIsInNjb3BlIjoiZW1haWwgY2xpZW50LXJlZ2lzdHJhdGlvbiIsInNpZCI6ImNmN2YyMGE0LWE3M2MtNDJjNy04ZDk0LTZiMmU5Njk1M2NlOCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJjcmVhdGVkVGltZXN0YW1wIjoxNjIxNTkyMDgzMTQxLCJidXNpbmVzc190eXBlIjoiQ1UiLCJuYW1lIjoiTWFnaWMgUGFydG5lciAxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibWFnaWMucGFydG5lcjEiLCJnaXZlbl9uYW1lIjoiTWFnaWMiLCJmYW1pbHlfbmFtZSI6IlBhcnRuZXIgMSIsImVtYWlsIjoibWprQGRlbW9pbXBvcnRlcnBhcnRuZXIxLmNvbSIsImNvbnRhY3RfbnVtYmVyIjoiMjA2In0.oyhY6eZ06x2X9LrD6BWsSc1bj49P5HBA4Uvj7N7QgR97Z6cqI9NBHdDcwZE6iluUOAjkOllzVOlSBKiOkXaXtvQ0ioi7U0fKeRghealfkfWHIzj_qkNZ-KgKPDJaH3r1jW_WKwS308f50M0-eG_EMDotEajJsdnPwQ5DnsacHYEiwbt2kr03rTCcFpJ2KGKc71ncKQ5p9lMz9sQGpkm9gYT2zYocNQtRjvRVSBZyhSG6SNywhSXFqFWGKCVsWgtTCk9O6dKOOK3KzFpi8C_63Tc57A9spna-Z08lo5GAEmDdgljdSy_jK-jfQcf-jGBW9u5w0EDcI38bPhjBQ4Cf2Q',
  feedback_apiKey: 'U0h8NbCBIhJYBt4G1W4vd9l5dbmpUgO0',
  feedback_apiValue: 'X-DPW-ApiKey',
  onFooterHideRoutes: ['/signup'],
  noHeaderRoutes: [
    'login',
    'register',
    'verify',
    'signup',
    'signup-old',
    'new-user',
    'partnership',
    'set-company',
    'forgot-password',
    'approval',
    'changeEmail',
    '_tracking',
    'documents',
    'widget',
  ],
  publicPageRoutes: ['/_tracking', '/_tracking_shipment', '/widget'],
  HEADER: {
    X_API_KEY: 'X-DPW-ApiKey',
    X_USER_TOKEN: 'X-DPW-User-Token',
    X_TRACE_CONTEXT: 'X-Trace-Context',
    X_CLIENT_SOURCE: 'X-Client-Source', // android-native, ios-native, android-web, ios-web web
    X_TOTAL_COUNT: 'X-Total-Count',
    X_RATELIMIT_REMAINING: 'X-RateLimit-Remaining',
    X_RATELIMIT_LIMIT: 'X-RateLimit-Limit',
    X_DISPLAY_MODE: 'X-Display-Mode', // web, mobile
    AUTHORIZATION: 'Authorization',
    IMPERSONATED_TOKEN: 'Impersonated-Token',
  },

  regex: {
    // explain the regex: /^ *$/ means empty string, ^ means start of string, $ means end of string, * means 0 or more
    emptyStringValidation: /^ *$/,
    emailValidation: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    passwordValidation: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
    // phone regex is for 8-15 digits, from mawani team
    phone: /^\\d{8,15}$/,
  },

  feedbackUrl: {
    g2: 'https://url.avanan.click/v2/___https:/c21bfd90815549219de1719e4a65f24a.svc.dynamics.com/t/t/SkZBLnq2winWKPWKp6a4W2yobtGHKqIJxOf4J5oKUKsx/2sHVOVsRx4erBKpexx5ptP9ddoNLD1zvhAeeoB87QUQx___.YXAxZTpkcHdvcmxkLWhvOmE6bzoyZGRjNzZlMWRlMTQzNDViOWQwZjdkOGQ5NDE0M2E1Nzo2OjhmOGE6YTBiMTViNWM3YTRlYWRmOWFjYTBkZDFlY2QyN2ZmYmJlNGQ3NzQ4ZGQ3YjRlYzllNmQwNzY2MmY2ZmMwOWE4NDpoOlQ',
    gartner: 'https://gtnr.io/o9Ppopf33',
  },

  gaEvents: {
    names: {
      navbar: 'dl_navbar',
      groupFilter: 'group-filter',
      filterChange: 'dl_filterapplied',
      filterSelected: 'dl_filterselected',
      shipmentSearch: 'dl_filtersearch',
      addShipments: 'Add Shipments',
      shipments: 'Shipments',
      userNotification: 'dl_userNotification',
      buttonClick: 'dl_buttonclick',
      toggle: 'dl_toggle',
      notificationClick: 'dl_notificationclick',
      tabSelected: 'dl_tabSelected',
      columnInteraction: 'dl_columnInteraction',
      bannerImpression: 'dl_banner_impression',
    },
    actions: {
      tabChange: 'tab-change',
      login: 'login',
      addContainerFromDlg: 'add-container-via-dlg',
      addContainerFromFile: 'add-container-via-file-upload',
      addMilestoneFromFile: 'add-milestone-via-file-upload',
      updateDTUser: 'update-dt-user',
      exportCSVOcean: 'export-intermodal-csv',
      exportCSVTruck: 'export-truck-csv',
      exportPod: 'export-pod',
      sharePartner: 'share-partner',
      deleteShipments: 'delete',
      shareLink: 'share',
      loadLink: 'load',
      signInCargoes: 'dl_signin',
      userTracking: 'user_role',
      publicSearch: 'dl_searchhome',
      vesselSchedulesSearch: 'dl_vesselschedule',
      searchInitiated: 'search initiated',
      searchSuccess: 'search success',
      searchNoResult: 'search - no result found',
      searchError: 'search - error',
      flightSchedulesSearch: 'dl_flightschedule',
      feedbackWidgetDialog: 'dl_feedbackwidgetdialog',
      feedbackWidgetTrigger: 'dl_feedbackwidgettrigger',
      feedbackWidgetDisplayed: 'feedback widget visible',
      rateUsClick: 'rate us click',
      crossButtonClick: 'cross button click',
      fwThankYouDoneClick: 'thank you done click',
      hamburgerClick: 'hamburger click',
      buttonClick: 'button click',
      indicatorClick: 'Indicator',
      actionClick: 'Action',
      trackingNumber: 'Tracking Number',
      flag: 'Flag',
      markComplete: 'Mark as Complete',
      addProduct: 'Add Product',
      productOrOrder: 'Product or Order',
      deleteTag: 'Delete Tag',
      addTag: 'Add Tag',
      update: 'Update',
      error: 'Error',
      browseFile: 'Document - Browse Files',
      uploadFile: 'Document - File Uploaded',
      addNote: 'Notes - Add Note',
      groupBy: 'Group By',
      orderBy: 'Order By',
      feedbackBanner: 'Feedback Banner',
      g2Action: 'Write A Review On G2',
      gartnerAction: 'Write A Review On Gartner Peer Insights',
      close: 'Close',
    },
    categories: {
      containerPage: 'ocean-shipments',
      roadPage: 'road-shipments',
      login: 'login', //login page
      addContainer: 'add-container',
      dtUserWelcome: 'dt-user-welcome-dlg',
      exportCSVDlg: 'export-csv-pod-dlg',
      shareWithPartner: 'share-with-partner',
      deleteShipments: 'delete-shipments',
      shareLink: 'share-link',
      userId: 'user_id',
      publicSearch: 'search',
      capchaValidated: 'capcha success',
      failure: 'no result found',
      success: 'search success',
      searchClick: 'search click',
      error: 'Error',
      initiated: 'initiated',
      vesselSchedulesPage: 'Vessel Schedule',
      flightSchedulesPage: 'Flight Schedule',
      feedbackWidget: 'feedback widget',
      navBar: 'navigation bar',
      statusbar: 'shipment status bar filter',
      shipmentFilterIcons: 'shipment filter icons',
      filterApplied: 'Filter Applied',
      buttonClick: 'Button Click',
      oceanShipments: 'ocean shipments',
      airShipments: 'air shipments',
      roadShipments: 'road shipments',
      calendar: 'calendar',
      performance: 'performance',
      addShipment: 'add shipment',
      userSettingNotification: 'user setting notification',
      notificationEvents: 'notification events',
      groupFilter: 'Group Filter',
      tabSwitched: 'Tab Switched',
      bannerImpression: 'Bannner Impression',
    },
    /* tracks a users progress through the use of the site, from first login to veteran user.*/
    milestones: {
      startRegisterAccount: 'start_register_account',
      endRegisterAccount: 'end_register_account',
      verifiedEmail: 'verified_email',
      login: 'first_login',
      uploadDiscovered: 'upload_discovered', // when user first discovers the upload functionality
      downloadTemplate: 'download_template', //template they must use to upload container details.
      uploadTemplate: 'upload_template',
      uploadSuccess: 'upload_success', // when the upload succeeds
      firstViewShipment: 'first_view_shipment',
      first3Logins: 'first_3_logins',
      first7Logins: 'first_7_logins',
      first15Logins: 'first_15_logins',
      first50Logins: 'first_50_logins',
      first150Logins: 'first_150_logins',
    },
    labels: {
      success: 'success',
      failure: 'failure',
      activeException: 'Active Exception',
      products: 'Products',
      add: 'Add',
      getLink: 'Get Link',
      document: 'Document',
      notes: 'Notes',
      viewDetail: 'View Detail',
      flagged: 'Flagged',
      unFlagged: 'Unflagged',
      sharePartner: 'Share with partner',
      update: 'update shipment',
      delete: 'Delete Shipment',
      trackingError: 'Tracking Error',
      demmurage: 'Demurrage and Detention Configuration',
      addCarrier: 'Add Carrier',
      milstone: 'Milestone',
      oceanTrackingDetail: 'Ocean Shipment Tracking Detail Page',
      airTrackingDetail: 'Air Shipment Tracking Detail Page',
      roadTrackingDetail: 'Road Shipment Tracking Detail Page',
      docAvailabe: 'Doc Available',
      docNA: 'Doc N/A',
      pod: 'Proof of Delivery',
      tripStarted: 'Cannot update Driver and vehicle Detail',
      tripNotStarted: 'Update Driver and vehicle Detail',
      consentStatus: 'Check Consent Status',
      markAsComplete: 'Mark As Complete',
      newCardViewPopup: 'New Card View Pop Up',
      feedbackBanner: 'Feedback Banner',
      skipForNow: 'Skip For Now',
      tryItNow: 'Try It Now',
    },
    status: {
      successful: 'successful',
    },
  },

  urls: {
    purchaseOrders: 'po/purchaseOrders',
    shipments: 'shipment/shipments',
    shipmentExceptions: 'shipment/exceptions',
    oceanShipments: 'ocean_shipment/ocean_shipments',
    oceanshipment_events: 'te/oceanShipmentEvents',
    oceanShipments_events_summary: 'te/oceanShipmentEventSummary',
    shipmentItems: 'ocean_shipment/shipments_items',
    containers: 'container/containers',
    newContainers: 'new_container/containers',
    newContainersFilter: 'new_container/filterDropdown',
    containerEvnts: 'te/containerEvents',
    trackingEventService: {
      baseUrl: 'te/',
      tracking: 'tracking', //?containerNumber=TCLU4835069
    },
    containerCalendar: 'container/containerCalendar',
    container_shipmenttypes: 'container/shipmentTypes',
    container_origins: 'container/portsOfDeparture',
    container_destinations: 'container/portsOfArrival',
    customerPurchaseOrders: 'po/customerPOs',
    poSuppliers: 'po/suppliers',
    poDivisionCodes: 'po/divisioncodes',
    register: 'admin/register',
    login: 'admin/login',
    // ssoLogin: 'admin/sso-login',
    loginWithToken: 'admin/generateUserInformationWithToken',
    isEmailAlreadyUsed: 'admin/isEmailAlreadyUsed',
    containerExist: 'new_container/checkContainerNumber',
    groupUploadValidate: 'shipmentGroup/upload/validate',
    defaultUserPage: '', //default should be to the root
    template_sensitivity: 'files/templateSensitivity',
    files_upload: 'files/upload',
    files_download: 'files/download',
    files_template: 'files/download-template/containers',
    container_template: 'files/download-template/',
    files_document: 'files/document',
    containerStats: 'container/containerStats',
    containerSummary: 'container/containerStatsSummary',
    statistics1: 'insight/statistics',
    statistics2: 'insight/leadTimePortPerformance',
    intermodalJourneyStatistics: 'insight/intermodalJourneyDurationStats',
    partsOfArrival: 'shipment/portsOfArrival',
    partsOfDeparture: 'shipment/portsOfDeparture',
    carriers: 'shipment/carriers',
    shipmentFilter: 'shipment/filterDropdown',
    shipmentV2Filter: 'shipmentV2/filterDropdown',
    roadPerformanceDropdowns: 'shipmentV2/filterDropDown',
    container_vessel: 'container/vessels',
    container_voyages: 'container/voyages',
    oceanShipment_upload: 'files/upload',
    container_carriers: 'container/carriers',
    vessel: 'https://fosq22wld7.execute-api.eu-west-1.amazonaws.com/dev/vesseltracksingle',
    locationCreate: 'locations',
    alerts: 'alert/alerts',
    template_file: 'files/download-template/templateFile',
    // requests used by the calendar tab --------
    calendarDays: 'shipment/calendar',
    shippingDay: 'shipment/calendar/day',
    locations: 'shipment/calendar/locations',
    calendar_dropdowns: 'shipmentV2/calendar/filterDropDown',
    shipmentCalendar: 'shipmentV2/calendar',
    shipmentCalendarDay: 'shipmentV2/calendar/day',
    // requests used by the user management tab and processes ---------
    users_in_organization: 'admin/users',
    invite_users: 'admin/inviteUsers',
    remove_user: 'admin/users/:userCode/remove',
    change_user_admin: 'admin/users/:userCode/admin',
    change_user_role: 'admin/users/:userCode/roles/:roleName',
    get_user: 'admin/users',
    edit_user: 'admin/users/:userCode',
    change_password: 'admin/users/:userCode/password',
    verify_email: 'admin/verifyEmail',
    get_user_by_verification_code: 'admin/users/verificationCode',
    static_data_remote: 'admin/staticData',
    organization: 'admin/organizations',
    meta_info: 'organization/meta',
    settings: 'settings',
    register_email: 'admin/registerUser',
    companySearch: 'admin/organizations/search',
    setAdminApprovalStatus: 'admin/setAdminApprovalStatus',
    forgot_password: 'admin/forgotPassword',
    contactUs: 'admin/contactUs',
    changeEmailConfirm: 'admin/users/changeUserEmail',
    webUpdates: 'admin/getUserWebUpdates',
    setWebUpdate: 'admin/upsertWebUpdateMetadata',
    getSA_OrganizationsUrl: 'admin/systemAdminSearch',
    sa_getUserToken: 'admin/loginUserUsingSystemAdmin',
    //sa_getUserTokenSSO: 'admin/loginUserUsingSystemAdminSSO',
    orgFlag: 'admin/userOrgFlag',
    orgAccountLimit: 'admin/updateOrganizationAccountLimit',
    updatePartnershipStatus: 'admin/partnership',
    updatePartnershipApprovalStatus: 'admin/setPartnershipApprovalStatus',
    shipmentV2: 'shipmentV2',
    deliveryBoard: 'shipmentV2/delivery/dashboard',
    deliveryMetrics: 'shipmentV2/delivery/metrics',
    invoices: 'invoices',
    reportErrorPrivate: 'shipmentV2/report-error',
    reportErrorPublic: 'shipmentV2/report-error/public',
    schedules: 'schedules',
    orgLogo: 'admin/orgLogo',
    saveSummaryCard: 'shipmentV2/filter/save',
    getSummaryCard: 'shipmentV2/filter/summaryCard',
    deleteSummaryCard: 'shipmentV2/filter/',
    dashboardConfig: 'dashboard/configurations',
    dashboardShipmentMetrics: 'dashboard/shipmentMetrics',
    missingCarrierFlag: 'shipmentV2/shipments/carrierMissingFlag',
    upsertOrganizationProperties: 'admin/organizationProperties/upsertOrganizationProperties',
    milestoneUploads: 'files/milestoneUploads',
    teams: 'admin/teams',
    initiateConsent: 'admin/initiateConsent',
    checkConsentStatus: 'admin/checkConsentStatus',
    drayageLinkUnlinkShipment: 'shipmentV2/drayageShipment/upsert',
  },
  lineTrancateLimit: {
    poList: 200,
    poDetail: 200,
    shipmentList: 200,
    shipmentDetail: 200,
  },
  tablePageSize: 20,
  debounceTime: 450,
  alertRemoveTimer: 1500, // 1.5 seconds to mark alerts as read
  cacheMaxAge: {
    poList: 60000, // 60 seconds
    poSuppliers: 300000, // 5 minutes
  },

  //todo: move other constants into this group
  constants: {
    millisecondsInHour: 3600000,
    millisecondsInDay: 86400000,
    // datePatterns: [
    //   'YYYY-MM-DD',
    //   'YYYY-M-D',
    //   'YYYY/MM/DD',
    //   'YYYY/M/D',
    //   'YYYY.MM.DD',
    //   'YYYY.M.D',

    //   'YY-MM-DD',
    //   'YY-M-D',
    //   'YY/MM/DD',
    //   'YY/M/D',
    //   'YY.M.D',

    //   'MM/DD/YYYY',
    //   'MM/DD/YY',
    //   'M/D/YYYY',
    //   'M/D/YY',
    //   'D-M-YYYY',
    //   'D-M-YY',
    //   'DD-MM-YYYY',
    //   'DD-MM-YY',
    //   'DD-MMM-YY',
    //   'DD/MMM/YYYY',
    //   'DD/MMM/YY',

    //   'DD.MMM.YYYY',
    //   'DD.MM.YYYY',
    //   'DD.MM.YY',
    //   'DD.M.YY',
    //   'DD.MMM.YYYY',

    //   'D/MM/YY',
    //   'D/M/YYYY',
    //   'D/M/YY',

    //   'D.MM.YYYY',
    //   'D.M.YYYY',
    //   'D.M.YY',
    //   'D. M. YY',
    //   'D MMM YYYY',
    //   'D.MM.YYYY',
    // ],
    // timePatterns: [
    //   'h:mm A',
    //   'hh:mm A',
    //   'H:mm',
    //   'HH:mm',
    //   'HH.mm [Uhr]',
    //   'H.mm',
    //   'HH.mm',
    //   'HH[H]mm',
    //   'HH[ h ]mm',
    //   'HH[h]mm',
    //   'A h:mm',
    //   'A hh:mm',
    // ],
  },

  apiKey: 'X-DPW-ApiKey',
  apiKeyVessel: 'x-api-key',
  uiSetting: {
    select_All_DisplayText: 'All',
  },
  storage: {
    currentUser: 'current-user',
    currentOrg: 'current-org',
    feedbackWidgetSession: 'fw-session',
    superAdminUser: 'super-admin-user',
    superAdminOrg: 'super-admin-org',
    metrics: 'metrics',
    poFilters: 'po-filters',
    containerFilters: 'container-filters',
    oceanShipmentFilters: 'shipment-filters',
    productFilters: 'product-filters',
    locationFilters: 'location-filters',
    tpFilters: 'tp-filters',
    InlandShipmentFilters: 'inland-shipment-filters',
    ShipmentFilters: 'shipments-filters',
    applicationSettings: 'application-settings',
    currentSession: 'currentSession', // data for each login session
    webUpdatesHandled: 'webUpdatesHandled', //each login session
    filters: 'filters',
    permissible: 'permissible',
    showAddCarrierStrip: 'showAddCarrierStrip',
    selectedBenchmarks: 'selectedBenchmarks',
    sidebarCollapse: 'sidebarCollapse',
    oceanSwitchViewState: 'oceanSwitchViewState',
    showFeedbackBanner: 'showFeedbackBanner',
    chatbotOnboarding: 'chatbotOnboarding',
  },
  emptyPOFilters: {
    dateStart: undefined,
    dateEnd: undefined,
    deliveryDateStart: undefined,
    deliveryDateEnd: undefined,
    shipDateStart: undefined,
    shipDateEnd: undefined,
    divisionCode: 'All',
    flag: 'All',
    state: 'All',
    supplier: { All: 0 },
  },

  emptyContainerFilters: {
    departure: 'All',
    arrival: 'All',
    vessel: 'All',
    voyage: 'All',
    arrivalDateStart: undefined,
    arrivalDateEnd: undefined,
    departureDateStart: undefined,
    departureDateEnd: undefined,
    flag: 'All',
    state: 'All',
    alert: 'All',
  },

  emptyShipmentFilters: {
    departure: 'All',
    arrival: 'All',
    vessel: 'All',
    voyage: 'All',
    carrier: 'All',
    arrivalDateStart: undefined,
    arrivalDateEnd: undefined,
    departureDateStart: undefined,
    departureDateEnd: undefined,
    flag: 'All',
    state: 'All',
    alert: 'All',
  },

  emptyshipmentFilters: {},

  emptyProductFilters: {
    category: 'All',
    perishable: 'All',
  },

  emptyTPFilters: {
    partnerType: 'All',
  },

  emptyLocationFilters: {
    countryCode: 'All',
    locationType: 'All',
  },

  emptyInlandShipmentFilters: {
    flag: 'All',
    destination: 'All',
    origin: 'All',
    estimateDeliveryStart: undefined,
    estimateDeliveryEnd: undefined,
    estimateArrivalStart: undefined,
    estimateArrivalEnd: undefined,
    actualDeliveryStart: undefined,
    actualDeliveryEnd: undefined,
    actualArrivalStart: undefined,
    actualArrivalEnd: undefined,
  },

  API_RESPONSE_CODES: {
    STATUS_CODES: {
      200: 'Ok',
      201: 'Created',
      204: 'no content',
      400: 'Bad request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Not found',
      409: 'Conflict',
      429: 'Too Many Requests',
      440: 'Login expired',
      500: 'Server error',
    },
    REASON_CODES: {
      // the first three digits are the corresponding status code; the text will be used in log
      20000: 'Success',
      20100: 'Created',
      20400: 'No content by current parameters',
      40010: 'Invalid request data',
      40020: 'Invalid email address',
      40030: 'Bad argument error',
      40110: 'No API Key provided',
      40120: 'Invalid API Key',
      40130: 'Blocked by access_control',
      40140: 'Blacklisted IP address',
      40150: 'Missing required header',
      40160: 'Invalid value in header',
      40310: 'Client not provisioned to access route',
      40320: 'Missing Authorization header or it is invalid',
      40330: 'Incorrect username or password',
      40340: 'Username in header does match login request parameter',
      40410: 'The requested object does not exist',
      40910: 'Conflict in creation',
      42910: 'The rate of requests is over provisioned limit',
      44010: 'User token expired',
      50090: 'Internal Sever Error',
    },
  },
  insights_reports: {
    MaxEntryCountForChart: 40,
  },
  map: {
    restriction: {
      latLngBounds: {
        north: 85.0,
        south: -85.0,
        west: -180.0,
        east: 180.0,
      },
      strictBounds: true,
    },
  },
  snackBarDuration: {
    success: 3000,
    warning: 5000,
    error: 8000,
  },

  tracking_error_report_type: {
    'TERT-EVENT-INCORRECT-ORDER': 'Shipment events are not in the correct order',
    'TERT-WRONG-CARRIER-MBL': 'Wrong carrier name or MBL #',
    'TERT-NOT-FOUND': 'Shipment not found',
    'TERT-EVENT-WRONG-MISSING': 'Shipment events are wrong or missing',
    'TERT-WRONG-TRANSPORT-TYPE': 'Wrong transport type (e.g. truck, rail, or vessel)',
    'TERT-WRONG-MAP': 'Something is wrong with the map',
    'TERT-OTHER': 'Other',
  },
  tracking_error_report_type_by_vessel: {
    'TERT-WRONG-ORIGIN-DESTINATION': 'Wrong origin or destination port',
    'TERT-NOT-FOUND': 'Shipment not found',
    'TERT-WRONG-MAP': 'Something is wrong with the map',
    'TERT-OTHER': 'Other',
  },
  tracking_error_report_type_by_air: {
    'TERT-EVENT-INCORRECT-ORDER': 'Shipment events are not in the correct order',
    'TERT-NOT-FOUND': 'Shipment not found',
    'TERT-EVENT-WRONG-MISSING': 'Shipment events are wrong or missing',
    'TERT-WRONG-MAP': 'Something is wrong with the map',
    'TERT-OTHER': 'Other',
  },
  tracking_error_report_type_by_road: {
    'TERT-EVENT-INCORRECT-ORDER': 'Shipment events are not in the correct order',
    'TERT-NOT-FOUND': 'Shipment not found',
    'TERT-EVENT-WRONG-MISSING': 'Shipment events are wrong or missing',
    'TERT-WRONG-MAP': 'Something is wrong with the map',
    'TERT-OTHER': 'Other',
  },
  // for dropdowns, we use enum type, it has limitation to deal with enum key
  // we are moving to basic json object as below
  filterTypeList: {
    ArrivalDateFilterTypeList: {
      ARRIVAL_CURRENT_MONTH: 'Arrived: Current month',
      ARRIVAL_LAST_3_MONTH: 'Arrived: Past 3 months',
      ARRIVAL_LAST_6_MONTH: 'Arrived: Past 6 months',
      ARRIVAL_LAST_12_MONTH: 'Arrived: Past 12 months',
      ARRIVAL_YEAR_TO_DATE: 'Arrived: Year to date',
      ARRIVAL_LAST_CALENDAR_YEAR: 'Arrived: Last calendar year',
      ARRIVAL_CUSTOM: 'Arrived: Custom Range',
    },
    DepartDateFilterTypeList: {
      DEPART_CURRENT_MONTH: 'Depart: Current month',
      DEPART_LAST_3_MONTH: 'Depart: Past 3 months',
      DEPART_LAST_6_MONTH: 'Depart: Past 6 months',
      DEPART_LAST_12_MONTH: 'Depart: Past 12 months',
      DEPART_YEAR_TO_DATE: 'Depart: Year to date',
      DEPART_LAST_CALENDAR_YEAR: 'Depart: Last calendar year',
      DEPART_CUSTOM: 'Depart: Custom Range',
    },
    ScheduleEventTypeList: {
      arrivals: 'Arrival',
      departures: 'Departure',
      pickups: 'Pickup',
      deliveries: 'Delivery',
    },
  },
  dpwStorageKeys: {
    currentUser: 'current-user-cargoes-rates',
    actingUser: 'acting-user-cargoes-rates',
    token: 'Token',
    headerTokenKey: 'X-DPW-User-Token',
    commonData: 'DP-Common-Data',
    dpwUserType: 'dpwUserType',
    dpwUserOrg: 'dpwUserOrg',
    dpwOrgSelectedUser: 'dpwOrgSelectedUser',
    CrRegistrationClicked: 'CR-RegistrationClicked',
    CrRegistrationReturnUrl: 'CR-RegistrationReturnUrl',
    FrRegistrationReturnUrl: 'FR-RegistrationReturnUrl',
    UserRecognitionCookie: 'x-user-recognition-cookie',
  },
  validators: {
    phone: '^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\0-9]*$',
  },
  orgWithWideLogo: [100000957, 100001518, 100001935],

  pages: {
    airShipment: 'airShipment',
    oceanShipment: 'oceanShipment',
    boardDemurrage: 'boardDemurrage',
    boardDetention: 'boardDetention',
    OrderList: 'OrderList',
    olTracking: 'olTracking',
    productMaster: 'productMaster',
    purchaseOrder: 'purchaseOrder',
    roadShipment: 'roadShipment',
    tbgGroup: 'tbgGroup',
    tbgShipment: 'tbgShipment',
    tbgDocument: 'tbgDocument',
    oceanShipmentPlus: 'oceanShipmentPlus',
  },
};
