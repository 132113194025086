<section id="users-teams-tabs-page" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="uds-page-title" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
    {{getManageUsersTeamsLabel()}}
  </div>

  <section #pageZone class="uds-page-content-fill">
    <nav mat-tab-nav-bar>
      <ng-container *ngFor="let link of navLinks">
        <a mat-tab-link *ngIf="link.display" [routerLink]="link.link" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
          [disabled]="rla.disabled">
          {{link.label}}
        </a>
      </ng-container>
    </nav>
    <router-outlet></router-outlet>
  </section>
</section>