import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Utility } from '@dp/utilities';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-not-found',
  template: '', // Empty template
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    if (this.authService.isAuth()) {
      // Check if the user is logged in
      this.router.navigate(['/welcome']); // Navigate to the welcome path for logged in users
    } else if (environment.baseUrl.includes('localhost')) {
      this.router.navigate(['/']);
    } else {
      Utility.redirectTo('cargoes/flow/welcome');
    }
  }
}
