import { Utility } from '@dp/utilities';
import { SHIPMENT_STATE_TYPE, PortAction } from '../shipments2.model';
export type Point = { x: number; y: number };
export type ContainerBlock = { w: number; h: number; padding: number };
export enum TrackingTraceState {
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
}

export class Spot {
  // point: Point;
  radius: number;
  fill: string;
  stroke?: string;
  stroke_width?: number;
  state?: TrackingTraceState;
  tick: { p1: Point; p2: Point; p3: Point; p4: Point };

  title: string;
  titleStyle: string;
  titleX: number;
  titleY: number;

  message: string;
  messageStyle: string;
  messageX: number;
  messageY: number;
  duration: string;

  vertical: boolean;

  constructor(public point: Point) {}
}

export interface TrackingTrace {
  title: string;
  isCurrent: boolean;
  message: string;
  state?: TrackingTraceState;
  isFuture?: boolean;
  height?: number;
  heightSoFar?: number;
  duration?: string;

  daysToGo?: string;
  displayDate?: string;
  dpwFlag?: boolean;
  locationActions?: PortAction[];
}

export interface Line {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  stroke: string;
  stroke_width: number;
  stroke_dasharray?: string;
}

export class TimeLine {
  lineStroke = { solid: '#173A64', dashed: '#CFCFCF' };

  lineStrokeVertical = { solid: '#173A64', dashed: '#CFCFCF' };

  // used for horizontal
  line_y = 27;

  // used for vertical
  line_x = 40;

  circle_r = 7;
  points: Point[] = [];
  step: number;

  lines: Line[] = [];
  trackingTraces: TrackingTrace[] = [];
  spots: Spot[] = [];
  currentSpotState: string;

  checkOffsetX = 28;
  checkOffsetY = -20;

  constructor(
    private container: ContainerBlock,
    private trackingTracesOriginal: TrackingTrace[],
    private state: SHIPMENT_STATE_TYPE,
    private vertical: boolean = false,
    private exceptionSeverity?: number,
    private params: any = null
  ) {
    if (vertical) {
      this.lineStroke = this.lineStrokeVertical;
    }

    this.polishData();
    this.buildPoints();
    this.buildLines();
    this.buildStops();
    this.setCurrentSpotState();
    this.setCurrentExceptionSeverityState();
  }

  polishData() {
    let foundCurrent = false;
    this.trackingTracesOriginal.map((item) => {
      let state: TrackingTraceState;

      if (!this.vertical) {
        if (foundCurrent) {
          state = TrackingTraceState.FUTURE;
        } else if (item.isCurrent) {
          state = TrackingTraceState.CURRENT;
          foundCurrent = true;
        } else state = TrackingTraceState.PAST;
      } else {
        if (item.isFuture) {
          state = TrackingTraceState.FUTURE;
        } else if (item.isCurrent) {
          state = TrackingTraceState.CURRENT;
        } else {
          state = TrackingTraceState.PAST;
        }
      }

      const trace = { ...item, state };
      this.trackingTraces.push(trace);
    });
  }

  buildPoints() {
    if (!this.vertical) {
      const length = this.container.w - this.circle_r - this.circle_r - this.container.padding - this.container.padding;
      this.step = length / (this.trackingTraces.length - 1);
      for (let i = 0; i < this.trackingTraces.length; i++) {
        const x = this.circle_r + this.container.padding + this.step * i;
        this.points.push({ x: x, y: this.line_y });
      }
    } else {
      const height = this.container.h - this.circle_r - this.circle_r - this.container.padding - this.container.padding;

      this.step = height / (this.trackingTraces.length - 1);

      let heightSoFar = 0;
      ////console.log("this.step: " + this.step);
      for (let i = 0; i < this.trackingTraces.length; i++) {
        //const y = this.circle_r + this.container.padding + this.step * i;

        let y = 0;
        if (this.trackingTraces[i].height) {
          y = this.circle_r + this.container.padding + heightSoFar;
        } else {
          y = this.circle_r + this.container.padding + this.step * i;
        }
        //console.log('y: ' + y + '  adding: ' + this.trackingTraces[i].height);

        this.points.push({ x: this.line_x, y: y });

        heightSoFar += this.trackingTraces[i].height;
      }
    }
  }

  buildLines() {
    if (!this.vertical) {
      let isSolid = !this.trackingTraces[0].isCurrent;
      for (let i = 1; i < this.trackingTraces.length; i++) {
        let line: Line = null;

        line = {
          x1: this.points[i - 1].x,
          y1: this.line_y,
          x2: this.points[i].x,
          y2: this.line_y,
          stroke: isSolid ? this.lineStroke.solid : this.lineStroke.dashed,
          stroke_width: 2,
          stroke_dasharray: isSolid ? null : '5 3',
        };

        if (isSolid) {
          isSolid = !this.trackingTraces[i].isCurrent;
        }
        this.lines.push(line);
      }
    } else {
      let line: Line;

      for (let i = this.trackingTraces.length - 2; i >= 0; i--) {
        const solidLine = !this.trackingTraces[i].isFuture;
        // console.log("solidLine: " + solidLine + " trace : " + JSON.stringify(this.trackingTraces[i]));
        if (this.vesselOnStep(i)) {
          const halfStep = (this.points[i + 1].y - this.points[i].y) / 2;
          this.lines.push({
            x1: this.line_x,
            y1: this.points[i].y,
            x2: this.line_x,
            y2: this.points[i].y + halfStep,
            stroke: this.lineStroke.dashed,
            stroke_width: 2,
            stroke_dasharray: '5 3',
          });
          this.lines.push({
            x1: this.line_x,
            y1: this.points[i].y + halfStep,
            x2: this.line_x,
            y2: this.points[i + 1].y,
            stroke: this.lineStroke.dashed,
            stroke_width: 2,
            stroke_dasharray: null,
          });
        } else {
          this.lines.push({
            x1: this.line_x,
            y1: this.points[i].y,
            x2: this.line_x,
            y2: this.points[i + 1].y,
            stroke: solidLine ? this.lineStroke.solid : this.lineStroke.dashed,
            stroke_width: 2,
            stroke_dasharray: solidLine ? null : '5 3',
          });
        }
      }
    }
  }

  private vesselOnStep(i: number) {
    return (
      this.trackingTraces[i].isFuture &&
      this.trackingTraces[i + 1].isCurrent &&
      (Utility.isEmpty(this.trackingTraces[i + 1].locationActions) || this.trackingTraces[i + 1].locationActions[0].isCurrent)
    );
  }

  buildStops() {
    this.trackingTraces.map((item, i) => {
      const spot = new Spot(this.points[i]);
      const state = item.state;
      spot.state = state;

      spot.title = item.title;
      spot.message = item.message;
      spot.duration = item.duration ? item.duration : '';

      if (!this.vertical) {
        spot.vertical = false;
        if (i === 0) {
          spot.titleStyle = 'title text-start';
          spot.titleX = 0;
          spot.messageStyle = 'messsage text-start';
          spot.messageX = 0;
        } else if (i === this.trackingTraces.length - 1) {
          spot.titleStyle = 'title text-end';
          spot.titleX = this.container.w;
          spot.messageStyle = 'messsage text-end';
          spot.messageX = this.container.w;
        } else {
          spot.titleStyle = 'title text-middle';
          spot.titleX = spot.point.x;
          spot.messageStyle = 'message text-middle';
          spot.messageX = spot.point.x;
        }
      } else {
        // vertical
        spot.vertical = true;
        if (i === 0) {
          spot.titleStyle = 'title text-start';

          spot.titleX = spot.point.x + 22;
          // spot.messageStyle = 'messsage text-start';
          spot.messageY = spot.point.y + 20;
        } else if (i === this.trackingTraces.length - 1) {
          // spot.titleStyle = 'title text-end';
          spot.messageStyle = 'messsage text-end';
          spot.messageY = spot.point.y;
        } else {
          // spot.titleStyle = 'title text-middle';
          spot.messageStyle = 'message text-middle';
          spot.messageY = spot.point.y + 20;
        }
        spot.titleStyle = 'title';
        spot.titleY = spot.point.y + 5;
        spot.titleX = 60;
      }

      if (state === TrackingTraceState.PAST) {
        spot.radius = 7;
        spot.fill = 'white';
        spot.stroke = '#173A64';
        spot.stroke_width = 2;
        if (!this.vertical) {
          spot.tick = this.getTick(i);
        } else {
          spot.tick = this.getTickVertical(i);
        }
      } else if (state === TrackingTraceState.FUTURE) {
        spot.radius = 8;
        spot.fill = '#CFCFCF';
      } else {
        spot.radius = 9;
        spot.fill = 'white';
        spot.stroke = '#173A64';
        spot.stroke_width = 2;
      }
      spot.radius = state === TrackingTraceState.PAST ? 7 : state === TrackingTraceState.FUTURE ? 8 : 9;

      this.spots.push(spot);
    });
    // console.log('spots', this.spots);
  }

  setCurrentSpotState() {
    let result = 'current ';
    switch (this.state) {
      case SHIPMENT_STATE_TYPE.CUSTOM_HOLD:
      case SHIPMENT_STATE_TYPE.VERY_DELAYED:
        result += 'error-medium';
        break;
      case SHIPMENT_STATE_TYPE.DELAYED:
        result += 'warning-dark';
        break;
      case SHIPMENT_STATE_TYPE.COMPLETED:
        result += 'black-disabled-100';
        break;
      case SHIPMENT_STATE_TYPE.ON_TIME:
        result += 'success-dark';
        break;
      default:
        result += 'success-dark';
    }
    this.currentSpotState = result;
    // console.log('this.currentSpotState', result);
  }

  setCurrentExceptionSeverityState() {
    if (this.exceptionSeverity >= 0) {
      let result = 'current ';
      switch (this.exceptionSeverity) {
        case 2:
          result += 'error-medium';
          break;
        case 1:
          result += 'warning-dark';
          break;
        case 0:
          result += 'success-dark';
          break;
        default:
          result += 'success-dark';
      }
      this.currentSpotState = result;
    }
  }

  private getTick(i: number) {
    const tick0 = { p1: { x: 5.0, y: 27.6 }, p2: { x: 6.3, y: 29.8 }, p3: { x: 5.7, y: 29.8 }, p4: { x: 11.2, y: 24.4 } };
    const tick = { ...tick0 };
    const offset = i * this.step + this.container.padding;
    tick.p1.x += offset;
    tick.p2.x += offset;
    tick.p3.x += offset;
    tick.p4.x += offset;
    return tick;
  }

  private getTickVertical(i: number) {
    const tick0 = {
      p1: { x: 10.0 + this.checkOffsetX, y: 27.6 + this.checkOffsetY },
      p2: { x: 11.3 + this.checkOffsetX, y: 29.8 + this.checkOffsetY },
      p3: { x: 10.7 + this.checkOffsetX, y: 29.8 + this.checkOffsetY },
      p4: { x: 16.2 + this.checkOffsetX, y: 24.4 + this.checkOffsetY },
    };
    const tick = { ...tick0 };

    let offset = null;
    if (this.trackingTraces[i].height) {
      offset = this.trackingTraces[i].heightSoFar + this.container.padding;
    } else {
      offset = i * this.step + this.container.padding;
    }
    tick.p1.y += offset;
    tick.p2.y += offset;
    tick.p3.y += offset;
    tick.p4.y += offset;
    return tick;
  }
}
