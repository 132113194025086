import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

export const crossFieldValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const totalWeight = control.get('total_weight');
  const weightUom = control.get('weight_uom');

  if (totalWeight && weightUom) {
    if ((totalWeight.value && !weightUom.value) || (!totalWeight.value && weightUom.value)) {
      if (!weightUom.value) {
        return { uomfieldsMismatch: true };
      } else {
        return { weightfieldsMismatch: true };
      }
    }
  }
  return null;
};
