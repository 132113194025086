import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dp-port',
  templateUrl: './port.component.html',
  styleUrls: ['./port.component.scss'],
})
export class PortComponent implements OnInit {
  @Input() name: string;
  @Input() maxWidth: string;
  @Input() hasData?: boolean;
  constructor() {}

  ngOnInit(): void {}
}
