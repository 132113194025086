<section id="welcome-page">

  <div class="logo">
    <dp-logo [orgCode]="orgCode" [logoMode]="LogoMode.full"></dp-logo>
  </div>

  <div class="my-12" style="background-color: #fafafa;">
    <dp-cargoes-features></dp-cargoes-features>
  </div>

  <div class="container-fluid" *ngIf="!isAuth || isQuickTrackAllowed()">

    <div class="d-flex justify-content-center align-items-end">
      <mat-button-toggle-group class="toggle-groups shipment-controls mx-12" [(ngModel)]="shipmentType" (change)="shipmentTypeChanged()">
        <mat-button-toggle [value]="ShipmentType.INTERMODAL_SHIPMENT">
          <label for="">Ocean</label>
        </mat-button-toggle>
        <mat-button-toggle [value]="ShipmentType.TRUCK_SHIPMENT">
          <span>Road</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="ShipmentType.AIR_SHIPMENT">
          <span>Air</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <dp-search-box appearance="outline" (cancelClicked)="cancelClicked()" [value]="trackingNumber" (search)="onSearchEvent($event)"
        class="shipment-search" [placeholder]="getPlaceholderText(shipmentType)" (valueChange)="onValueChange($event)" (keyup)="keyup()"
        (cancelClicked)="onClear()">
      </dp-search-box>

      <form class="carrier-form easy-form mx-12 no-hint" style="width: 220px" [hidden]="carrierControl.disabled || !trackingNumber">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Choose a carrier</mat-label>
          <input type="text" matInput [formControl]="carrierControl" [matAutocomplete]="auto">

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option value="Any carrier">
              Any carrier
            </mat-option>
            <ng-container *ngFor="let option of filteredOptions | async">
              <!-- Filter by shipmentType: intermodal, road, air -->
              <mat-option [value]="option.carrier_name" *ngIf="option.shipment_type === this.shipmentType && !option.requires_bol">
                {{option.carrier_name}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <!-- <ng-container *ngIf="filteredOptions | async as options">
              <p *ngIf="carrierName && options.length===0">
                <mat-error class="text-error">
                  Invalid carrier. Please check carrier name again.
                </mat-error>
              </p>
            </ng-container> -->
      </form>
      <!-- hints -->
      <span class="hint" *ngIf="isDetectingCarrierName">
        <span class="text-black-50">Detecting the carrier...</span>
      </span>

      <span *ngIf="!isDetectingCarrierName">
        <span class="hint" *ngIf="trackingNumber && (carrierName && carrierName !== DEFAULT_CARRIER) && showMessage">
          <mat-error class="text-warning">
            Please verify the carrier and correct it if needed
          </mat-error>
        </span>
        <span class="hint"
          *ngIf="!canDetectCarrierName && !(trackingNumber && carrierName && carrierName !== DEFAULT_CARRIER) && showMessage">
          <mat-error class="text-warning">
            We could not identify the carrier
            for this tracking number. Please select a carrier.
          </mat-error>
        </span>
      </span>

    </div>

    <div class="d-flex justify-content-center align-items-center mt-16">
      <ng-container *ngIf="!isAuth">
        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
          (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'"
          (ngModelChange)="onCaptchaUpdate($event)" [ngModel]="recaptcha"></ngx-recaptcha2>

        <button mat-raised-button color="primary" (click)="onSearchEvent(trackingNumber)"
          style="min-width: 45px; height: 33px; margin-left: 10px;" [disabled]="!trackingNumber || !carrierName || !recaptcha || isBusy">
          <mat-icon class="search-icon">search</mat-icon>
        </button>
      </ng-container>

      <button style="padding: 12px;" *ngIf="isAuth" mat-raised-button color="primary" (click)="onSearchEvent(trackingNumber)"
        [disabled]="!trackingNumber || !carrierName || isBusy">
        Search
        <mat-icon class="search-btn-icon">search</mat-icon>
      </button>

    </div>

  </div>

  <ng-container *ngIf="isBusy; else elseBlock">

    <div fxLayoutAlign="center center" style="margin-top: 40px; font-size: 22.4px;">
      Searching... this may take a minute.
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 300px;">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #elseBlock>

    <div fxLayoutAlign="center center" style="margin-top: 40px; font-size: 1.25rem;" *ngIf="errorText !=='' ">
      <mat-error>{{errorText}}</mat-error>
    </div>

    <!-- Payload Result -->

    <div style="padding: 12px; min-height: 400px; background-color: white" *ngIf="payload">

      <ng-container *ngIf="payload.trackingType === 'bl_number'">
        <!-- search by mbl #, or bk # -->
        <dp-tracking-result-mbl-bk [payload]="payload.data"></dp-tracking-result-mbl-bk>
      </ng-container>

      <!-- Container Type -->
      <ng-container *ngIf="payload.trackingType === 'container_number' || payload.trackingType === 'external_shipment_number'">
        <div *ngIf="!payload.data?.transportJourneys?.roadTransport" class="container tracking-result">
          <dp-tracking-result [payload]="payload.data" [errorText]="errorText" [showException]="false"></dp-tracking-result>
        </div>

        <div *ngIf="payload.data?.transportJourneys?.roadTransport" class="container tracking-result-road-transport">
          <dp-tracking-result-road-transport [payload]="payload.data" [showTemperature]="false" [errorText]="errorText">
          </dp-tracking-result-road-transport>
        </div>
      </ng-container>

      <!-- Air Shipment Type -->
      <ng-container *ngIf="payload.trackingType === 'AIR_SHIPMENT' || payload.trackingType == 'awb_number'">
        <div class="container tracking-result">
          <dp-tracking-result-air [payload]="payload.data" [errorText]="errorText"></dp-tracking-result-air>
        </div>

      </ng-container>

    </div>
  </ng-template>

</section>