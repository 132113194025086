import { Component, Input, OnInit } from '@angular/core';
import { SensorRecord, SensorTypes } from '@dp/types';
import { SensorValueState } from '../../../types/dp-model';
import { Utility } from '@dp/utilities';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'dp-data-point',
  templateUrl: './data-point.component.html',
  styleUrls: ['./data-point.component.scss'],
})
export class DataPointComponent implements OnInit {
  @Input() record: SensorRecord;
  @Input() sensorType: SensorTypes;
  @Input() maxThresholdValue: string;
  @Input() minThresholdValue: string;
  @Input() leadingText: string;
  @Input() uom: string;
  visible = true;
  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    if (!this.uom) {
      this.uom = Utility.getDefaultUOM(this.sensorType);
    }
    if (this.uom.indexOf('warning') > -1) {
      this.visible = false;
    }
  }

  getRecordState(): SensorValueState {
    if (this.maxThresholdValue === null || this.minThresholdValue === null) return SensorValueState.NORMAL;
    if (+this.record?.value < +this.minThresholdValue) return SensorValueState.LOW;
    if (+this.record?.value > +this.maxThresholdValue) return SensorValueState.HIGH;
    return SensorValueState.NORMAL;
  }
  getSensorText() {
    return Utility.getSensorText(this.record?.value, this.uom, this.sensorType);
  }
  getSensorSvg() {
    return Utility.getSensorSvg(this.sensorType, this.getRecordState());
  }
  getLabel() {
    return this.leadingText ? `${this.leadingText} ${this.sensorType}` : this.sensorType;
  }
}
