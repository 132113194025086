import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Observable, Subject } from 'rxjs';
import { filter, finalize, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { UIService } from 'app/shared/ui.service';
import { DpConfigService } from '@dp/services/config.service';
import { AuthService } from 'app/auth/auth.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  AddContainerByTypes,
  AusTenantNavItemsList,
  BaseMenu,
  MenuSchema,
  NavItemsList,
  SideNavBarItem,
  TemplateFileExtensionTypes,
  TemplateMenuSchema,
} from '../nav.model';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContainersService } from '@dp/services/containers.service';
import { dpAnimations } from '@dp/animations';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { ACCOUNT_TYPES, User } from 'app/auth/user.model';
import { NavItem } from 'app/navigation/nav.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LogoMode } from '@dp/components/logo/logo.component';
import { IUploadResult } from 'app/upload-history/models/uh.model';
import { MilestoneService } from '@dp/services/milestone.service';
import { ShipmentType } from 'app/shared/shared.model';
import { SideNavRouteVsLabel } from '../nav.constant';
import { UserAccessService } from 'app/auth/user-access.service';
import { USER_ACCESS } from 'app/user-access.constants';
import { UtilsService } from '@dp/services/utils.service';
import { Organization } from 'app/settings/users/users.model';
import { UDS_ICON_MAP } from 'app/image.constants';

@Component({
  selector: 'dp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  animations: dpAnimations,
})
export class SidenavListComponent implements OnInit {
  udsIconMap = UDS_ICON_MAP;
  ShipmentType = ShipmentType;
  LogoMode = LogoMode;
  loadSquareLogoFailed = false;
  loadLongLogoFailed = false;
  selectedAnchorBg = ' accent-500-bg';
  orgCode: string;
  user: User;
  org: Organization;
  sideNavDataFromOrg: MenuSchema[] = [];
  isProdOrStaging = environment.environmentType === 'staging' || environment.environmentType === 'prod';
  isNotProd = environment.productionHosting;
  orgType: ACCOUNT_TYPES;
  ACCOUNT_TYPES = ACCOUNT_TYPES;
  isTruckShipmentsUploadAllowed = false;
  isOLShipmentsUploadAllowed = true;
  TemplateFileExtensionTypes = TemplateFileExtensionTypes;
  AddContainerByTypes = AddContainerByTypes;
  currentRouteURL: string;
  // is the currently logged in user an admin?
  isAdmin = false;
  SideNavRouteVsLabel = SideNavRouteVsLabel;
  panelOpenState = false;
  accessKeys = USER_ACCESS;

  navItemsList: SideNavBarItem[];
  @Input() isExpanded: boolean;
  @Output() closeSidenav = new EventEmitter<void>();
  @ViewChild('navList', { static: true, read: ElementRef }) navList: ElementRef;
  selectedAnchor: HTMLElement;

  @ViewChild('topMenu') contextMenu1: MatMenuTrigger;
  @ViewChild('schedulesMenu') schedulesMenu: MatMenuTrigger;
  @ViewChild('shipmentsMenu') shipmentsMenu: MatMenuTrigger;
  @ViewChild('globalFileInput') fileInput: ElementRef;
  children = [];
  menuOpened = '';
  menuTrigger = null;

  dpConfig: any;
  private unsubscribeAll: Subject<any>;
  svgAttributes = {
    width: 24,
    height: 24,
    fill: '#000000',
  };
  olSvgAttributes = {
    width: 19,
    height: 19,
    fill: '#000000',
  };

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));
  // .pipe(tap(result => //console.log(result)));
  private isQuickTrackAllowed: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private renderer: Renderer2,
    private uiService: UIService,
    private authService: AuthService,
    private dpConfigService: DpConfigService,
    private library: FaIconLibrary,
    private addContainerDlg: MatDialog,
    private containersService: ContainersService,
    private dpProgressBarService: DpProgressBarService,
    private gaService: GoogleAnalyticsService,
    private milestone: MilestoneService,
    public userAccessService: UserAccessService,
    private utilsService: UtilsService
  ) {
    this.router.events
      .pipe(
        withLatestFrom(this.isHandset$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe((_) => this.onClose());

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlTokens = event.url.split('/');
        this.currentRouteURL = `/${urlTokens[1]}`;
      }
    });

    this.isHandset$.subscribe((data) => {
      if (data === true) {
        this.renderer.addClass(document.body, 'isHandset');
      } else {
        this.renderer.removeClass(document.body, 'isHandset');
      }
      this.uiService.isHandsetChanged.next(data);
      // //console.log('uiService is set to' + data);
    });

    this.unsubscribeAll = new Subject();

    window['dataLayer'] = window['dataLayer'] || [];
  }

  ngOnInit(): void {
    this.dpConfigService.config.pipe(takeUntil(this.unsubscribeAll)).subscribe((config) => {
      this.dpConfig = config;
    });

    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      this.org = this.authService.currentOrganizationValue;
      this.sideNavDataFromOrg = this.org?.organizationProperties?.find((prop) => prop.propertyKey === 'sideNav')?.propertyValue || [];
      this.orgCode = user ? user.orgCode : '';
      this.isAdmin = user ? user.isAdmin : false;
      this.orgType = user?.accountType;
      this.isQuickTrackAllowed = this.authService.currentUserValue?.isQuickTrackingEnabled || this.userAccessService.getOrgIdForQuickTrack() === this.authService.currentUserValue?.organizationId;
      this.isOLShipmentsUploadAllowed = this.user?.isOutsourcedLogisticsShipmentTrackingAllowed;
      this.isTruckShipmentsUploadAllowed = user?.accountCapabilities?.isTruckShipmentsUploadAllowed;

      if (user?.accountType !== ACCOUNT_TYPES.PLUS) {
        this.navItemsList = [...NavItemsList];
      } else {
        this.navItemsList = [...AusTenantNavItemsList];
      }
      if (this.orgCode) {
        this.navItemsList = this.navItemsList
          .filter((item) => {
            return this.showMenu(item.navItem);
          })
          .map((item) => {
            if (item.navItem.uri === '/shipments') {
              item.children = item.children.filter(
                (childItem) =>
                  childItem.navItem.uri !== '/outsourced-logistics/tracking' || this.user?.isOutsourcedLogisticsShipmentTrackingAllowed
              );
            }
            return item;
          });
      }
      this.loadSquareLogoFailed = false;
      this.loadLongLogoFailed = false;
    });
  }

  private navItemVisibilityCache: { [key: string]: boolean } = {};
  showNavItemWithParentKey(labelKey: string, extType: TemplateFileExtensionTypes, root = 'add_shipments'): boolean {
    if (!this.sideNavDataFromOrg.length) return true;

    const cacheKey = `${labelKey}-${extType}-${root}`;
    if (this.navItemVisibilityCache.hasOwnProperty(cacheKey)) {
      return this.navItemVisibilityCache[cacheKey];
    }

    const parentLabelKey = extType === TemplateFileExtensionTypes.csv ? 'download_csv_template' : 'download_excel_template';
    let result = false;

    if (this.sideNavDataFromOrg.length) {
      const rootItem = this.sideNavDataFromOrg.find((item) => item.labelKey === root);
      if (rootItem) {
        const parentItem = (rootItem.children as BaseMenu[]).find((item) => item.labelKey === parentLabelKey);
        if (parentItem) {
          result = !!((parentItem as MenuSchema).children as BaseMenu[]).find((item) => item.labelKey === labelKey);
        }
      }
    }

    this.navItemVisibilityCache[cacheKey] = result;
    return result;
  }

  showNavItem(labelKey: string, current: MenuSchema[] | TemplateMenuSchema[] = this.sideNavDataFromOrg): boolean {
    if (!this.sideNavDataFromOrg.length) return true;

    const cacheKey = `${labelKey}`;
    if (this.navItemVisibilityCache.hasOwnProperty(cacheKey)) {
      return this.navItemVisibilityCache[cacheKey];
    }

    let found = false;
    for (const item of current) {
      if (item.labelKey === labelKey) {
        found = true;
        break; // Exit the loop if the item is found
      }
      if ('children' in item && item.children.length) {
        found = this.showNavItem(labelKey, item.children);
        if (found) break; // Exit the outer loop if the item is found in the children
      }
    }
    if (current === this.sideNavDataFromOrg) {
      this.navItemVisibilityCache[cacheKey] = found;
    }
    return found;
  }

  onClose() {
    this.closeSidenav.emit();
  }

  showMenu(item?: NavItem) {
    if (!item) return false;

    if (item.uri === '/container-pickup') {
      return this.orgType === ACCOUNT_TYPES.ENTERPRISE;
    } else if (item.uri === '/welcome') {
      return this.isQuickTrackAllowed;
    }
    return true;
  }

  onMenuItemClick(item: SideNavBarItem, event: MouseEvent) {
    if (item.children?.length) {
      if (this.isExpanded) {
        item.isShown = !item.isShown;
      } else {
        const { uri, matMenu } = item.navItem || {};
        if (matMenu) {
          this.menuOpened = matMenu;
          switch (matMenu) {
            case 'schedules':
              this.menuTrigger = this.schedulesMenu;
              break;
            case 'shipments':
              this.menuTrigger = this.shipmentsMenu;
              break;
          }
          this.children = item.children;
          this.showMenuOptions(event, this.menuTrigger);
        } else if (uri) {
          //TODO: looks like this path won't happen
          this.router.navigateByUrl(uri);
        }
      }
    } else {
      this.trackNavigateByUrl(item.navItem.label, item.navItem.uri);
      const routePath = this.utilsService.migrateRouteHandler(item.navItem.uri);
      !!routePath && this.router.navigateByUrl(routePath);
    }
  }

  trackNavigateByUrl(label: string, uri: string) {
    window['dataLayer'].push({
      eventDetail: null,
    });
    window['dataLayer'].push({
      event: environment.gaEvents.names.navbar,
      eventDetail: {
        eventCategory: environment.gaEvents.categories.navBar,
        eventAction: label,
        eventLabel: uri,
      },
    });
  }

  toggleChild(item: SideNavBarItem) {
    item.isShown = !item.isShown;
  }

  showMenuOptions(event: MouseEvent, menuTrigger: MatMenuTrigger) {
    this.contextMenuPosition.x = event.currentTarget['offsetLeft'] + event.currentTarget['offsetWidth'] + 'px';
    this.contextMenuPosition.y = event.currentTarget['offsetTop'] + event.currentTarget['clientHeight'] + 11 + 'px';
    menuTrigger.menu.focusFirstItem('mouse');
    menuTrigger.openMenu();
  }

  selectItem(event) {
    const clickedAnchor = event.path.find((path) => path.tagName === 'A') as HTMLElement;
    if (clickedAnchor !== this.selectedAnchor) {
      if (this.selectedAnchor) {
        this.selectedAnchor.className = this.selectedAnchor.className.replace(this.selectedAnchorBg, '');
      }
      this.selectedAnchor = clickedAnchor;
      this.selectedAnchor.className = this.selectedAnchor.className.concat(this.selectedAnchorBg);
    }
  }

  loadSquareLogoFail() {
    this.loadSquareLogoFailed = true;
  }

  loadLongLogoFail() {
    this.loadLongLogoFailed = true;
  }

  // addContainer() {
  //   // this.addContainerDlg.open(AddContainerDlgComponent, { maxHeight: 750, disableClose: true });
  // }

  addShipments(shipmentType: ShipmentType) {
    // push event to dataLayer
    window['dataLayer'].push({
      eventDetail: null,
    });
    window['dataLayer'].push({
      event: environment.gaEvents.names.navbar,
      eventDetail: {
        eventCategory: environment.gaEvents.categories.navBar,
        eventAction:
          shipmentType === ShipmentType.AIR_SHIPMENT
            ? 'Add Air Shipments'
            : ShipmentType.OL_SHIPMENT
            ? 'Add Outsourced Logistics Shipments'
            : 'Add Ocean Shipments',
        eventLabel: '/add-shipments/' + shipmentType,
      },
    });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/add-shipments', shipmentType]));
  }

  trackDownloadEvent(type: AddContainerByTypes, extType: TemplateFileExtensionTypes) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      eventDetail: null,
    });
    let containerType = '';
    switch (type) {
      case AddContainerByTypes.ByContainer:
        containerType = 'container';
        break;
      case AddContainerByTypes.ByBooking:
        containerType = 'booking';
        break;
      case AddContainerByTypes.ByMBL:
        containerType = 'MBL';
        break;
      case AddContainerByTypes.ByVessel:
        containerType = 'vessel';
        break;
      case AddContainerByTypes.ByAir:
        containerType = 'air';
        break;
      case AddContainerByTypes.ByOL:
        containerType = 'outsourced-logistics';
        break;
      case AddContainerByTypes.ByRoadUpdate:
      case AddContainerByTypes.ByRoad:
        containerType = 'road';
        break;
      case AddContainerByTypes.ByBulk:
        containerType = 'bulk';
        break;
      default:
        break;
    }
    const extTypeName = extType === TemplateFileExtensionTypes.csv ? 'CSV' : 'Excel';
    const eventDetail = {
      eventCategory: environment.gaEvents.categories.navBar,
      eventAction: `download ${extTypeName} template of upload ${containerType} #`,
    };
    this.authService.logEvent(environment.gaEvents.names.navbar, eventDetail);
  }

  downloadTemplate(type: AddContainerByTypes, extType: TemplateFileExtensionTypes) {
    this.milestone.checkForEvent(environment.gaEvents.milestones.downloadTemplate);

    this.trackDownloadEvent(type, extType);

    this.dpProgressBarService.show();
    this.containersService
      .downloadTemplateFile2(type, extType)
      .pipe(
        finalize(() => {
          this.dpProgressBarService.hide();
        })
      )
      .subscribe(
        (result: BlobPart) => {
          const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          const templateURL = URL.createObjectURL(new Blob([result], { type: `text/csv` }));
          csvDownloadAnchor.href = templateURL;
          csvDownloadAnchor.download = this.getTemplateName(type, extType);
          csvDownloadAnchor.click();
          URL.revokeObjectURL(templateURL);
        },
        (error) => {
          console.error('Download template file error', error);
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  getTemplateName(type: AddContainerByTypes, ext: TemplateFileExtensionTypes): string {
    const fileName =
      type === AddContainerByTypes.ByContainer
        ? 'CONTAINER_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByMBL
        ? 'UPLOAD_BY_MBL_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByBooking
        ? 'UPLOAD_BY_BOOKING_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByVessel
        ? 'UPLOAD_BY_VESSEL_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByAir
        ? 'UPLOAD_BY_AWB_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByOL
        ? 'UPLOAD_BY_OUTSOURCED_LOGISTICS_TEMPLATE'
        : type === AddContainerByTypes.ByRoad
        ? 'VEHICLE_NUMBER_UPLOAD_TEMPLATE'
        : type === AddContainerByTypes.ByBulk
        ? 'BULK_UPDATE_TEMPLATE'
        : type === AddContainerByTypes.ByRoadUpdate
        ? 'ROAD_BULK_UPDATE_TEMPLATE'
        : type === AddContainerByTypes.ByMileStone
        ? 'BULK_MILESTONE_UPDATE_TEMPLATE'
        : '';

    if (fileName === 'BULK_UPDATE_TEMPLATE') return `${fileName}.zip`;
    const fileExt = ext === TemplateFileExtensionTypes.csv ? 'csv' : 'xlsx';
    return `${fileName}.${fileExt}`;
  }

  contextMenuPosition = { x: '0px', y: '0px' };
  showContextMenu(event: MouseEvent) {
    this.milestone.checkForEvent(environment.gaEvents.milestones.uploadDiscovered);

    event.preventDefault();
    this.contextMenuPosition.x = event.currentTarget['offsetLeft'] + event.currentTarget['offsetWidth'] + 'px';
    this.contextMenuPosition.y = event.currentTarget['offsetTop'] + event.currentTarget['clientHeight'] + 11 + 'px';
    this.contextMenu1.menu.focusFirstItem('mouse');
    this.contextMenu1.openMenu();
  }
  trackGotoUploadHistory() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      eventDetail: null,
    });
    window['dataLayer'].push({
      event: environment.gaEvents.names.navbar,
      eventDetail: {
        eventCategory: environment.gaEvents.categories.navBar,
        eventAction: 'Upload History',
        eventLabel: '/upload-history',
      },
    });
  }

  gotoUploadHistory(openReport: boolean, result: IUploadResult) {
    this.trackGotoUploadHistory();
    //note: a solution to force the url to reload, even if it is the current url.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: openReport,
          ...result,
        },
      });
    });
  }

  isSelected(item: SideNavBarItem) {
    if (this.isExpanded || !item.children?.length) {
      return item.navItem.label === SideNavRouteVsLabel[this.currentRouteURL];
    }
    return item.children.filter((child) => child.navItem.label === SideNavRouteVsLabel[this.currentRouteURL]).length;
  }

  isSubMenuOpen(item: SideNavBarItem) {
    if (this.menuOpened) {
      if (item.navItem.matMenu === this.menuOpened) {
        return true;
      }
      return false;
    }

    return this.isSelected(item);
  }

  menuClosed() {
    this.menuOpened = '';
  }

  getSvgAttributes(navItem: NavItem) {
    return navItem.uri === '/outsourced-logistics/tracking' ? this.olSvgAttributes : this.svgAttributes;
  }
}
