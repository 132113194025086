import { LogoMode } from "@dp/components/logo/logo.component";
import { environment } from "environments/environment";

const CDN_BASE_URL = environment.cdnAssetsBasePath;

//uds_icon
export const UDS_ICON_MAP = {
  HeaderUser: `${CDN_BASE_URL}uds_icons/HeaderUser.svg`,
  helpCenter: `${CDN_BASE_URL}uds_icons/helpCenter.svg`,
  notification: `${CDN_BASE_URL}uds_icons/notification.svg`,
  invoices: `${CDN_BASE_URL}uds_icons/invoices.svg`,
  advanceConsent: `${CDN_BASE_URL}uds_icons/advance-consent.svg`,
  logout: `${CDN_BASE_URL}uds_icons/logout.svg`,
  manage_users: `${CDN_BASE_URL}uds_icons/manage_users.svg`,
  user_settings: `${CDN_BASE_URL}uds_icons/user_settings.svg`,
  company_settings: `${CDN_BASE_URL}uds_icons/company_settings.svg`,
  support: `${CDN_BASE_URL}uds_icons/online_chat.svg`,
  contact: `${CDN_BASE_URL}uds_icons/contact.svg`,
  tutorial: `${CDN_BASE_URL}uds_icons/tutorial.svg`,
  info: `${CDN_BASE_URL}uds_icons/info.svg`,
  infoIcon: `${CDN_BASE_URL}uds_icons/info-icon.svg`,
  addTag: `${CDN_BASE_URL}uds_icons/addTag.svg`,
  flag: `${CDN_BASE_URL}uds_icons/flag.svg`,
  flagFilled: `${CDN_BASE_URL}uds_icons/flagFilled.svg`,
  share_person: `${CDN_BASE_URL}uds_icons/share_person.png`,
  document: `${CDN_BASE_URL}uds_icons/document.svg`,
  note: `${CDN_BASE_URL}uds_icons/note.svg`,
  product: `${CDN_BASE_URL}uds_icons/product.svg`,
  productMaster: `${CDN_BASE_URL}uds_icons/product_master.svg`,
  report: `${CDN_BASE_URL}uds_icons/report.svg`,
  exception: `${CDN_BASE_URL}uds_icons/exception.svg`,
  exceptionWarning: `${CDN_BASE_URL}uds_icons/exception-red.svg`,
  exceptionIcon: `${CDN_BASE_URL}uds_icons/exceptionIcon.svg`,
  exceptionIcon2: `${CDN_BASE_URL}uds_icons/exceptionIcon2.svg`,
  pod: `${CDN_BASE_URL}uds_icons/pod.svg`,
  edit: `${CDN_BASE_URL}uds_icons/edit.svg`,
  search: `${CDN_BASE_URL}uds_icons/search.svg`,
  search_blue: `${CDN_BASE_URL}uds_icons/search_blue.svg`,
  export: `${CDN_BASE_URL}uds_icons/export.svg`,
  notFoundContainers: `${CDN_BASE_URL}uds_icons/notFoundContainers.svg`,
  noResultMileStones: `${CDN_BASE_URL}uds_icons/noResultMileStones.png`,
  noResultSchedules: `${CDN_BASE_URL}uds_icons/noResultSchedules.png`,
  noResultExceptions: `${CDN_BASE_URL}uds_icons/noResultExceptions.png`,
  noNotifications: `${CDN_BASE_URL}uds_icons/noNotifications.png`,
  noResultTbgLeft: `${CDN_BASE_URL}uds_icons/noResultTbgLeft.png`,
  noOrderProductsFound: `${CDN_BASE_URL}uds_icons/noOrderProductsFound.png`,
  noOrderShipmentsFound: `${CDN_BASE_URL}uds_icons/noOrderShipmentsFound.png`,
  noCalendarFound: `${CDN_BASE_URL}uds_icons/noCalendarFound.png`,
  noCalendarFoundHR: `${CDN_BASE_URL}uds_icons/noCalendarFoundHighResolution.png`,
  noExceptionsFound: `${CDN_BASE_URL}uds_icons/noCalendarFound.png`,
  noMilestonesFound: `${CDN_BASE_URL}uds_icons/noMilestonesFound.png`,
  oceanOnDashboard: `${CDN_BASE_URL}uds_icons/oceanOnDashboard.png`,
  airOnDashboard: `${CDN_BASE_URL}uds_icons/airOnDashboard.png`,
  noResultTbg: `${CDN_BASE_URL}uds_icons/noResultTbg.png`,
  noExceptions: `${CDN_BASE_URL}uds_icons/noExceptions.svg`,
  noResultFound: `${CDN_BASE_URL}uds_icons/no_result_found.svg`,
  addContainer: `${CDN_BASE_URL}uds_icons/addContainer.svg`,
  history: `${CDN_BASE_URL}uds_icons/history.svg`,
  upload: `${CDN_BASE_URL}uds_icons/upload.svg`,
  open_eye: `${CDN_BASE_URL}uds_icons/open_eye.svg`,
  open_eye_gray: `${CDN_BASE_URL}uds_icons/open_eye_gray.svg`,
  filter: `${CDN_BASE_URL}uds_icons/filter.svg`,
  filter1: `${CDN_BASE_URL}uds_icons/filter1.svg`,
  filterActive: `${CDN_BASE_URL}uds_icons/filterActive.svg`,
  delete: `${CDN_BASE_URL}uds_icons/delete.svg`,
  complete: `${CDN_BASE_URL}uds_icons/complete.svg`,
  inComplete: `${CDN_BASE_URL}uds_icons/inComplete.svg`,
  shareLink: `${CDN_BASE_URL}uds_icons/shareLink.svg`,
  arrowDown: `${CDN_BASE_URL}uds_icons/arrowDown.svg`,
  arrowUp: `${CDN_BASE_URL}uds_icons/arrowUp.svg`,
  airFreight: `${CDN_BASE_URL}uds_icons/airFreight.svg`,
  uds_delete: `${CDN_BASE_URL}uds_icons/uds_delete.svg`,
  f_tracking: `${CDN_BASE_URL}uds_icons/f_tracking.svg`,
  f_supplyChain: `${CDN_BASE_URL}uds_icons/f_supplyChain.svg`,
  f_exception: `${CDN_BASE_URL}uds_icons/f_exception.svg`,
  f_insight: `${CDN_BASE_URL}uds_icons/f_insight.svg`,
  demurrage_configuration: `${CDN_BASE_URL}uds_icons/demurrage-configuration.svg`,
  airShipment: `${CDN_BASE_URL}uds_icons/air-shipments.svg`,
  olShipment: `${CDN_BASE_URL}uds_icons/Outsourced_logistic_icon.svg`,
  roadShipment: `${CDN_BASE_URL}uds_icons/road-1.svg`,
  flight: `${CDN_BASE_URL}uds_icons/flight.svg`,
  vessel: `${CDN_BASE_URL}uds_icons/vessel.svg`,
  co2: `${CDN_BASE_URL}uds_icons/co2.svg`,
  blueTick: `${CDN_BASE_URL}uds_icons/blueTickIcon.svg`,
  whiteTick: `${CDN_BASE_URL}uds_icons/whiteTickIcon.svg`,
  closeIcon: `${CDN_BASE_URL}uds_icons/close-icon.svg`,
  closeIcon2: `${CDN_BASE_URL}uds_icons/close-icon2.svg`,
  dragIcon: `${CDN_BASE_URL}uds_icons/drag.svg`,
  shipments: `${CDN_BASE_URL}uds_icons/oceanSideNav.svg`,
  air_shipments: `${CDN_BASE_URL}uds_icons/air-shipment-2.svg`,
  truck: `${CDN_BASE_URL}uds_icons/road-1.svg`,
  expand: `${CDN_BASE_URL}uds_icons/expand.svg`,
  expandRev: `${CDN_BASE_URL}uds_icons/expand-rev.svg`,
  predictedEta: `${CDN_BASE_URL}uds_icons/predicted_eta.svg`,
  arrivingPredictedEta: `${CDN_BASE_URL}uds_icons/arriving_predicted_eta.svg`,
  infoExclamation: `${CDN_BASE_URL}uds_icons/info-exclamation.svg`,
  carouselLeft: `${CDN_BASE_URL}uds_icons/carousel-left.svg`,
  carouselRight: `${CDN_BASE_URL}uds_icons/carousel-right.svg`,
  closeIconFilter: `${CDN_BASE_URL}uds_icons/close_icon.svg`,
  demdetGraph: `${CDN_BASE_URL}uds_icons/demdetGraph.svg`,
  demdetTable: `${CDN_BASE_URL}uds_icons/demdetTable.svg`,
  config_icon: `${CDN_BASE_URL}uds_icons/config_icon.svg`,
  graph_loader: `${CDN_BASE_URL}uds_icons/graph_loader.svg`,
  no_graph_data: `${CDN_BASE_URL}uds_icons/no_graph_data.svg`,
  customisation_icon: `${CDN_BASE_URL}uds_icons/customisation_icon.svg`,
  port_vessel_change: `${CDN_BASE_URL}uds_icons/port_vessel_change.svg`,
  checkConsentStatus: `${CDN_BASE_URL}uds_icons/checkConsentStatus.svg`,
  vehicleupdate: `${CDN_BASE_URL}uds_icons/vehicleupdate.svg`,
  vehiclenotupdate: `${CDN_BASE_URL}uds_icons/vehiclenotupdate.svg`,
  warningvehicle: `${CDN_BASE_URL}uds_icons/warningvehicle.svg`,
  milestone: `${CDN_BASE_URL}uds_icons/milestone.svg`,
  time: `${CDN_BASE_URL}uds_icons/time.svg`,
  user_1: `${CDN_BASE_URL}uds_icons/user-1.svg`,
  user_3: `${CDN_BASE_URL}uds_icons/user-3.svg`,
  noNewsResult: `${CDN_BASE_URL}uds_icons/no_news_result.svg`,
  interModalJourney: `${CDN_BASE_URL}uds_icons/intermodal-journey.svg`,
  locations2: `${CDN_BASE_URL}uds_icons/locations.svg`,
  chatbotIcon: `${CDN_BASE_URL}uds_icons/chat-icon.svg`,
  addShipment: `${CDN_BASE_URL}uds_icons/add-2.svg`,
  dashboard: `${CDN_BASE_URL}uds_icons/dashboard-3.svg`,
  orders: `${CDN_BASE_URL}uds_icons/order-2.svg`,
  storage_cost_icon: `${CDN_BASE_URL}uds_icons/storage_cost.svg`,
  shipment: `${CDN_BASE_URL}uds_icons/shipment-parent.svg`,
  calendar_nav: `${CDN_BASE_URL}uds_icons/calendar-nav.svg`,
  analytics_nav: `${CDN_BASE_URL}uds_icons/analytics-nav.svg`,
  schedules: `${CDN_BASE_URL}uds_icons/schedulesParent.svg`,
  outsourced_logistics: `${CDN_BASE_URL}uds_icons/Outsourced_logistic_icon.svg`,
};

//images
export const IMAGES_MAP = {
  cargoFlowNewSquare: `${CDN_BASE_URL}/cargoflow-new-square.svg`,
  cargoFlowNew: `${CDN_BASE_URL}/cargoflow-new.svg`,
  container_delivery: `${CDN_BASE_URL}images/svg/side-nav/dashboard/ContainerDelivery-2.svg`,
};

export const getCorpLogo = (orgCode, logoMode) => {
  let corp_logo = `${CDN_BASE_URL}images/corp_logos/${orgCode}`;
  switch(logoMode) {
    case LogoMode.compact:
      corp_logo = corp_logo + `_sqaure.png`;
      break;
    case LogoMode.wide:
      corp_logo = corp_logo + `_long.png`;
      break;
    case LogoMode.full:
      corp_logo = corp_logo + `_full.png`;
      break;
    default:
      break;
  };
  return corp_logo;
};