import { environment } from 'environments/environment';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { finalize, startWith, map } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentMap } from 'app/shipments2/shipments2.model';
import { DpWidgetApiService } from 'app/dp-widget/dp-widget-api-service';
import { Observable, Subscription } from 'rxjs';
import { LogoMode } from '@dp/components/logo/logo.component';
import { StaticDataService } from '@dp/services/static-data.service';
import { UntypedFormControl } from '@angular/forms';
import { ShipmentType } from 'app/shared/shared.model';
import { CaptchaBase } from 'app/auth/captcha.base';
import { TrackContainer } from './track-container';
import { UtilsService } from '@dp/services/utils.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { SharedApiService } from 'app/shared/shared.service';
import { UserAccessService } from '../../auth/user-access.service';

@Component({
  selector: 'dp-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: dpAnimations,
})
export class WelcomeComponent extends CaptchaBase implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  recaptcha = null;
  LogoMode = LogoMode;
  isAuth: boolean;
  orgCode = '';
  token: string;
  containerNumber: string | number;

  ShipmentType = ShipmentType;
  shipmentType: ShipmentType = ShipmentType.INTERMODAL_SHIPMENT;
  // shipmentType: ShipmentType = ShipmentType.AIR_SHIPMENT; // default to Air Shipment

  trackingSearchSub: Subscription;

  isBusy = false;
  errorText = '';
  payload: any; //payload response
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  // trackingNumber: string = 'AMFU8810207'; //test
  trackingNumber = '';

  source = ''; //user website source, i.e canada

  isDetectingCarrierName = false;
  canDetectCarrierName = true;
  showMessage = true;
  DEFAULT_CARRIER = 'Any carrier';
  carrierControl = new UntypedFormControl(this.DEFAULT_CARRIER);
  carriersListOptions: any[] = [];
  trackContainerModel: TrackContainer = {
    gToken: '',
    trackingNumber: '',
    shipmentType: '',
    carrierName: '',
    source: {},
  };

  filteredOptions: Observable<string[]> = this.carrierControl.valueChanges.pipe(
    startWith(''),
    map((value) => this._filter(value))
  );

  get carrierName(): string {
    return this.carrierControl.value;
  }

  private _filter(value: string): string[] {
    value = value.trim();
    // don't filter list
    if (value === this.DEFAULT_CARRIER) return this.carriersListOptions;
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase().trim();
    }
    return this.carriersListOptions.filter((option) => {
      if (option && option.carrier_name) {
        return option.carrier_name.toLowerCase().indexOf(filterValue) === 0;
      }
      return true;
    });
  }

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dpWidgetApiService: DpWidgetApiService,
    private staticDataService: StaticDataService,
    private utils: UtilsService,
    private sharedApiService: SharedApiService,
    private userAccessService: UserAccessService
  ) {
    super();
  }

  ngOnInit() {
    const staticData = this.staticDataService.getStaticDataDirect();
    this.carriersListOptions = staticData['carriers'];

    this.carrierControl.disable();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.containerNumber) {
        this.trackingNumber = params.containerNumber;
      }

      this.token = params['token'];
      if (this.token) {
        this.isAuth = false;
        this.authService.loginWithToken(this.token).subscribe(
          (user) => {
            this.router.navigate(['/']);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });

    this.authService.currentUser.subscribe((user) => {
      this.isAuth = this.authService.isAuth();
      this.orgCode = this.isAuth ? this.authService.currentUserValue.orgCode : '';
    });
  }

  clearData() {
    this.payload = null;
    this.errorText = '';
  }

  onClear() {
    this.trackingNumber = '';
    this.showMessage = false;
    this.carrierControl.reset(this.DEFAULT_CARRIER);
  }

  onCaptchaUpdate(event) {
    this.recaptcha = event;
    if (event) {
      this.pushEventToDatalayer(environment.gaEvents.categories.capchaValidated);
    }
  }

  onSearchEvent(e: any) {
    if (this.isBusy) return; // if search in progress
    if (!this.isAuth && !this.recaptcha) {
      console.log('Please check recaptcha');
      return;
    }

    const careerValue =
      this.shipmentType === ShipmentType.INTERMODAL_SHIPMENT ? 'OCEAN' : this.shipmentType === ShipmentType.TRUCK_SHIPMENT ? 'ROAD' : 'AIR';

    if (typeof e === 'string') {
      this.trackContainerModel.trackingNumber = e.trim().toUpperCase();
    } else if (e instanceof KeyboardEvent) {
      const value = (e.target as HTMLInputElement).value;
      this.trackContainerModel.trackingNumber = value.trim().toUpperCase();
    }

    if (!this.trackContainerModel.trackingNumber || !this.carrierName) {
      return;
    }

    this.pushEventToDatalayer(
      environment.gaEvents.categories.searchClick,
      this.trackContainerModel.trackingNumber,
      this.carrierName,
      careerValue
    );
    this.isBusy = true;
    this.showMessage = false;
    this.clearData();
    this.trackContainerModel.gToken = this.recaptcha || undefined;
    this.trackContainerModel.shipmentType = this.shipmentType;
    this.trackContainerModel.carrierName = this.carrierName;
    this.trackContainerModel.source = this.source;

    this.trackingSearchSub = this.dpWidgetApiService
      // test using a carrierName
      .searchContainer(this.trackContainerModel)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (payload: any) => {
          this.resetRecaptcha();
          if (payload.error) {
            this.errorText = payload.error;
            this.pushEventToDatalayer(
              environment.gaEvents.categories.failure,
              this.trackContainerModel.trackingNumber,
              this.carrierName,
              careerValue
            );
            return;
          }
          this.sharedApiService.initFeedbackWidget.next(true);
          this.payload = payload;
          const journey = Array.isArray(payload.data) ? payload.data[0].transportJourneys : payload.data.transportJourneys;
          const source = this.shipmentType === ShipmentType.TRUCK_SHIPMENT ? journey.roadTransport.origin : journey.portToPort.originPort;
          const destination =
            this.shipmentType === ShipmentType.TRUCK_SHIPMENT ? journey.roadTransport.destination : journey.portToPort.destinationPort;
          const dataParams = {
            search_route_from: source,
            search_route_to: destination,
          };
          this.pushEventToDatalayer(
            environment.gaEvents.categories.success,
            this.trackContainerModel.trackingNumber,
            this.carrierName,
            careerValue,
            dataParams
          );
        },
        (error) => {
          this.clearData();
          this.resetRecaptcha();
          if (typeof error === 'string') {
            this.errorText = error;
          } else if (error && error.status === 429) {
            this.errorText = 'Too many requests. Please wait a moment.';
          } else if (error) {
            if (this.shipmentType === ShipmentType.AIR_SHIPMENT) {
              this.errorText = 'Please provide a valid AWB number.';
            } else {
              this.errorText = 'Please provide a valid tracking number.';
            }
          } else {
            this.errorText = "We couldn't find any information for this tracking number.";
          }
          this.pushEventToDatalayer(environment.gaEvents.categories.error, this.errorText, this.carrierName, careerValue);
        }
      );
  }

  resetRecaptcha() {
    if (this.isAuth) {
      return;
    }
    this.captchaElem.reloadCaptcha();
    this.recaptcha = null;
  }

  handleSuccess(value: string) {
    this.dpWidgetApiService.reCaptcha = value;
  }

  shipmentTypeChanged() {
    // console.log('shipmentType', this.shipmentType);
  }

  onValueChange({ newValue, oldValue }) {
    if (newValue?.length && !oldValue) {
      this.pushEventToDatalayer(environment.gaEvents.categories.initiated);
    }
    if (!newValue || !newValue.trim()) {
      this.carrierControl.reset(this.DEFAULT_CARRIER);
      // this.canDetectCarrierName = false;
      this.trackingNumber = '';
      this.carrierControl.disable();
      return;
    }

    this.carrierControl.enable();
    this.trackingNumber = newValue.trim();
    this.showMessage = true;
    // Todo test more
    this.isDetectingCarrierName = true;

    // TODO: check carrier name with Tracking Number at least 5 characters
    this.dpWidgetApiService.getCarrierName(newValue, this.shipmentType).subscribe(
      (payload: { carrierName: string }) => {
        if (payload) {
          if (payload.carrierName) {
            this.carrierControl.setValue(payload.carrierName);
            this.canDetectCarrierName = true;
          } else {
            // null
            this.carrierControl.setValue(this.DEFAULT_CARRIER);
            this.canDetectCarrierName = false;
          }
        }
        this.isDetectingCarrierName = false;
      },
      (error) => {
        this.carrierControl.reset(this.DEFAULT_CARRIER);
        this.isDetectingCarrierName = false;
        console.error('error: ', error);
      }
    );
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  cancelClicked() {
    console.log('cancelClicked;');
    if (this.trackingSearchSub) {
      this.trackingSearchSub.unsubscribe();
    }
  }

  keyup(): void {
    return;
  }

  getPlaceholderText(shipmentType) {
    if (shipmentType === ShipmentType.INTERMODAL_SHIPMENT) return 'Please enter the container #, or MBL #';
    if (shipmentType === ShipmentType.TRUCK_SHIPMENT) return 'Please enter the tracking #';
    if (shipmentType === ShipmentType.AIR_SHIPMENT) return 'Please enter the AWB #';
  }

  pushEventToDatalayer(eventAction, eventLabel?, careerValue?, shippingMode?, dataParams?) {
    let event = {
      event: environment.gaEvents.actions.publicSearch,
      eventDetails: {
        eventCategory: environment.gaEvents.categories.publicSearch,
      },
    };
    if (dataParams) {
      event = { ...event, ...dataParams };
    }
    if (eventAction) {
      event.eventDetails['eventAction'] = eventAction;
    }
    if (eventLabel) {
      event.eventDetails['eventLabel'] = eventLabel;
    }
    if (careerValue) {
      event.eventDetails['carrierName'] = careerValue;
    }
    if (shippingMode) {
      event.eventDetails['shippingMode'] = shippingMode;
    }

    window['dataLayer'].push({ eventdetail: null });
    window['dataLayer'].push(event);
  }

  isQuickTrackAllowed(): boolean {
    return this.authService.currentUserValue?.isQuickTrackingEnabled || this.userAccessService.getOrgIdForQuickTrack() === this.authService.currentUserValue?.organizationId;
  }
}
