<ng-container *ngIf="minThresholdValue || maxThresholdValue">
  <div class="inline-block">
    Required {{sensorType}}:
  </div>
  <div class="inline-block">
    <span class="LOW ml-8" *ngIf="minThresholdValue">{{Utility.getSensorText(minThresholdValue, this.uom,
      this.sensorType)}}</span>
    <ng-container *ngIf="minThresholdValue && maxThresholdValue">
      &nbsp;&nbsp;~&nbsp;&nbsp;
    </ng-container>
    <span class="HIGH" *ngIf="maxThresholdValue">{{Utility.getSensorText(maxThresholdValue, this.uom,
      this.sensorType)}}</span>
  </div>

</ng-container>