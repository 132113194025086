<div class="page-title">
  Invite Users
</div>

<div fxLayout="column" style="width: 25rem; margin: 1rem;">

  <form [formGroup]="emailFormGroup">

    <div Name="emailArray">

      <div *ngFor="let email of getEmailControls(); let i=index">

        <mat-form-field class="email-field">
          <input matInput placeholder="email address" type="email" [formControlName]="i" autocomplete="off" required>

          <mat-error *ngIf="email.invalid">{{ getErrorMessage(email) }}</mat-error>
        </mat-form-field>

        <button (click)="removeEmailSlot(i)" mat-button>
          <mat-icon>clear</mat-icon>
        </button>

      </div>
    </div>
  </form>

  <a role="button" style="cursor: pointer; margin-top: 1rem;" (click)="addNewEmailSlot()">
    Add More
  </a>
</div>

<button mat-button style="width: 49%;" (click)="onCancelClicked()">
  Cancel
</button>

<button mat-button style="width: 49%;" type="submit" (click)="onSubmitClicked()" [disabled]="!emailFormGroup.valid">
  Submit
</button>