<div class="title">Products</div>
<div class="subTitle">Get notified of shipment with any selected products.</div>
<div #contentZone class="contentZone">
  <div *ngIf="initializing"></div>

  <ng-container *ngIf="!initializing">
    <div *ngIf="noProduct">
      <div class="flex">
        <div>
          <img style="margin: 20px auto; width: 30px; display: block" src="assets/images/svg/failed.svg" />
          <p>You haven't created any product yet!</p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!noProduct">
      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        class="uds-table colored-header"
        *ngIf="dataSource?.data?.length || addingMode"
      >
        <ng-container *ngFor="let item of columnsToDisplay">
          <ng-container matColumnDef="{{ item }}">
            <th mat-header-cell *matHeaderCellDef>{{ tableColumnMap[item] }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container [ngSwitch]="item">
                <ng-container *ngSwitchCase="'delete'">
                  <button mat-icon-button (click)="removeProduct(element)">
                    <dp-svg [src]="SvgMap.delete"></dp-svg>
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="'productCode'">
                  <span style="max-width: 300px;" #theElementYouWantToCheck class="ellipsis" (dpHesitate)="openPopover(popoverTrigger,element[item], theElementYouWantToCheck)" [duration]="300"
                    [mdePopoverTriggerFor]="partnersPopover" [mdePopoverOverlapTrigger]="true" mdePopoverTriggerOn="none"
                    (mouseleave)="closePopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger">
                    {{ element[item] }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <span>{{ element[item] }}</span>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>

      <!-- <div *ngIf="!dataSource || !dataSource.data.length" class="flex">
        <div>
          <img style="margin: 20px auto; width: 30px; display: block" src="assets/images/svg/failed.svg" />
          <div class="no-data">No product subscribed yet!</div>
        </div>
      </div> -->

      <ng-container *ngIf="!subscribedAllProducts()">
        <button *ngIf="!addingMode" mat-flat-button class="easy-button secondary medium add-product-btn" (click)="onAddProduct()">
          {{dataSource?.data?.length ? 'Add another Product' : 'Add a product'}}
        </button>
        <div class="fields easy-form no-hint" fxLayout="row" [hidden]="!addingMode || !products.length">
          <mat-form-field class="form-group add-product" appearance="outline">
            <mat-label>Add a product</mat-label>
            <mat-select placeholder="Add a product" #productList [disableOptionCentering]="true" [(ngModel)]="productToAdd" (selectionChange)="onProductSelection()">
              <mat-option>
                <ngx-mat-select-search [formControl]="productFilterCtrl" placeholderLabel="Search..." noEntriesFoundLabel="'No matching product found'">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                {{product.productNumber || product.productCode || productToAdd?.productDescription || productToAdd?.HSCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="form-group desc">
            <mat-input>{{ productToAdd?.productDescription || productToAdd?.description }}</mat-input>
          </div>
          <div class="form-group code">
            <mat-input>{{ productToAdd?.HSCode || productToAdd?.hsCode }}</mat-input>
          </div>
          <div class="btn-wrapper">
            <button mat-icon-button (click)="addingMode = false">
              <dp-svg [src]="SvgMap.delete"></dp-svg>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="subscribedAllProducts()">
        <p>Great job, you have subscribed shipment notifications with all products</p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<mde-popover id="partnersPopover" #partnersPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverOffsetY="25">
  <div class="indicatorContent mat-elevation-z10" *ngIf="productNoOrCode">
    <p class="multiple-line"> {{productNoOrCode}} </p>
  </div>
</mde-popover>