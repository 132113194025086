import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BingMapsService {
  map: Microsoft.Maps.Map;
  clusterLayer: Microsoft.Maps.ClusterLayer;
  clusterPins = {};

  loadMap(id: string, config: Microsoft.Maps.IMapLoadOptions = {}) {
    this.map = new Microsoft.Maps.Map(id, {
      credentials: environment.bingMapkey,
      disableScrollWheelZoom: true,
      showLocateMeButton: false,
      mapTypeId: Microsoft.Maps.MapTypeId.road,
      showMapTypeSelector: false,
      showScalebar: false,
      showTermsLink: false,
      enableClickableLogo: false,
      ...config,
    });
  }

  location(lat: number, lon: number) {
    return new Microsoft.Maps.Location(lat, lon);
  }

  pushpin(location: Microsoft.Maps.Location, options?: Microsoft.Maps.IPushpinOptions) {
    return new Microsoft.Maps.Pushpin(location, options);
  }

  polyline(locations: Microsoft.Maps.Location[], options?: Microsoft.Maps.IPolylineOptions) {
    return new Microsoft.Maps.Polyline(locations, options);
  }

  infobox(location: Microsoft.Maps.Location, options?: Microsoft.Maps.IInfoboxOptions) {
    return new Microsoft.Maps.Infobox(location, options);
  }

  point(x: number, y: number) {
    return new Microsoft.Maps.Point(x, y);
  }

  setView(viewOptions: Microsoft.Maps.IViewOptions) {
    this.map.setView(viewOptions);
  }

  pushEntities(primitive: Microsoft.Maps.IPrimitive | Microsoft.Maps.IPrimitive[]) {
    this.map.entities.push(primitive);
  }

  locationRect(locations: Microsoft.Maps.Location[]) {
    return Microsoft.Maps.LocationRect.fromLocations(locations);
  }

  addEventListener(target: any, eventName: string, handler: (eventArg?: any) => void) {
    return Microsoft.Maps.Events.addHandler(target, eventName, handler);
  }

  setBoardCustomInfobox(location: Microsoft.Maps.Location, content: string, visible = false) {
    const infobox = this.infobox(location, {
      htmlContent: `<div class="customBoardInfobox">
        ${content}
      </div>`,
      visible,
    });
    infobox.setMap(this.map);
    return infobox;
  }

  setCustomInfobox(location: Microsoft.Maps.Location, content: string, visible = false) {
    const infobox = this.infobox(location, {
      htmlContent: `<div class="bingMapsCustomInfobox">
        <span class="body">${content}</span>
        <span class="infobox-close-img">x</span>
        <span class="arrow"></span>
      </div>`,
      visible,
    });
    infobox.setMap(this.map);
    return infobox;
  }

  infoboxCloseHandler(infobox: Microsoft.Maps.Infobox): void {
    this.addEventListener(infobox, 'click', (e) => {
      const isCloseAction = e.originalEvent?.target?.['className'] == 'infobox-close-img';
      if (isCloseAction) {
        e.target.setOptions({ visible: false });
      }
    });
  }

  createClusterLayer(pushpins: Microsoft.Maps.Pushpin[], options?: Microsoft.Maps.IClusterLayerOptions) {
    this.clusterLayer = new Microsoft.Maps.ClusterLayer(pushpins, {
      clusteredPinCallback: this.createCustomClusteredPin,
      callback: this.createPushpinList,
      gridSize: 80,
      ...options,
    });
    this.map.layers.insert(this.clusterLayer);
  }

  loadClusteringModule(options?: (() => void) | Microsoft.Maps.IModuleOptions): void {
    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', options);
  }

  createPushpinList = () => {
    if (this.clusterLayer != null) {
      const pins = this.clusterLayer.getDisplayedPushpins();
      for (let i = 0, len = pins.length; i < len; i++) {
        const pin = pins[i];
        !this.clusterPins[pin['id']] &&
          pin.metadata?.infobox?.setOptions({
            visible: true,
          });
      }
    }
    this.clusterPins = {};
  };

  createCustomClusteredPin = (cluster: Microsoft.Maps.ClusterPushpin) => {
    cluster.containedPushpins?.forEach((pin) => {
      this.clusterPins[pin['id']] = true;
      pin.metadata?.infobox?.setOptions({
        visible: false,
      });
    });

    const pushpinData = this.pushpinsInfo(cluster.containedPushpins);

    //Customize the clustered pushpin using the generated SVG and anchor on its center.
    cluster.setOptions({
      icon: this.getClusterIcon(pushpinData),
      anchor: this.point(20, 20),
      text: '',
    });

    //Add click event to clustered pushpin
    this.addEventListener(cluster, 'click', this.clusterClicked);
  };

  pushpinsInfo(containedPushpins: Microsoft.Maps.Pushpin[]) {
    return containedPushpins.reduce(
      (acc, cur) => {
        acc.size += cur['metadata'].size;
        acc.severities.low += cur['metadata'].severities.low;
        acc.severities.medium += cur['metadata'].severities.medium;
        acc.severities.high += cur['metadata'].severities.high;
        return acc;
      },
      {
        size: 0,
        severities: {
          low: 0,
          medium: 0,
          high: 0,
        },
      }
    );
  }

  clusterClicked = (e) => {
    if (e.target.containedPushpins) {
      const locs = [];
      for (let i = 0, len = e.target.containedPushpins.length; i < len; i++) {
        const pin = e.target.containedPushpins[i];
        locs.push(pin.getLocation());
      }
      this.map.setView({ bounds: this.locationRect(locs), padding: 100 });
    }
  };

  // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
  getClusterIcon(pushpinData: any) {
    const { size, severities } = pushpinData;

    let textAlignX = 9;
    if (size >= 10 && size < 100) {
      textAlignX = 5;
    }
    let path = '';
    if (severities.low && severities.medium && severities.high) {
      path = `<path d="M1.47167 17.7615L1.61679 17.6775L4.19238 16.1906C3.51943 14.9421 3.13645 13.5149 3.13645 11.9999C3.13645 7.20493 6.96518 3.29 11.7257 3.1457V0C9.6226 0.0395531 7.8449 0.539286 5.99818 1.60531C0.346183 4.8683 -1.64811 12.0667 1.47167 17.7615Z" fill="#E53935"/>
      <path d="M22.2531 18.2347L19.5294 16.6621C17.9647 19.1804 15.1747 20.8616 11.9979 20.8616C8.82168 20.8616 6.03169 19.181 4.46703 16.6629C4.46703 16.6627 4.46703 16.6627 4.46703 16.6627L1.74414 18.235C2.82953 20.0364 4.15115 21.3264 5.99814 22.3929C7.82222 23.4461 9.89316 24.0029 11.987 24.0029C16.1761 24.0029 20.0845 21.7996 22.2531 18.2347Z" fill="#00C853"/>
      <path d="M12.2715 0V3.14488C17.0312 3.29054 20.8589 7.20493 20.8589 11.9993C20.8589 13.5141 20.4762 14.9407 19.8035 16.1889L22.5266 17.7612C23.5444 15.92 24.0002 14.1305 24.0002 11.9982C24.0002 5.47196 18.7636 0.145664 12.2715 0Z" fill="#FFA000"/>`;
    } else if (severities.low && severities.medium && !severities.high) {
      path = `<path d="M12.2734 0V3.14451C17.0328 3.28964 20.8605 7.2048 20.8605 12C20.8605 16.7952 17.0328 20.7104 12.2734 20.8552V24C18.7646 23.8543 24.0007 18.5272 24.0007 12C24.0007 5.47282 18.7646 0.145673 12.2734 0Z" fill="#FFA000"/>
      <path d="M11.7273 24V20.8552C6.96791 20.7104 3.13991 16.7952 3.13991 12C3.13991 7.2048 6.96791 3.28964 11.7273 3.14479V0C5.23609 0.145673 0 5.47282 0 12C0 18.5272 5.23609 23.8543 11.7273 24Z" fill="#00C853"/>`;
    } else if (!severities.low && severities.medium && severities.high) {
      path = `<path d="M12.2734 0V3.14451C17.0328 3.28964 20.8605 7.2048 20.8605 12C20.8605 16.7952 17.0328 20.7104 12.2734 20.8552V24C18.7646 23.8543 24.0007 18.5272 24.0007 12C24.0007 5.47282 18.7646 0.145673 12.2734 0Z" fill="#E53935"/>
      <path d="M11.7273 24V20.8552C6.96791 20.7104 3.13991 16.7952 3.13991 12C3.13991 7.2048 6.96791 3.28964 11.7273 3.14479V0C5.23609 0.145673 0 5.47282 0 12C0 18.5272 5.23609 23.8543 11.7273 24Z" fill="#FFA000"/>`;
    } else if (severities.low && !severities.medium && severities.high) {
      path = `<path d="M12.2734 0V3.14451C17.0328 3.28964 20.8605 7.2048 20.8605 12C20.8605 16.7952 17.0328 20.7104 12.2734 20.8552V24C18.7646 23.8543 24.0007 18.5272 24.0007 12C24.0007 5.47282 18.7646 0.145673 12.2734 0Z" fill="#E53935"/>
      <path d="M11.7273 24V20.8552C6.96791 20.7104 3.13991 16.7952 3.13991 12C3.13991 7.2048 6.96791 3.28964 11.7273 3.14479V0C5.23609 0.145673 0 5.47282 0 12C0 18.5272 5.23609 23.8543 11.7273 24Z" fill="#00C853"/>`;
    } else if (severities.low) {
      path = `<circle cx="12" cy="12" r="12" fill="#00C853" />`;
    } else if (severities.medium) {
      path = `<circle cx="12" cy="12" r="12" fill="#FFA000" />`;
    } else if (severities.high) {
      path = `<circle cx="12" cy="12" r="12" fill="#E53935" />`;
    }

    return `<svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${path}
      <circle cx="12" cy="12" r="10" fill="white"/>
      <text font-size="14" font-weight="500" fill="#000" x="${textAlignX}" y="17">${size}</text>
    </svg>`;
  }

  clear() {
    this.map && this.map.dispose();
  }
}
