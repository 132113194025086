import { Component, Input } from '@angular/core';
import { IChatMessage } from '../chatbot/chatbot.model';
import { IMAGES_MAP } from 'app/image.constants';

@Component({
  selector: 'dp-chatbot-message',
  templateUrl: './chatbot-message.component.html',
  styleUrls: ['./chatbot-message.component.scss'],
})
export class ChatbotMessageComponent {
  @Input() chatMessage: IChatMessage;
  imageMap = IMAGES_MAP;
}
