import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { AuthService } from 'app/auth/auth.service';
import { DpWidgetApiService } from 'app/dp-widget/dp-widget-api-service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { UIService } from 'app/shared/ui.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'dp-container-accordion',
  templateUrl: './container-accordion.component.html',
  styleUrls: ['./container-accordion.component.scss'],
  animations: dpAnimations,
})
export class ContainerAccordionComponent implements OnInit {
  SvgMap = SvgMap;
  showReportError = false;
  isAuth: boolean;
  @Input() container: any;
  shipmentDetailPayload: any = null;
  errorMsg = '';
  loading = false;
  @ViewChild('matPanel') panel;

  constructor(private dpWidgetApiService: DpWidgetApiService, private uiService: UIService, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.currentUser.subscribe((user) => {
      this.isAuth = this.authService.isAuth();
    });
  }

  onOpened(container) {
    if (!this.isAuth && !this.dpWidgetApiService.reCaptcha) {
      this.errorHandler();
      return;
    }
    this.loading = true;
    this.errorMsg = '';

    if (this.shipmentDetailPayload) return;

    const { id, blNumber, shipmentGroupUploadId } = container;
    this.dpWidgetApiService.searchTrackingNumberDetail(id, shipmentGroupUploadId, blNumber).subscribe(
      (payload: any) => {
        this.shipmentDetailPayload = payload?.data;
        this.loading = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorMsg = errorResponse.error;
        this.loading = false;
        this.dpWidgetApiService.reCaptcha = '';
        !this.isAuth && this.errorHandler();
      }
    );
  }

  errorHandler() {
    this.panel._toggle();
    this.uiService.showSnackbar('Session expired, please verify CAPTCHA again.', null, {
      duration: environment.snackBarDuration.warning,
      panelClass: 'warn',
    });
  }

  getStatusColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'cool-secondary-100';
      case 'COMPLETED':
        return 'cool-primary-100';
      case 'UNTRACKABLE':
        return 'warning-dark';
      case 'PENDING':
        return 'black-disabled-100';
      default:
        return '';
    }
  }

  reportError() {
    this.showReportError = true;
  }

  close() {
    this.showReportError = false;
  }
}
