<div class="tracking-details px-8" *ngIf="transportJourneys; let t">

  <div *ngIf="!isManualIntermodalLinkage; else intermodalBoxView">
    <div class="row line pb-18">
      <!-- Left side -->
      <div class="col">
  
        <p *ngIf="t.shipToPort?.origin">
          <td class="label">Origin </td>
          <td class="value text-bold">
            {{t.shipToPort?.origin || ''}}
          </td>
        </p>
  
        <!-- Origin ATD/ETD -->
        <p *ngIf="t.shipToPort?.atd">
          <td class="label">Origin ATD </td>
          <td class="value text-bold">{{ t.shipToPort.atd }}</td>
        </p>
        <p *ngIf="!t.shipToPort?.atd && t.shipToPort?.etd">
          <td class="label">Origin ETD </td>
          <td class="value text-bold">{{ t.shipToPort.etd}}</td>
        </p>
      </div>
  
      <!-- Right side -->
      <div class="col right mr-12">
        <p *ngIf="t.shipToDestination?.destination">
          <span class="label">Destination</span>
          <span class="value">{{t.shipToDestination?.destination || ''}}</span>
        </p>
  
        <p *ngIf="t.shipToDestination?.ata">
          <span class="label">Destination ATA</span>
          <span class="value">{{ t.shipToDestination.ata }}</span>
        </p>
        <p *ngIf="!t.shipToDestination?.ata && t.shipToDestination?.eta">
          <span class="label">Destination ETA</span>
          <span class="value">{{ t.shipToDestination?.eta || '' }}</span>
        </p>
  
      </div>
    </div>
  
  
    <div class="row py-8">
      <div class="col">
        <p>
          <td class="label">Shipper</td>
          <td class="value">
            <span>
              {{shipment?.consignor || ''}}
            </span>
          </td>
        </p>
        <p>
          <td class="label">Origin Port</td>
          <td class="value">
            <span *ngIf="t.portToPort?.originHub">
              {{t.portToPort.originHub || ''}}
            </span>
          </td>
        </p>
  
        <p>
          <td class="label">Port Gate in</td>
          <td class="value">
            <span *ngIf="t.portToPort.gateInTime">
              {{t.portToPort.gateInTime}}
            </span>
          </td>
        </p>
  
  
        <!-- Port ATD/ETD -->
        <p *ngIf="t.portToPort.hubAtd">
          <td class="label">Port ATD </td>
          <td class="value">{{t.portToPort.hubAtd }}</td>
        </p>
        <p *ngIf="!t.portToPort.hubAtd">
          <td class="label">Port ETD </td>
          <td class="value">{{ t.portToPort.hubEtd || '' }}</td>
        </p>
  
      </div>
  
      <!-- Right side -->
      <div class="col right justify-centent-end">
        <p>
          <td class="label text-right">Consignee</td>
          <td class="value">
            <span>
              {{shipment?.consignee || ''}}
            </span>
          </td>
        </p>
        <p>
          <span class="label text-right">Destination Port</span>
          <span class="value">{{t.portToPort.destinationHub || ''}} </span>
        </p>
        <p *ngIf="t.portToPort.hubAta">
          <span class="label text-right"> Port ATA </span>
          <span class="value"> {{ t.portToPort.hubAta }} </span>
        </p>
        <p *ngIf="!t.portToPort.hubAta && !t.portToPort.predictedHubEta">
          <span class="label text-right"> Carrier ETA </span>
          <span class="value"> {{ t.portToPort.hubEta || '' }} </span>
        </p>
  
        <p *ngIf="t.portToPort.predictedHubEta && !t.portToPort.hubAta">
          <span class="label text-right predicted-eta" placement="bottom" 
                [tooltip]="getPredictiveTootltip(t)">
            <img width="14" [src]="SvgMap.flowNewSquare" alt="">
            Flow Predicts
          </span>
          <span class="value d-inline-flex justify-content-end">
              <span>{{ t.portToPort.predictedHubEta}}</span>
              <span *ngIf="t.portToPort.hubEtaDaysDifference" class='highlight'> {{getPredictiveDaysDifference(t.portToPort.hubEtaDaysDifference)}}</span>
          </span>
        </p>
  
        <p *ngIf="t.portToPort.gateOutTime">
          <span class="label text-right"> Port Gate out </span>
          <span class="value">{{t.portToPort.gateOutTime}}</span>
        </p>
  
      </div>
    </div>
  </div>
  <ng-template #intermodalBoxView>
    <div class="box hScroller" >
      <div fxLayout="row" fxLayoutGap="16px">
        <div>
          <lable class="label label-box">Shipper</lable>
          <div class="mt-4 text-bold value value-box"> {{ shipment?.consignor || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Origin Address</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToPort?.originFullAddress || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">ETD</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToPort?.etd || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">ATD</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToPort?.atd || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Mode</lable>
          <div class="mt-4 text-bold value value-box">Road</div>
        </div>
      </div>
      <hr class="separator">
      <div fxLayout="row" fxLayoutGap="16px">
        <div>
          <lable class="label label-box"></lable>
          <div class="mt-4 text-bold value value-box"></div>
        </div>
        <div>
          <lable class="label label-box">Origin Port</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.portToPort?.originHub || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Port Gate In</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.portToPort?.gateInTime || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Port ATD</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.portToPort?.hubAtd || '-' }}</div>
        </div>
      </div>
    </div>
    <div class="box hScroller">
      <div fxLayout="row" fxLayoutGap="16px">
        <div>
          <lable class="label label-box">Consignee</lable>
          <div class="mt-4 text-bold value value-box">{{ shipment?.consignee || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Destination Address</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToDestination?.destinationFullAddress || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">ETA</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToDestination?.eta || '-' }}</div>
        </div>
        <div >
          <lable class="label label-box">ATA</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.shipToDestination?.ata || '-' }}</div>
        </div>
        <div >
          <lable class="label label-box">Mode</lable>
          <div class="mt-4 text-bold value value-box">Road</div>
        </div>
      </div>
      <hr class="separator">
      <div fxLayout="row" fxLayoutGap="16px">
        <div>
          <lable class="label label-box"></lable>
          <div class="mt-4 text-bold value value-box"></div>
        </div>
        <div>
          <lable class="label label-box">Destination Port</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.portToPort?.destinationHub || '-' }}</div>
        </div>
        <div>
          <lable class="label label-box">Port ETA</lable>
          <div class="mt-4 text-bold value value-box"> {{ t.portToPort?.hubEta || '-' }}</div>
        </div>
        <div *ngIf="t.portToPort.predictedHubEta">
          <label class="label label-box text-right predicted-eta" placement="bottom" 
                [tooltip]="getPredictiveTootltip(t)">
            <img width="14" [src]="SvgMap.flowNewSquare" alt="">
            Flow Predicts
          </label>
          <div *ngIf="isUserLoggedIn && t.portToPort.predictedHubEta && !t.portToPort.hubAta">
            <div class="value value-box justify-content-end mt-4" *ngIf='isPredictiveEtaEnabled'>
                <span>{{ t.portToPort.predictedHubEta}}</span>
                <span *ngIf="t.portToPort.hubEtaDaysDifference" class='highlight'>
                  {{getPredictiveDaysDifference(t.portToPort.hubEtaDaysDifference)}}
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
