/* eslint-disable for-direction */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { containerValidator } from '@dp/validators/dp-container-validator';
import { StaticDataService } from '@dp/services/static-data.service';
import { ContainerService } from './container.service';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { AddShipmentResult, Currency, SHIPPING_MODES, VESSEL_MODES, VISIBILITY_LEVELS } from './container.model';
import { ProgressRef, ProgressService, UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DpEntityExistValidator } from '@dp/validators/dp-container-exist-validator';
import { Utility } from '@dp/utilities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { dpAnimations } from '@dp/animations';
import { uniqueOrEmptyValueValidator } from '@dp/validators/dp-unique-value-validator';
import { requiredByOtherFieldValidator } from '@dp/validators/dp-required-by-other-field-validator';
import moment from 'moment';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Carrier, Incoterms, StaticData } from '@dp/services/static-data.model';
import { AddContainerByTypes } from 'app/navigation/nav.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MilestoneService } from '@dp/services/milestone.service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { IUploadResult } from 'app/upload-history/models/uh.model';
import { deepClone } from '@dp/utilities/deep-clone';
import { ShipmentType } from 'app/shared/shared.model';
import { AWBValidator } from '@dp/validators/dp-awb-validator';
import { crossFieldValidator } from '@dp/validators/dp-weight-uom-validator';
import { SharedApiService } from '../../shared/shared.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { animate, style, transition, trigger } from '@angular/animations';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { Device, ShipmentUploadTypes, Tag } from '@dp/types';
import { AuthService } from '../../auth/auth.service';
import { Organization } from '../users/users.model';
import { MatDialog } from '@angular/material/dialog';
import { MissingCarrierDialogComponent } from './missing-carrier-dialog/missing-carrier-dialog.component';
import { UsersService } from '../users/users.service';
import { Team } from '../settings.model';
import { ContainersService } from '@dp/services/containers.service';
import { User } from 'app/auth/user.model';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('1500ms', style({ opacity: 0 }))]),
]);
@UntilDestroy()
@Component({
  selector: 'dp-add-shipment-one-by-one',
  templateUrl: './add-shipment-one-by-one.component.html',
  styleUrls: ['./add-shipment-one-by-one.component.scss'],
  animations: [dpAnimations, fadeAnimation],
  // animations: [fadeAnimation],
})
export class AddShipmentOneByOneComponent implements OnInit {
  //modes: string[] = ['Container Number Only', 'Advance'];
  keys = Object.keys;
  Object = Object;
  VISIBILITY_LEVELS = VISIBILITY_LEVELS;
  initialVisibilityLevel = VISIBILITY_LEVELS.ALL;
  ShipmentType = ShipmentType;
  SvgMap = SvgMap;
  isLoading = true;
  environment = environment;
  AddContainerByTypes = AddContainerByTypes;
  ports: string[];
  origin_port_code: string;
  destination_port_code: string;
  vesselMode: VESSEL_MODES | null = null;
  VESSEL_MODES = VESSEL_MODES;

  devices: Device[];
  teams: string[];
  staticData: StaticData;
  carriers: string[];
  airlines: Carrier[];
  logisticsProvider: Carrier[];
  public carrierFilterCtrl: FormControl = new FormControl();
  public logisticProviderFilterCtrl: FormControl = new FormControl();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  @ViewChild('logisticProviderSelect', { static: true }) logisticProviderSelect: MatSelect;
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredLogisticsProviders: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  incoterms: Incoterms;
  public incotermsFilterCtrl: FormControl = new FormControl();
  @ViewChild('incotermsSelect', { static: true }) incotermsSelect: MatSelect;
  carriersContainer: string[];
  logisticProviderContainer: string[];
  carriersBK: string[];
  carriersMBL: string[];
  f: FormGroup = null;
  isBusy = false;
  scrollDown: number;
  dateFormat: string;
  addContainerByType: AddContainerByTypes = AddContainerByTypes.ByContainer; //set first tab selected
  user: User;
  currencies: Currency[];
  public filteredCurrencies: ReplaySubject<Currency[]> = new ReplaySubject<Currency[]>(1);
  @ViewChild('currencySelect', { static: true }) currencySelect: MatSelect;
  @ViewChild('globalFileInput') fileInput: ElementRef;
  @ViewChild('contentZone', { static: true }) contentZone: ElementRef;
  progress: ProgressRef;
  protected _onDestroy = new Subject<void>();
  currencyFilterCtrl = new FormControl('');
  shippingModes = SHIPPING_MODES;
  organization: Organization;
  visibleTo = 'ALL';
  hblNo = null;

  constructor(
    public formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    public authService: AuthService,
    private containerService: ContainerService,
    private containersService: ContainersService,
    private uiService: UIService,
    private sharedApiService: SharedApiService,
    private router: Router,
    private route: ActivatedRoute,
    private entityExistValidator: DpEntityExistValidator,
    private gaService: GoogleAnalyticsService,
    private milestoneService: MilestoneService,
    private progressService: ProgressService,
    private dpProgressBarService: DpProgressBarService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {
    this.dateFormat = moment(new Date()).localeData().longDateFormat('L').toLowerCase();
    this.user = this.authService.currentUserValue;
    const staticData = this.staticDataService.getStaticDataDirect();
    this.ports = staticData.ports.map((item) => {
      return `${item.port}, ${item.country_code} (${item.location_code})`;
    });

    this.route.params.subscribe((params: Params) => {
      this.shipmentType = params['shipmentType'];
      switch (this.shipmentType) {
        case ShipmentType.AIR_SHIPMENT:
          this.addContainerByType = AddContainerByTypes.ByAir;
          break;
        case ShipmentType.INTERMODAL_SHIPMENT:
          this.addContainerByType = AddContainerByTypes.ByContainer;
          break;
        case ShipmentType.OL_SHIPMENT:
          this.addContainerByType = AddContainerByTypes.ByOL;
          break;
        // case ShipmentType.TRUCK_SHIPMENT:
        // this.addContainerByType = AddContainerByTypes.ByTruck;

        default:
          break;
      }
    });
  }

  shipmentType: ShipmentType;
  ngOnInit(): void {
    this.organization = this.authService.currentOrganizationValue;
    this.staticData = this.staticDataService.getStaticDataDirect();
    this.carriersContainer = this.staticData['carriers']
      .filter((carrier) => carrier['shipment_type'] === 'INTERMODAL_SHIPMENT')
      .map((carrier) => carrier.carrier_name);
    this.carriersBK = this.staticData['carriers']
      .filter(
        (carrier) =>
          carrier.supports_search_by_bk &&
          !carrier.requires_container_number_on_search_by_bk &&
          carrier['shipment_type'] === 'INTERMODAL_SHIPMENT'
      )
      .map((carrier) => carrier.carrier_name);
    this.carriersMBL = this.staticData['carriers']
      .filter(
        (carrier) =>
          carrier.supports_search_by_bol &&
          !carrier.requires_container_number_on_search_by_bol &&
          carrier['shipment_type'] === 'INTERMODAL_SHIPMENT'
      )
      .map((carrier) => carrier.carrier_name);
    this.incoterms = this.staticData['incoterms'];

    if (this.addContainerByType === AddContainerByTypes.ByAir) {
      const staticData = this.staticDataService.getStaticDataDirect();
      this.airlines = staticData.carriers.filter((carrier) => carrier.shipment_type === MapSpace.ShipmentTypes.AIR_SHIPMENT);
      this.filteredAirlines = this.airlineSuggest.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : (value as Carrier)?.carrier_name;
          return name ? this._filter(name || '') : this.airlines.slice();
        })
      );
    }

    if (this.addContainerByType === AddContainerByTypes.ByOL) {
      this.logisticProviderContainer = this.staticData['carriers']
        .filter((carrier) => carrier['shipment_type'] === 'OUTSOURCED_LOGISTICS_SHIPMENT')
        .map((carrier) => carrier.carrier_name);

      this.filteredLogisticsProviders.next(this.logisticProviderContainer);

      this.logisticProviderFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterLogisticProvider();
      });
    }

    this.route.params.subscribe((params: Params) => {
      this.shipmentType = params['shipmentType'];
      if (this.shipmentType === ShipmentType.AIR_SHIPMENT) {
        this.addContainerByType = AddContainerByTypes.ByAir;
      }
    });

    if (this.isLoading) {
      this.progress = this.progressService.showProgress(this.contentZone);
      forkJoin([this.sharedApiService.getTagSuggestions(), this.sharedApiService.getDevices(), this.usersService.getTeamsInOrganization()])
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isLoading = false;
            this.progressService.detach(this.progress);
          })
        )
        .subscribe({
          next: ([tags, devices, teams]) => {
            this.sharedApiService.tagSuggestions = tags;
            this.devices = devices;
            this.teams = teams;
            this.processStaticData();
            this.f = this.formBuilder.group({
              containers: this.formBuilder.array([this.createContainerFormGroup()]),
            });
            this.addContainerByTypeChange();
          },
          error: (error) => {
            this.uiService.showSnackbar("We can't do this right now. Please try again later.", null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          },
        });
    }
  }

  getTitle() {
    return this.addContainerByType === AddContainerByTypes.ByAir
      ? 'Add Air Shipments One By One'
      : this.addContainerByType === AddContainerByTypes.ByOL
      ? 'Add Outsourced Logistics Shipments One By One'
      : 'Add Ocean Shipments One By One';
  }
  optionChanged(dataType: 'origin_port_code' | 'destination_port_code' | 'vessel_number', f: FormGroup, data: string) {
    const ctrl = f.get(dataType);
    ctrl.setValue(data);
    ctrl.markAsDirty();
  }

  handleFileInput(files: FileList) {
    this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadTemplate);

    if (!files.length || !files.item(0)) return;

    let fileToUpload = files.item(0);

    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    //https://dpwhotfsonline.visualstudio.com/DTLP/_wiki/wikis/DTLP.wiki/32/File-uploads?anchor=import-data
    formData.append('uploadType', 'CONTAINER_SHEET');
    this.dpProgressBarService.show();
    this.containersService
      .uploadContainerFile(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);

          this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadSuccess);

          this.gotoUploadHistory(true, result);
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.message, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }
  gotoUploadHistory(openReport: boolean, result: IUploadResult) {
    //note: a solution to force the url to reload, even if it is the current url.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: openReport,
          ...result,
        },
      });
    });
  }

  get formContainers() {
    return this.f?.get('containers') as FormArray;
  }

  numToWord(num: number): string {
    return Utility.numToWord(num);
  }

  // getRequiredFieldLabel() {
  //   return this.addContainerByType === AddContainerByTypes.ByContainer
  //     ? 'Container # '
  //     : this.addContainerByType === AddContainerByTypes.ByBooking
  //     ? 'Booking # '
  //     : 'MBL # ';
  // }

  // getSubTitle() {
  //   const label =
  //     this.addContainerByType === AddContainerByTypes.ByContainer
  //       ? 'container #'
  //       : this.addContainerByType === AddContainerByTypes.ByBooking
  //       ? 'booking #'
  //       : 'MBL #';
  //   return `Simply enter the ${label} and we will do the rest.`;
  // }
  processStaticData() {
    this.currencies = this.staticData['currencies'];
    this.filteredCurrencies.next(this.currencies.slice());
    // let currencyFilterCtrl = new FormControl();
    this.currencyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCurrencies();
    });
  }

  addContainers = () => {
    const formData = this.getFormData();
    if (
      this.addContainerByType !== AddContainerByTypes.ByVessel &&
      this.addContainerByType !== AddContainerByTypes.ByAir &&
      this.addContainerByType !== AddContainerByTypes.ByOL
    ) {
      const shipmentWthoutCarrier = formData.filter((shipment) => !shipment.ocean_line).length;
      if (shipmentWthoutCarrier) {
        this.dialog.open(MissingCarrierDialogComponent, {
          width: '700px',
          data: {
            shipmentWthoutCarrier,
            onProceed: this.addShipment,
          },
          panelClass: 'dp-missing-carrier-dialog',
        });
        return;
      }
    }
    this.addShipment();
  };

  addShipment = () => {
    const formData = this.getFormData();
    this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadTemplate);
    const uploadType =
      this.addContainerByType === AddContainerByTypes.ByContainer
        ? 'FORM_BY_CONTAINER_NUMBER'
        : this.addContainerByType === AddContainerByTypes.ByMBL
        ? 'FORM_BY_MBL_NUMBER'
        : this.addContainerByType === AddContainerByTypes.ByVessel
        ? 'FORM_BY_VESSEL_ID'
        : this.addContainerByType === AddContainerByTypes.ByBooking
        ? 'FORM_BY_BOOKING_NUMBER'
        : this.addContainerByType === AddContainerByTypes.ByAir
        ? 'FORM_BY_AWB_NUMBER'
        : this.addContainerByType === AddContainerByTypes.ByOL
        ? 'FORM_BY_LOGISTICS_ID'
        : null;
    const payload = {
      formData,
      uploadType,
    };
    this.isBusy = true;

    this.containerService
      .addShipments(payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (result: AddShipmentResult) => {
          if (result.totalError) {
            const firstMsg = result.flag === 'SUCCESS' ? 'There are warnings in the process:' : 'Submit failed!';

            if (result.flag === 'SUCCESS') {
              this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadSuccess);
            }

            result.errorDetail.unshift(firstMsg);
            this.uiService.showMultiLinesSnackbar(
              result.errorDetail,
              environment.snackBarDuration.warning,
              result.flag === 'SUCCESS' ? 'warn' : 'failed'
            );
          }
          // this.closeDlg();
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromDlg}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);
          if (result.flag === 'SUCCESS') {
            this.navigateToContainerUploadPage(result);
          }
        },
        (error) => {
          let message = "We can't process this right now. Please try again later.";
          if (error.error.errorCode === '40030' && uploadType === 'FORM_BY_LOGISTICS_ID') {
            message = 'Duplicate Outsourced Logistics upload, please update details';
          }
          this.uiService.showSnackbar(message, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromDlg}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
        }
      );
  };

  getFormData() {
    let containers = this.formContainers?.value;

    if (!containers || !containers.length) {
      return null;
    }

    for (let i = containers.length - 1; i >= 0; i--) {
      if (
        (!containers[i].awb_number && this.addContainerByType === AddContainerByTypes.ByAir) ||
        (!containers[i].logistics_id && this.addContainerByType === AddContainerByTypes.ByOL) ||
        (!containers[i].container_number && this.addContainerByType === AddContainerByTypes.ByContainer) ||
        (!containers[i].booking_number && this.addContainerByType === AddContainerByTypes.ByBooking) ||
        (!containers[i].vessel_number && this.addContainerByType === AddContainerByTypes.ByVessel) ||
        (!containers[i].mbl_number && this.addContainerByType === AddContainerByTypes.ByMBL)
      ) {
        containers.splice(i, 1);
      } else if (containers[i].products && containers[i].products.length > 1) {
        for (let j = containers[i].products.length - 1; j >= 0; j--) {
          if (!containers[i].products[j].product_number && containers[i].products.length > 1) {
            containers[i].products.splice(j, 1);
          }
        }
      }
    }

    containers = containers.flatMap((container) => {
      container = deepClone(container);
      container = Utility.cleanupDataAndTrimSpace(container);
      if (container.vessel_number) {
        container.vessel_id = container.vessel_number.id;
        delete container.vessel_number;
        if (container.vessel_carrier) {
          container.carrier = container.vessel_carrier;
          delete container.vessel_carrier;
        }
        if (container.vessel_container) {
          container.container_number = container.vessel_container;
          delete container.vessel_container;
        }
      }
      if (container.tags) {
        container.shipment_tags = container.tags.map((tag) => tag.name).join('; ');
        delete container.tags;
      }
      if (container.team_names) {
        container.team_names = container.team_names.join('; ');
      }

      let parentLevel = { ...container };
      delete parentLevel.products;
      delete parentLevel.showProducts;
      if (!container.products || !container.products.length) {
        return { ...parentLevel };
      } else {
        return container?.products.map((product) => {
          product = Utility.cleanupDataAndTrimSpace(product);
          let expiration_date = product.expiration_date ? moment(product.expiration_date).format('YYYY-MM-DD') : null;
          let production_date = product.production_date ? moment(product.production_date).format('YYYY-MM-DD') : null;
          let order_date = product.order_date ? moment(product.order_date).format('YYYY-MM-DD') : null;
          return {
            ...parentLevel,
            ...product,
            ...{
              expiration_date,
              production_date,
              order_date,
            },
          };
        });
      }
    });
    return containers;
  }

  navigateToContainerUploadPage(result) {
    let currentUrl = this.router.url;
    if (currentUrl.indexOf('upload-history') === -1) {
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: true,
          ...result,
        },
      });
    } else {
      //note: simplest trick to reload current page
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate([currentUrl]);

        this.router.navigate([currentUrl], {
          queryParams: {
            report: true,
            ...result,
          },
        });
      });
    }
  }

  addContainerFormGroup() {
    const containers = this.formContainers;
    containers.push(this.createContainerFormGroup());

    //todo: scroll to bottom
    // this.scrollDown = this.containerList.nativeElement.scrollHeight;
  }

  addProductFormGroup(i) {
    const currentContainer = this.formContainers.controls[i];
    (currentContainer.get('products') as FormArray).push(this.createProductFormGroup());
  }
  expandContainerDetail(e: Event, i: number) {
    e.preventDefault();
    const currentContainer = this.formContainers.controls[i];
    const showProductsCtl = currentContainer.get('showProducts');
    if (!showProductsCtl.value) {
      showProductsCtl.setValue(!showProductsCtl.value);
    } else {
      this.addProductFormGroup(i);
    }
  }
  hideProducts(i: number) {
    const currentContainer = this.formContainers.controls[i];
    const showProductsCtl = currentContainer.get('showProducts');
    if (showProductsCtl.value) {
      showProductsCtl.setValue(!showProductsCtl.value);
    }
  }
  getExpandIcon(i: number): string {
    const current = this.formContainers.controls[i];
    const showProductsCtl = current.get('showProducts');
    return (showProductsCtl.value as boolean) ? 'expand_less' : 'expand_more';
  }

  shouldShowContainerDetailRows(i: number): boolean {
    const current = this.formContainers.controls[i];
    return current.get('showProducts').value as boolean;
  }

  // shouldShowExpandBtn(): boolean {
  //   return this.addContainerByType === AddContainerByTypes.ByContainer ? true : false;
  // }

  removeOrClearContainer(i: number) {
    const containers = this.formContainers;
    if (containers.length > 1) {
      containers.removeAt(i);
    } else {
      containers.reset();
    }
  }

  removeOrClearProduct(i: number, j: number) {
    const currentContainer = this.formContainers.controls[i];
    const products = currentContainer.get('products') as FormArray;
    if (products.length > 1) {
      products.removeAt(j);
    } else {
      products.reset();
      this.hideProducts(i);
    }
  }

  resetProducts() {
    const products = this.formContainers.controls[0].get('products') as FormArray;
    for (let i = products.length - 1; i > 0; i--) {
      products.removeAt(i);
    }
    products.reset();
  }

  productRowDeleteDisabled(i: number, j: number): boolean {
    const currentContainer = this.formContainers.controls[i];
    const products = currentContainer.get('products') as FormArray;

    return products.length > 1 || !Utility.checkAllPropertiesAreEmpty(products.value[0]) ? false : true;
  }

  addContainerByTypeChange() {
    this.carriers =
      this.addContainerByType === AddContainerByTypes.ByContainer
        ? this.carriersContainer
        : this.addContainerByType === AddContainerByTypes.ByBooking
        ? this.carriersBK
        : this.carriersMBL;

    this.filteredCarriers.next(this.carriers.slice());
    this.carrierFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCarriers();
    });

    const containers = this.formContainers;
    for (let i = containers.length - 1; i >= 1; i--) {
      containers.removeAt(i);
    }
    this.resetProducts();

    let fgs = this.f.controls.containers['controls'] as FormGroup[];

    fgs.forEach((fg) => {
      fg.reset();
      fg.get('visible_to').setValue('ALL'); // set default to ALL
      this.adjustValidators(fg);
    });
  }

  private filterCarriers() {
    if (!this.carriers) {
      return;
    }
    let search = this.carrierFilterCtrl.value;
    if (!search) {
      this.filteredCarriers.next(this.carriers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.toLowerCase().includes(search)));
  }

  private filterLogisticProvider() {
    if (!this.logisticProviderContainer) {
      return;
    }

    this.filteredLogisticsProviders.next(this.logisticProviderContainer.slice());
    let search = this.logisticProviderFilterCtrl.value;
    if (!search) {
      this.filteredLogisticsProviders.next(this.logisticProviderContainer.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLogisticsProviders.next(this.logisticProviderContainer.filter((carrier) => carrier.toLowerCase().includes(search)));
  }
  private adjustValidators(fg: FormGroup) {
    const awbNumber = fg.get('awb_number');
    this.addContainerByType === AddContainerByTypes.ByAir ? awbNumber.enable() : awbNumber.disable();

    const logisticId = fg.get('logistics_id');
    this.addContainerByType === AddContainerByTypes.ByOL ? logisticId.enable() : logisticId.disable();

    const containerNumber = fg.get('container_number');
    this.addContainerByType === AddContainerByTypes.ByContainer ? containerNumber.enable() : containerNumber.disable();

    const bookingNumber = fg.get('booking_number');
    bookingNumber.setValidators(
      this.addContainerByType === AddContainerByTypes.ByBooking ? [uniqueOrEmptyValueValidator('booking_number')] : null
    );
    bookingNumber.setAsyncValidators(
      this.addContainerByType === AddContainerByTypes.ByBooking
        ? [this.entityExistValidator.entityExistValidator(ShipmentUploadTypes.BK)]
        : null
    );

    const mblNumber = fg.get('mbl_number');
    mblNumber.setValidators(this.addContainerByType === AddContainerByTypes.ByMBL ? [uniqueOrEmptyValueValidator('mbl_number')] : null);
    mblNumber.setAsyncValidators(
      this.addContainerByType === AddContainerByTypes.ByMBL
        ? [this.entityExistValidator.entityExistValidator(ShipmentUploadTypes.MBL)]
        : null
    );

    const vesselNumber = fg.get('vessel_number');
    const vesselContainer = fg.get('vessel_container');
    const origin_port_code = fg.get('origin_port_code');
    const destination_port_code = fg.get('destination_port_code');
    const byVessel = this.addContainerByType === AddContainerByTypes.ByVessel;
    const team_names = fg.get('team_names');
    // team_names.disable();
    byVessel ? vesselNumber.enable() : vesselNumber.disable();
    byVessel ? origin_port_code.enable() : origin_port_code.disable();
    byVessel ? destination_port_code.enable() : destination_port_code.disable();
    byVessel ? vesselContainer.disable() : vesselContainer.disable(); // all should set to disable until mode is containerized

    if (this.addContainerByType === AddContainerByTypes.ByContainer) {
      fg.get('hbl_number')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((dataValue) => {
          if (dataValue && this.hblNo !== dataValue) {
            this.hblNo = dataValue;
            this.entityExistValidator
              .entityExists(fg.get('container_number').value, ShipmentUploadTypes.CONTAINER, fg.get('mode').value, dataValue)
              .subscribe((res) => {
                const errors = fg.get('container_number').errors;
                if (res?.['valid'] && errors?.entityExists && Object.keys(errors)?.length === 1) {
                  fg.get('container_number').setErrors(null);
                } else {
                  errors && fg.get('container_number').setErrors({ ...errors, entityExists: !res?.['valid'] });
                }
              });
          }
        });
    }

    fg.updateValueAndValidity();
  }

  createContainerFormGroup(): FormGroup {
    const alphaNumericPattern = '^[a-zA-Z0-9]*';
    let f = new FormGroup(
      {
        awb_number: new FormControl('', [], [AWBValidator.createValidator(this.sharedApiService)]),
        logistics_id: new FormControl('', [Validators.required, Validators.pattern(alphaNumericPattern)]),
        container_number: new FormControl(
          '',
          [containerValidator()],
          this.entityExistValidator.entityExistValidator(ShipmentUploadTypes.CONTAINER)
        ),
        vessel_container: new FormControl('', [containerValidator()]),
        mbl_number: new FormControl(''),
        vessel_carrier: new FormControl(''),
        booking_number: new FormControl(''),
        hbl_number: new FormControl('', [uniqueOrEmptyValueValidator('hbl_number', true)]),
        shipment_reference: new FormControl(''),
        shipper: new FormControl(''),
        consignee: new FormControl(''),
        promised_eta: new FormControl(''),
        promised_etd: new FormControl(''),
        manual_eta: new FormControl(''),
        device_ids: new FormControl('', [uniqueOrEmptyValueValidator('device_ids', true)]),
        // ocean_line: new FormControl('', [requireBOLValidator(this.staticData)]),
        ocean_line: new FormControl(''),
        incoterm: new FormControl(''),
        showProducts: new FormControl(false),
        vessel_name: new FormControl(''),
        port_origin: new FormControl(''),
        port_destination: new FormControl(''),
        mode: new FormControl(''),
        tags: new FormControl([]),
        total_pack: new FormControl(''),
        pack_uom: new FormControl(''),
        total_weight: new FormControl(''),
        weight_uom: new FormControl(''),
        total_volume: new FormControl(''),
        volume_uom: new FormControl(''),
        products: this.formBuilder.array([this.createProductFormGroup()]),
        vessel_number: new FormControl('', [Validators.required]),
        origin_port_code: new FormControl('', [Validators.required]),
        destination_port_code: new FormControl('', [Validators.required]),
        visible_to: new FormControl(Utility.getEnumKeyByEnumValue(VISIBILITY_LEVELS, VISIBILITY_LEVELS.ALL), [Validators.required]),
        team_names: new FormControl('', [Validators.required]),
      },
      { validators: crossFieldValidator }
    );

    if (!this.authService.isTeamFeatureEnabled) {
      f.get('visible_to').disable();
    }
    f.get('team_names').disable();

    if (this.addContainerByType === AddContainerByTypes.ByOL) {
      // @ts-ignore
      f.addControl('logistics_partner', new FormControl('', [Validators.required]));
    }

    this.adjustValidators(f);

    f['that'] = this;
    const container_number = f.get('container_number');
    const device_ids = f.get('device_ids');

    if (this.addContainerByType !== AddContainerByTypes.ByContainer) {
      container_number.disable();
    }
    if (this.addContainerByType !== AddContainerByTypes.ByContainer || !this.organization?.isSensorDataTrackingEnabled) {
      device_ids.disable();
    }
    // container_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
    //   this.containerFormAutoAppend(f);
    // });
    // mbl_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
    //   this.containerFormAutoAppend(f);
    // });
    // booking_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
    //   this.containerFormAutoAppend(f);
    // });

    f.valueChanges.subscribe((val) => {
      let carrierRequires_bol = val.ocean_line
        ? this.addContainerByType === AddContainerByTypes.ByContainer &&
          this.staticData.carriers.find((carrier) => carrier.carrier_name === val.ocean_line).requires_bol
        : false;
      if (carrierRequires_bol && !f.controls.mbl_number.validator) {
        this.setMBLRequired(f.controls.mbl_number, true);
      } else if (!carrierRequires_bol && f.controls.mbl_number.validator && this.addContainerByType !== AddContainerByTypes.ByMBL) {
        this.setMBLRequired(f.controls.mbl_number, false);
      }
    });

    return f;
  }

  setMBLRequired(control: AbstractControl, isRequired: boolean) {
    control.setValidators(isRequired ? Validators.required : null);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  getKeyField(f: FormGroup): AbstractControl {
    return this.addContainerByType === AddContainerByTypes.ByContainer
      ? f.controls['container_number']
      : this.addContainerByType === AddContainerByTypes.ByBooking
      ? f.controls['booking_number']
      : this.addContainerByType === AddContainerByTypes.ByMBL
      ? f.controls['mbl_number']
      : this.addContainerByType === AddContainerByTypes.ByVessel
      ? f.controls['vessel_number']
      : null;
  }

  // containerFormAutoAppend(f: FormGroup) {
  //   //if it is the last one, add new one
  //   const keyField = this.getKeyField(f);
  //   if (keyField.status !== 'INVALID' && keyField.value) {
  //     // it is the last row
  //     const parentArray = f.parent as FormArray;
  //     const lastF = parentArray.controls[parentArray.length - 1];
  //     if (lastF === f && parentArray.length < 10) {
  //       // make sure the row is not empty
  //       const rowData = Utility.cleanupData(f.value);
  //       if (rowData) {
  //         // append an new record
  //         parentArray.push(f['that'].createContainerFormGroup());
  //       }
  //     }
  //   }
  //   return null;
  // }

  createProductFormGroup(): FormGroup {
    let f = new FormGroup({
      po_number: new FormControl(''),
      product_number: new FormControl('', [requiredByOtherFieldValidator()]),
      product_description: new FormControl(''),
      hs_code: new FormControl(''),
      product_quantity: new FormControl(''),
      quantity_uom: new FormControl(''),
      unit_price: new FormControl(''),
      price_currency: new FormControl(''),
      lot_number: new FormControl(''),
      production_date: new FormControl(''),
      expiration_date: new FormControl(''),
      order_date: new FormControl(''),
      invoice_number: new FormControl(''),
    });
    f.setValidators(this.triggerValidate);
    f['that'] = this;
    return f;
  }

  triggerValidate(f: FormGroup) {
    Object.keys(f.controls).forEach((key) => {
      if (f.controls[key].validator) {
        f.controls[key].validator(f.controls[key]);
      }
    });
    return null;
  }

  getErrorMessage(ctrl: FormControl, fieldName?: 'mbl' | 'logistics_id') {
    if (!ctrl.errors) {
      return null;
    }
    if (ctrl.errors?.required) {
      return fieldName === 'mbl' ? 'MBL # is required for the selected carrier' : 'This # is required';
      // let subFormData = Utility.cleanupData(ctrl.parent.getRawValue());
      // delete subFormData['showProducts'];
      // if (!_.isEmpty(subFormData)) {
      // }
    } else if (ctrl.errors['containerError']) {
      return ctrl.errors['containerError'];
    } else if (ctrl.errors['entityExists']) {
      return 'This # already exists';
    } else if (ctrl.errors['hasDuplicates']) {
      return 'This # is already in your form';
    } else if (ctrl.errors['awb_exists']) {
      return 'This awb # already in your system';
    } else if (ctrl.errors['awb_invalid']) {
      return 'AWB # is invalid';
    } else if (ctrl.errors?.pattern && fieldName === 'logistics_id') {
      return 'Logistics ID is invalid';
    }

    return null;
  }
  getAWBAirport(ctrl: FormControl): string {
    return this.sharedApiService.awbAirport[ctrl.value];
  }
  getProdErrorMessage(ctrl: FormControl) {
    if (ctrl.errors && ctrl.errors['hasDuplicates']) {
      return 'This # has to be unique';
    }
    return null;
  }

  filterCurrencies() {
    // get the search keyword
    let search = this.currencyFilterCtrl.value;
    if (!search) {
      this.filteredCurrencies.next(this.currencies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCurrencies.next(this.currencies.filter((currency) => currency.name.toLowerCase().indexOf(search) > -1));
  }

  modeSelectionChange(res: string, container: FormGroup) {
    // console.log('res', res);
    const vesselContainer = container.get('vessel_container');
    res === VESSEL_MODES.BULK ? vesselContainer.disable() : vesselContainer.enable();
    //console.log('control status: ', vesselContainer.disabled);
  }

  invalidForm(): boolean {
    let fgs = this.f?.controls.containers['controls'] as FormGroup[];
    let isInvalid = false;
    let hasData = false;
    for (let i = 0; i < fgs?.length; i++) {
      if (fgs[i].dirty) {
        hasData = true;
        if (fgs[i].status === 'INVALID') {
          isInvalid = true;
        }
      }
    }
    if (!hasData) {
      isInvalid = true;
    }
    return isInvalid;
  }

  hideVesselContainer(fg: FormGroup): Boolean {
    return fg.get('vessel_container').disabled ? true : false;
  }

  deleteCarrier(e: PointerEvent, container: FormGroup) {
    container.get('ocean_line').setValue('');
    e.stopPropagation();
  }

  deleteLogisticProvider(e: PointerEvent, container: FormGroup) {
    container.get('logistics_partner').setValue('');
    e.stopPropagation();
  }

  deleteIncoterms(e: PointerEvent, container: FormGroup) {
    container.get('incoterm').setValue('');
    e.stopPropagation();
  }

  numberOnly(event: KeyboardEvent): boolean {
    const key = event.key;
    return (key >= '0' && key <= '9') || key === '-' ? true : false;
  }

  //#region
  showAirlineSuggest = false;
  airlineSuggest = new FormControl('');
  filteredAirlines: Observable<Carrier[]>;
  airlineSelected(event: MatAutocompleteSelectedEvent, awbControl: FormControl) {
    const airline = event.option.value;
    awbControl.setValue(airline.awb);
    this.showAirlineSuggest = false;
  }
  showAirlineSearch(airlineSuggest: FormControl) {
    this.showAirlineSuggest = !this.showAirlineSuggest;
    if (this.showAirlineSearch) {
      airlineSuggest.setValue(null);
    }
  }

  openDatePicker(picker) {
    picker.open();
  }

  displayFn(airline: Carrier): string {
    return airline?.carrier_name || '';
  }
  private _filter(name: string): Carrier[] {
    const filterValue = name.toLowerCase();

    return this.airlines.filter((airline) => airline.carrier_name.toLowerCase().includes(filterValue));
  }
  private getSuggestions(container): Tag[] {
    const tags = container.get('tags').value || [];
    const suggestions = this.sharedApiService.tagSuggestions?.filter((tagSuggestion) => !tags.find((tag) => tag.id === tagSuggestion.id));
    return suggestions;
  }
  createTag(tag: Tag, container) {
    let tags = container.get('tags').value || [];
    tags.push(tag);
    container.get('tags').setValue(tags);
  }
  deleteTag(tag: Tag, container) {
    let tags = container.get('tags').value || [];
    tags = tags.filter((item) => item.name !== tag.name);
    container.get('tags').setValue(tags);
  }

  onModeChnage(evt, container) {
    this.entityExistValidator
      .entityExists(container.get('container_number').value, ShipmentUploadTypes.CONTAINER, evt.value, container.get('hbl_number').value)
      .subscribe((res) => {
        container.get('container_number').setErrors(!res?.['valid'] ? { entityExists: true } : null);
      });
  }

  getTags(container): Tag[] {
    return container.get('tags').value;
  }
  //#endregion
  handlePromisedEtaChange(event, container) {
    container.patchValue({ promised_eta: moment(event.value).format('YYYY-MM-DD') });
  }
  handlePromisedEtdChange(event, container) {
    container.patchValue({ promised_etd: moment(event.value).format('YYYY-MM-DD') });
  }
  handleManualEtdChange(event, container) {
    container.patchValue({ manual_eta: moment(event.value).format('YYYY-MM-DD') });
  }
  drayageTrackingEnabled() {
    return this.user?.accountCapabilities?.drayageTrackingStatus === 'ENABLED';
  }
  getDeviceLabel(device: Device): string {
    return `${device?.deviceId} (${device.iotServiceProvider})`;
  }

  asIsOrder() {
    return 1;
  }

  visibilityChange(level: string, container) {
    const team_names = container.get('team_names');
    const showTeamNames = level === 'TEAMS';
    showTeamNames ? team_names.enable() : team_names.disable();
  }
  shouldDisableTeams(level: string) {
    return level === 'TEAMS' && this.teams?.length === 0;
  }
  logEvent(eventAction, container) {
    const event = environment.gaEvents.names.filterChange;
    const eventLabel = container.get(eventAction).value;
    if (!eventLabel) return;

    let byType = '';
    switch (this.addContainerByType) {
      case AddContainerByTypes.ByContainer:
        byType = 'By container #';
        break;

      case AddContainerByTypes.ByBooking:
        byType = 'By booking #';
        break;

      case AddContainerByTypes.ByMBL:
        byType = 'By MBL #';
        break;

      case AddContainerByTypes.ByVessel:
        byType = 'By vessel';
        break;

      case AddContainerByTypes.ByAir:
        byType = 'By air';
        break;

      default:
        break;
    }

    const eventDetail = {
      eventCategory: environment.gaEvents.categories.filterApplied,
      eventAction: `${byType} - ${eventAction}`,
      eventLabel,
    };
    this.authService.logEvent(event, eventDetail);
  }
  hideTeams(container: FormGroup): boolean {
    const selected = container.get('visible_to').value;
    const turnOff = selected !== 'TEAMS';
    return turnOff;
  }
  clearTeam(select: NgModel) {
    select.update.emit([]);
  }
}

interface Airline {
  awb: number;
  name: string;
}
