// ----- CalendarDays Request Models -----------------------------------------------

// the base object used to make requests for the calendar
export interface CalendarDaysRequest {
  startDate: string;
  endDate: string;
  filters: Array<ShippingFilters>;
}

// how incoming data is filtered for the calendar, for example by Product or Origin
export interface ShippingFilters {
  type: FilterType; // example: Product, Origin, Destination, Product Name
  value: string; // example: Ford f-150
}

// the current options for filtering data on the calendar.
export enum FilterType {
  ProductId,
  Location,
  ProductName,
}

// ----- CalendarDays Response Models -----------------------------------------------

// top level of data model, holds all info to render calendar
export interface CalendarDays {
  shippingDays: Array<ShippingDay>;
}

// a day in which any shipping activity is happening
export interface ShippingDay {
  date: string;
  shippingSets: Array<ShippingSet>; //when a user clicks on a date on the calendar, we request for that day, and the response replaces this value
  start?: Date; //we manually generate this, calendar data binds to it.
  displayDate?: string; // we generate this to display on the DayDetails
}

// set of shipments all falling into a criteria : departed, departing, arrived, arriving for a specific day
export interface ShippingSet {
  shippingStatus: ShippingStatus;
  time: string; // ETA or ATD
  containerCount: Number; // total number of containers that are in the ShippingStatus for this day.
  shipments?: Array<Shipment>; // null when returned from Calendar Request, Filled in with data when returned from ShippingDayRequest
}

export interface Shipment {
  shipmentId: string;
  shipmentNumber: string;
  shipmentType: string;
  containers: Array<Container>;
  from: string;
  to: string;
  bol: string;
  shipmentReference?: string;
}

// one shipment can have many of these containers within it.
export interface Container {
  containerId: number;
  containerNumber: string;
  containerType: string;
  containerSize: string;
  containerState?: string;
  productCount: Number;
  unitOfMeasurement: string;
  seal?: string; //included in Shipment page
  status?: string;
  trackingId?: string;
  products?: any[];
}

// the current state of a shipment
export enum ShippingStatus {
  Departed = 'DEPARTED',
  Departing = 'DEPARTING',
  Arrived = 'ARRIVED',
  Arriving = 'ARRIVING',
  None = 'NONE', // for errors or missing data
}

// ----- ShippingDay Request Models -----------------------------------------------
export interface ShippingDayRequest {
  date: string; // the day we are requesting data for
  filters: Array<ShippingFilters>;
}

// ----- Shipping Day Response Models -----------------------------------------------
//  just returns a ShippingDay which is defined above

// ----- ShippingLocation Response Models --------------------------------------------

export interface ShippingLocations {
  locations: Array<ShippingLocation>;
}

export interface ShippingLocation {
  country: string; // should this be an enum?
  city: string; // maybe not enum on this
  type: ShippingLocationType;
  // do we need any other properties?
}

export enum ShippingLocationType {}
// need Edward to fill in types here

// ----- ProductById Models ---------------------------------------------------

export interface ProductByIdRequest {
  id: string;
}

export interface ProductInfo {
  id: string;
  name: string;
  displayName: string;
}

// ----- ProductsByName Models ---------------------------------------------------

export interface ProductsByNameRequest {
  name: string;
}

export interface ProductsByNameResponse {
  products: Array<ProductInfo>;
}
