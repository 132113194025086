<div *ngIf="payload.error" class="mt-12">
  <mat-error>{{payload.error}}</mat-error>
</div>

<section class="tracking-info p-8" *ngIf="payload.length > 0">

  <!-- shipment info -->
  <div class="row my-20" style="justify-content: space-around;" *ngIf="shipmentInfo">
    <div class="col" style="flex: 1 1 0">
      <label class="label">MBL #: </label>
      <span>{{shipmentInfo.blNumber || ''}}</span>
    </div>
    <div class="col" style="flex: 1 1 0">
      <label class="label">Booking #: </label>
      <span>{{shipmentInfo.bkNumber || ''}}</span>
    </div>

    <div class="col" style="flex: 1 1 0">
      <label class="label">Carrier: </label>
      <span>{{ shipmentInfo.carrier}}</span>
    </div>

    <div class="col" style="flex: 1 1 0">
      <label class="label"># of Container: </label>
      <span>{{ shipmentInfo.numOfContainers}}</span>
    </div>
  </div>

  <div class="row my-20" style="justify-content: space-between;">
    <div class="col" style="flex: 1 1 0">
      <label class="label">From: </label>
      <span>{{ shipmentInfo.originHub }}</span>
    </div>
    <div class="col" style="flex: 1 1 0">
      <label class="label">To: </label>
      <span> {{ shipmentInfo.destinationHub }} </span>
    </div>

    <div class="col" style="flex: 1 1 0">
      <label class="label">Port {{ shipmentInfo.atd ? 'ATD' : 'ETD'}}: </label>
      <span> {{ shipmentInfo.atd || shipmentInfo.etd || '' }}</span>
    </div>

    <div class="col" style="flex: 1 1 0">
      <label class="label">Port {{ shipmentInfo.ata ? 'ATA' : 'ETA'}}: </label>
      <span> {{ shipmentInfo.ata || shipmentInfo.eta || '' }} </span>
    </div>
  </div>


  <div class="box" *ngFor="let container of payload">
    <dp-container-accordion [container]="container"></dp-container-accordion>
  </div>
</section>