import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, Route, CanLoad } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class SuperAdminGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuth() && this.authService.currentUserValue.systemAdminPanelAccess) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
    }
  }

  canLoad(route: Route) {
    if (this.authService.isAuth() && this.authService.currentUserValue.systemAdminPanelAccess) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }
}
