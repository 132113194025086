<div class="content" *ngIf="!loading && notificationEvents.length === 0; else eventDetail">
  <div class="fullHeight" fxLayoutAlign="center center" fxLayout="column" fxFlexFill>
    <dp-svg [src]="SvgMap.noNotifications" w="200"></dp-svg>
    <div class="info mt-20">
      <h3>You are all caught up!</h3>
      <p>Check back later for new notifications.</p>
    </div>
  </div>
</div>

<ng-template #eventDetail>
  <div
    class="content event-details"
    #contentZone
    infinite-scroll
    [infiniteScrollDistance]="distance"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="scrollThrottle"
    (scrolled)="onScroll()"
  >
    <dp-event-detail
      class="notificationItem"
      *ngFor="let event of notificationEvents; let i = index; let count = count"
      [notificationEvent]="event"
      [ngClass]="{ isRead: event.isRead }"
      [index]="i"
      [count]="count"
      (notificationEventChange)="isRead(event)"
      (dismiss)="dismissIt()"
    >
    </dp-event-detail>
  </div>
  <div class="footer p-42">
    <span [class.disabled]="!hasUnread">
      <a class="link text-underline" (click)="markAllRead()">Mark all as read</a>
    </span>
  </div>
</ng-template>
