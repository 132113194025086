<div class="title">Meet Your New Chatbot Assistant! - CARGOES Flow GPT</div>
<div class="content">
  <span>Get quick updates on your shipment status - just ask the chatbot!</span>
  <br /><br />
  <span>Key benefits :</span>
  <ol>
    <li>Track any container</li>
    <li>Know ETA of the container</li>
    <li>Check the CO2 emission for container journey</li>
    <li>And many more</li>
  </ol>
  <div class="action-btn">
    <button id="skip" class="not-now-btn">SKIP FOR NOW</button>
    <button id="next" color="primary">TRY IT NOW</button>
  </div>
</div>