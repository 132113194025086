import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { OrganizationComponent, UsersComponent } from '.';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { Users2Component } from '../users-teams/users/users2.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { DpSharedModule } from '@dp/shared.module';
import { UserCardComponent } from '../users-teams/users/user-card/user-card.component';
import { UserDetailComponent } from '../users-teams/users/user-detail/user-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { UserDetailEmptyComponent } from '../users-teams/users/user-detail-empty/user-detail-empty.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DpComponentsModule } from '@dp/components/components.module';
import { NewSettingsShellComponent } from './new-settings-shell/new-settings-shell.component';
import { NotificationMetricsComponent } from './notification-metrics/notification-metrics.component';
import { MetricFormComponent } from './notification-metrics/metric-form/metric-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MetricFormAddComponent } from './notification-metrics/metric-form-add/metric-form-add.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { NewSettingsComponent } from './new-settings/new-settings.component';
import { TaggedShipmentComponent } from './user-notification/tagged-shipment/tagged-shipment.component';
import { ShippingLaneComponent } from './user-notification/shipping-lane/shipping-lane.component';
import { ProductComponent } from './user-notification/product/product.component';
import { EditPasswordDlg } from './new-settings/edit-password-dlg/edit-password-dlg.component';
import { CompanySettingComponent } from './organization/company-setting/company-setting.component';
import { RouterModule } from '@angular/router';
import { AddShipmentOneByOneComponent } from './add-shipment/add-shipment-one-by-one.component';
import { AddShipmentComponent } from './add-shipment/add-shipment.component';
import { ContainerService } from './add-shipment/container.service';
import { MissingCarrierDialogComponent } from './add-shipment/missing-carrier-dialog/missing-carrier-dialog.component';

@NgModule({
  declarations: [
    OrganizationComponent,
    UsersComponent,
    InviteUsersComponent,
    //ProfileFormComponent,
    PasswordFormComponent,
    Users2Component,
    UserCardComponent,
    UserDetailComponent,
    UserDetailEmptyComponent,
    ContactUsComponent,
    NewSettingsShellComponent,
    UserNotificationComponent,
    NewSettingsComponent,
    CompanySettingComponent,
    NotificationMetricsComponent,
    MetricFormComponent,
    MetricFormComponent,
    MetricFormAddComponent,
    TaggedShipmentComponent,
    ShippingLaneComponent,
    ProductComponent,
    EditPasswordDlg,
    AddShipmentOneByOneComponent,
    AddShipmentComponent,
    MissingCarrierDialogComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    InlineSVGModule,
    FontAwesomeModule,
    MaterialModule,
    FlexLayoutModule,
    DpDirectivesModule,
    DpPipesModule,
    DpSharedModule,
    DpComponentsModule,
    ReactiveFormsModule,
  ],
  providers: [ContainerService],
  exports: [NotificationMetricsComponent],
})
export class SettingsModule {}
