<div id="users-page" class="page-layout two-column simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header2 dp-white p-12 pl-24 mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
      Company Profile
    </span>
  </div>



  <!-- content area: users exist -->

  <div class="content px-12 fullHeight pt-12">
    <div class="center mat-elevation-z2" dpPerfectScrollbar>
      <div class="content fullHeight dp-white" #userDetailZone>
        <dp-company-setting></dp-company-setting>
      </div>
    </div>
  </div>

</div>