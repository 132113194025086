import { Sort } from '@angular/material/sort';
import { Pagination } from '@dp/types';

export interface TeamUser {
  id: number;
  userEmail: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName: string;
  createdAt: string;
  teamName: string;
}
export interface TeamModifier {
  email: string;
  date: string;
}
export interface Team {
  id: number;
  teamName: string;
  teamDescription?: string;
  totalUsers: number;
  createdByUserEmail: string;
  createdAt: string;
  updatedByUserEmail?: string;
  updatedAt: string;
  showingUsers?: boolean;
  users?: TeamUser[];
}

export interface TeamsState {
  items: Team[];
  selectedItem?: Team;
  pagination: Pagination;
  loading: boolean;
  loadingTable: boolean;
  sort: Sort;
  ready: boolean; // this field is only changed to true by the reset action
}
export const initialTeamsState: TeamsState = {
  items: [],
  selectedItem: null,
  pagination: {
    currentPage: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    total: 0,
  },
  loading: true,
  loadingTable: false,
  sort: { active: 'teamName', direction: 'desc' },
  ready: false,
} as TeamsState;
