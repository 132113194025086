import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment';
import { CreatePartnership, UpdatePartnership } from './partnership.model';
import { IGeoFence } from '@dp/types';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingService {
  private partnershipStatusUrl = environment.rootUrl + environment.urls.updatePartnershipStatus;
  private partnershipApprovalStatusUrl = environment.rootUrl + environment.urls.updatePartnershipApprovalStatus;
  private upsertOrganizationPropertiesUrl = environment.rootUrl + environment.urls.upsertOrganizationProperties;
  constructor(private http: HttpClient) {}

  public createPartnership(organizationNumber): Observable<CreatePartnership> {
    return this.http.post<CreatePartnership>(this.partnershipStatusUrl, [{ organizationNumber }]);
  }

  public updatePartnerStatus(orgId, action): Observable<UpdatePartnership> {
    return this.http.put<UpdatePartnership>(this.partnershipStatusUrl, { orgId, action });
  }

  public updatePartnerApprovalStatus(action, actionCode) {
    return this.http.put(this.partnershipApprovalStatusUrl, { action, actionCode });
  }

  // PUT
  // @param {"geofenceRadius": number}
  updateOrganizationProperties(geofenceRadius: number): Observable<IGeoFence[]> {
    return this.http.put<IGeoFence[]>(this.upsertOrganizationPropertiesUrl, {
      propertyKey: 'geofenceRadius',
      propertyValue: geofenceRadius + '',
    });
  }
}
