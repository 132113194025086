import { Component, Input, Output, EventEmitter, HostListener, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NotificationCodes, NotificationEvent } from '../../notification.model';
import { NotificationService } from '../../notification.service';
import { UIService } from '../../../../../shared/ui.service';
import { ProgressService } from '../../../../../shared/progress-container/progress.service';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'app/settings/users/users.service';
import { ActionRequest, ApprovalStatusResponse } from '@dp/types';

@Component({
  selector: 'dp-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  // host: { '[class.isRead]': 'isRead' },
})
export class EventDetailComponent {
  // isRead: boolean = false;
  @HostListener('click', ['$event']) onClick(e) {
    e.stopPropagation();
    //console.log('xxx', e);
    if (!this.notificationEvent.isRead) {
      const progress = this.progressService.showProgress(this.hostElement);
      this.service
        .markRead(this.notificationEvent.notificationIds)
        .pipe(
          finalize(() => {
            this.progressService.detach(progress);
          })
        )
        .subscribe(
          () => {
            this.notificationEvent.isRead = true;
            this.notificationEventChange.emit(this.notificationEvent);
          },
          (error) => {
            this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
          }
        );
    }
  }
  @Input() notificationEvent: NotificationEvent;
  @Output() notificationEventChange = new EventEmitter<NotificationEvent>();
  @Input() index: number;
  @Input() count: number;
  @Output() dismiss = new EventEmitter<void>();
  notificationCodes = NotificationCodes;
  constructor(
    private service: NotificationService,
    private uiService: UIService,
    private hostElement: ElementRef,
    private progressService: ProgressService,
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef
  ) {}

  getRouterLink(): string {
    return this.notificationEvent.shipmentType === 'intermodal' ? '/ocean-shipments/tracking' : '/road-shipments/tracking';
  }
  redirect() {
    this.dismiss.emit();
  }

  action(action: string, item: string, approvalRequest: string) {
    item[action] = true;
    const actionRequest: ActionRequest = {
      payload: {
        action,
        actionCode: item['actionCode'],
      },
      approvalRequest: approvalRequest,
    };
    this.usersService
      .setApprovalStatus(actionRequest)
      .pipe(
        finalize(() => {
          item[action] = false;
          this.changeDetector.detectChanges();
        })
      )
      .subscribe(
        (res: ApprovalStatusResponse) => {
          item['action'] = action === 'accept' ? 'VERIFIED' : 'DECLINED';
          const msg = action === 'accept' ? 'The request is accepted!' : 'The request is declined!';
          this.uiService.showSnackbar(msg, null, { duration: 3000, panelClass: 'accent' });
        },
        (err) => {
          this.uiService.showSnackbar('This action failed, please try again later!', null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  buildUploadResult() {
    const item = this.notificationEvent;
    let res = '';
    const subItem = item.notificationData[0];
    let { totalUploadCount, uploadType, pendingUploadCount } = subItem as any;

    // will remove this block tomorrow
    totalUploadCount = +totalUploadCount;
    pendingUploadCount = +pendingUploadCount;
    // end of block

    if (totalUploadCount) {
      uploadType = uploadType.slice(0, uploadType.indexOf('_'));
      res +=
        totalUploadCount > 1
          ? `${totalUploadCount} new ${uploadType} numbers have been uploaded. `
          : `${totalUploadCount} new ${uploadType} number has been uploaded. `;
    }

    const tracked = totalUploadCount - pendingUploadCount;

    if (pendingUploadCount === 0) {
      if (totalUploadCount === 1) {
        res += 'It has been tracked successfully.';
      } else {
        res += 'They have been tracked successfully.';
      }
    } else if (tracked === 0) {
      if (totalUploadCount === 1) {
        res += `It couldn't be tracked yet, but the system will keep trying for a few more days.`;
      } else {
        res += 'They have not been tracked yet, but the system will keep trying for a few more days.';
      }
    } else {
      res += `${tracked === 1 ? 'One' : tracked} of them have been tracked successfully. `;
      res += `${
        pendingUploadCount === 1 ? 'One' : pendingUploadCount
      } of them couldn't be tracked yet, but the system will keep trying for a few more days.`;
    }

    return res;
  }

  getTitle(): string {
    if (this.notificationEvent.notificationCode === NotificationCodes.FILE_UPLOAD_SUMMARY) {
      const fiNum = this.notificationEvent.notificationData[0]?.['uploadNumber'];
      return fiNum ? `${this.notificationEvent.title} ${fiNum}` : 'Tracking results are ready for your recent upload';
    }
    return this.notificationEvent.title;
  }

  getUserNoteText(userNote) {
    return `${userNote.noteCreatedBy} wrote : '${userNote.noteText}'`;
  }

  getBreachMessage(sensorName: string, count:number): string {
    return `${count > 1? ' have' : 'has'} encountered ${sensorName.toLowerCase().replace('_', ' ')} data breach for installed device.`;
  }
}
