import { ValidatorFn, AbstractControl, ValidationErrors, UntypedFormGroup } from '@angular/forms';

export function atLeastOne(validator: ValidatorFn, controls: string[] = []): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control) return null;
    const formGroup = control as UntypedFormGroup;
    const res =
      formGroup && controls.some((k) => !validator(formGroup.controls[k]))
        ? null
        : {
            atLeastOne: true,
          };
    return formGroup && controls.some((k) => !validator(formGroup.controls[k]))
      ? null
      : {
          atLeastOne: true,
        };
  };
}
