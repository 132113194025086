import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Utility } from '@dp/utilities';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'app/shared';
import { AddShipmentResult } from './container.model';

@Injectable()
export class ContainerService {
  private addContainerUrl = environment.rootUrl + 'new_container/containers';
  private addVesselUrl = environment.rootUrl + 'vessels/vessels';
  private addAWBUrl = environment.rootUrl + 'awbs/awbs';
  private addOutsourcedLogisticsUrl = environment.rootUrl + 'outsourcedLogistics/create';
  constructor(private http: HttpClient, private messageService: MessageService) {}

  addShipments(payload: Object): Observable<AddShipmentResult> {
    payload = Utility.cleanupData(payload);
    const url =
      payload['uploadType'] === 'FORM_BY_VESSEL_ID'
        ? this.addVesselUrl
        : payload['uploadType'] === 'FORM_BY_AWB_NUMBER'
        ? this.addAWBUrl
        : payload['uploadType'] === 'FORM_BY_LOGISTICS_ID'
        ? this.addOutsourcedLogisticsUrl
        : this.addContainerUrl;
    return this.http.post<AddShipmentResult>(url, payload);
  }
}
