export interface AddShipmentResult {
  flag: 'FAILED' | 'SUCCESS';
  message: string;
  totalError: number;
  errorDetail: string[];
  missingCarrierUploadCount: number;
  totalUploadCount: number;
  newUploadCount: number;
  exisitingUploadCount: number;
  uploadType: 'vessel_id' | 'container_number' | 'booking_number' | 'mbl_number';
}

export interface Currency {
  id: string;
  name: string;
}

export enum VESSEL_MODES {
  BULK = 'BULK',
  CONTAINERIZED = 'CONTAINERIZED',
}

export const SHIPPING_MODES = [
  {
    id: 'FCL',
    value: 'FCL',
  },
  {
    id: 'LCL',
    value: 'LCL',
  },
  {
    id: 'BCN',
    value: 'BCN',
  },
];

export enum VISIBILITY_LEVELS {
  ALL = 'Visible to all',
  PRIVATE = 'Private to me',
  TEAMS = 'Visible in teams',
}
