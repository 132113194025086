import { SideNavLabel } from './nav.constant';
import { ROUTE_PATH } from 'app/route.constants';
import data from './navItemData.json';
import { USER_ACCESS } from 'app/user-access.constants';
import { IMAGES_MAP, UDS_ICON_MAP } from 'app/image.constants';

export class NavItem {
  uri: string;
  label: string;
  labelKey: string;
  svg: string;
  matIcon: string;
  matMenu: string;
  accessKey: string;

  constructor(uri: string, label: string, labelKey: string, svgPath: string, matIcon: string = null, matMenu: string = null, accessKey: string = '') {
    this.uri = uri;
    this.label = label;
    this.labelKey = labelKey;
    this.matMenu = matMenu;
    this.accessKey = accessKey;
    if (svgPath) {
      this.svg = svgPath;
    }
    if (matIcon) {
      this.matIcon = matIcon;
    }
  }
}
export class SideNavBarItem {
  navItem: NavItem;
  children?: SideNavBarItem[];
  isShown?: boolean;
  constructor(navItem: NavItem, children?: SideNavBarItem[], isShown?: boolean) {
    this.navItem = navItem;
    this.children = children;
    this.isShown = isShown;
  }
}

// Base interface
export interface BaseMenu {
  label: string;
  labelKey: string;
}

// Extending BaseMenu for MenuSchema
export interface MenuSchema extends BaseMenu {
  routePath?: string;
  angularRoutePath?: string;
  iconImage?: string;
  onlyMenu?: boolean;
  accessKey?: string;
  children?: MenuSchema[] | TemplateMenuSchema[];
}

// Extending BaseMenu for TemplateMenuSchema
export interface TemplateMenuSchema extends BaseMenu {
  id?: string;
  type: 'csv' | 'xlsx';
}

export enum AddContainerByTypes {
  ByContainer,
  ByBooking,
  ByMBL,
  ByVessel,
  ByAir,
  ByRoad,
  ByBulk,
  ByOL,
  ByRoadUpdate,
  ByMileStone,
}

const assets_location = 'assets/images/svg/side-nav/';
const uds_icons = 'assets/uds_icons/';
const udsIconMap = UDS_ICON_MAP;
const imageMap = IMAGES_MAP;

export const SVGIcons = {
  dashboard: uds_icons + 'dashboard-3.svg',
  container: uds_icons + 'container-1.svg',
  truck: uds_icons + 'road-1.svg',
  road: 'assets/svg/truck_shipment2.svg',
  container_delivery: assets_location + 'dashboard/ContainerDelivery-2.svg',
  business: assets_location + 'dashboard/business-stats.svg',
  insights: assets_location + 'dashboard/insights&reports.svg',
  calendar: assets_location + 'dashboard/calendar.svg',
  quotations: assets_location + 'explorer/quotation.svg',
  shipments: 'assets/uds_icons/oceanSideNav.svg',
  containers: assets_location + 'explorer/containers.svg',
  container_upload: assets_location + 'polished/container-upload.svg',
  products: assets_location + 'products.svg',
  product2: 'assets/images/svg/Shipment/product.svg',
  shipment_detail: 'assets/images/svg/Shipment/shipment_detail.svg',
  locations: assets_location + 'edit_location.svg',
  social: assets_location + 'social.svg',
  ship: 'assets/images/svg/ship.svg',
  truck2: 'assets/svg/truck_shipment2.svg',
  rail: 'assets/uds_icons/rail.svg',
  multipleMode: 'assets/uds_icons/multipleMode.svg',
  setting: assets_location + 'configure.svg',
  tbg: 'assets/svg/tbg.svg',
  documents: 'assets/svg/documents.svg',
  air_freight: 'assets/uds_icons/airFreight.svg',
  air_shipments: 'assets/uds_icons/air-shipment-2.svg',
  air_shipment_gray: 'assets/uds_icons/air-shipments-gray.svg',
  ol_shipment_gray: 'assets/uds_icons/ol_shipment_gray.svg',
  outsourced_logistics: 'assets/uds_icons/Outsourced_logistic_icon.svg',
  orders: 'assets/uds_icons/order-2.svg',
  integrated_orders: 'assets/uds_icons/order-2.svg',
  container_gray: 'assets/uds_icons/addContainerGray.svg',
  schedules: 'assets/uds_icons/schedulesParent.svg',
  flight: `assets/uds_icons/flight.svg`,
  vessel: `assets/uds_icons/vessel.svg`,
  shipment: `assets/uds_icons/shipment-parent.svg`,
  calendar_nav: `assets/uds_icons/calendar-nav.svg`,
  analytics_nav: `assets/uds_icons/analytics-nav.svg`,
};

const AusTenantNavItemsList: SideNavBarItem[] = [
  {
    navItem: new NavItem('/dashboard', SideNavLabel.DASHBOARD, 'dashboard', udsIconMap.dashboard, null),
  },
  {
    navItem: new NavItem('/purchase-orders', SideNavLabel.INTEGRATED_ORDERS, 'purchase_orders', udsIconMap.orders, null, null),
  },
  {
    navItem: new NavItem('/shipments', SideNavLabel.SHIPMENT, 'shipments', udsIconMap.shipment, null, 'shipments'),
    children: [
      {
        navItem: new NavItem('/ocean-shipments/tracking-by-group', SideNavLabel.OCEAN_SHIPMENT, 'ocean_shipments', udsIconMap.shipments, null, null),
        isShown: false,
      },
      {
        navItem: new NavItem('/air-shipments/tracking', SideNavLabel.AIR_SHIPMENT, 'air_shipments', udsIconMap.air_shipments, null, null),
        isShown: false,
      },
    ],
  },
  {
    navItem: new NavItem('/calendar/ocean-shipments', SideNavLabel.CALENDAR, 'calendar', udsIconMap.calendar_nav, null, null),
  },
  {
    navItem: new NavItem('/performance/ocean-shipments', SideNavLabel.ANALYTICS, 'performance', udsIconMap.analytics_nav, null, null),
  },
];

//note: only labelKey is used for the first version. label/routePath/iconImage will be utilized in the future
//      for order of menu will be the same as what is in the code, it would take a long time to support the order rearrange feature if we could re-construct the whole code much cleaner.
export const NavItemData: MenuSchema[] = data;

const NavItemsList: SideNavBarItem[] = [
  {
    navItem: new NavItem('/welcome', SideNavLabel.WELCOME, 'welcome', udsIconMap.schedules, null, null),
  },
  {
    navItem: new NavItem('/dashboard', SideNavLabel.DASHBOARD, 'dashboard', udsIconMap.dashboard, null, null, USER_ACCESS.DASHBOARD_TAB_VISIBILITY),
  },
  {
    navItem: new NavItem('/shipments', SideNavLabel.SHIPMENT, 'shipments', udsIconMap.shipment, null, 'shipments', USER_ACCESS.SHIPMENT_TAB_VISIBILITY),
    children: [
      {
        navItem: new NavItem(ROUTE_PATH.OCEAN_TRACKING, SideNavLabel.OCEAN_SHIPMENT, 'ocean_shipments', udsIconMap.shipments, null, null, USER_ACCESS.SHIPMENT_OCEAN_PAGE),
        isShown: false,
      },
      {
        navItem: new NavItem('/road-shipments/tracking', SideNavLabel.ROAD_SHIPMENT, 'road_shipments', udsIconMap.truck, null, null, USER_ACCESS.SHIPMENT_ROAD_PAGE),
        isShown: false,
      },
      {
        navItem: new NavItem('/air-shipments/tracking', SideNavLabel.AIR_SHIPMENT, 'air_shipments', udsIconMap.air_shipments, null, null, USER_ACCESS.SHIPMENT_AIR_PAGE),
        isShown: false,
      },
      {
        navItem: new NavItem(
          '/outsourced-logistics/tracking',
          SideNavLabel.OUTSOURCED_LOGISTICS,
          'outsourced_logistics',
          udsIconMap.outsourced_logistics,
          null,
          null,
          USER_ACCESS.SHIPMENT_OL_PAGE
        ),
        isShown: false,
      },
    ],
  },
  {
    navItem: new NavItem('/calendar/ocean-shipments', SideNavLabel.CALENDAR, 'calendar', udsIconMap.calendar_nav, null, null, USER_ACCESS.CALENDAR_TAB_VISIBILITY),
  },
  {
    navItem: new NavItem('/performance/ocean-shipments', SideNavLabel.ANALYTICS, 'analytics', udsIconMap.analytics_nav, null, null, USER_ACCESS.ANALYTICS_TAB_VISIBILITY),
  },
  {
    navItem: new NavItem('/orders', SideNavLabel.ORDERS, 'orders', udsIconMap.orders, null, null, USER_ACCESS.ORDERS_TAB_VISIBILITY),
  },
  {
    navItem: new NavItem(ROUTE_PATH.VESSEL_SCHEDULE, SideNavLabel.SCHEDULES, 'schedules', udsIconMap.schedules, null, 'schedules', USER_ACCESS.SCHEDULES_TAB_VISIBILITY),
    children: [
      {
        navItem: new NavItem(ROUTE_PATH.VESSEL_SCHEDULE, SideNavLabel.VESSEL_SCHEDULES, 'vessel_schedules', udsIconMap.vessel, null, null, USER_ACCESS.SCHEDULES_VESSEL),
        isShown: false,
      },
      {
        navItem: new NavItem(ROUTE_PATH.FLIGHT_SCHEDULE, SideNavLabel.FLIGHT_SCHEDULES, 'flight_schedules', udsIconMap.flight, null, null, USER_ACCESS.SCHEDULES_AIR),
        isShown: false,
      },
    ],
  },
  {
    navItem: new NavItem(
      '/container-pickup',
      SideNavLabel.INBOUND_CONTAINER_PICKUP,
      'inbound_container_pickup',
      imageMap.container_delivery,
      null,
      null
    ),
  },
];

export { NavItemsList };
export { AusTenantNavItemsList };

export enum TemplateFileExtensionTypes {
  csv,
  xlsx,
}
