import { Injectable } from '@angular/core';
import { UsersService } from 'app/settings/users/users.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';
import { UIService } from 'app/shared/ui.service';
import { environment } from 'environments/environment';
import { deepClone } from '@dp/utilities';
import { Team, TeamsState, initialTeamsState } from 'app/settings/settings.model';
@Injectable({
  providedIn: 'root',
})
export class TeamsFacade {
  private store = new BehaviorSubject<TeamsState>(initialTeamsState);
  public state$: Observable<TeamsState> = this.store.asObservable();

  items$ = this.state$.pipe(
    map((state) => state.items),
    distinctUntilChanged()
  );
  selectedItem$ = this.state$.pipe(
    map((state) => state.selectedItem),
    distinctUntilChanged()
  );

  pagination$ = this.state$.pipe(
    map((state) => state.pagination),
    distinctUntilChanged()
  );
  loadingTable$ = this.state$.pipe(
    map((state) => state.loadingTable),
    distinctUntilChanged()
  );
  loading$ = this.state$.pipe(
    map((state) => state.loading),
    distinctUntilChanged()
  );
  sort$ = this.state$.pipe(
    map((state) => state.sort),
    distinctUntilChanged()
  );
  ready$ = this.state$.pipe(
    map((state) => state.ready),
    distinctUntilChanged()
  );

  vm$: Observable<TeamsState> = combineLatest([
    this.items$,
    this.selectedItem$,
    this.pagination$,
    this.loading$,
    this.loadingTable$,
    this.sort$,
    this.ready$,
  ]).pipe(
    map(([items, selectedItem, pagination, loading, loadingTable, sort, ready]) => {
      return {
        items,
        selectedItem,
        pagination,
        loading,
        loadingTable,
        sort,
        ready,
      };
    })
  );

  constructor(private usersService: UsersService, private uiService: UIService) {
    combineLatest([this.pagination$, this.sort$, this.ready$])
      .pipe(
        distinctUntilChanged(),
        filter(([pagination, sort, ready]) => ready),
        debounceTime(10),
        switchMap(([pagination, sort, ready]) => {
          return this.usersService.getTeams(pagination, sort);
        })
      )
      .subscribe(
        (pagedItems) => {
          this.currentState.pagination.total = pagedItems.total;
          this.updateState({
            items: pagedItems.items,
            loading: false,
            loadingTable: false,
          });
        },
        (error) => {
          this.uiService.showSnackbar('Failed to load the content. Please try again later!', null, { duration: 3000, panelClass: 'warn' });
          this.updateState({
            items: [],
            loading: false,
          });
        }
      );
  }

  get currentState() {
    return this.store.getValue();
  }
  reset() {
    const state = deepClone(initialTeamsState);
    state.ready = true;
    this.updateState(state);
  }

  updatePagination(partial: object) {
    const pagination = { ...this.currentState.pagination, ...partial };
    const newState = { pagination, loadingTable: true };
    this.updateState(newState);
  }
  updateState(partial: object) {
    const newState = { ...this.currentState, ...partial };
    this.store.next(newState);
  }

  deleteTeam(team: Team) {
    this.updateState({ loadingTable: true });
    this.usersService
      .deleteTeam(team.id)
      .pipe(finalize(() => this.updateState({ loadingTable: false })))
      .subscribe({
        next: (v) => {
          const items = this.currentState.items.filter((item) => item.id !== team.id);
          this.currentState.pagination.total --;
          const pagination = deepClone(this.currentState.pagination);
          if (items.length === 0 && this.currentState.pagination.currentPage > 0) {
            pagination.currentPage = pagination.currentPage - 1;
          } 
          // force page to reload
          this.updateState({ pagination });
          this.uiService.showSnackbar(`Team ${team.teamName} is deleted!`, null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'success',
          });
        },
        error: (e) => {
          this.uiService.showSnackbar(`Failed to delete team ${team.teamName}. Please try later!`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        },
      });
  }
  toggleMember(team: Team) {
    const selectedItem = this.currentState.selectedItem === team ? null : team;
    this.updateState({ selectedItem });
  }
}
