import { Component, OnInit, Input, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { WebUpdate, WebUpdatePayload } from 'app/auth/user.model';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UsersService } from 'app/settings/users/users.service';
import { environment } from 'environments/environment';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

enum ButtonTypes {
  Dismiss,
  RemindMe,
}
@UntilDestroy()
@Component({
  selector: 'dp-web-update',
  templateUrl: './web-update.component.html',
  styleUrls: ['./web-update.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebUpdateComponent implements OnInit {
  isDismissBusy = false;
  isRemindMeBusy = false;
  ButtonTypes = ButtonTypes;
  data: WebUpdate;
  leftTime = environment.heartBeats.webUpdateHeartBeat;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public snackBarRef: MatSnackBarRef<WebUpdateComponent>,
    private usersService: UsersService,
    @Inject(MAT_SNACK_BAR_DATA) public webUpdates: WebUpdate[]
  ) {
    this.webUpdates = this.webUpdates.sort((a, b) => (a.priority > b.priority ? -1 : 1));
    this.data = this.webUpdates[0];
  }

  ngOnInit(): void {
    let paginatorIntl = this.paginator._intl;
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.nextPageLabel = '';
  }

  setBusy(btnType: ButtonTypes, isBusy: boolean) {
    if (btnType === ButtonTypes.Dismiss) {
      this.isDismissBusy = isBusy;
    } else if (btnType === ButtonTypes.RemindMe) {
      this.isRemindMeBusy = isBusy;
    }
  }

  btnClicked(btnType: ButtonTypes) {
    let payload: WebUpdatePayload;

    this.setBusy(btnType, true);
    if (btnType === ButtonTypes.Dismiss) {
      payload = {
        webUpdateId: this.data.id,
        isFinished: true,
      };
    } else if (btnType === ButtonTypes.RemindMe) {
      payload = {
        webUpdateId: this.data.id,
        isRemindMeLaterClicked: true,
      };
    }
    this.usersService
      .setUserWebUpdate(payload)
      .pipe(
        finalize(() => this.setBusy(btnType, false)),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.nextStep();
          //this.snackBarRef.dismiss();
        },
        (error) => {
          // this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
          //   duration: environment.snackBarDuration.error,
          //   panelClass: 'warn',
          // });
        }
      );
  }

  navigateTo(event: PageEvent) {
    // console.log(event.pageIndex);
    if (this.notActionable()) {
      this.data.actionFinished = true;
    }
    this.data = this.webUpdates[event.pageIndex];
  }

  notActionable() {
    return !this.data.isDismissAvailable && !this.data.isRemindMeLaterAvailable;
  }

  countdownFinished(event) {
    console.log('countdown finished');
    if (event.action === 'done') {
      this.nextStep();
    }
  }

  nextStep() {
    this.data.actionFinished = true;
    if (this.paginator.hasNextPage()) {
      this.paginator.nextPage();
    } else {
      this.snackBarRef.dismiss();
    }
  }
}
