import { Component, OnInit, Input } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { MatDialog } from '@angular/material/dialog';
import { RailStationDialogComponent } from 'app/ocean-shipments/components/rail-station-dialog/rail-station-dialog.component';

enum journeyLocation {
  railStation = 'Trans Rail Station',
  destinationPort = 'Destination Port',
};
@Component({
  selector: 'dp-tracking-step',
  templateUrl: './tracking-step.component.html',
  styleUrls: ['./tracking-step.component.scss'],
})
export class TrackingStepComponent implements OnInit {
  @Input() steps;
  @Input() statusHeader = 'Location activities';
  @Input() context: any;
  SvgMap = SvgMap;
  firstRow = null; // first Flow Tracking service milestone
  transRailStation = {
    title: "Rail Stations",
    data: [],
  };

  ngOnInit(): void {
    this.checkTransRailStation();
    if (this.firstRow) return;
    for (const step of this.steps) {
      if (step.locationActions) {
        for (const row of step.locationActions)
          if (!row.isManualMilestone) {
            this.firstRow = row;
            return;
          }
      }
    }
  }
  constructor(private dialog: MatDialog) {}

  checkTransRailStation() {
    this.transRailStation.data = this.steps.filter(step => step.title === journeyLocation.railStation);
    const idx = this.steps.findIndex(step => step.title === journeyLocation.destinationPort);
    if (!this.transRailStation.data.length) {
      return;
    }
    this.steps = this.steps.filter(step => step.title !== journeyLocation.railStation);
    this.steps.splice(idx + 1, 0, this.transRailStation);
  }

  getEventClass(event) {
    if (event.isCurrent) {
      return 'event-current';
    }
    if (event.isFuture) {
      return 'event-future';
    }
    return 'event-past';
  }

  childrenHaveFuture(steps: any[] = []): boolean {
    return steps?.some((step) => step.isFuture);
  }

  getPredictiveDaysDifference(hubEtaDaysDifference: number): string {
    if (!hubEtaDaysDifference) {
      return '';
    }
    let date = '';
    if (hubEtaDaysDifference > 0) {
      date = `(+${hubEtaDaysDifference}`;
    } else {
      date = `(${hubEtaDaysDifference}`;
    }
    if ([-1, 1].includes(hubEtaDaysDifference)) {
      return `${date} day)`;
    }
    return `${date} days)`;
  }

  openRailStationDlg(step) {
    this.dialog.open(RailStationDialogComponent, {
      data: {
        step
      },
      width: '641px',
      minHeight: '206px',
    });
  }

  getPredictedTitle() {
    return this.context?.hubAta ? 'Flow Predicted' : 'Flow Predicts';
  }
}
