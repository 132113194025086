import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { WebUpdate } from 'app/auth/user.model';
import { WebUpdateComponent } from 'app/navigation/header/web-update/web-update.component';

@Injectable()
export class WebUpdateService {
  constructor(private snackbar: MatSnackBar) {}

  webUpdates: WebUpdate[];
  snackBarRef: MatSnackBarRef<WebUpdateComponent>;

  showChainedSnackbars(webUpdates?: WebUpdate[]) {
    if (webUpdates) {
      this.webUpdates = webUpdates;
    }

    //const webUpdate = this.webUpdates.shift();
    this.snackBarRef = this.snackbar.openFromComponent(WebUpdateComponent, {
      data: webUpdates,
      duration: undefined, // webUpdate.isDismissAvailable ? undefined : 6000,
      panelClass: 'chainedSnackbar',
    });

    // this.snackBarRef.afterDismissed().subscribe(() => {
    //   console.log('data', this.snackBarRef['data']);
    //   if (this.webUpdates.length > 0) {
    //     this.showChainedSnackbars();
    //   }
    // });
  }

  stop() {
    this.webUpdates = [];
    this.snackBarRef?.dismiss();
  }
}
