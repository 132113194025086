import { StaticData } from '@dp/services/static-data.model';
import { StaticDataService } from '@dp/services/static-data.service';
import { environment } from 'environments/environment';
import moment from 'moment';
export class DateTimePattern {
  readonly siteKey = environment.recapchaKey;
  staticData: StaticData;

  testDate = moment('2020-01-30 04:35:58');
  // datePatterns = environment.constants.datePatterns;
  // timePatterns = environment.constants.timePatterns;
  datePatterns: string[];
  timePatterns: string[];

  constructor(dataService: StaticDataService) {
    this.staticData = dataService.getStaticDataDirect();
    this.datePatterns = this.staticData.date_patterns;
    this.timePatterns = this.staticData.time_patterns;
  }

  getStamp(format: string): string {
    return `${this.testDate.format(format)} (${format})`;
  }
}
