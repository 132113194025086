<div class="row container-details">
  <div class="col" *ngIf="container.vesselName">
    <label class="label">Vessel name / No.</label>
    <p>
      <span span class="value">
        {{getVesselNameNumber(container) || ''}}
      </span>
    </p>
  </div>

  <div class="col">
    <label class="label">Container #</label>
    <p>
      <span span class="value">
        {{container.containerNumber || ''}}
      </span>
    </p>
  </div>

  <div class="col" *ngIf="container.containerSize">
    <label class="label">Container Type</label>
    <p><span class="value">
        {{container.containerSize || ''}}
      </span>
      &nbsp;
      <span span class="value">
        {{container.containerType || ''}}
      </span>
    </p>
  </div>

  <div class="col" *ngIf="container.status">
    <label class="label">Status</label>
    <p class="text-bold status">
      <!-- <span class="value">{{container.status | shipmentStatus}}</span> -->
      <span [innerHTML]="container.status | shipmentStatus"></span>
    </p>
  </div>

  <!-- Interport (100003537) as a client has requested that the MBL needs to be hidden when public link is being shared -->
  <div class="col" *ngIf="container.blNumber && container.organizationId != '100003537'">
    <label class="label">MBL #</label>
    <p>
      <span class="value">{{container.blNumber || '' }}</span>
    </p>
  </div>

  <div class="col" *ngIf="container.bkNumber">
    <label class="label">Booking #</label>
    <p>
      <span class="value">{{container.bkNumber || '' }}</span>
    </p>
  </div>

  <div class="col" *ngIf="container.referenceNumber">
    <label class="label">Shipment reference</label>
    <p>
      <span class="value">{{container.referenceNumber || '' }}</span>
    </p>
  </div>

  <div class="col" *ngIf="container.transportJourneys.portToPort; let p">
    <label class="label">Carrier</label>
    <p>
      <span class="value">{{p.carrier || '' }}</span>
    </p>
  </div>

  <div class="col" *ngIf="container.emissions?.co2e?.value let v">
    <label class="label">Carbon Emissions</label>
    <p>
      <span class="value">{{ v | number : '1.0-2' }} {{container.emissions.co2e.unit || '' }}</span>
      <span>
        <dp-svg [src]="udsIconMap.infoExclamation" w="16" placement="bottom"
          tooltip="Total Port to Port GHG emission basis GLEC framework Default factors"></dp-svg>
      </span>
    </p>
  </div>


</div>