<div class="tags-items-container" fxLayout="row wrap" fxLayoutAlign="start center" clickOutside (clickOutside)="clickOutside()">

  <span class="mr-4 nowrap" [ngClass]="getTagClass(i)" *ngFor="let tag of tags;let i = index">
    <span class="tagItem" [ngClass]="{ellipsis: !showFullTag}" showTooltipIfTruncated tooltip="{{tag.name}}">
      <span
        [innerHTML]="showFullTag? (tag.name | highlightSearch: searchTerm) : (tag.name | highlightSearch: searchTerm | truncate:[16])"></span>
    </span>
    <span class="closeItem addTag cursor-pointer" (click)="clearTag(tag)" *ngIf="!readOnly" [class]="userAccessService.accessClass(accessKeys.TAG, sharedByOrganizationId)" [tooltip]="userAccessService.getTooltip(accessKeys.TAG, sharedByOrganizationId)">
      <i class="material-icons plusItem">clear</i>
    </span>
  </span>
  <span class="nowrap compactMode" *ngIf="showDots">
    <span class="tagDots" tooltip="{{getTitle()}}">...</span>
  </span>

  <div [hidden]="editingMode || readOnly" [class]="userAccessService.accessClass(accessKeys.TAG, sharedByOrganizationId)">
    <span class="tagItem cursor-pointer" *ngIf="tags?.length===0" (click)="switchToEditing()" [tooltip]="userAccessService.getTooltip(accessKeys.TAG, sharedByOrganizationId)">Add tag
      <!-- <dp-svg [src]="SvgMap.addTag" w="16"></dp-svg> -->
    </span>
    <span class="plusItem addTag cursor-pointer" [tooltip]="userAccessService.getTooltip(accessKeys.TAG, sharedByOrganizationId) || 'Add Tag'"
      *ngIf="tags?.length > 0" (click)="switchToEditing()">
      <i class="material-icons">add</i>
    </span>
  </div>
  <mat-spinner [diameter]="18" class="ml-8" [hidden]="!busy"></mat-spinner>
  <form class="inline-block easy-form no-hint" [hidden]="!editingMode">
    <mat-form-field class="port-field ml-12 w-120" floatLabel='never' style="height: inherit;">
      <input type="text" class="w-120 port-field-input" placeholder="" aria-label="Tag" matInput [formControl]="tagEditingCtrl" [matAutocomplete]="auto"
        (keyup.enter)="onEnter($event.target.value)" (keyup.escape)="closeEditing()" (blur1)="onEnter($event.target.value)"
        floatLabel="never" #editTagInput>
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption="true" (optionSelected)="tagSelected($event)" (closed)="closed()">
        <div class="suggestions p-4">Suggestions</div>
        <mat-option *ngFor="let tag of tagFiltered$ | async" [value]="tag">
          {{tag.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>