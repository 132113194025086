import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StaticDataService } from '@dp/services/static-data.service';
import { UserAccessService } from './auth/user-access.service';
import { environment } from 'environments/environment';
import { Utility } from '@dp/utilities';
import { LoginCompanyStatusType } from './auth/user.model';

export function get_settings(staticDataRemoteService: StaticDataService) {
  return () => staticDataRemoteService.getSettings();
}

export function loadUserAccessData(userAccessService: UserAccessService) {
  const currentUser = Utility.getItemDecrypted(environment.storage.currentUser);
  return () =>
    currentUser?.companyStatus === LoginCompanyStatusType.VERIFIED && currentUser?.userCode
      ? userAccessService.fetchDataOnRefresh(currentUser.userCode)
      : null;
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    StaticDataService,
    { provide: APP_INITIALIZER, useFactory: get_settings, deps: [StaticDataService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadUserAccessData, deps: [UserAccessService], multi: true },
  ],
})
export class AppLoadModule {}
