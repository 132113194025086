import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UIService } from 'app/shared/ui.service';
import { AuthService } from 'app/auth/auth.service';
import { IChatQueryRequest, IChatbotResponse, IChatbotConfig } from './chatbot.model';
import { environment } from 'environments/environment';
import { Utility } from '@dp/utilities';

const defaultChatbotConfig: IChatbotConfig = {
  showChatBox: false,
  showChatBubble: false,
};

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  chatbotUrl = environment.rootUrl + 'chatbot/query';
  store: BehaviorSubject<IChatbotConfig> = new BehaviorSubject(defaultChatbotConfig);
  state$ = this.store.asObservable();
  isLoggedIn = false;
  userId;
  userInitial = '';

  constructor(private http: HttpClient, private authService: AuthService, private uiService: UIService) {
    this.authService.currentUser.subscribe((user) => {
      this.isLoggedIn = this.authService.isAuth();
      this.userInitial = Utility.getInitials(user);
      if (this.isLoggedIn) {
        this.updateState({ showChatBox: false, showChatBubble: true });
        this.userId = user.id;
      } else {
        this.updateState(defaultChatbotConfig);
        this.userId = null;
      }
    });
  }

  get currentState() {
    return this.store.getValue();
  }

  updateState(partial: object) {
    if (this.isLoggedIn) {
      const newState = { ...this.currentState, ...partial };
      this.store.next(newState);
    }
  }

  toggleChatbot() {
    const newState = { showChatBox: !this.currentState.showChatBox, showChatBubble: !this.currentState.showChatBubble };
    this.updateState(newState);
  }

  getChatResponse(query: string, instructions = '', newSession = true): Observable<IChatbotResponse> {
    const payload: IChatQueryRequest = {
      userId: this.userId + '',
      query,
      newSession,
      instructions,
    };
    return this.http.post<IChatbotResponse>(this.chatbotUrl, payload);
  }
}
