<form class="full-width easy-form" *ngIf="{
  options: filteredOptions$ | async,
  loading: loading$ | async
} as obs">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [formControl]="vesselInput" [matAutocomplete]="auto" required placeholder="Search vessel name or No.">
    <mat-error>{{ getErrors() }}
    </mat-error>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelectedChange($event)" [displayWith]="displayFn" panelWidth="300px">
      <ng-container *ngIf="!obs.loading">
        <mat-option *ngFor="let option of obs.options" [value]="option">
          {{ option.name + ' / ' + option.imo }}
        </mat-option>
      </ng-container>

      <mat-option disabled class="loading" *ngIf="obs.loading">
        <mat-spinner diameter="35"></mat-spinner>
      </mat-option>
      <mat-option disabled *ngIf="obs.options?.length === 0 && !obs.loading">
        No vessel found
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>