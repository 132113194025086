<div class="detail" *ngIf="payload; let data">
  <div class="row">
    <div class="col">
      <label class="label">Air Waybill</label>
      <p class="text-bold">{{data.blNumber || 'N/A'}}</p>
    </div>

    <div class="col">
      <label class="label">Airline</label>
      <p class="text-bold">{{ data.transportJourneys.portToPort.transportName }}</p>
    </div>

    <div class="col" *ngIf="data.status">
      <label class="label">Status</label>
      <p class="text-bold" [ngClass]="data.status">{{data.status}}</p>
    </div>
  
      <div class="col" *ngIf="data.emissions?.co2e?.value let v">
        <label class="label">Carbon Emissions</label>
        <p>
          <span class="value">{{ v | number : '1.0-2' }} {{data.emissions.co2e.unit || '' }}</span>
          <span>
            <dp-svg [src]="SvgMap.infoExclamation" w="16" placement="bottom"
              tooltip="Total Port to Port GHG emission basis GLEC framework Default factors"></dp-svg>
          </span>
        </p>
      </div>
  
    <div class="col" *ngIf="data.referenceNumber">
      <label class="label">Shipment reference</label>
      <p class="text-bold">{{data.referenceNumber || '' }}</p>
    </div>
      
  </div>

  <div class="row">
    <div class="col">
      <label class="label">Shipper</label>
      <p class="text-bold">{{ data.consignor || 'N/A'}}</p>
    </div>

    <div class="col">
      <label class="label">Consignee</label>
      <p class="text-bold">{{ data.consignee || 'N/A' }}</p>
    </div>

    <div class="col">
      <label class="label">Weight</label>
      <p class="text-bold">{{ data.totalWeight }} {{ data.totalWeightUom}}</p>
    </div>

    <div class="col">
      <label class="label">Piece</label>
      <p class="text-bold">{{ data.totalNumberOfPackages }} </p>
    </div>
  </div>
</div>