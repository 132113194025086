<div class="page-layout simple left-sidebar inner-sidebar inner-scroll " style="background-color: white;">
  <div class="uds-page-title" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
    Playground
  </div>

  <div class="uds-page-content">
    <div>
      <h1>Build info</h1>
      <p>Build time (local): {{localTime}}</p>
      <mat-divider></mat-divider>
    </div>
    <div>
      <h1>UDS Topography</h1>
      <div class="card">
        <div class="uds-default"> uds/default</div>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div>
      <h1>Badge</h1>
      <div class="card">
        <p> 1 Digit </p>
        <dp-mini-fab [total]="9"></dp-mini-fab>
      </div>
      <div class="card">
        <p> 2 Digits </p>
        <dp-mini-fab [total]="22"></dp-mini-fab>
      </div>
      <div class="card">
        <p> 3 Digits </p>
        <dp-mini-fab [total]="333"></dp-mini-fab>
      </div>
      <div class="card">
        <p> 4 Digits </p>
        <dp-mini-fab total="4444"></dp-mini-fab>
      </div>
      <div class="card">
        <p> 5 Digits </p>
        <dp-mini-fab total="55555"></dp-mini-fab>
      </div>

      <div class="card">
        <p> Color: uds-tertiary-tiger</p>
        <dp-mini-fab total="123" bgColor="uds-tertiary-tiger"></dp-mini-fab>
      </div>

      <mat-divider></mat-divider>
    </div>
    <div>
      <h1>svg icon</h1>
      <div class="card">
        <p> dp-svg </p>
        <dp-svg [src]="SvgMap.delete"></dp-svg>
      </div>
      <div class="card">
        <p> dp-svg-icon </p>
        <dp-svg-icon [src]="SvgMap.uds_delete"></dp-svg-icon>
      </div>
      <div class="card">
        <p> dp-svg-icon, disabled </p>
        <dp-svg-icon [disabled]="true" [src]="SvgMap.uds_delete"></dp-svg-icon>
      </div>
      <div class="card">
        <p> dp-svg-icon, large size </p>
        <dp-svg-icon [w]="'25px'" [h]="'25px'" [src]="SvgMap.uds_delete"></dp-svg-icon>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <h1>UDS toggle slider</h1>
      <div class="card">
        <mat-slide-toggle>unchecked uds uds slide</mat-slide-toggle>
      </div>
      <div class="card">
        <mat-slide-toggle [checked]="true">checked uds uds slide</mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <h1>Form</h1>
      <form action="" class="easy-form">
        <mat-form-field appearance="outline">
          <mat-label>InputXXX</mat-label>
          <input matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-200">
          <mat-label>Select</mat-label>
          <mat-select>
            <mat-option value="one">First option</mat-option>
            <mat-option value="two">Second option</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Textarea</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
      </form>
      <mat-divider></mat-divider>
    </div>

    <div>
      <h1>UDS Buttons</h1>
      <p>buttons with all variations</p>
      <div>
        <div class="card">
          <p> regular button </p>
          <button mat-flat-button class="easy-button">regular</button>
        </div>
        <div class="card">
          <p> disabled </p>
          <button mat-flat-button class="easy-button" disabled>disabled</button>
        </div>
        <div class="card">
          <p> secondary </p>
          <button mat-flat-button class="easy-button secondary">secondary</button>
        </div>
        <div class="card">
          <p> secondary disabled </p>
          <button mat-flat-button class="easy-button secondary" disabled>secondary disabled</button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div class="card">
          <p> medium </p>
          <button mat-flat-button class="easy-button medium">medium</button>
        </div>
        <div class="card">
          <p> medium disabled </p>
          <button mat-flat-button class="easy-button medium" disabled>disabled</button>
        </div>
        <div class="card">
          <p> medium secondary </p>
          <button mat-flat-button class="easy-button secondary medium">secondary</button>
        </div>
        <div class="card">
          <p> medium disabled secondary </p>
          <button mat-flat-button class="easy-button secondary medium" disabled>disabled secondary</button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div class="card">
          <p> small button </p>
          <button mat-flat-button class="easy-button small">regular</button>
        </div>
        <div class="card">
          <p> small button, disabled </p>
          <button mat-flat-button class="easy-button small" disabled>disabled</button>

        </div>
        <div class="card">
          <p> small button, secondary </p>
          <button mat-flat-button class="easy-button small secondary">secondary</button>

        </div>
        <div class="card">
          <p> small button icon</p>
          <button mat-flat-button class="easy-button small">
            <mat-icon>clear</mat-icon>
            <span> regular </span>
          </button>

        </div>
        <div class="card">
          <p> small button, disabled </p>
          <button mat-flat-button class="easy-button small" disabled>
            <mat-icon>save</mat-icon>
            <span>SAVE</span>
          </button>
        </div>
        <div class="card">
          <p> small button, secondary, icon </p>
          <button mat-flat-button class="easy-button small secondary">
            <mat-icon>clear</mat-icon>
            <span>secondary</span>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div class="card">
          <p> tiny button </p>
          <button mat-flat-button class="easy-button tiny">tiny</button>
        </div>
        <div class="card">
          <p> tiny button, disabled </p>
          <button mat-flat-button class="easy-button tiny" disabled>disabled</button>
        </div>
        <div class="card">
          <p> tiny button, secondary </p>
          <button mat-flat-button class="easy-button tiny secondary">secondary</button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <h1>ToolTips</h1>
      <div class="card">
        <p> above tooltips </p>
        <dp-svg [src]="SvgMap.helpCenter" w="26" tooltip="Above ToolTips"></dp-svg>
      </div>
      <div class="card">
        <p> below tooltips </p>
        <dp-svg [src]="SvgMap.helpCenter" w="26" tooltip="below ToolTips" placement="bottom"></dp-svg>
      </div>
      <div class="card">
        <p> left tooltips </p>
        <dp-svg [src]="SvgMap.helpCenter" w="26" tooltip="left ToolTips" placement="left"></dp-svg>
      </div>
      <div class="card">
        <p> right tooltips </p>
        <dp-svg [src]="SvgMap.helpCenter" w="26" tooltip="right ToolTips" placement="right"></dp-svg>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
