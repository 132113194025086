export let types = {
  get(prop) {
    return Object.prototype.toString.call(prop);
  },
  null: '[object Null]',
  object: '[object Object]',
  array: '[object Array]',
  string: '[object String]',
  boolean: '[object Boolean]',
  number: '[object Number]',
  date: '[object Date]'
};
