import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'dp-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})

/**
 * A prompt used by the Users tab. Allows user to input many email addresses of users 
 * they would like to invite.
 * @author Charles Skinner <charles.skinner@dpworld.com>
 */
export class InviteUsersComponent implements OnInit {

  emailFormGroup: UntypedFormGroup = null;

  constructor(public dialogRef: MatDialogRef<InviteUsersComponent>,
    public formBuilder: UntypedFormBuilder) { }

  ngOnInit() {

    this.emailFormGroup = this.formBuilder.group({
      emailArray: this.formBuilder.array([
        this.formBuilder.control('', [Validators.required, Validators.email]),
      ])
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  // provides the error messages that show up below an email entry input
  getErrorMessage(email: UntypedFormControl) {
    return email.hasError('required') ? 'You must enter a value' :
      email.hasError('email') ? 'Not a valid email' :
        '';
  }

  onSubmitClicked() {
    let emails: Array<string> = (this.emailFormGroup.get('emailArray') as UntypedFormArray).getRawValue();
    this.dialogRef.close(emails);
  }

  addNewEmailSlot() {
    (this.emailFormGroup.get('emailArray') as UntypedFormArray).push(this.formBuilder.control('', [Validators.required, Validators.email]));
  }

  removeEmailSlot(indexToRemove: number) {
    (this.emailFormGroup.get('emailArray') as UntypedFormArray).removeAt(indexToRemove);
  }

  getEmailControls(): Array<AbstractControl> {

    return (this.emailFormGroup.get('emailArray') as UntypedFormArray).controls;
  }
}
