<div id="user-invite-action" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div id="register-form-wrapper" #contentZone dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="register-form">
      <div class="logo" fxHide.gt-xs [routerLink]="'/'">
        <img [src]="SvgMap.flowNewSquare" alt="">
      </div>

      <div class="title">{{getTitle()}}</div>
      <div class="sub-title black-medium-text" *ngIf="hasResult">Company invitation is {{actionMap[action]}} successfully!</div>
      <div class="sub-title black-medium-text" *ngIf="!hasResult && error">{{getError()}}</div>



    </div>
  </div>
</div>
