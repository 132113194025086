import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UIService } from 'app/shared';
import { UsersService } from 'app/settings/users/users.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';
import { Utility } from '@dp/utilities';

@Component({
  selector: 'dp-snackbar-template',
  templateUrl: './snackbar-template.component.html',
  styleUrls: ['./snackbar-template.component.scss'],
})
export class SnackbarTemplateComponent implements OnInit {
  isBusy = false;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarTemplateComponent>,
    private uiService: UIService,
    private usersService: UsersService,
    private authService: AuthService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeMe() {
    this.snackBarRef.dismiss();
    this.uiService.remindMeClosed = true;
    this.setRemindMeLater();
  }

  setRemindMeLater() {
    Utility.addToLocalStorageObject(environment.storage.currentSession, 'remindMeLater', true);
    
  }

  resendEmail() {
    this.isBusy = true;
    const payload = {
      newEmail: this.authService.currentUserValue.pendingUserEmail,
    };
    this.usersService
      .updateUser(payload, this.authService.currentUserValue.userCode)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        (result) => {
          this.snackBarRef.dismiss();
          this.uiService.showSnackbar('Email is sent!', null, { duration: environment.snackBarDuration.success, panelClass: 'accent' });
        },
        (error) => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }
}
