import { HumidityInfo, TemperatureInfo, Tag, TimelineSpot, SensorApiItem, SensorUiItem } from '@dp/types';
import { LogisticsPricingInfo } from 'app/ocean-shipments/ocean-shipments-models';
import { Note } from 'app/shared/components/shipment-note/shipment-note.component';
import { TruckPage } from 'app/truck-shipments/trucks-models';
import { SensorData } from '../../@dp/types/dp-model';

export enum SHIPMENT_DATE_TYPE {
  ATD = 'ATD',
  ETD = 'ETD',
  ETA = 'ETA',
  ATA = 'ATA',
}

export enum SHIPMENT_STATE_TYPE {
  ON_TIME = 'ON_TIME',
  DELAYED = 'DELAYED',
  VERY_DELAYED = 'VERY_DELAYED',
  COMPLETED = 'COMPLETED',
  CUSTOM_HOLD = 'CUSTOM_HOLD',
}

export enum SHIPMENT_EXCEPTION_TYPE {
  ON_TIME = 'ON TIME',
  DELAYED = 'DELAYED',
  CUSTOM_HOLD = 'CUSTOM HOLD',
}

// export const SHIPMENT_EXCEPTIONS = [
//   {
//     key: 'OS_ARRIVED_LATE',
//     value: 'ATA greater than ETA',
//   },
//   {
//     key: 'OS_MISSED_ARRIVAL',
//     value: 'ETA passed, no arrival',
//   },
//   {
//     key: 'OS_MISSED_DEPARTURE',
//     value: 'ETD passed, no departure',
//   },
//   {
//     key: 'OS_ARRIVED_EARLY',
//     value: 'ATA less than ETA',
//   },
//   {
//     key: 'OS_DEPARTED_LATE',
//     value: 'ATD greater than ETD',
//   },
// ];

export enum SHIPMENT_STATUS_TYPE {
  ALL = 'All',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  NEW = 'Pending',
  UNTRACKABLE = 'Unable to track or update',
}

export const SHIPMENT_STATUS_TYPE2 = [
  {
    key: 'ALL',
    value: 'All',
    iconClass: '',
  },
  {
    key: 'ACTIVE',
    value: 'Active',
    iconClass: 'material-icons cool-secondary-100-text',
  },
  {
    key: 'COMPLETED',
    value: 'Completed',
    iconClass: 'material-icons cool-primary-100-text',
  },
  {
    key: 'NEW',
    value: 'Pending',
    iconClass: 'material-icons black-disabled-100-text',
  },
  {
    key: 'UNTRACKABLE',
    value: 'Unable to track or update',
    iconClass: 'material-icons-outlined warning-dark-text',
  },
];

export enum SHIPMENT_SUB_STATUS1_TYPE {
  BOOKED = 'BOOKED',
  CONFIRMED = 'CONFIRMED',
  PREPARING = 'PREPARING',
  IN_TRANSIT = 'IN TRANSIT',
  DELIVERED = 'DELIVERED',
}

// wiki: https://dev.azure.com/dpwhotfsonline/DTLP/_wiki/wikis/DTLP.wiki/21/Shipment-Page?anchor=shipment-substatus-2
export enum SHIPMENT_SUB_STATUS2_TYPE {
  IN_POO = 'IN POO',
  ON_WATER = 'ON WATER',
  IN_POD = 'IN POD',
}

// export enum ARRIVAL_DATE_FILTER_TYPE {
//   LAST_6_MONTH = "Last 6 month",
//   YEAR_TO_DATE = "Year to date",
//   CUSTOM = "Custom",
// }


export enum FILTER_FLAG_TYPE {
  FLAGGED = 'FLAGGED',
  NOTFLAGGED = 'NOT FLAGGED',
}

export enum NOTIFICATION_TYPE {
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export interface Shipment {
  serviceMode: string;
  shipmentType: string;
  bookingDate: string;
  carrierInfo: BusinessInfo;
  bolNumber: string;
  vessel: string;
  voyage: string;
  etd;
  atd;
  eta;
  ata: string;
  originalEta: string;
  incoterm: string;
  instructionsCutOffDate: string;
  shipmentProcedure?: TimelineSpot[];
  id: number;
  shipmentNumber: string;
  shipmentName: string;
  shipmentReference: string;
  bookingNumber: string;
  oceanCarrier: string;
  departureDate: string;
  departureDateType: SHIPMENT_DATE_TYPE;
  shipmentContent?: string;
  portOfDeparture: string;
  departureDpwFlag?: boolean;
  portOfArrival: string;
  arrivalDpwFlag?: boolean;
  arrivalDate: string;
  arrivalDateType: SHIPMENT_DATE_TYPE;
  totalContainers: number;
  flagged: boolean;
  subscribed: boolean; //new
  state: SHIPMENT_STATE_TYPE;
  status: SHIPMENT_STATUS_TYPE;
  trackingTrace?: TimelineSpot[];
  matchingContainerNumbers?: string[];
  matchingProductNumbers?: string[];
  exceptionSeverity?: number;
  exceptionMessage?: string;
  totalProducts: number;
  totalWeight?: string;
}

export interface ShipmentFilters {
  flagged?: FILTER_FLAG_TYPE; // 3 states, null means accept all
  status?: string; // SHIPMENT_STATUS_TYPE;
  exception?: SHIPMENT_EXCEPTION_TYPE;
  subStatus1?: SHIPMENT_SUB_STATUS1_TYPE[];
  subStatus2?: SHIPMENT_SUB_STATUS2_TYPE[];
  carriers?: string[];
  originHubs?: string[]; //portsOfDeparture?: string[];
  destinationHubs?: string[]; //portsOfArrival?: string[];
  notification?: NOTIFICATION_TYPE;
}

export interface ShipmentDetail {
  createdAt: string;
  containerType?: string;
  containerSize?: string;
  shippingMode?: string;
  id: number;
  shipmentNumber: string;
  shipmentName?: string;
  shipmentReference?: string;
  referenceNumber?: string;
  shipmentType: string;
  shipmentContent: string;
  serviceMode: string;
  bookingNumber: string;
  bookingDate: string;
  oceanCarrier: string;
  departureDate: string;
  departureDateType: string;
  arrivalDpwFlag: boolean;
  departureDpwFlag: boolean;
  arrivalDate: string;
  arrivalDateType: string;
  portOfDeparture: string;
  portOfArrival: string;
  totalContainers: number;
  flagged: boolean;
  bolNumber: string;
  vessel: string;
  voyage: string;
  etd: string;
  atd: string;
  originalEta?: null;
  eta: string;
  ata: string;
  incoterm: string;
  instructionsCutOffDate: string;
  state: string; //DEPRECATED
  status: string;
  subStatus1: string;
  trackingTrace?: TimelineSpot[];
  shipmentProcedure?: TimelineSpot[];
  exceptionSeverity: number;
  //containers?: Array<ShipmentContainer>;
  consignor?: BusinessInfo;
  consignee?: BusinessInfo;
  carrierInfo: BusinessInfo;
  load?: Load;
  services: Services;
  customBrokerage: BrokerageInfo;
  shipmentEvents?: ShipmentEvents;
  shipmentContainers?: ShipmentContainer[];
  shipmentMap?: ShipmentMap;
  shipmentExceptions?: ShipmentExceptions;
  documents?: IDocument[];
  exceptions?: any;
  transportJourneys?: any;
  containerSealNumber: string;
  emptyContainerWeight: string;
  emptyContainerWeightUom: string;
  maxContainerWeight: string;
  maxContainerWeightUom: string;
  cargoType: string;
  temperatureInfo?: TemperatureInfo;
  humidityInfo?: HumidityInfo;
  totalWeight?: string;
  totalWeightUom?: string;
  netWeight?: string;
  netWeightUom?: string;
  totalVolume?: string;
  totalVolumeUom?: string;
  totalNumberOfPackages?: string;
  commodity?: string;
  packageType?: string;
  shipmentTags?: Tag[];
  logisticsPricingInfo?: LogisticsPricingInfo;
  shipmentPartners: any[];
  uploadType: string;
  notes?: Note[];
  sharedByOrganizationId?: number;
  isTemperatureDataAvailable?: boolean;
  isShockDataAvailable?: boolean;
  isLightDataAvailable?: boolean;
  isHumidityDataAvailable?: boolean;
  isSensorDataAvailable?: boolean;
  sensorUiItems?: SensorUiItem;
}

export interface IDocument {
  id?: number;
  organizationId?: number;
  name?: string;
  documentNumber?: string;
  documentType?: string;
  documentReference?: string;
  documentExtension?: string;
  isSystemGenerated?: boolean;
  isReadOnly?: boolean;
  entityType?: string;
  userName?: string;
  createdAt?: string;
  organizationName?: string;
  shipmentNumber?: number;
  uploadedBy?: string;

  //new
  shipmentNumbers?: string[];
  entityIds?: number[];
  containerNumbers?: string[];

  //deprecated?
  isPrivate?: boolean;
  channelName?: string;
  documentHash?: string;
  version?: number | string;
}

export interface Load {
  serviceType: string;
  pickupDate: string;
  containerLoad?: string[] | null;
  productName: string;
  productType: string;
  totalValue: number;
  totalValueCurrency: string;
  productGrossWeight: number;
  productGrossWeightUom: string;
  hazardousShipment: string;
}

export interface Services {
  insurance: string;
  oceanBooking: string;
  weightSource: string;
  grossWeight: number;
  grossWeightUom: string;
  netWeight: number;
  netWeightUom: string;
  productValue: number;
  productValueCurrency: string;
  provider: string;
  preferredOceanCarrier: string;
  contractNumber: string;
}

/**
 * Used by shipment-details sub-tab to show all the details
 */

// what goes in this?
export interface BrokerageInfo {
  exportDeclaration: Declarations;
  importDeclaration: Declarations;
}

export interface Declarations {
  billOfEntryNum: number;
  duty: string; // 'Yes' or 'No'
  customBondUS: string; // 'Yes' or 'No'
  outOfCharge: string; // 'Yes' or 'No'
  chargeDate: string;
}

export interface RequestedService {
  insurance?: string;
  productValue?: string;
  provider?: string;
  insuranceDocId?: string;
  oceanBooking?: string;
  preferredCarrier?: string;
  contactNum?: string;
  weightSource?: string;
  grossWeight?: string;
  netWeight?: string;
}

export interface LocationInfo {
  street: string;
  state: string;
  city: string;
  country: string;
}

export interface ShippingStep {
  title: string;
  isCurrent: boolean;
  isFuture: boolean;
  message?: string;
  address?: string;
  dpwFlag: boolean;
  locationActions?: Array<PortAction>;
  date?: string;
  daysToGo?: string;
  displayDate?: string; //we generate this on the front end to a format we will show in the UI
  height: number;
}

export interface ExceptionSteps {
  title: string;
  isCurrent: boolean;
  isFuture: boolean;
  exceptions: Array<Exception>;
  height?: number;
  heightSoFar?: number;
}

export interface ExceptionSummary {
  originalEta: string;
  originalEtd: string;
  departureDate: string;
  departureDateType: string;
  arrivalDate: string;
  arrivalDateType: string;
  etdDifference: string;
  etdDifferenceSeverity: number;
  etaDifference: string;
  etaDifferenceSeverity: number;
}

export interface Exception {
  name: string;
  details: string;
  date: string;
  severity: ExceptionSeverity; // color exception name shows up as
  days: string;
}

export enum ExceptionSeverity {
  GREEN,
  YELLOW,
  RED,
}

export interface PortAction {
  name: string;
  date: string;
  duration: string;
  isCurrent: boolean;
  isFuture: boolean;
  //properties below are not in api
  // days?: string;
  displayDate?: string;
}

/**
 * used for storing info about Carrier, Consignee, Consignor, etc
 */
export interface BusinessInfo {
  name: Name;
  contact: string;
  address: string;
  email: string;
  phone: string;
  taxNumber?: string;
}

export interface ShipmentProcedureEntity {
  title: string;
  isCurrent: boolean;
  message: string;
}

export class Name extends String {
  getAbbr(): string {
    if (this.indexOf('_') === 0 && this.length > 0) {
      return this.charAt(1).toUpperCase();
    }
    if (this.indexOf(' ') === -1) {
      return this.slice(0, 1).toUpperCase();
    }

    const words = this.split(' ');
    return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
  }
}

export interface ShipmentEvents {
  steps?: ShippingStep[];
}

export interface ShipmentExceptions {
  summary?: ExceptionSummary;
  steps?: ExceptionSteps[];
}

export interface ShipmentContainer {
  containerId: number;
  containerNumber: string;
  sealNumbers?: string[];
  containerSize: string;
  containerType: string;
  containerState: string;
  containerEvents?: ContainerEvent[];
}

export interface ContainerEvent {
  id: number;
  eventType: string;
  carrier?: null;
  estimateTime: string;
  actualTime: string;
  location: string;
  isCurrent?: boolean;
}

export interface ShipmentPort {
  latitude: number;
  longitude: number;
  name: string;
  isFuture: boolean;
}

export interface ShipmentMap {
  shipmentLocation: ShipmentLocation;
  journeyTime?: number;
  journeyDistance?: number;
  ports: ShipmentPort[];
  futureRoute: JourneyRoute[];
  pastRoute: JourneyRoute[];
  journeyStops?: any[];
}
export interface ShipmentLocation {
  latitude: number;
  longitude: number;
  compassDirection?: number;
  locationType?: string;
}

export interface JourneyRoute {
  lon: number;
  lat: number;
  trackDistance: number;
  name?: string;
  locode: string;
  clazz: number;
  isFuture?: boolean;
}

// export interface IContainerCSV {
//   id: number;
//   containerNumber: string;
//   containerSize: string;
//   containerType: string;
//   sealNumber: string;
//   flagged: boolean;
//   alerts: number;
//   totalAlerts: number;
//   maxSeverity: number;
//   trackingState: TRACKING_STATE;
//   owner: string;
//   bookingOceanCarrier: string;
//   oceanShipment: OceanShipment;
//   createdAt: moment.Moment;
// }

export interface IShipmentFilterDropdown {}

export enum SHIPMENT_VIEW_TYPES {
  ALL_SHIPMENT = 'ALL_SHIPMENT',
  ONE_SHIPMENT = 'ONE_SHIPMENT',
}

export interface SegmentData {
  transportName: string;
  tripNumber: string;
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}
