<section class="chat-wrapper d-flex" [ngClass]="chatMessage?.user !== 'chatbot'? 'self-message' : ''">
  <div class="user-icon">
    <button disabled mat-mini-fab *ngIf="chatMessage.user === 'chatbot'" class="chatbot-logo">
      <dp-svg [src]="imageMap.cargoFlowNewSquare" w="15"></dp-svg>
    </button>
    <button disabled mat-mini-fab class="easy-button initials" *ngIf="chatMessage.user !== 'chatbot'">
      {{chatMessage.user}}
    </button>
  </div>

  <div class="message" *ngIf="chatMessage.content">
    {{chatMessage.content}}
  </div>

  <ng-content></ng-content>

</section>