<section class="summary mt-8 mb-32">

  <div class="summary-content" fxLayout="row" *ngIf="shipmentDetail?.exceptions; let exceptions">
    <div fxLayout="column" fxLayoutAlign="start start">
      <div style="margin-bottom: 10px;">
        <span class="text-muted">
          Original ETA:
        </span>
        <span class="text-bold ml-8"> {{ exceptions.summary.originalEta }}
        </span>
      </div>
      <div>
        <span class="text-muted">Original ETD:</span>
        <span class="text-bold ml-8"> {{ exceptions.summary.originalEtd }} </span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <div style="margin-bottom: 10px;">
        <span class="text-muted">
          <ng-container *ngIf="exceptions.summary.arrivalDateType && exceptions.summary.arrivalDateType !== '' ">
            {{ exceptions.summary.arrivalDateType }}:
          </ng-container>
        </span>
        <span class="text-bold ml-8">
          {{ exceptions.summary.arrivalDate }}
        </span>
      </div>
      <div>

        <span class="text-muted">
          <ng-container *ngIf="exceptions.summary.departureDateType && exceptions.summary.departureDateType !== ''">
            {{ exceptions.summary.departureDateType }}:
          </ng-container>
        </span>

        <span class="text-bold ml-8">
          {{ exceptions.summary.departureDate }}
        </span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <div style="margin-bottom: 10px;" [style.color]="getColor(exceptions.summary.etaDifferenceSeverity)" class="text-bold">
        {{ exceptions.summary.etaDifference }}
      </div>
      <div [style.color]="getColor(exceptions.summary.etdDifferenceSeverity)" class="text-bold">
        {{ exceptions.summary.etdDifference }}
      </div>
    </div>
  </div>
</section>


<ng-container *ngIf="(steps?.length > 0 && countTotalExceptions() > 0) ; else elseBlock" class="text-dark">
  <div fxLayout="row" fxLayoutAlign="center center" class="my-8">
    <div style="width: 155px" class="text-bold">
      Progress
    </div>
    <div fxLayout="row" class="text-bold full-width">
      <div fxFlex="27">
        <span class="pl-8">
          Exceptions
        </span>
      </div>
      <div fxFlex="14">
        Status
      </div>
      <div fxFlex="16">
        Detected at
      </div>
      <div fxFlex="30">
        Description
      </div>
      <div fxFlex="13" class="text-right">
        Detail
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutAlign="start start" *ngIf="(steps?.length > 0) ; else elseBlock">

    <div *ngIf="steps?.length > 0" [style.height.px]="totalHeight-10" style="height: auto; width: 155px; margin-left: -25px;">
      <dp-shipment-timeline [spots]="steps" [state]="" [vertical]="true" [height]="totalHeight" [exceptionSeverity]="1">
      </dp-shipment-timeline>
    </div>

    <div style="height: auto; width: 100%;" class="fs-12">

      <div class="timeline" fxLayout="column" fxLayoutAlign="start start" [style.height.px]="step.height"
        *ngFor="let step of steps; let i=index">

        <ng-container *ngIf="step.exceptions.length>0;">
          <div style="min-height: 50px; width:100%" *ngIf="step.exceptions[0].name !== noPendingExceptions"></div>

          <div fxLayout="row" fxLayoutAlign="start start" *ngFor="let exception of step.exceptions" class="full-width">

            <div fxFlex="27" style="height: 100%;">
              <div style="min-height: 50px;" class="d-flex align-items-start">
                <dp-svg [src]="SvgMap.exceptionIcon2" class="mr-8"></dp-svg>
                <span [style.color]="getColor(exception.severity)">
                  {{ exception.name }}
                </span>
              </div>
            </div>

            <div fxFlex="14" style="height: 100%">
              <div style="min-height: 50px;">
                <span class="text-primary-ocean">{{exception.state | titlecase}}</span>
              </div>
            </div>

            <div fxFlex="16" style="height: 100%;">
              <div style="min-height: 50px;">
                <span class="text-shadow">{{ exception.date }} </span>
              </div>
            </div>

            <div fxFlex="30" style="height: 100%;">
              <div style="min-height: 50px;">
                <span class="text-shadow">{{ exception.details }}</span>
              </div>
            </div>

            <div fxFlex="13" style="height: 100%;min-height: 50px;" class="text-right">
              <span class="text-shadow">
                {{shipmentType === ShipmentType.AIR_SHIPMENT ? exception.hours : exception.days }}
              </span>
            </div>

          </div>

        </ng-container>

        <ng-template #elseBlockNoExceptions>
          <div style="width:100%; height: 100%;" fxLayoutAlign="start start">
            <span class="text-shaow">
              <h3>
                No exceptions at this point
              </h3>
            </span>
          </div>
        </ng-template>

      </div>
    </div>

  </div>
</ng-container>

<ng-template #elseBlock>
  <div style="width: 100%; height: 700px;">
    <div fxLayout="column" fxLayoutAlign="center center">
      <dp-svg [src]="SvgMap.noExceptions" w="70"></dp-svg>
      <h2 style="font-size: 15px; line-height: 21px" class="text-bold mb-0">Congratulations!</h2>
      <p style="font-size: 15px; line-height: 20px ">All shipments are on time.</p>
    </div>
  </div>
</ng-template>