import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NotificationService } from '../notification.service';
import { UIService, ProgressService } from 'app/shared';
import { environment } from 'environments/environment';
import { MatRadioChange } from '@angular/material/radio';
import { AuthService } from 'app/auth/auth.service';
import { Subscription } from 'rxjs';
import mockNotifications from '../data/notifications.json';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/settings/users/users.service';
import { User } from 'app/auth/user.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dp-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss'],
})
export class NotificationSettingComponent implements OnInit, OnDestroy {
  notificationSetting;
  successMessage = 'Email notification settings saved successfully!';
  errorMessage = 'Email notification could not be saved!';
  isBusy = false;

  notificationSubscription: Subscription;
  updateNotificationSubscription: Subscription;
  currentUser = this.authService.currentUserValue;
  enableEmailNotifications: boolean = this.currentUser.enableEmailNotifications;
  isLoading = true;
  panelOpenState = false;

  @ViewChild('contentZone') contentZone?: ElementRef;
  @ViewChild('emailNotificationToggle') emailNotificationToggle: MatSlideToggle;
  constructor(
    private notificationService: NotificationService,
    private uiService: UIService,
    private progressService: ProgressService,
    public authService: AuthService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.notificationSubscription = this.notificationService.getNotificationSettings().subscribe(
      (data) => {
        this.notificationSetting = data;
        if (this.authService.currentUserValue.pendingUserEmail) {
          this.progressService.showProgress(this.contentZone, true);
        }
        this.isLoading = false;
      },
      () => {
        //TODO: notify user that backend is having trouble

        this.isLoading = false;
      }
    );
  }

  closeDlg(): void {
    // this.dialogRef.close();
  }

  saveSetting(item) {
    item.isEnabled = !item.isEnabled;
    // let body = this.getRequestBody();
    // for (let key in body) {
    //   delete body[key].forAllShipments;
    // }
    let requestBody = {
      [item.code]: {
        isEnabled: item.isEnabled,
        forAllShipments: item.forAllShipments,
      },
    };
    this.notificationService.updateNotificationSettings(requestBody).subscribe(
      (result) => {
        item.isBusy = false;
        if (!result) {
          item.isEnabled = !item.isEnabled;
          this.showError();
          return;
        }
        // this.showSuccess();
      },
      (error) => {
        item.isEnabled = !item.isEnabled;
        item.isBusy = false;
        this.showError();
      }
    );
  }

  showSuccess() {
    this.uiService.showSnackbar(this.successMessage, null, {
      duration: environment.snackBarDuration.success,
      panelClass: 'accent',
    });
  }

  showError() {
    this.uiService.showSnackbar(this.errorMessage, null, {
      duration: environment.snackBarDuration.warning,
      panelClass: 'warn',
    });
  }

  //TODO use new API when available
  isSubscribed(): boolean {
    let body = this.getRequestBody();
    for (let key in body) {
      return body[key].forAllShipments;
    }
  }

  updateSubscription(event: MatRadioChange) {
    this.isBusy = true;
    const state = event.value;
    this.notificationService.subscribe(state).subscribe(
      (result) => {
        this.isBusy = false;
        if (!result) {
          this.showError();
          return;
        }
        this.showSuccess();
        this.updateRequestBody(state);
      },
      (error) => {
        this.showError();
        this.isBusy = false;
      }
    );
  }

  /*
  example req body
  {
    "VSDP": {"isEnabled": true, "forAllShipments": true},
    "APOA": {"isEnabled": true, "forAllShipments": true},
  }
  */
  getRequestBody() {
    let result = {};
    if (this.notificationSetting) {
      this.notificationSetting.forEach((category) => {
        if (Array.isArray(category.subscriptions) && category.subscriptions.length > 0) {
          for (let subscription of category.subscriptions) {
            for (let singleSetting of subscription.content) {
              const { code, isEnabled, forAllShipments } = singleSetting;
              result[code] = {
                isEnabled,
                forAllShipments,
              };
            }
          }
        }
      });
    }
    return result;
  }

  updateRequestBody(updatedforAllShipments: boolean) {
    this.updateNotificationSubscription = this.notificationSetting.forEach((category) => {
      if (Array.isArray(category.subscriptions) && category.subscriptions.length > 0) {
        for (let subscription of category.subscriptions) {
          for (let singleSetting of subscription.content) {
            singleSetting.forAllShipments = updatedforAllShipments;
          }
        }
      }
    });
  }

  updateEmailNotification(event: MatSlideToggleChange) {
    let request = {
      enableEmailNotifications: event.checked,
      timePattern: this.currentUser.timePattern || 'hh:mm A',
      datePattern: this.currentUser.datePattern || 'MM/DD/YYYY',
    };
    this.enableEmailNotifications = event.checked;

    this.usersService
      .editUser(request, this.currentUser.userCode)
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;

          return user;
        })
      )
      .subscribe(
        (user: User) => {
          // update localStorage, handle page refresh
          this.authService.userUpdated(user);
        },
        (error: HttpErrorResponse) => {
          this.enableEmailNotifications = !this.enableEmailNotifications;
          this.emailNotificationToggle.toggle();
          const msg = 'Error: ' + error.statusText;
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        },
        () => {}
      );
  }

  ngOnDestroy() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }

    if (this.updateNotificationSubscription) {
      this.updateNotificationSubscription.unsubscribe();
    }
  }
}
