<div class="banner" *ngIf="showBanner">
  <span class="bannerText">Your feedback matters. Share your experience on G2 and Gartner Peer Insights!</span>
  <button class="reviewBtn" (click)="onNavigate('g2')">
    <span class="buttonText">Write A Review On G2</span>
  </button>
  <button class="reviewBtn" style="margin-left: 0px;" (click)="onNavigate('gartner')">
    <span class="buttonText">Write A Review On Gartner Peer Insights</span>
  </button>
  <span class="close" (click)="onClose()">
    <dp-svg style="display: inline;" [src]="SvgMap.closeIcon2" w="16" h="16"></dp-svg>
  </span>
</div>