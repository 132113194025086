import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// check if control contains any <> tags
export function hasHTMLtagValidator(control: AbstractControl): ValidationErrors | null {
  const htmlTag = '<.+?>';
  const regex = new RegExp(htmlTag, 'gi');
  if (regex.test(control.value)) {
    return { hasHTMLtag: true };
  }
  return null;
}
