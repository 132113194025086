import { ValidatorFn, AbstractControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export function uniqueOrEmptyValueValidator(keyName: string, emptyAllowed = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentContainer: AbstractControl = control.parent;
    if (keyName === 'hbl_number' && !control.value?.trim()) {
      const mode = currentContainer && currentContainer.get('mode').value;
      return mode && mode !== 'FCL' ? { required: true } : null;
    } else if (!control.value) {
      //const isDirty = (control.parent as FormGroup)?.dirty;
      //return isDirty? { required: true } : null;
      return emptyAllowed? null : { required: true };
    }

    if (!control.parent || !control.parent.parent) return null;

    let fgs = control.parent.parent as UntypedFormArray;

    let hasSameValue = !!fgs.controls.some((fg: UntypedFormGroup) => {
      // fg.controls[keyName] !== control &&
      //console.log(fg);
      let hasDuplicate = fg.controls[keyName] !== control && fg.controls[keyName].value?.toUpperCase() === control.value.toUpperCase();
      if (hasDuplicate && keyName === 'container_number' && fg.controls['mode'].value && fg.controls['mode'].value !== 'FCL') {
        hasDuplicate = false;
        currentContainer.patchValue({ 'mode': fg.controls['mode'].value });
      } else if (hasDuplicate && keyName === 'hbl_number' && fg.controls['container_number'].value?.toUpperCase() !== control.parent?.controls?.['container_number']?.value?.toUpperCase()) {
        hasDuplicate = false;
      }
      return hasDuplicate;
      // return Object.keys(fg.controls).find(
      //   (key) => {
      //     return (
      //       fg.controls[key] !== control &&
      //       typeof fg.controls[key]?.value === 'string' &&
      //       fg.controls[key]?.value?.toUpperCase() === control.value?.toUpperCase()
      //     ); });
    });
    if (!hasSameValue) {
      control.setErrors(null);
      return null;
    }
    control.setErrors({ hasDuplicates: true });
    return { hasDuplicates: true };
  };
}
