import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dp-missing-carrier-dialog',
  templateUrl: './missing-carrier-dialog.component.html',
  styleUrls: ['./missing-carrier-dialog.component.scss'],
})
export class MissingCarrierDialogComponent {
  count: number;
  svgAttributes = {
    width: 36,
    height: 30,
    fill: '#ffa000',
  };
  constructor(public dialogRef: MatDialogRef<MissingCarrierDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.count = data.shipmentWthoutCarrier;
  }

  proceed() {
    this.data.onProceed();
    this.dialogRef.close();
  }
}
