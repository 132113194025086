<div class="header d-flex align-items-center">
  <button mat-icon-button class="back-button mr-12" (click)="onGoBack()" *ngIf="!reportErrorData.loadReportShipmentErrorFromTable">
    <mat-icon color="primary">keyboard_arrow_left</mat-icon>
  </button>
  <h2 class="title ml-16 mr-auto">Report Tracking Error</h2>

  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>

<div #contentZone fxLayout="row" fxLayoutAlign="center start">

  <div *ngIf="reportErrorData && !showSuccessResult">
    <div class="info">
      <div class="w-120 mr-40">
        <sub>Tracking No.</sub>
        <h3 class="text-bold">{{reportErrorData.shipmentNumber}}</h3>
      </div>
      <div *ngIf="reportErrorData.uploadType == 'AWB_NUMBER' then airTital_content ; else ocean_road_content">
      </div>
      <ng-template #ocean_road_content>
        <ng-container *ngIf="isRoadShipment.includes(reportErrorData.shippingMode); then roadTital_content else oceanTital_content">
        </ng-container>
      </ng-template>
      <ng-template #oceanTital_content>
        <div class="w-120" *ngIf="!isVesselUpload()">
          <sub>Container No.</sub>
          <h3 class="text-bold">{{reportErrorData.containerNumber}}</h3>
        </div>
        <div class="w-320" *ngIf="isVesselUpload()">
          <sub>Vessel Name / No.</sub>
          <h3 class="text-bold">{{getVesselNumber()}}</h3>
        </div>
      </ng-template>
      <ng-template #roadTital_content>
        <div class="w-120">
          <sub>Vehicle No.</sub>
          <h3 class="text-bold">{{reportErrorData.transportJourneys?.roadTransport?.truckNumber}}</h3>
        </div>
      </ng-template>
      <ng-template #airTital_content>
        <div class="w-120">
          <sub>AWB No.</sub>
          <h3 class="text-bold">{{reportErrorData.awbNumber}}</h3>
        </div>
      </ng-template>
    </div>

    <p>Please select the type of error. We would help you find the best way to fix the error.</p>

    <form class="easy-form" [formGroup]="f" autocomplete="off" fxLayout="column" fxLayoutAlign="start start"
      (keydown.enter)="$event.preventDefault()">
      <mat-form-field appearance="outline">
        <mat-label>Select the type of error (Required)</mat-label>
        <mat-select formControlName="trackingError" placeholder="trackingError" #trackingErrorSelect [disableOptionCentering]="true"
          required (selectionChange)="trackingErrorChange()" panelClass="no-change">
          <mat-option
            *ngFor="let item of (reportErrorData.uploadType === 'AWB_NUMBER' ? errorReportTypesByAir : isRoadShipment.includes(reportErrorData.shippingMode) ? errorReportTypesByRoad : isVesselUpload() ? errorReportTypesByVessel : errorReportTypes) | keyvalue: asIsOrder "
            [value]="item.key">{{ item.value }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="emailField" [hidden]="!isEmail">
        <mat-label>Your work email</mat-label>
        <input type="email" matInput placeholder="name@company.com" name="email" email formControlName="email" required>
        <mat-error *ngIf="f.controls['email'].invalid">{{getErrorMessage(f.controls['email'])}}</mat-error>
      </mat-form-field>
      
      <div appearance="outline"
        [hidden]="commentOnly || isSharedBy || hasShowCommentBox() || !showActions || notSelected('trackingError', ['TERT-WRONG-CARRIER-MBL', 'TERT-NOT-FOUND', 'TERT-WRONG-ORIGIN-DESTINATION'])">
        <p>Based on the error type you selected, we recommend the following actions</p>
        <mat-radio-group aria-labelledby="mode-radio-group-label" class="easy-radio-button mode-radio-group"
          formControlName="retrackResubmitAction" required (change)="retrackResubmitActionChange()" [ngModelOptions]="{standalone: true}">
          <mat-radio-button class="my-8" [value]="'TERR-CONTACT-CUSTOMER-SUPPORT'">
            Send a message to the support team
            <dp-svg [src]="SvgMap.info" w="16"
             tooltip="The Cargoes Flow customer team is here to help. We would look into the reported issue and fix it within three business days. You may provide any additional comments to help us better understand your problem and facilitate solving the issue.">
            </dp-svg>
          </mat-radio-button>
          <mat-form-field appearance="outline" class="cdkTextareaAutosizeCls" [hidden]="hideSubFields('TERR-CONTACT-CUSTOMER-SUPPORT')" @expandCollapse>
            <mat-label>Please leave a comment here (Optional)</mat-label>
            <textarea formControlName="eventsNotExcludedComment" #eventsNotExcludedComment matInput rows="8" cols="40" maxlength="255" cdkAutosizeMinRows="8" cdkAutosizeMaxRows="10"></textarea>
            <mat-hint align="end">{{eventsNotExcludedComment.value.length}} / 255 max chars</mat-hint>
          </mat-form-field>
          <mat-radio-button class="my-8" [value]="'TERR-UPDATE-RETRACK-SHIPMENT'">
            Update shipment data and re-track it
            <dp-svg [src]="SvgMap.info" w="16" tooltip="material-icons-outlined" color="primary"
             [tooltip]="reportErrorData.uploadType !== 'VESSEL_ID'? 'Try to update the shipment by providing the correct carrier or MBL#. The system will reset updated tracking data in a couple of minutes. Please submit the error report to customer service if the issue is not solved.' : 'Try to update the shipment by providing the correct origin and destination. The system will reset updated tracking data in a couple of minutes. Please submit the error report to customer service if the issue is not solved.'">
            </dp-svg>
          </mat-radio-button>
          <div class="mt-16" [hidden]="hideSubFields('TERR-UPDATE-RETRACK-SHIPMENT')">
            <div *ngIf="reportErrorData.uploadType !== 'VESSEL_ID'">
              <mat-form-field appearance="outline">
                <mat-label color="accent">Carrier</mat-label>
                <mat-select formControlName="carrier" placeholder="Carrier" #carrierSelect [disableOptionCentering]="true" panelClass="no-change">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="carrierFilterCtrl" placeholderLabel="Search..."
                      noEntriesFoundLabel="'no matching carrier found'">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">{{ carrier }}</mat-option>
                </mat-select>
                <button mat-button matSuffix aria-label="Clear" (click)="deleteCarrier($event)" class="deleteCarrier">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-icon color="warn" class="cached-icon"
               tooltip="This field is used for tracking. Changing this value will cause the tracking data to be reset and the shipment to be re-tracked.">
                cached</mat-icon>
            </div>
            <div *ngIf="reportErrorData.uploadType !== 'VESSEL_ID'">
              <mat-form-field appearance="outline">
                <mat-label for="mbl">MBL #</mat-label>
                <input id="mbl" matInput formControlName="mbl">
              </mat-form-field>
              <mat-icon color="warn" class="cached-icon"
               tooltip="This field is used for tracking. Changing this value will cause the tracking data to be reset and the shipment to be re-tracked."
                *ngIf="reportErrorData.uploadType === 'CONTAINER_NUMBER'">cached</mat-icon>
            </div>
            <div class="full-height d-flex justify-content-start" *ngIf="reportErrorData.uploadType === 'VESSEL_ID'">
              <section class="dp-port-width">
                <dp-port-select class="dp-port-width" [defaultValue]="defaultPortOfOrigin" [options]="ports" [label]="'Port of origin'" required
                  [placeholder]="'Search port code or name'" (optionChanged)="optionChanged('originPortId', $event)">
                </dp-port-select>
              </section>
              <mat-icon color="warn" class="cached-icon center-icon"
               tooltip="This field is used for tracking. Changing this value will cause the tracking data to be reset and the shipment to be re-tracked.">
                cached</mat-icon>
            </div>
            <div class="full-height d-flex justify-content-start" *ngIf="reportErrorData.uploadType === 'VESSEL_ID'">
              <section class="dp-port-width">
                <dp-port-select class="dp-port-width" [defaultValue]="defaultDestinationPort" [options]="ports" [label]="'Port of destination'"
                  [placeholder]="'Search port code or name'" (optionChanged)="optionChanged('destinationPortId', $event)">
                </dp-port-select>
              </section>
              <mat-icon color="warn" class="cached-icon center-icon"
               tooltip="This field is used for tracking. Changing this value will cause the tracking data to be reset and the shipment to be re-tracked.">
                cached</mat-icon>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="cdkTextareaAutosizeCls"
        [hidden]="!commentOnly && !hasShowCommentBox() && !isSharedBy && notSelected('trackingError', ['TERT-EVENT-WRONG-MISSING', 'TERT-EVENT-INCORRECT-ORDER', 'TERT-WRONG-TRANSPORT-TYPE', 'TERT-WRONG-MAP', 'TERT-OTHER'], true)">
        <mat-label>Please leave a comment here (Optional)</mat-label>
        <textarea formControlName="comment" #comment matInput rows="8" cols="40" maxlength="255" cdkAutosizeMinRows="8" cdkAutosizeMaxRows="10"></textarea>
        <mat-hint align="end">{{comment.value.length}} / 255 max chars</mat-hint>
      </mat-form-field>

      <button mat-flat-button class="easy-button ml-160 mt-40 " type="button" [dpBusy]="isBusy" [disabled]="(!f.dirty && !isMBLChanged) || f.invalid"
        type="submit" (click)="submit()">Submit</button>

    </form>
  </div>
  <div *ngIf="showSuccessResult" class="relative report-error-result">
    <img class="w-48" class="absolute" src="assets/svg/green_white_checkmark.svg">
    <ng-container *ngIf="!hasSlowField()">
      <div class="resultTitle">
        The error report has been sent successfully!
      </div>
      <p>Our technical team would follow up on your case within 48 business hours.</p>
    </ng-container>
    <ng-container *ngIf="hasSlowField()">
      <div class="resultTitle">
        Your update request has been accepted. Tracking data will be reset and the shipment will be re-tracked.
      </div>
      <p>It can take a few minutes for the new tracking data to be available. You can close this page and check back
        later.</p>
    </ng-container>

    <div class="info">
      <div class="w-120 mr-40">
        <sub>Tracking No.</sub>
        <h3>{{reportErrorData.shipmentNumber}}</h3>
      </div>
      <div *ngIf="reportErrorData.uploadType == 'AWB_NUMBER' then air_content ; else road_ocean_content">
      </div>
      <ng-template #road_ocean_content>
        <ng-container *ngIf="isRoadShipment.includes(reportErrorData.shippingMode); then road_content else ocean_content">
        </ng-container>
      </ng-template>
      <ng-template #ocean_content>
        <div class="w-120" *ngIf="!isVesselUpload()">
          <sub>Container No.</sub>
          <h3 class="text-bold">{{reportErrorData.containerNumber}}</h3>
        </div>
        <div class="w-320" *ngIf="isVesselUpload()">
          <sub>Vessel Name / No.</sub>
          <h3 class="text-bold">{{getVesselNumber()}}</h3>
        </div>
      </ng-template>
      <ng-template #road_content>
          <div class="w-120">
            <sub>Vehicle No.</sub>
            <h3 class="text-bold">{{reportErrorData.transportJourneys?.roadTransport?.truckNumber}}</h3>
          </div>
      </ng-template>
      <ng-template #air_content>
        <div class="w-120">
          <sub>AWB No.</sub>
          <h3 class="text-bold">{{reportErrorData.awbNumber}}</h3>
        </div>
      </ng-template>
    </div>
    <!-- <div class="info">
      <sub>Action</sub>
      <h3>Submit error report to customer service.</h3>
    </div> -->
    <div class="info">
      <sub>Type of error</sub>
      <h3>{{getTypeOfErrorText()}}</h3>
    </div>
    <div class="info" *ngIf="payload.userEnteredReportData?.description">
      <sub>Comments</sub>
      <h3>{{this.payload.userEnteredReportData?.description}}</h3>
    </div>
    <div class="info" *ngIf="payload.userEnteredReportData?.originPortCode">
      <sub>Port of origin</sub>
      <h3>{{getPort(this.payload.userEnteredReportData?.originPortCode)}}</h3>
    </div>
    <div class="info" *ngIf="payload.userEnteredReportData?.destinationPortCode">
      <sub>Port of destination</sub>
      <h3>{{getPort(this.payload.userEnteredReportData?.destinationPortCode)}}</h3>
    </div>
    <div class="info" *ngIf="payload.userEnteredReportData?.carrier">
      <sub>Carrier</sub>
      <h3>{{this.payload.userEnteredReportData?.carrier}}</h3>
    </div>
    <div class="info" *ngIf="payload.userEnteredReportData?.mbl">
      <sub>MBL #</sub>
      <h3>{{this.payload.userEnteredReportData?.mbl}}</h3>
    </div>

  </div>

</div>
