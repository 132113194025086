import { Injectable } from '@angular/core';
import { Utility } from '@dp/utilities';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackBannerService {
  private store: BehaviorSubject<boolean> = new BehaviorSubject(this.getSessionVisibility());
  state$ = this.store.asObservable();
  showBanner: boolean;

  constructor(private authService: AuthService) {
    this.updateState(this.getSessionVisibility());
  }

  private getSessionVisibility() {
    const storedValue = Utility.getItem(environment.storage.showFeedbackBanner, 'session');
    return storedValue ?? true;
  }

  updateState(isBannerVisible: boolean) {
    Utility.setItem(environment.storage.showFeedbackBanner, isBannerVisible, 'session');
    this.store.next(isBannerVisible);
  }

  getBannerVisibility() {
    this.state$.subscribe(isBannerVisible => {
      this.showBanner = isBannerVisible;
    });
    return this.authService.isAuth() && 
      this.showBanner && 
      this.authService.currentUserValue.accountType === 'PREMIUM' && 
      this.authService.currentUserValue.g2Banner;
  }
}
