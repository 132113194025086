<mat-toolbar id="app-toolbar" [hidden]="!visible">
  <!-- <a routerLink="/" *ngIf="!isAuth || isHandset" style="position: absolute;top:10px;left:10px;">
    <mat-icon svgIcon="site_logo"></mat-icon>
  </a> -->
  <dp-logo routerLink="/" *ngIf="isAuth && !isHandset" [orgCode]="orgCode" [orgLogo]="fileSrc?.url" [logoMode]="LogoMode.wide"></dp-logo>

  <span class="spacer">
    <dp-global-search *ngIf='isAccountPlus'></dp-global-search>
    <!-- <dp-logo routerLink="/" *ngIf="isAuth && !isHandset" [orgCode]="orgCode" [logoMode]="LogoMode.wide"></dp-logo> -->
    <!-- <img src="assets/images/logos/100000076.png" alt="company logo" width="auto" style="height: 35px;"> -->
  </span>
  <ul fxLayout fxLayoutGap="10px" fxLayoutAlign="space-around center" class="top-nav-items">
    <li style="padding-right: 2px;" [hidden]="!isAuth || currentUser.companyStatus !== LoginCompanyStatusType.VERIFIED">

      <button mat-icon-button aria-label="Super Admin Tool" *ngIf="showSAPageLinkBtn()" (click)="goToSAPage()">
        <mat-icon tooltip="Goto Super-Admin page" placement="bottom" aria-label="goAdmin"
          class="material-icons-outlined" color="primary">supervisor_account</mat-icon>
      </button>

      <button mat-icon-button aria-label="Back to admin" *ngIf="showSABackBtn()" (click)="backToSA()" class="mr-8">
        <mat-icon tooltip="Go back to Super-Admin" placement="bottom" aria-label="goBack"
          class="material-icons-outlined" color="primary">supervised_user_circle</mat-icon>
      </button>

      <button mat-icon-button aria-label="Invite Users" [mdePopoverTriggerFor]="inviteUserPopover" mdePopoverTriggerOn="click"
        *ngIf="isAuth && currentUser.isAdmin" class="mr-4" [class]="userAccessService.accessClass(accessKeys.ADD_USER)">
        <dp-svg [src]="udsIconMap.HeaderUser" w="26" [tooltip]="userAccessService.getTooltip(accessKeys.ADD_USER) || 'Add User'" placement="bottom"></dp-svg>
      </button>

      <button mat-icon-button [hidden]='isAccountPlus' [ngClass]="{'withDot': hasNotification}" aria-label="Notification" #myTrigger="mdePopoverTrigger"
        [mdePopoverTriggerFor]="notificationPopover" mdePopoverTriggerOn="click" #notificationTrigger="mdePopoverTrigger" class="mr-4">
        <dp-svg [src]="udsIconMap.notification" w="26" tooltip="Notification" placement="bottom"></dp-svg>
      </button>
      
      <button mat-icon-button aria-label="Help Center" [mdePopoverTriggerFor]="helpPopover" mdePopoverTriggerOn="click" *ngIf="isAuth"
        class="mr-4" tooltip="Help Center" placement="bottom">
        <dp-svg [src]="udsIconMap.helpCenter" w="26"></dp-svg>
      </button>

      <button mat-mini-fab class="ml-8 easy-button" style="box-shadow:none;" [mdePopoverTriggerFor]="profilePopover"
        mdePopoverTriggerOn="click" #profileTrigger="mdePopoverTrigger" (opened)="popoverOpened()">
        {{initials}}
        <mat-icon aria-label="profile" *ngIf="!initials">person</mat-icon>
      </button>
    </li>
    <li fxHide>
      <mat-checkbox [(ngModel)]="applicationSettings.darkMode" (change)="ngDarkModeChange()">Dark</mat-checkbox>
      <br />
    </li>
    <li *ngIf="!isAuth"><a routerLink="/signup">Sign Up</a></li>
    <li *ngIf="!isAuth">
      <a style="margin-right: 15px;" routerLink="/login">Login</a>
    </li>
    <!-- <li *ngIf="isAuth"><a (click)="onLogout()">Logout</a></li> -->
  </ul>
</mat-toolbar>

<mde-popover #profilePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#0b78d0'"
  [mdePopoverCloseOnClick]="true" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="profile-popover-card" *ngIf="currentUser">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-500 p-4">
      <mat-card-title class="m-0">{{currentUser.fullName}}</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeProfile()">
        close
      </mat-icon>
    </mat-card-header>
    <mat-list class="userProfileMenu">
      <button mat-menu-item (click)="gotoUserSettings()">
        <dp-svg [src]="udsIconMap.user_settings"></dp-svg>
        <span class="pl-8">User Settings</span>
      </button>

      <button mat-menu-item *ngIf="!isAccountPlus" (click)="gotoCompanySettings()">
        <dp-svg [src]="udsIconMap.company_settings" w="20"></dp-svg>
        <span class="pl-8">Company Settings</span>
      </button>
      <button mat-menu-item (click)="navigateTo('users-teams')" [class]="userAccessService.accessClass(accessKeys.MANAGE_USERS)" [tooltip]="userAccessService.getTooltip(accessKeys.MANAGE_USERS)">
        <dp-svg [src]="udsIconMap.manage_users" w="20"></dp-svg>
        <span class="pl-8">{{getManageUsersTeamsLabel()}}</span>
      </button>
      <button mat-menu-item (click)="navigateTo('/locations')" [class]="userAccessService.accessClass(accessKeys.LOCATIONS_TAB_VISIBILITY)">
        <dp-svg [src]="udsIconMap.locations2" h="20"></dp-svg>
        <span class="pl-8">Locations</span>
      </button>
      <button mat-menu-item (click)="navigateTo('/dnd-cost')" [class]="userAccessService.accessClass(accessKeys.STORAGE_COST_TAB_VISIBILITY)" *ngIf="currentUser?.accountCapabilities?.isDemurrageDetentionCostEnabled && currentUser?.accessRole == 'ADMIN'">
        <dp-svg [src]="udsIconMap.storage_cost_icon" h="32" w="32"></dp-svg>
        <span class="pl-8">D&D Costs</span>
      </button>
      <button mat-menu-item (click)="navigateTo('invoices')" *ngIf="currentUser?.accountCapabilities?.billableState === 'ACTIVE' ">
        <dp-svg [src]="udsIconMap.invoices"></dp-svg>
        <span class="pl-8">Invoices</span>
      </button>
      <button mat-menu-item (click)="navigateTo('product-master')" [hidden]='currentUser.accountType === ACCOUNT_TYPES.PLUS' [class]="userAccessService.accessClass(accessKeys.PRODUCT_MASTER_TAB_VISIBILITY)">
        <dp-svg [src]="udsIconMap.productMaster"></dp-svg>
        <span class="pl-8">Product Master</span>
      </button>
      <button *ngIf="currentUser.isAdvancedConsentForRoadTrackingEnabled" mat-menu-item (click)="navigateTo('advance-consent')" [hidden]='currentUser.accountType === ACCOUNT_TYPES.PLUS'>
        <dp-svg [src]="udsIconMap.advanceConsent"></dp-svg>
        <span class="pl-8">Advance Consent</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <dp-svg [src]="udsIconMap.logout"></dp-svg>
        <span class="pl-8">Log Out</span>
      </button>
    </mat-list>
  </mat-card>
</mde-popover>

<mde-popover #inviteUserPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#ffffff'"
  [mdePopoverCloseOnClick]="false" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="pendingUser-popover-card">
    <mat-card-content class="mat-card-popover">
      <dp-invite-user-popover (inviteUserClose)="this.closeInviteUsers()"></dp-invite-user-popover>
    </mat-card-content>
  </mat-card>
</mde-popover>

<mde-popover #helpPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#0b78d0'" [mdePopoverCloseOnClick]="true"
  [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="help-popover-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-500 p-4">
      <mat-card-title class="m-0">Help Center</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeHelp()">
        close
      </mat-icon>
    </mat-card-header>
    <mat-list class="helpCenter">
      <button mat-menu-item (click)="contactUs()" [class]="userAccessService.accessClass(accessKeys.HELP_SUPPORT_CENTER)">
        <dp-svg [src]="udsIconMap.support" w="40" h="40" class="support-icon"></dp-svg>
        <span class="pl-8" class="support-label">Support Center</span>
      </button>
      <button mat-menu-item (click)="playVideo()">
        <dp-svg [src]="udsIconMap.tutorial" w="20"></dp-svg>
        <span class="pl-8">Tutorial Video (90s)</span>
      </button>
      <button mat-menu-item (click)="openFAQ()">
        <dp-svg [src]="udsIconMap.info"></dp-svg>
        <span class="pl-8">FAQ</span>
      </button>
    </mat-list>
  </mat-card>
</mde-popover>

<mde-popover #notificationPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#f2f2f2'"
  [mdePopoverCloseOnClick]="false" [class]="'pt-0 notification-events'" (close)="onCloseNotificationDlg()">
  <mat-card class="notification-popover-card" *ngIf="isAuth">
    <div class="title p-42">Notifications</div>
    <mat-icon class="close-button" (click)="notificationTrigger.togglePopover()">close</mat-icon>
    <div class="content">
      <dp-notification-dlg [hidden]="showSettings" (dismiss)="closeNotification()"
        [opened]="notificationTrigger.popoverOpen && !showSettings">
      </dp-notification-dlg>
      <div *ngIf="showSettings" class="notificationSetting">
        <dp-notification-setting></dp-notification-setting>
      </div>
      <span class="material-icons material-icons-outlined settings" (click)="toggle()"
       tooltip="{{showSettings? 'View notifications' : 'Manage notifications'}}">
        <dp-svg [src]="udsIconMap.user_settings" w="21">
        </dp-svg>
      </span>
    </div>
  </mat-card>
</mde-popover>
