import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { DpComponentsModule } from '@dp/components/components.module';
import { MdePopoverModule } from '@material-extended/mde';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DpDateAdapter } from './services/date.adapter';
import { Platform, PlatformModule } from '@angular/cdk/platform';

const matModules = [MatCardModule, MatButtonModule, MatSelectModule, MatIconModule, MatListModule, MatDialogModule, MatInputModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DpDirectivesModule,
    DpComponentsModule,
    MdePopoverModule,
    PlatformModule,
    ...matModules,
  ],
  // providers: [DpDateAdapter, { provide: DateAdapter, useClass: DpDateAdapter }],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DpDirectivesModule,
    DpComponentsModule,
    MdePopoverModule,
    PlatformModule,
    ...matModules,
  ],
})
export class DpSharedModule {}
