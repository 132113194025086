/* eslint-disable for-direction */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { dpAnimations } from '@dp/animations';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { StaticData } from '@dp/services/static-data.model';
import { AddContainerByTypes } from 'app/navigation/nav.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MilestoneService } from '@dp/services/milestone.service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { ContainersService } from '@dp/services/containers.service';
import { IUploadResult } from 'app/upload-history/models/uh.model';
import { ShipmentType } from 'app/shared/shared.model';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { IMilestoneUploadResult } from 'app/new-ocean-shipment/ocean-tracking/ocean-tracking.model';
import { UtilsService } from '@dp/services/utils.service';
import { ROUTE_PATH } from 'app/route.constants';

@UntilDestroy()
@Component({
  selector: 'dp-add-shipment',
  templateUrl: './add-shipment.component.html',
  styleUrls: ['./add-shipment.component.scss'],
  animations: dpAnimations,
  // encapsulation: ViewEncapsulation.None,
})
export class AddShipmentComponent implements OnInit {
  //modes: string[] = ['Container Number Only', 'Advance'];
  keys = Object.keys;
  SvgMap = SvgMap;
  environment = environment;
  AddContainerByTypes = AddContainerByTypes;
  staticData: StaticData;
  carriers: string[];
  public carrierFilterCtrl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @ViewChild('globalFileInput') fileInput: ElementRef;
  protected _onDestroy = new Subject<void>();

  constructor(
    public formBuilder: UntypedFormBuilder,
    private containersService: ContainersService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private gaService: GoogleAnalyticsService,
    private authService: AuthService,
    private milestoneService: MilestoneService,
    private dpProgressBarService: DpProgressBarService,
    private dialog: MatDialog,
    private utilsService : UtilsService,
  ) {}

  shipmentType: ShipmentType;
  ShipmentType = ShipmentType;
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.shipmentType = params['shipmentType'];
    });
  }

  handleFileInput(files: FileList, isRoadUploadType: string = null) {
    this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadTemplate);

    if (!files.length || !files.item(0)) return;

    let fileToUpload = files.item(0);

    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    //https://dpwhotfsonline.visualstudio.com/DTLP/_wiki/wikis/DTLP.wiki/32/File-uploads?anchor=import-data
    const uploadType = ShipmentType.OL_SHIPMENT === this.shipmentType ? 'SHEET_BY_LOGISTICS_ID' : isRoadUploadType ?? 'CONTAINER_SHEET';
    formData.append('uploadType', uploadType);
    this.dpProgressBarService.show();
    this.containersService
      .uploadContainerFile(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);

          this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadSuccess);

          this.gotoUploadHistory(true, result);
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.error.errorMessage ? error.error.errorMessage : error.error, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  handleBulkUpdateFileInput(files: FileList) {
    if (!files.length || !files.item(0)) return;
    let fileToUpload = files.item(0);

    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    // formData.append('uploadType', 'CONTAINER_SHEET');
    this.dpProgressBarService.show();
    this.containersService
      .checkTemplateSensitivity(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result) => {
          if (result && result.sensitive) {
            this.openConfirmSensitivityDialog(files);
            return;
          }
          // no template sensitive
          this.gotoUploadHistory(true, result);
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.error.errorMessage ? error.error.errorMessage : error.error, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  openConfirmSensitivityDialog(files: FileList) {
    const message = `You have modified one or more tracking fields, which may cause the tracking data to be reset and the shipment to be re-tracked. Are you sure you want to continue?`;
    const dialogData = new ConfirmDialogModel('Update Shipment', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: dialogData,
      panelClass: 'app-confirm-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.bulkUpdateShipments(files);
      }
    });
  }

  bulkUpdateShipments(files: FileList) {
    if (!files.length || !files.item(0)) return;
    let fileToUpload = files.item(0);
    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    this.dpProgressBarService.show();
    this.containersService
      .updateContainerFile(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);
          this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadSuccess);
          this.gotoUploadHistory(true, result);
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.error.errorMessage ? error.error.errorMessage : error.error, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  gotoUploadHistory(openReport: boolean, result: IUploadResult) {
    //note: a solution to force the url to reload, even if it is the current url.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: openReport,
          ...result,
        },
      });
    });
  }

  gotoAddShipmentOneByOne() {
    if (this.shipmentType === 'TRUCK_SHIPMENT') {
      this.utilsService.migrateRouteHandler(ROUTE_PATH.ADD_SINGLE_ROAD_SHIPMENT);
    } else {
      this.router.navigate(['/add-shipments-one-by-one', this.shipmentType]);
    }
  }
  getTitle() {
    let title = '';
    switch (this.shipmentType) {
      case ShipmentType.AIR_SHIPMENT:
        title = 'Add Air Shipments';
        break;

      case ShipmentType.TRUCK_SHIPMENT:
        title = 'Add Road Shipments';
        break;

      case ShipmentType.INTERMODAL_SHIPMENT:
        title = 'Add Ocean Shipments';
        break;

      case ShipmentType.OL_SHIPMENT:
        title = 'Add Outsourced Logistics Shipments';
        break;

      default:
        title = 'Wrong data type';
        break;
    }
    return title;
  }
  isRoadShipment(): boolean {
    return this.shipmentType === ShipmentType.TRUCK_SHIPMENT;
  }

  logEvent(eventAction) {
    const event = environment.gaEvents.names.buttonClick;
    const eventDetail = {
      eventCategory: environment.gaEvents.categories.buttonClick,
      eventAction,
    };
    this.authService.logEvent(event, eventDetail);
  }

  handleMilestonesFileInput(files: FileList) {
    if (!files.length || !files.item(0)) return;
    const fileToUpload = files.item(0);
    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    this.dpProgressBarService.show();
    this.containersService
      .uploadMilestoneFile(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result: IMilestoneUploadResult) => {
          if (result.flag === 'SUCCESS') {
            const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addMilestoneFromFile}_${environment.gaEvents.labels.success}`;
            this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);
            this.uiService.showSnackbar(result.message, null, {
              duration: environment.snackBarDuration.success,
              panelClass: 'accent',
            });
          } else if (result.flag === 'FAILED') {
            const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addMilestoneFromFile}_${environment.gaEvents.labels.failure}`;
            this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
            this.uiService.showSnackbar('Failed to upload file. File provided could be invalid.', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addMilestoneFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.error.errorMessage ? error.error.errorMessage : error.error, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }
}
