import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { dpAnimations } from '@dp/animations';

@Component({
  selector: 'dp-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: dpAnimations,
})
export class IntroComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
