import { AbstractControl, ValidationErrors } from '@angular/forms';

export const UserNameValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';

  if (!value) {
    return null;
  }

  const alphaOnly = /^[a-zA-Z]+$/;
  if (alphaOnly.test(value) === false) {
    return { passwordStrength: `Must be alphabetic only` };
  }

  if (value.length > 25) {
    return { passwordStrength: 'maximum of 25 characters allowed' };
  }
  return null;
};
