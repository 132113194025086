export const USER_ROLES = [
  {
    id: 'ADMIN',
    value: 'Admin',
  },
  {
    id: 'COLLABORATOR',
    value: 'Collaborator',
  },
  {
    id: 'VIEWER',
    value: 'Viewer',
  },
];
