import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared';
import { LoginCompanyStatusType, STATES, User } from '../user.model';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaBase } from '../captcha.base';
import { Validators } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MilestoneService } from '@dp/services/milestone.service';

@Component({
  selector: 'dp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: dpAnimations,
})
export class LoginComponent extends CaptchaBase implements OnInit {
  //readonly siteKey = environment.recapchaKey;
  email = '';
  LoginCompanyStatusType = LoginCompanyStatusType;
  fromSignup = false;
  isBusy = false;
  returnUrl = '/';
  errorCode: number;
  showLoginCompanyStatus = false;
  user: User = null;
  f: UntypedFormGroup;
  env = environment;

  environment = environment;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialog,
    private gaService: GoogleAnalyticsService,
    private milestoneService: MilestoneService
  ) {
    super();
    if (this.authService.currentUserValue !== null) {
      // TODO: re-enable next
      // this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.dialogRef.closeAll();

    if (this.route.snapshot.queryParams.email) {
      this.email = this.route.snapshot.queryParams.email;
      this.fromSignup = this.route.snapshot?.queryParams?.previousUrl?.indexOf('signup') > -1;
    }

    if (this.env.productionHosting) {
      this.f = this.formBuilder.group({
        email: [this.email, [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        recaptcha: ['', Validators.required],
      });
    } else {
      this.f = this.formBuilder.group({
        email: [this.email, [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      });
    }
  }

  submit() {

    this.beforeSubmit();
    this.authService
      .login({
        email: this.f.value.email,
        password: this.f.value.password,
      })
      // .pipe(first())
      .subscribe(
        (response) => {
          let user = response.body as User;
          this.gaService.pageView('success', 'login', '/login', {
            user_email: user.userEmail,
          });
          const action = `${environment.gaEvents.categories.login}_${environment.gaEvents.actions.login}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.login, environment.gaEvents.labels.success);

          if (user.companyStatus !== LoginCompanyStatusType.VERIFIED) {
            this.router.navigate(['/set-company']);
          } else if (user.systemAdminPanelAccess) {
            this.router.navigate(['/admin']);
          } else if (user.state === STATES.VERIFIED) {
            this.router.navigate(['/dashboard']);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 401 && errorResponse.url.endsWith(environment.urls.organization)) {
            //go to set company page
            this.router.navigate(['/set-company']);
          } else {
            this.errorCode = errorResponse.status;
            let message = errorResponse.error.error;
            if (!message) {
              this.errorCode = +errorResponse.error.errorCode;
              if ([50090, 40410].includes(this.errorCode)) {
                message = 'Invalid user name or password';
              } else {
                message = environment.API_RESPONSE_CODES.REASON_CODES.hasOwnProperty(this.errorCode)
                  ? environment.API_RESPONSE_CODES.REASON_CODES[this.errorCode]
                  : 'unknown error';
              }
            }
            const action = `${environment.gaEvents.categories.login}_${environment.gaEvents.actions.login}_${environment.gaEvents.labels.failure}`;
            this.gaService.event(action, environment.gaEvents.categories.login, environment.gaEvents.labels.failure);
            this.uiService.showSnackbar(message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
            this.isBusy = false;
          }
        }
      );
  }

  beforeSubmit() {
    this.isBusy = true;
    this.errorCode = 0;
    this.showLoginCompanyStatus = false;
  }

  // in dev and staging, allows anyone to login to any account with 1 click.
  loginHelper(email: string, password: string) {
    this.f.patchValue({
      email: email,
    });
    this.f.patchValue({
      password: password,
    });
    this.submit();
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = '';
    if (control.hasError('required')) {
      msg = 'You must enter a value';
    } else if (control.hasError('email')) {
      msg = 'This is not a valid email address';
    }
    return msg;
  }
}
