import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from '../dates/localized-date.pipe';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  constructor(public localizedDatePipe: LocalizedDatePipe) {}

  transform(value: any, args?: any): any {
    const intervals = {
      // year: 31536000,
      // month: 2592000,
      // week: 604800,
      day: 3600 * 24,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      // less than 30 seconds ago will show as 'Just now'
      if (seconds < 29) return 'Just now';

      //  Over 24 hrs：return date back: 11/10/2020 16:00
      if (seconds > intervals.day) {
        return this.localizedDatePipe.transform(value, 'local-date-time');
      }

      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
