import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';

@Component({
  selector: 'dp-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DpProgressBarComponent implements OnInit, OnDestroy {
  bufferValue: number;
  mode: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
  visible: boolean;

  private unsubscribeAll: Subject<any>;

  constructor(private dpProgressBarService: DpProgressBarService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.dpProgressBarService.bufferValue.pipe(takeUntil(this.unsubscribeAll)).subscribe(bufferValue => {
      this.bufferValue = bufferValue;
    });

    this.dpProgressBarService.mode.pipe(takeUntil(this.unsubscribeAll)).subscribe(mode => {
      this.mode = mode;
    });

    this.dpProgressBarService.value.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
      this.value = value;
    });

    this.dpProgressBarService.visible.pipe(takeUntil(this.unsubscribeAll)).subscribe(visible => {
      this.visible = visible;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

}
