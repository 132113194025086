<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div id="login-form-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="login-form">
      <div *ngIf="!showLoginCompanyStatus">

        <div fxHide fxShow.lt-md style="color: red; margin-bottom: 20px;"> 
          <h2>We don't support mobile.</h2> 
          The site will not function correctly at this screen resolution. 
        </div>

        <div class="title">LOGIN TO YOUR ACCOUNT</div>
        <p class="dp-body-2" *ngIf="fromSignup">Looks like you already have an account. Please sign in.</p>
        <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" [formGroup]="f" (ngSubmit)="submit()">
          <mat-form-field appearance="fill">
            <mat-label>Your work email</mat-label>
            <input type="email" matInput name="email" email required formControlName="email">
            <mat-error *ngIf="f.controls['email']">{{getErrorMessage(f.controls['email'])}}</mat-error>
            <!-- <mat-error *ngIf="emailInput.hasError('required')">Field must not be empty.</mat-error>
            <mat-error *ngIf="!emailInput.hasError('required')">Email format is invalid.</mat-error> -->
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Your password</mat-label>
            <input type="password" matInput placeholder="Your password" formControlName="password" name="password" required minlength="6"
              autocomplete="on">
            <mat-error *ngIf="f.controls['password']">{{getErrorMessage(f.controls['password'])}}</mat-error>
          </mat-form-field>

          <ng-container *ngIf="env.productionHosting">
            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
              (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'"
              formControlName="recaptcha"></ngx-recaptcha2>
          </ng-container>

          <button style="height: 40px; margin-bottom: 20px;" type="submit" mat-raised-button [disabled]="f.invalid || isBusy" class="submit-button primary-800">
            <span *ngIf="!isBusy">Login</span>
            <span *ngIf="isBusy" fxLayoutAlign="center center">
              <mat-spinner [diameter]="25"></mat-spinner>
            </span>
          </button>

          <!--<div ngIf="isBusy">
            <mat-spinner></mat-spinner>
          </div>-->

          <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
        </form>
        <!-- <div class="login" fxLayout="column" fxLayoutAlign="center center"> -->
        <div>
          <span class="text">Don't have an account? </span>
          <a class="link" [routerLink]="'/signup'">Sign up now.</a>
        </div>
        <div>Forgot password? Click <a class="link" [routerLink]="'/forgot-password'">here</a> to reset it.</div>
      </div>

      <mat-card *ngIf="!environment.productionHosting" style="margin-top: 20px;">
        <h2>Login Helper</h2>
        <h3>Staging Accounts</h3>
        <button class="ml-24" mat-raised-button style="margin-top: 10px;"
        (click)="loginHelper('mjk@demoimporter.com', 'P8ssw0rd$')"> 
          MJK (Admin)
        </button>
        <button class="ml-24" mat-raised-button style="margin-top: 10px;" (click)="loginHelper('Cheery.Woods@demoimporter.com', 'P8ssw0rd$')">
          Cherry (Not Admin)
        </button>

        <h3>Dev Accounts</h3>
        <button class="ml-24" mat-raised-button style="margin-top: 10px;" (click)="loginHelper('mjk@demoimporter.com', 'P8ssw0rd$')">
          MJK (Admin)
        </button>
        <button class="ml-24" mat-raised-button style="margin-top: 10px;"
        (click)="loginHelper('allen@demoimporter.com', 'P8ssw0rd$')"> 
          Allen (Not Admin)
        </button>
        <!--<button class="ml-24" mat-raised-button style="margin-top: 10px;"
        (click)="loginHelper('demo@dpworld.com', 'WZcRhp&68')"> 
          Demo Account
        </button>-->
      </mat-card>

      <!-- <div *ngIf="showLoginCompanyStatus && this.user.companyStatus === LoginCompanyStatusType.DECLINED">
        <div class="title">YOUR ACCOUNT STATUS</div>
        <p class="dp-body-2">Your request to join <span class="text-bold">{{this.user.companyName}}</span> is declined. Click <a href='./set-company'>here</a> to try other choices.</p>
      </div>
      <div *ngIf="showLoginCompanyStatus && this.user.companyStatus === LoginCompanyStatusType.PENDING">
        <div class="title">You are pending for approval</div>
        <p class="dp-body-2">Your request to join <span class="text-bold">{{this.user.companyName}}</span> is pending.</p>
      </div> -->
    </div>
  </div>
</div>