import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, takeUntil } from 'rxjs/operators';
import { OceanShipmentsApiService } from '../../../ocean-shipments/ocean-shipments-api-service';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { StaticData } from '@dp/services/static-data.model';
import { StaticDataService } from '@dp/services/static-data.service';
import { dpAnimations } from '../../../../@dp/animations/index';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UIService } from 'app/shared';
import { IOceanShipment } from 'app/ocean-shipments/ocean-shipments-models';
import { SvgMap } from '../svg/uds-svg-map';
import { Utility } from '@dp/utilities';
import { AuthService } from '../../../auth/auth.service';
import { ACCOUNT_TYPES } from '../../../auth/user.model';

class CrossFieldSimpleErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.invalid;
  }
}

@UntilDestroy()
@Component({
  selector: 'dp-report-error',
  templateUrl: './report-error.component.html',
  styleUrls: ['./report-error.component.scss'],
  animations: dpAnimations,
})
export class ReportErrorComponent implements OnInit, AfterViewInit {
  SvgMap = SvgMap;
  errorReportTypes: Object;
  errorReportTypesByVessel: Object;
  errorReportTypesByAir: Object;
  errorReportTypesByRoad: Object;
  notContainerType: boolean;
  notByVessel: boolean;
  staticData: StaticData;
  carriers: string[] = [];
  ports: string[];
  originPortId: string;
  defaultPortOfOrigin: string;
  destinationPortId: string;
  defaultDestinationPort: string;
  reportErrorData: IOceanShipment;
  showSuccessResult: boolean;
  payload: Object;
  f: UntypedFormGroup;
  isMBLChanged = false;
  errorMatcher = new CrossFieldSimpleErrorMatcher();
  protected _onDestroy = new Subject<void>();
  isRoadShipment = ['FTL', 'LTL'];
  public carrierFilterCtrl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  isBusy = false;
  isSharedBy = false;

  @Output() goBack = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() saveUpdatedShipmentToTable = new EventEmitter<IOceanShipment>();

  @Input() set data(data: IOceanShipment) {
    const callSetup = !!this.reportErrorData;
    this.reportErrorData = data;
    this.showSuccessResult = false;
    if (callSetup) {
      this.setup();
    }

    // this.reportErrorData.carrier = data['transportJourneys']?.portToPort?.carrier;
  }
  @Input() commentOnly: boolean;
  @Input() isEmail: boolean = false;
  showActions = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private oceanService: OceanShipmentsApiService,
    private staticDataService: StaticDataService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private uiService: UIService,
    private authService: AuthService
  ) {
    this.staticData = this.staticDataService.getStaticDataDirect();
  }

  ngOnInit(): void {
    this.setup();
    this.onMBLChange();
  }
  ngAfterViewInit(): void {
    if (this.reportErrorData.uploadType === 'VESSEL_ID') {
      this.f.get('originPortId').setValue(this.reportErrorData.transportJourneys?.portToPort?.originPortId);
      this.f.get('destinationPortId').setValue(this.reportErrorData.transportJourneys?.portToPort?.destinationPortId);
    } else {
      this.f.get('originPortId').disable();
      this.f.get('destinationPortId').disable();
    }
  }
  setup() {
    if (this.reportErrorData.sharedByOrganizationId) {
      this.isSharedBy = true;
    }
    this.notContainerType = this.reportErrorData.uploadType !== 'CONTAINER_NUMBER';
    this.notByVessel = this.reportErrorData.uploadType !== 'VESSEL_ID';
    this.errorReportTypes = environment.tracking_error_report_type;
    this.errorReportTypesByVessel = environment.tracking_error_report_type_by_vessel;
    this.errorReportTypesByAir = environment.tracking_error_report_type_by_air;
    this.errorReportTypesByRoad = environment.tracking_error_report_type_by_road;
    this.ports = this.staticData.ports.map((item) => {
      return `${item.port}, ${item.country_code} (${item.location_code})`;
    });
    this.showSuccessResult = false;
    this.carriers = this.staticData['carriers']
      .filter((carrier) => carrier['shipment_type'] === 'INTERMODAL_SHIPMENT')
      .map((carrier) => carrier.carrier_name);
    this.filteredCarriers.next(this.carriers.slice());
    // listen for search field value changes
    this.carrierFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCarriers();
    });
    this.f = this.formBuilder.group({
      originPortId: [this.reportErrorData.transportJourneys?.portToPort?.originPortId, [Validators.required]],
      destinationPortId: [this.reportErrorData.transportJourneys?.portToPort?.destinationPortId],
      trackingError: ['', [Validators.required]], //mike xxx
      email: ['', Validators.compose([Validators.required, Validators.pattern(environment.regex.emailValidation)])],
      comment: [''],
      eventsNotExcludedComment: [''],
      retrackResubmitAction: [null, [Validators.required]],
      carrier: [this.reportErrorData.transportJourneys?.portToPort?.carrier],
      mbl: [this.reportErrorData.blNumber],
    });
    if (this.reportErrorData.uploadType === 'VESSEL_ID') {
      this.defaultPortOfOrigin = this.getPort(this.reportErrorData.transportJourneys?.portToPort?.originPortId);
      this.defaultDestinationPort = this.getPort(this.reportErrorData.transportJourneys?.portToPort?.destinationPortId);
    } else {
      this.defaultPortOfOrigin = '';
      this.defaultDestinationPort = '';
    }
    if (this.commentOnly || this.hasShowCommentBox() || this.isSharedBy) {
      this.f.get('retrackResubmitAction').disable();
      this.f.get('carrier').disable();
      this.f.get('mbl').disable();
      this.f.get('originPortId').disable();
      this.f.get('destinationPortId').disable();
    }

    if (!this.isEmail) {
      this.f.get('email').disable();
    }
  }

  hasSlowField(): boolean {
    return (
      (this.reportErrorData.uploadType === 'CONTAINER_NUMBER' &&
        (this.payload['userEnteredReportData'].hasOwnProperty('carrier') || this.payload['userEnteredReportData'].hasOwnProperty('mbl'))) ||
      (this.reportErrorData.uploadType === 'VESSEL_ID' &&
        (this.payload['userEnteredReportData'].hasOwnProperty('originPortCode') ||
          this.payload['userEnteredReportData'].hasOwnProperty('destinationPortCode')))
    );
  }

  trackingErrorChange() {
    if (this.commentOnly || this.hasShowCommentBox() || this.isSharedBy) {
      return;
    }

    const trackingError = this.f.get('trackingError').value;
    this.f.get('retrackResubmitAction').disable();
    this.f.get('comment').disable();
    this.f.get('carrier').disable();
    this.f.get('mbl').disable();
    this.f.get('originPortId').disable();
    this.f.get('destinationPortId').disable();
    this.f.get('eventsNotExcludedComment').disable();

    if (['TERT-NOT-FOUND', 'TERT-WRONG-CARRIER-MBL', 'TERT-WRONG-ORIGIN-DESTINATION'].includes(trackingError)) {
      this.f.get('retrackResubmitAction').enable();
    } else {
      this.f.get('comment').enable();
    }

    if (
      ['TERT-NOT-FOUND', 'TERT-WRONG-CARRIER-MBL'].includes(trackingError) &&
      this.authService.currentUserValue.accountType === ACCOUNT_TYPES.PLUS
    ) {
      this.showActions = false;
      this.f.get('comment').enable();
      this.f.get('retrackResubmitAction').disable();
    }
  }

  hideSubFields(val: string) {
    const retrackResubmitAction = this.f.get('retrackResubmitAction').value;
    return this.f.get('retrackResubmitAction').disabled || retrackResubmitAction !== val;
  }
  retrackResubmitActionChange() {
    const retrackResubmitAction = this.f.get('retrackResubmitAction').value;

    this.f.get('carrier').disable();
    this.f.get('mbl').disable();
    this.f.get('originPortId').disable();
    this.f.get('destinationPortId').disable();
    this.f.get('eventsNotExcludedComment').disable();

    if (retrackResubmitAction === 'TERR-CONTACT-CUSTOMER-SUPPORT') {
      this.f.get('eventsNotExcludedComment').enable();
    } else if (retrackResubmitAction === 'TERR-UPDATE-RETRACK-SHIPMENT') {
      if (this.reportErrorData.uploadType !== 'VESSEL_ID') {
        this.f.get('carrier').enable();
        this.f.get('mbl').enable();
      } else {
        this.f.get('originPortId').enable();
        this.f.get('destinationPortId').enable();
      }
      if (
        (this.f.get('carrier').value === this.reportErrorData.transportJourneys?.portToPort?.carrier &&
          this.f.get('mbl').value === this.reportErrorData.blNumber) ||
        (this.f.get('originPortId').value === this.reportErrorData.transportJourneys?.portToPort?.originPortId &&
          this.f.get('destinationPortId').value === this.reportErrorData.transportJourneys?.portToPort?.destinationPortId)
      ) {
        this.f.markAsPristine();
      }
    }
  }
  protected filterCarriers() {
    // get the search keyword
    let search = this.carrierFilterCtrl.value;
    if (!search) {
      this.filteredCarriers.next(this.carriers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.toLowerCase().indexOf(search) > -1));
  }

  deleteCarrier(e: PointerEvent) {
    this.f.get('carrier').setValue('');
    this.f.get('carrier').markAsDirty();
    e.stopPropagation();
  }

  onClose() {
    this.close.emit();
  }
  onGoBack() {
    this.goBack.emit();
  }

  submit() {
    const errorReportRecommendationCode = this.f.get('retrackResubmitAction').enabled
      ? this.f.get('retrackResubmitAction').value
      : 'TERR-CONTACT-CUSTOMER-SUPPORT';

    this.payload = {
      errorReportTypeCode: this.f.get('trackingError').value,
      errorReportRecommendationCode,
      shipmentNumber: this.reportErrorData.shipmentNumber,
    };

    if (this.isEmail) {
      const email = this.f.get('email').value;
      this.payload = { ...this.payload, email };
    }

    let userEnteredReportData = null;
    if (errorReportRecommendationCode === 'TERR-CONTACT-CUSTOMER-SUPPORT') {
      const description = this.f.get('comment').enabled ? this.f.get('comment').value : this.f.get('eventsNotExcludedComment').value;
      userEnteredReportData = {
        description: description,
      };
    } else if (errorReportRecommendationCode === 'TERR-UPDATE-RETRACK-SHIPMENT') {
      if (this.reportErrorData.uploadType !== 'VESSEL_ID') {
        userEnteredReportData = {
          carrier: this.f.get('carrier').value || '',
          mbl: this.f.get('mbl').value?.trim() || '',
        };
      } else {
        userEnteredReportData = {
          originPortCode: this.f.get('originPortId').value || '',
          destinationPortCode: this.f.get('destinationPortId').value || '',
        };
      }
    }

    if (userEnteredReportData) {
      this.payload = { ...this.payload, userEnteredReportData };
    }

    if (this.commentOnly) {
      const shareToken = this.route.snapshot.queryParams.token;
      this.payload = { ...this.payload, shareToken };
    }

    if (this.hasSlowField()) {
      const message = `You have modified one or more tracking fields, which may cause the tracking data to be reset and the shipment to be re-tracked. Are you sure you want to continue?`;
      const dialogData = new ConfirmDialogModel('Update shipment', message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '450px',
        data: dialogData,
        panelClass: 'app-confirm-dialog',
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        //true: delete
        if (dialogResult) {
          this.DoIt();
        }
      });
    } else {
      this.DoIt();
    }
  }
  notSelected(radioGroup: string, options: string[], commentOnly?: boolean): boolean {
    const selected = this.f.get(radioGroup)?.value;
    if (
      this.authService.currentUserValue.accountType === ACCOUNT_TYPES.PLUS &&
      ['TERT-NOT-FOUND', 'TERT-WRONG-CARRIER-MBL'].includes(selected)
    ) {
      return false;
    }
    return !options.includes(selected);
  }

  private DoIt() {
    this.isBusy = true;
    this.oceanService
      .reportShipmentError(this.payload, this.commentOnly)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isBusy = false))
      )
      .subscribe(
        (res) => {
          this.showSuccessResult = true;
          this.updateParent();
        },
        (err) => {
          let msg = 'Something went wrong. Please try again later.';
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  updateParent() {
    if (this.reportErrorData.uploadType === 'CONTAINER_NUMBER' && this.payload['userEnteredReportData']?.hasOwnProperty('carrier')) {
      this.reportErrorData['transportJourneys'].portToPort.carrier = this.payload['userEnteredReportData']?.carrier;
      this.reportErrorData.blNumber = this.payload['userEnteredReportData']?.mbl;
      if (this.hasSlowField()) {
        this.reportErrorData.status = 'PENDING';
      }
      this.saveUpdatedShipmentToTable.emit(this.reportErrorData);
    } else if (
      this.reportErrorData.uploadType === 'VESSEL_ID' &&
      (this.payload['userEnteredReportData']?.hasOwnProperty('originPortCode') ||
        this.payload['userEnteredReportData']?.hasOwnProperty('destinationPortCode'))
    ) {
      this.reportErrorData['transportJourneys'].portToPort.originPortId = this.payload['userEnteredReportData']?.originPortCode;
      this.reportErrorData['transportJourneys'].portToPort.destinationPortId = this.payload['userEnteredReportData']?.destinationPortCode;
      if (this.hasSlowField()) {
        this.reportErrorData.status = 'PENDING';
      }
      this.saveUpdatedShipmentToTable.emit(this.reportErrorData);
    }
  }

  asIsOrder(a, b) {
    // const ai = Object.keys(ReportTrackingErrors).indexOf(a.key);
    // const bi = Object.keys(ReportTrackingErrors).indexOf(b.key);

    // return ai > bi ? 1 : -1;
    return 1;
  }
  selected(radioGroup: string, options: string[], commentOnly?: boolean): boolean {
    const selected = this.f.get(radioGroup)?.value;
    return options.includes(selected);
  }

  isVesselUpload() {
    return this.reportErrorData.uploadType === 'VESSEL_ID';
  }

  getVesselNumber() {
    return Utility.getVesselNameNumber(this.reportErrorData);
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = '';
    if (control.hasError('required')) {
      msg = 'Email is required';
    } else if (control.hasError('email') || control.hasError('pattern')) {
      msg = 'This is not a valid email address.';
    }
    return msg;
  }

  hasShowCommentBox() {
    const notContainerType =
      !this.notContainerType && this.isRoadShipment.includes(this.reportErrorData.shippingMode) ? true : this.notContainerType;
    return notContainerType && this.notByVessel;
  }

  optionChanged(dataType: 'originPortId' | 'destinationPortId', data: string) {
    const ctrl = this.f.get(dataType);
    ctrl.setValue(data);
    if (data.length > 0) {
      ctrl.markAsDirty();
    }
  }

  getPort(PortId: string) {
    if (!PortId) {
      return '';
    }
    const name = this.staticData['ports'].find((item) => {
      return item.location_code === PortId;
    });
    return name ? `${name.port}, ${name.country_code} (${name.location_code})` : '';
  }

  getPortValue(PortId: string) {
    if (!PortId) {
      return '';
    }
    const name = this.staticData['ports'].find((item) => {
      return item.location_code === PortId;
    });
    return name ? `${name.port}` : '';
  }
  getTypeOfErrorText() {
    const typeCode = this.payload['errorReportTypeCode'];
    return this.reportErrorData.uploadType === 'AWB_NUMBER'
      ? this.errorReportTypesByAir[typeCode]
      : this.isRoadShipment.includes(this.reportErrorData.shippingMode)
      ? this.errorReportTypesByRoad[typeCode]
      : this.reportErrorData.uploadType === 'VESSEL_ID'
      ? this.errorReportTypesByVessel[typeCode]
      : this.errorReportTypes[typeCode];
  }

  onMBLChange() {
    this.f.get('mbl')?.valueChanges?.subscribe((res) => {
      this.isMBLChanged = this.f.get('mbl').value !== this.reportErrorData.blNumber;
    });
  }
}
