<span type="button" class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<h1 mat-dialog-title>{{data? 'Edit Team' : 'Create New Team'}}</h1>

<form class="easy-form" autocomplete="off" [formGroup]="f" (ngSubmit)="submit($event)" >
  <mat-form-field appearance="outline" class="form-field full-width">
    <mat-label>Team Name</mat-label>
    <input matInput required type="text" #teamName formControlName="teamName" autocomplete="off" name="team name" maxlength="255">
    <mat-error *ngIf="f.get('teamName').hasError('required')">Team name is required</mat-error>
    <mat-error *ngIf="f.get('teamName').hasError('hasSpaces')">Team name cannot have leading or trailing spaces.</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="cdkTextareaAutosizeCls form-field full-width">
    <mat-label>Team Description</mat-label>
    <textarea matInput rows="4" formControlName="teamDescription" #description cdkTextareaAutosize maxlength="200" cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4" ></textarea>
    <mat-hint align="end">{{description.value.length}} / 200 max chars</mat-hint>
  </mat-form-field>
  <button mat-flat-button type="submit" [disabled]="f.invalid || !f.dirty" class="easy-button" style="float: right"
    [dpBusy]="isBusy">
    {{data? 'SAVE CHANGES' : 'CREATE TEAM'}}
  </button>
</form>
