<div class="user-profile" style="min-height: 600px">
  <form [formGroup]="f" class="easy-form" [autocomplete]="'on'" (keydown.enter)="$event.preventDefault()">
    <div class="easy-form-block">
      <div class="easy-form-block-title">Basic information</div>
      <div class="easy-form-block-detail">
        <!-- email zone -->
        <div #emailZone>
          <div class="entities p-0" *ngIf="!emailEditMode" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <span class="entity-label">Sign-in email </span>
              <span class="entity-property">{{ user.userEmail }}</span>
            </div>
            <!-- <button matSuffix mat-icon-button (click)="emailEdit(true)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button> -->
          </div>

          <div #emailBlock>
            <mat-form-field appearance="outline" *ngIf="emailEditMode">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" name="email" autocomplete="email" email required />
              <button mat-button matSuffix class="inlineIcon cool-primary-80-text" *ngIf="canUpdateEmail()"
                (click)="changeEmail()">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-button matSuffix class="inlineIcon">
                <mat-icon class="material-icons-outlined" (click)="cancelEmail()">clear</mat-icon>
              </button>
              <mat-error *ngIf="f.controls['email'].invalid"> {{ getErrorMessage(f.controls['email']) }}</mat-error>
            </mat-form-field>
          </div>
          <div class="entities p-0" *ngIf="user.pendingUserEmail">
            <div class="mb-8">
              <span class="entity-label">Pending email </span>
              <span class="entity-property">{{ user.pendingUserEmail }}</span>
            </div>
            <mat-card-header>
              <mat-icon mat-card-avatar class="material-icons-outlined">info</mat-icon>
              <mat-card-subtitle>Your email is pending verification. We've sent you an email to confirm the address. You will continue to
                use
                your old email ({{
                user.userEmail
                }}) to sign-in until you complete the process.</mat-card-subtitle>
            </mat-card-header>
          </div>
        </div>
        <!-- //email zone -->

        <!-- password zone -->
        <div class="entities p-0" *ngIf="!passwordEditMode" #passwordZone>
          <div class="inline-block">
            <span class="entity-label">Password </span>
            <span class="entity-property">********</span>
          </div>
          <a (click)="openEditPasswordDlg()" class="ml-100 pointer">
            <!-- <mat-icon class="material-icons-outlined">edit</mat-icon> -->
            <dp-svg [src]="SvgMap.edit" w="24"></dp-svg>
            <span class="components-table-heading" style="vertical-align: text-bottom;">
              EDIT PASSWORD
            </span>
          </a>
        </div>

        <div #passwordBlock *ngIf="passwordEditMode">
          <mat-form-field appearance="outline">
            <mat-label>Current password</mat-label>
            <input matInput type="password" formControlName="oldPassword" name="oldPassword" required autocomplete="current-password" />
            <mat-error *ngIf="f.controls['oldPassword'].invalid"> {{ getErrorMessage(f.controls['oldPassword']) }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>New password</mat-label>
            <input matInput type="password" formControlName="password" name="password" autocomplete="off" required />
            <mat-error *ngIf="f.controls['password'].invalid"> {{ getErrorMessage(f.controls['password']) }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Confirm new password</mat-label>
            <input matInput type="password" formControlName="confirmPassword" required autocomplete="new-password"
              [errorStateMatcher]="errorMatcher" />
            <mat-error *ngIf="f.controls['confirmPassword'].invalid">
              {{ getErrorMessage(f.controls['confirmPassword']) }}
            </mat-error>
            <mat-error *ngIf="f.hasError('passwordsDoNotMatch')"> Passwords do not match! </mat-error>
          </mat-form-field>

          <mat-error *ngIf="f.hasError('sameAsOld')"> Can not use the same password! </mat-error>

          <button mat-button matSuffix class="inlineIcon" (click)="changePassword()" [disabled]="!f.valid">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-button matSuffix class="inlineIcon">
            <mat-icon class="material-icons-outlined" (click)="passwordEdit(false)">clear</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">

          <mat-form-field appearance="outline">
            <mat-label>First name</mat-label>
            <input matInput type="text" formControlName="firstName" name="fname" autocomplete="given-name" required />
            <mat-error *ngIf="f.controls['firstName'].invalid"> {{ getErrorMessage(f.controls['firstName']) }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Middle name</mat-label>
            <input matInput type="text" formControlName="middleName" name="mname" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Last name</mat-label>
            <input matInput type="text" formControlName="lastName" name="lname" required />
            <mat-error *ngIf="f.controls['lastName'].invalid"> {{ getErrorMessage(f.controls['lastName']) }}</mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>
    <div class="easy-form-block">
      <div class="easy-form-block-title">Professional information</div>
      <div class="easy-form-block-detail">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <mat-form-field appearance="outline">
            <mat-label>Department</mat-label>
            <input matInput type="text" formControlName="department" autocomplete="off" name="organization" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Job title</mat-label>
            <input matInput type="text" formControlName="role" autocomplete="organization-title" name="organization-title" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput type="tel" formControlName="phone" placeholder="Phone" name="tel" />
            <mat-error *ngIf="f.controls['phone'].invalid"> {{ getErrorMessage(f.controls['phone']) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="easy-form-block">
      <div class="easy-form-block-title">Date & Time information</div>
      <div class="easy-form-block-detail">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Date format</mat-label>
            <mat-select formControlName="datePattern" type="datetime-local" [disableOptionCentering]="true" required panelClass="no-change">
              <mat-option *ngFor="let datePattern of datePatterns" [value]="datePattern">{{ getStamp(datePattern) }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Time format</mat-label>
            <mat-select formControlName="timePattern" type="datetime-local" [disableOptionCentering]="true" required panelClass="no-change">
              <mat-option *ngFor="let timePattern of timePatterns" [value]="timePattern">{{ getStamp(timePattern) }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Timezone</mat-label>
            <mat-select formControlName="timezone" type="datetime-local" #timezoneSelect [disableOptionCentering]="true" panelClass="no-change">
              <mat-option>
                <ngx-mat-select-search [formControl]="timezoneFilterCtrl" placeholderLabel="Search..."
                  noEntriesFoundLabel="'no matching timezone found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.code">{{ timezone.name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- //password zone -->
    <div #restPropertyZone>

    </div>
  </form>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" #actionZone class="mb-12">
    <button mat-flat-button class="easy-button" [dpBusy]="isBusy" [disabled]="!f.valid || f.pristine" (click)="saveProfile()">
      SAVE CHANGES
    </button>
  </div>
</div>

<!-- <pre>{{f.errors | json}}</pre> -->