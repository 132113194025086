import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ACCOUNT_TYPES } from '../../auth/user.model';

@Component({
  selector: 'dp-new-settings',
  templateUrl: './new-settings.component.html',
  styleUrls: ['./new-settings.component.scss'],
  animations: dpAnimations,
})
export class NewSettingsComponent implements OnInit {
  selectedIndex = 0;
  environmentType: string;
  isUserPlus = false;
  constructor(private route: ActivatedRoute, private authService: AuthService) {
    this.environmentType = environment.environmentType;
  }

  ngOnInit(): void {
    if (this.route.snapshot?.queryParams?.tab === 'notifications') {
      this.selectedIndex = 1;
    }
    this.authService.currentUser.subscribe(user => {
      this.isUserPlus = user?.accountType === ACCOUNT_TYPES.PLUS;
    });
  }
}
