import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { VerifyComponent } from './verify/verify.component';
import { NewUserComponent } from './new-user/new-user.component';
import { Signup2Component } from './signup2/signup2.component';
import { SetCompanyComponent } from './set-company/set-company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { LoginSsoComponent } from './login-sso/login-sso.component';
import { environment } from 'environments/environment';
import { UserInviteActionComponent } from './user-invite-action/user-invite-action.component';


const routes: Routes = [
  { path: 'login', component: (environment.SSO && environment.production) ? LoginSsoComponent : LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'signup', component: Signup2Component },
  { path: 'verify/:verificationCode', component: VerifyComponent },
  { path: 'approval/:action/:actionCode', component: NewUserComponent },
  { path: 'partnership/:action/:actionCode', component: UserInviteActionComponent },
  { path: 'set-company', component: SetCompanyComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password/:resetCode', component: ForgotPasswordComponent },
  { path: 'changeEmail/:verificationCode', component: ChangeEmailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
