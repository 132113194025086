import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardConfigurations } from 'app/auth/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BoardConfigService {
  private boardConfigData = new BehaviorSubject<DashboardConfigurations>({});
  boardConfigData$ = this.boardConfigData.asObservable();
  displayMoreCard = {};

  constructor(private http: HttpClient) {}

  get currentBoardConfigData() {
    return this.boardConfigData.getValue();
  }

  updateBoardConfigData(data, updateDefault = false) {
    this.boardConfigData.next({ ...this.currentBoardConfigData, ...data, updateDefault });
  }

  getDashboardConfigurations() {
    return this.http.get(environment.rootUrl + environment.urls.dashboardConfig);
  }

  updateDashboardConfigurations(payload) {
    return this.http.post(environment.rootUrl + environment.urls.dashboardConfig, payload);
  }

  fetchShipmentMetrics(shipmentType) {
    return this.http.get(environment.rootUrl + environment.urls.dashboardShipmentMetrics + `?shipmentType=${shipmentType}`);
  }
}
