import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationCodes, NotificationData, NotificationEvent, NotificationTypes } from '../../../notification.model';
import { NotificationService } from '../../../notification.service';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { ROUTE_PATH } from 'app/route.constants';
import { UtilsService } from '@dp/services/utils.service';

@Component({
  selector: 'dp-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent {
  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  @Input() notificationEvent: NotificationEvent;
  @Output() redirectEvent: EventEmitter<string> = new EventEmitter();

  getRouterLink(data) {
    const shippingMode = this.notificationEvent.notificationData[0]?.shippingMode;
    const shipmentType = this.notificationEvent.shipmentType;
    let path = ROUTE_PATH.OCEAN_TRACKING;
    if (['FTL', 'LTL'].includes(shippingMode)) {
      path = ROUTE_PATH.ROAD_TRACKING;
    } else {
      switch (shipmentType) {
        case 'intermodal':
          path = ROUTE_PATH.OCEAN_TRACKING;
          break;
        case 'land':
          path = ROUTE_PATH.ROAD_TRACKING;
          break;
        case 'air':
          path = ROUTE_PATH.AIR_TRACKING;
          break;
        case 'outsourced_logistics':
          path = ROUTE_PATH.OL_TRACKING;
          break;
      }
    }

    this.redirect(data.shipmentNumber);
    const queryParams = this.getQueryParams(data);
    path = this.utilsService.migrateRouteHandler(path, {
      shipmentId: data.entityId,
      params: { ...(!!queryParams['tabLabel'] && { tab: queryParams['tabLabel'] }) },
    });
    !!path &&
      this.router.navigate([path], {
        queryParams,
      });
  }
  getRouterLinkForOrder(): string {
    return '/purchase-orders';
  }
  separator(i: number): string {
    if (i === this.notificationEvent.notificationData.length - 1) {
      return ' ';
    } else if (i === this.notificationEvent.notificationData.length - 2) {
      return ' and ';
    } else {
      return ', ';
    }
  }

  redirect(shipmentOrOrder: string) {
    const event = environment.gaEvents.names.notificationClick;
    const eventCategory =
      this.notificationEvent.notificationType.toLowerCase() === NotificationTypes.EVENT.toLowerCase()
        ? 'Events'
        : this.notificationEvent.notificationType.toLocaleLowerCase() === NotificationTypes.EXCEPTION.toLocaleLowerCase()
        ? 'Exceptions'
        : 'Others';
    const eventDetail = {
      eventCategory,
      eventAction: this.notificationEvent.title,
      eventLabel: shipmentOrOrder,
    };
    this.authService.logEvent(event, eventDetail);

    this.notificationService.closeNotificationDlg.next(true);
  }
  getQueryParams(data: NotificationData) {
    const queryParams = { shipmentNumber: data.shipmentNumber };
    if (this.notificationEvent.notificationType.toLowerCase() === NotificationTypes.EXCEPTION.toLowerCase()) {
      queryParams['tabLabel'] = 'Exceptions';
    } else if (this.notificationEvent.notificationCode === NotificationCodes.SYSTEM_DOCUMENT_UPLOADED) {
      queryParams['tabLabel'] = 'Documents';
    }
    return queryParams;
  }
}
