import { Component, Input } from '@angular/core';
import { IOceanShipment } from 'app/ocean-shipments/ocean-shipments-models';
import { AirShipmentDetail } from '../../../air-shipment/air-tracking/air-tracking.model';
import { AuthService } from '../../../auth/auth.service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

// similar as tracking-summary, with extra info
// Todo remove tracking-summary, after tracking-detail is well-tested
@Component({
  selector: 'dp-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss'],
})
export class TrackingDetailsComponent {
  @Input() transportJourneys;
  @Input() shipment: IOceanShipment | AirShipmentDetail;
  @Input() isManualIntermodalLinkage;
  isUserLoggedIn = false;
  isPredictiveEtaEnabled = false;
  SvgMap = SvgMap;
  constructor(private authService: AuthService) {
    const user = authService.currentUserValue;
    if (user) {
      this.isUserLoggedIn = true;
      this.isPredictiveEtaEnabled = user.isPredictiveEtaEnabled;
    }
  }

  getPredictiveDaysDifference(hubEtaDaysDifference: number): string {
    if (!hubEtaDaysDifference) {
      return '';
    }
    let date = '';
    if (hubEtaDaysDifference > 0) {
      date = `(+${hubEtaDaysDifference}`;
    } else {
      date = `(${hubEtaDaysDifference}`;
    }
    if ([-1, 1].includes(hubEtaDaysDifference)) {
      return `${date} day)`;
    }
    return `${date} days)`;
  }

  getPredictiveTootltip(t) {
    return `Flow Predicts - ${t.portToPort.predictedHubEta ? t.portToPort.predictedHubEta : ''} ${this.getPredictiveDaysDifference(
      t.portToPort.hubEtaDaysDifference
    )}
      _________________________________
      Takes into account historical data, route forecast along with other key factors to provide an accurate final ocean port ETA. Currently in beta with limited coverage.`;
  }
}
