<div fxLayout="row" fxLayoutAlign="space-between center">
  <div [class.disabled-text]="!_item.isEnabled" [ngClass]="isSmall? 'small-title' : 'title'">
    {{_item.title}}

    <!-- <mat-icon class="material-icons-outlined info-icon" *ngIf="_item.description" [tooltip]="_item.description"
      placement="right">
      info
    </mat-icon> -->

    <span *ngIf="_item.description" [tooltip]="_item.description" placement="right">
      <dp-svg [src]="SvgMap.info" w="21" h="16"></dp-svg>
    </span>

  </div>
  <div class="easy-form">
    <mat-spinner [diameter]="18" class="mr-8" [hidden]="!_item.isBusy"></mat-spinner>
    <mat-slide-toggle [checked]="_item.isEnabled" [disabled]="_item.isBusy || _item.allowChange === false" (change)="sliderChange()" appearance="outline">
      <!-- {{_item.isEnabled? 'On' : 'Off'}} -->
    </mat-slide-toggle>
  </div>
</div>
<div *ngIf="_item.subTitle" class="subTitle">{{_item.subTitle}}</div>
