import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { csvDlgData, csvDlgDateRangeTypes, DownloadShipmentsMode } from '@dp/components/csv-dlg/csv-dlg.model';
import { Device, SensorApiItem, SensorData, Tag } from '@dp/types';
import { Utility } from '@dp/utilities';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { environment } from 'environments/environment';
import moment from 'moment';
import { map, Observable, of, Subject } from 'rxjs';
import { AWBCheckResult, CSVDialogData, DeleteShipmentResponse, ShipmentType } from './shared.model';
import { catchError, throwError } from 'rxjs';
import { UIService } from './ui.service';
import { UserAccessService } from 'app/auth/user-access.service';
import { USER_ACCESS } from 'app/user-access.constants';
import sensors from './sensors.mock.json';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  initFeedbackWidget = new Subject();
  initFeedbackWidget$ = this.initFeedbackWidget.asObservable();
  getShipmentsPodFilesURL: string;
  getShipmentsURL: string;
  tagSuggestions: Tag[] = [];
  awbAirport = {};
  constructor(private http: HttpClient, private uiService: UIService, private userAccessService: UserAccessService) {}
  deleteTag(shipmentId: number, tagId: number) {
    return this.http.delete(environment.rootUrl + `shipmentTags/${shipmentId}/tags/${tagId}`);
  }

  createTag(shipmentId: number, tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(environment.rootUrl + `shipmentTags/${shipmentId}`, { name: tag.name }).pipe(
      catchError((err) => {
        let errMsg = 'Add tag failed!';
        if (err.error.errorCode === '40030') {
          errMsg += ' Only 20 tags allowed.';
        }
        if (err.error.errorCode === '50090') {
          errMsg += ' Tag content is over the limit.';
        }
        this.uiService.showSnackbar(errMsg, null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });

        return throwError(() => err);
      })
    );
  }
  getTagSuggestions(): Observable<Tag[]> {
    return this.userAccessService.anyOrgHasAccess(USER_ACCESS.TAG)
      ? this.http.get(environment.rootUrl + 'shipmentTags').pipe(map((result) => (result['data'] ? result['data'] : result)))
      : of([]);
  }
  getDevices(): Observable<Device[]> {
    return this.http.get(environment.rootUrl + 'devices').pipe(map((result) => (result['data'] ? result['data'] : result)));
  }

  deleteOrderTag(OrderAdvancedId: number, tagId: number) {
    return this.http.delete(environment.rootUrl + `orderTags/${OrderAdvancedId}/tags/${tagId}`);
  }

  createOrderTag(OrderAdvancedId: number, tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(environment.rootUrl + `orderTags/${OrderAdvancedId}`, { name: tag.name }).pipe(
      catchError((err) => {
        let errMsg = 'Add tag failed!';
        if (err.error.errorCode === '40030') {
          errMsg += ' Only 20 tags allowed.';
        }
        if (err.error.errorCode === '50090') {
          errMsg += ' Tag content is over the limit.';
        }
        this.uiService.showSnackbar(errMsg, null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });

        return throwError(() => err);
      })
    );
  }

  getOrderTagSuggestions(): Observable<Tag[]> {
    return this.http.get(environment.rootUrl + 'orderTags').pipe(map((result) => (result['data'] ? result['data'] : result)));
  }

  getMap(shipmentId: number): Observable<MapSpace.ShipmentMap> {
    const url = environment.rootUrl + `shipmentV2/shipments/${shipmentId}/map`;
    return this.http.get<MapSpace.ShipmentMap>(url);
  }
  bulkUpdateFlag(payload) {
    const url = environment.rootUrl + `shipmentV2/shipments`;
    return this.http.put(url, payload);
  }

  bulkDeleteShipments(payload): Observable<DeleteShipmentResponse> {
    const httpOptions = {
      headers: null,
      body: payload,
    };
    const url = environment.rootUrl + `shipmentV2/shipments`;
    return this.http.delete<DeleteShipmentResponse>(url, httpOptions);
  }

  //sample value:
  //dlgValues:  dateRange: "THIS_MONTH"
  //            exportBy: 'BOOKING_DATE';
  //            includePODZipFile: true;
  //            isProductDataRequired: true;

  exportShipments(
    dlgValues: csvDlgData,
    dlgData: CSVDialogData,
    downloadMode: DownloadShipmentsMode,
    includePodFiles = false
  ): Observable<HttpResponse<Blob>> {
    const url = this.isPod(dlgData, downloadMode)
      ? environment.rootUrl + `shipmentV2/shipments/podFiles?shipmentType=${dlgData.shipmentType}`
      : dlgData.istbg
      ? environment.rootUrl + `shipmentGroups/groupBy/referenceNumber?shipmentType=${dlgData.shipmentType}`
      : environment.rootUrl + `shipmentV2/shipments`;

    const defaultParams = {
      _page: '', // no page, _limit
      _limit: '',
    };

    const dlgParams = this.convertDlgValueToParams(dlgValues, dlgData);

    const commonParams: Params = { ...dlgParams, ...defaultParams };
    let params: Params = dlgData.istbg ? { ...commonParams, ...dlgData.filters } : { ...commonParams, ...dlgData.params, includePodFiles };

    if (downloadMode === DownloadShipmentsMode.singleFile) {
      params = { ...params, maxPage: true }; //*required to export as csv file instead of json
    } else {
      delete params.isProductDataRequired;
      delete params.isTransshipmentPortDataRequired;
      delete params.uiEnabledColumnsOnly;
    }

    delete params.mode;

    return this.http.get(url, { responseType: 'blob', observe: 'response', params });
  }
  exportPODFiles(url: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
  }
  convertDlgValueToParams(dlgValues: csvDlgData, dlgData: CSVDialogData): Object {
    let result = {};
    const range =
      csvDlgDateRangeTypes[dlgValues['dateRange']] === csvDlgDateRangeTypes.CUSTOM
        ? { start: moment(dlgValues['startDate']).format('YYYY-MM-DD'), end: moment(dlgValues['endDate']).format('YYYY-MM-DD') }
        : Utility.getPastDateInRange(dlgValues['dateRange']);

    if (dlgValues['exportBy'] === 'BOOKING_DATE') {
      result = dlgData.istbg
        ? { ...result, createdAt_gte: range.start, createdAt_lte: range.end }
        : { ...result, bookingDate_gte: range.start, bookingDate_lte: range.end };
    } else if (dlgValues['exportBy'] === 'ATA_DATE') {
      result =
        dlgData.shipmentType === ShipmentType.TRUCK_SHIPMENT
          ? { ...result, ataDate_gte: range.start, ataDate_lte: range.end }
          : dlgData.shipmentType === ShipmentType.AIR_SHIPMENT
          ? { ...result, portAtaDate_gte: range.start, portAtaDate_lte: range.end }
          : { ...result, hubAtaDate_gte: range.start, hubAtaDate_lte: range.end };
    } else {
      //pod dialog
      result = { ...result, ataDate_gte: range.start, ataDate_lte: range.end };
    }
    if (!dlgData.istbg) {
      // if (dlgValues['includePODZipFile'] !== undefined) {
      //   result = { ...result, hasPodDocument: dlgValues['includePODZipFile'] };
      // }
      if (dlgValues['isProductDataRequired'] !== undefined) {
        result = { ...result, isProductDataRequired: dlgValues['isProductDataRequired'] };
      }
      if (dlgValues['isTransshipmentPortDataRequired'] !== undefined) {
        result = { ...result, isTransshipmentPortDataRequired: dlgValues['isTransshipmentPortDataRequired'] };
      }
    }
    if (dlgValues['uiEnabledColumnsOnly'] !== undefined) {
      result = { ...result, uiEnabledColumnsOnly: dlgValues['uiEnabledColumnsOnly'] };
    }
    if (dlgData.mode !== 'pod') {
      result = { ...result, exportFormat: dlgValues.fileFormat };
    }

    return result;
  }

  getPodDownloadUrl(dlgValues: csvDlgData, dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode): string {
    let { url, params }: { url: string; params: Params } = this.paramsSetup(dlgData, downloadMode, dlgValues);
    return (
      environment.rootUrl +
      `shipmentV2/shipments/podFiles?shipmentType=${dlgData.shipmentType}` +
      '&' +
      new HttpParams({ fromObject: params }).toString()
    );
  }

  getPodCount(url): Observable<number> {
    return this.http.get<number>(url);
  }

  private paramsSetup(dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode, dlgValues: csvDlgData) {
    const url = this.isPod(dlgData, downloadMode)
      ? environment.rootUrl + `shipmentV2/shipments/podFiles?shipmentType=${dlgData.shipmentType}`
      : dlgData.istbg
      ? environment.rootUrl + `shipmentGroups/groupBy/referenceNumber?shipmentType=${dlgData.shipmentType}`
      : environment.rootUrl + `shipmentV2/shipments?shipmentType=${dlgData.shipmentType}`;

    const defaultParams = {
      _page: '',
      _limit: '',
    };

    const dlgParams = this.convertDlgValueToParams(dlgValues, dlgData);

    let params: Params = { ...dlgParams, ...dlgData.filters, ...defaultParams };

    if (downloadMode === DownloadShipmentsMode.singleFile) {
      params = { ...params, maxPage: true }; //*required to export as csv file instead of json
    } else {
      delete params.isProductDataRequired;
      delete params.exportFormat;
      delete params.uiEnabledColumnsOnly;
    }

    delete params.mode;
    return { url, params };
  }

  checkAWB(num: number): Observable<AWBCheckResult> {
    const url = environment.rootUrl + `awbs/checkInfo?q=${num}`;
    return this.http.get<AWBCheckResult>(url);
  }

  protected isPod(dlgData: CSVDialogData, downloadMode: DownloadShipmentsMode): boolean {
    return dlgData.mode === 'pod' || downloadMode === DownloadShipmentsMode.zip;
  }

  getPodHits(url): Observable<any> {
    return this.http.get<number>(url, {
      observe: 'response',
    });
  }

  getFeedbackMetadata(isLoggedIn: boolean) {
    return this.http.get(`${environment.feedbackServer}feedback/metadata?isForLoggedOutUser=${!isLoggedIn}`);
  }
  getShipmentSensorData(shipmentId: number, sensor?: string): Observable<SensorApiItem[]> {
    const url = environment.rootUrl + `sensor-data/${shipmentId}?shouldCombineLocationData=true`;
    return this.http.get<SensorApiItem[]>(url).pipe(map(items => {
      return items.filter(item => item.sensorName !== 'Location');
    }));
  }
}
