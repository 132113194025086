<mat-tab-group class="full-height easy-tab-group" mat-align-tabs="start" [selectedIndex]="selectedIndex">
  <mat-tab label="User Profile">
    <section class="tab-content">
      <dp-user-profile></dp-user-profile>
    </section>
  </mat-tab>
  <mat-tab label="Notifications" *ngIf="!isUserPlus">
    <section class="tab-content">
      <dp-user-notification></dp-user-notification>
    </section>
  </mat-tab>
</mat-tab-group>
