import { Component, Input } from '@angular/core';

@Component({
  selector: 'dp-air-shipment-details-2',
  templateUrl: './air-shipment-details-2.component.html',
  styleUrls: ['./air-shipment-details-2.component.scss'],
})
export class AirShipmentDetails2Component {
  @Input() transportJourneys: any;
}
