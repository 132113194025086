import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicTemplateCompilerService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  compileTemplate(component: Type<any>, context: any): { element: HTMLElement; componentRef: ComponentRef<any> } {
    // Create a component reference from the component
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    // Attach the context to the component
    Object.assign(componentRef.instance, context);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Return both the element and the componentRef
    return { element: domElem, componentRef: componentRef };
  }
}
