// export function deepClone<T extends object>(source: T): T {
//   return JSON.parse(JSON.stringify(source));
// }

// https://gist.github.com/erikvullings/ada7af09925082cbb89f40ed962d475e

export const deepClone = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[]; /*%= */
    (target as any[]).forEach(v => {
      cp.push(v);
    });
    return cp.map((n: any) => deepClone<any>(n)) as any;
  }
  if (typeof target === 'object') {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepClone<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};
