import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  SimpleChanges,
  OnChanges,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { MatLegacyProgressSpinner as MatProgressSpinner } from '@angular/material/legacy-progress-spinner';

@Directive({
  selector: 'button[dpBusy]',
})
export class DpBusyButtonDirective implements OnInit, OnChanges {
  @Input('dpBusy') isBusy: boolean;

  private spinnerElement: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.createSpinner();
    this.renderer.appendChild(this.el.nativeElement, this.spinnerElement);
  }

  private createSpinner() {
    this.viewContainerRef.clear();

    const factory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);

    const spinner = this.viewContainerRef.createComponent(factory);

    spinner.instance.mode = 'indeterminate';
    spinner.instance.diameter = 15;

    this.spinnerElement = spinner.injector.get(MatProgressSpinner)._elementRef.nativeElement;
    this.renderer.addClass(this.spinnerElement, 'dpBusySpinner');
    this.renderer.setStyle(this.spinnerElement, 'display', 'none');
    // this.renderer.setStyle(this.spinnerElement, 'top', '3px');
    // this.renderer.setStyle(this.spinnerElement, 'left', '15px');
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.spinnerElement && changes.isBusy) {
      if (this.isBusy) {
        this.el.nativeElement.disabled = true;
        this.renderer.setStyle(this.spinnerElement, 'display', 'inline-block');
      } else {
        this.el.nativeElement.disabled = false;
        this.renderer.setStyle(this.spinnerElement, 'display', 'none');
      }
    }
  }
}
