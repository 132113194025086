import { Component, OnInit, Input } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

@Component({
  selector: 'dp-air-shipment-details',
  templateUrl: './air-shipment-details.component.html',
  styleUrls: ['./air-shipment-details.component.scss'],
})
export class AirShipmentDetailsComponent implements OnInit {
  transportJourneys;
  SvgMap = SvgMap;
  @Input() payload: any;

  constructor() {}

  ngOnInit(): void {
    if (this.payload) this.transportJourneys = this.payload.data?.transportJourneys || this.payload.transportJourneys;
    // console.log('transport: ', this.transportJourneys);
  }
}
