import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { KeyValue } from '@angular/common';
import decamelize from 'decamelize';
import moment from 'moment';
import { types } from '@dp/utilities/types';

@Component({
  selector: 'dp-filter-label',
  templateUrl: './filter-label.component.html',
  styleUrls: ['./filter-label.component.scss']
})
export class DpFilterLabelComponent implements OnInit {
  @Output() filterChange = new EventEmitter<object>();
  @Input() filters: object;

  constructor() { }

  ngOnInit() {
    ////console.log(this.filters);
  }

  shouldShow(filter) {
    if (types.get(filter.value) === types.date) {
      return true;
    } else if (types.get(filter.value) === types.boolean) {
      return !filter.value;
    } else if (types.get(filter.value) === types.string) {
      return filter.value !== 'All';
    } else if (types.get(filter.value) === types.object) {
      return !filter.value.hasOwnProperty('All');
    }
    return false;
  }

  displayText(filter) {
    if (filter.value instanceof Date) {
      return filter.value.toLocaleString().split(',')[0];
    } else if (moment.isMoment(filter.value)) {
      return filter.value.format('l');
    } else if (filter.value instanceof Object) {
      return Object.keys(filter.value)[0];
    } else {
      return filter.value;
    }
  }

  displayKey(filter: KeyValue<string, Date | string>): string {
    return decamelize(filter.key, ' ');
  }

  delete(filter) {
    //console.log(filter);
    if (filter.value instanceof Date || moment.isMoment(filter.value)) {
      // this.filters[filter.key] = null; 
      delete this.filters[filter.key];
    } else if (filter.value instanceof Object) {
      this.filters[filter.key] = { All: 0 };
      filter.value = { All: 0 };
    } else if (typeof filter.value === 'string') {
      this.filters[filter.key] = 'All';
      filter.value = 'All';
    } else {
      this.filters[filter.key] = null;
      filter.value = null;
    }
    this.filterChange.emit(this.filters);
  }
}
