<ng-container *ngIf="segmentDataTable">
  <h3 *ngIf="heading">{{heading}}</h3>
  <div style="overflow: auto;">
    <table mat-table [dataSource]="segmentDataTable.rows" class="full-width uds-table colored-header">
      <ng-container *ngFor="let col of segmentDataTable.header; let i = index ">
        <ng-container matColumnDef={{col}}>
          <th mat-header-cell *matHeaderCellDef style="padding: 0 5px; min-width: 60px;">
            {{segmentDataTable.header[i]}}
          </th>
          <td mat-cell *matCellDef="let element; let row = index;" style="padding: 0 5px; min-width: 60px;" [ngClass]="element[i].className">
            <label class="text-muted" *ngIf="element[i].time">{{element[i].time}}</label>
            {{element[i].data}}
            <dp-svg *ngIf="showPredictedEta(i, row)" [src]="context?.hubAta ? SvgMap.predictedEta : SvgMap.arrivingPredictedEta" w="26" placement="bottom"
                    class="predicted-icon" tooltip="{{getPredictedTitle()}} - {{context?.predictedEta}} {{getPredictiveDaysDifference(context?.hubEtaDaysDifference)}}
                ____________________________________
                Takes into account historical data, route forecast along with other key factors to provide an accurate final ocean port ETA. Currently in beta with limited coverage."></dp-svg>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="segmentDataTable.header" class="compactRow"></tr>
      <tr mat-row *matRowDef="let row; columns: segmentDataTable.header;" class="compactRow"></tr>
    </table>
  </div>
</ng-container>
