import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { dpAnimations } from '@dp/animations';
import { finalize } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { ActivatedRoute } from '@angular/router';
import { CrossFieldErrorMatcher } from '@dp/types';
import { PasswordStrengthValidator } from '@dp/validators/password-strength.validator';

@Component({
  selector: 'dp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: dpAnimations,
})
export class ForgotPasswordComponent implements OnInit {
  readonly siteKey = environment.recapchaKey;
  resetCode = '';

  isFormBusy = false;
  f: UntypedFormGroup;
  f2: UntypedFormGroup;
  errorMatcher = new CrossFieldErrorMatcher();

  showResult = false;
  showResult2 = false;
  resetEmail = '';

  @ViewChild('contentZone', { static: true }) contentZone?: ElementRef;
  progress: ProgressRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private progressService: ProgressService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.resetCode = params['resetCode'];
      if (!this.resetCode) {
        this.f = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          recaptcha: ['', Validators.required],
        });
        this.progress = this.progressService.showProgress(this.contentZone);
      } else {
        this.f2 = this.formBuilder.group(
          {
            password: ['', [Validators.required, PasswordStrengthValidator]],
            confirmPassword: ['', [Validators.required]],
          },
          {
            validator: this.passwordValidator,
          }
        );
      }
    });
  }

  submit() {
    this.isFormBusy = true;
    this.authService
      .resetPasswordEmail(this.f.value.email)
      .pipe(finalize(() => (this.isFormBusy = false)))
      .subscribe(
        (result) => {
          this.showResult = true;
        },
        (error) => {
          let msg = error.status === 404 ? "This account doesn't exist!" : "We can't do this right now. Please try again later.";
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = control.hasError('required')
      ? 'You must enter a value'
      : control.hasError('minlength')
      ? 'Password has to be at least 6 characters'
      : control.hasError('passwordStrength')
      ? control.errors['passwordStrength']
      : '';
    return msg;
  }
  submit2() {
    this.isFormBusy = true;
    this.authService
      .resetPassword({
        password: this.f2.value.password,
        passwordResetCode: this.resetCode,
      })
      .pipe(finalize(() => (this.isFormBusy = false)))
      .subscribe(
        (result) => {
          this.showResult2 = true;
          this.resetEmail = result['userEmail'];
        },
        (error) => {
          let msg = error.status === 404 ? "This account doesn't exist!" : "We can't do this right now. Please try again later.";
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  passwordValidator(form: UntypedFormGroup) {
    let result = {};
    if (!form.touched) {
      return result;
    }
    if (form.get('password').value !== form.get('confirmPassword').value) {
      result = {
        ...result,
        passwordsDoNotMatch: true,
      };
    }

    return result;
  }

  // captcha
  theme = 'Light';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    if (this.progress) {
      this.progressService.detach(this.progress);
    }
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }
  // captcha end
}
