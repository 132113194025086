import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DpEmailValidator {
  url: string;
  constructor(private http: HttpClient) {
    this.url = environment.rootUrl + environment.urls.isEmailAlreadyUsed;
  }
  searchUser(email) {
    // debounce
    return timer(1000).pipe(
      switchMap(() => {
        // Check if username is available
        return this.http.post<Object>(this.url, { email });
      })
    );
  }

  userValidator(ignoreEmail?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.searchUser(control.value).pipe(
        map((res) => {
          if (control.value === ignoreEmail) return {};

          // if username is already taken
          if (res['isEmailAlreadyUsed']) {
            // return error
            return { emailExists: true };
          }
        })
      );
    };
  }
}
