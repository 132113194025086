import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { tap, catchError, map, delay } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import events from './data/Events.json';
import accountNotifications from './data/AccountNotifications.json';
import systemNotifications from './data/SystemNotifications.json';
import {
  NotificationAccount,
  NotificationCount,
  NotificationCounter,
  NotificationEvent,
  NotificationSystem,
  NotificationTypes,
  SubscribeResult,
} from './notification.model';
import { DateAgoPipe } from '@dp/pipes/date-ago/date-ago.pipe';
import { Tag } from '@dp/types';
import { ProductSubscribed } from '@dp/types/dp-model';
import { IMasterProduct } from 'app/product-master/products.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private settingsUrl = environment.rootUrl + environment.urls.settings;
  private settingsNotificationUrl = this.settingsUrl + '/notification';
  private settingsNotificationSubscribeUrl = this.settingsNotificationUrl + '/subscribe';
  closeNotificationDlg = new Subject();
  closeNotificationDlg$ = this.closeNotificationDlg.asObservable();

  constructor(private http: HttpClient, private dateAgoPipe: DateAgoPipe) {}

  getShipmentNotifications() {
    return this.http.get<Object>(environment.rootUrl + 'notification/subscriptions');
  }
  updateShipmentNotifications(payload: Object) {
    return this.http.put<Object>(this.settingsNotificationUrl, payload);
  }
  getNotificationSettings() {
    return this.http.get<any>(this.settingsNotificationUrl).pipe(
      tap((_) => console.log('fetched notification setting data')),
      catchError(this.handleError<any>('Setting Notification API', null))
    );
  }

  updateNotificationSettings(body) {
    return this.http.put<Observable<string>>(this.settingsNotificationUrl, body).pipe(
      tap((_) => console.log('update notification setting')),
      catchError(this.handleError<any>('Setting Notification API', null))
    );
  }

  saveOtherNotification(payload): Observable<string> {
    const url = environment.rootUrl + 'settings/notification';
    return this.http.put<string>(url, payload);
  }

  /* subscribe / unsubscribe
      request body: { "subscribeAll": true | false }
      */
  subscribe(subscribeState: boolean) {
    return this.http.put<Observable<string>>(this.settingsNotificationSubscribeUrl, { subscribeAll: subscribeState }).pipe(
      tap((_) => console.log('update notification setting')),
      catchError(this.handleError<any>('Setting Notification API', null))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  isSubscribed(settings): boolean {
    let body = this.getRequestBody(settings);
    for (let key in body) {
      return body[key].forAllShipments;
    }
  }

  getRequestBody(settings) {
    let result = {};
    settings.forEach((category) => {
      if (Array.isArray(category.subscriptions) && category.subscriptions.length > 0) {
        for (let subscription of category.subscriptions) {
          for (let singleSetting of subscription.content) {
            const { code, isEnabled, forAllShipments } = singleSetting;
            result[code] = {
              isEnabled,
              forAllShipments,
            };
          }
        }
      }
    });
    return result;
  }

  getEvents(page: number, limit: number, type: NotificationTypes): Observable<NotificationEvent[]> {
    const url = environment.rootUrl + 'notification';
    //const page = Math.floor(offset / limit) + 1;
    const params = new HttpParams().set('type', type.toString().toLowerCase()).set('_page', page.toString()).set('_limit', limit.toString());

    return this.http.get<NotificationEvent[]>(url, { params }).pipe(
      map((events) => {
        return events.map((event) => {
          let timeLabel = this.dateAgoPipe.transform(event.notificationInsertionTime);
          return { ...event, timeLabel };
        });
      })
    );
  }

  getCounts(): Observable<NotificationCounter> {
    const url = environment.rootUrl + 'notification/count';
    return this.http.get<NotificationCounter>(url);
  }
  getAccountNotifications(page: number, limit: number): Observable<NotificationAccount[]> {
    // let params = new HttpParams().set('_page', page.toString()).set('_limit', limit.toString());
    let data = accountNotifications
      .map((item, index) => {
        return { id: index, ...item };
      })
      .slice(page, page + limit);

    return of(data as NotificationAccount[]).pipe(
      map((events) => {
        return events.map((event) => {
          let timeLabel = this.dateAgoPipe.transform(event.date);
          let read = event.read === undefined ? true : event.read;
          return { ...event, timeLabel, read };
        });
      })
    );
  }
  getSystemNotifications(page: number, limit: number): Observable<NotificationSystem[]> {
    // let params = new HttpParams().set('_page', page.toString()).set('_limit', limit.toString());
    let data = systemNotifications
      .map((item, index) => {
        return { id: index, ...item };
      })
      .slice(page, page + limit);

    return of(data as NotificationSystem[]).pipe(
      map((events) => {
        return events.map((event) => {
          let timeLabel = this.dateAgoPipe.transform(event.date);
          let read = event.read === undefined ? true : event.read;
          return { ...event, timeLabel, read };
        });
      })
    );
  }

  markRead(notificationIds: number[]) {
    const url = environment.rootUrl + 'notification';
    return this.http.post(url, { notificationIds });
  }

  markAllRead(type: NotificationTypes): Observable<void> {
    const url = environment.rootUrl + 'notification/mark/' + type.toString();
    return this.http.post<void>(url, {});
  }
  addTag(tag: Tag): Observable<SubscribeResult> {
    const url = environment.rootUrl + 'notification/subscribe/tag';

    return this.http.put<SubscribeResult>(url, {
      tagIds: [tag.id],
      subscribed: true,
    });
  }
  removeTag(tag: Tag): Observable<SubscribeResult> {
    const url = environment.rootUrl + 'notification/subscribe/tag';

    return this.http.put<SubscribeResult>(url, {
      tagIds: [tag.id],
      subscribed: false,
    });
  }

  addProduct(product: ProductSubscribed) {
    const url = environment.rootUrl + 'notification/subscribe/product';

    return this.http.put<SubscribeResult>(url, {
      productIds: [product.id],
      subscribed: true,
    });
  }
  removeProduct(product: ProductSubscribed): Observable<SubscribeResult> {
    const url = environment.rootUrl + 'notification/subscribe/product';

    return this.http.put<SubscribeResult>(url, {
      productIds: [product.id],
      subscribed: false,
    });
  }
}
