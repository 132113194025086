import { Component, Input, OnInit } from '@angular/core';
import { Utility } from '@dp/utilities';
import { SensorTypes } from '../../../../types/dp-model';

@Component({
  selector: 'dp-data-range',
  templateUrl: './data-range.component.html',
  styleUrls: ['./data-range.component.scss'],
})
export class DataRangeComponent implements OnInit {
  Utility = Utility;
  @Input() uom: string;
  @Input() sensorType: SensorTypes;
  @Input() minThresholdValue: string;
  @Input() maxThresholdValue: string;
  constructor() {}

  ngOnInit(): void {
    if (!this.uom) {
      this.uom = Utility.getDefaultUOM(this.sensorType);
    }
  }
}
