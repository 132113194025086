export const PageIdentifier = {
  WELCOME: { title: 'Welcome', onPageLoad: false },
  ADD_SHIPMENT: { title: 'Add Shipment', onPageLoad: true },
  DASHBOARD: { title: 'Dashboard', onPageLoad: true },
  OCEAN_SHIPMENT: { title: 'Ocean Shipments', onPageLoad: true },
  ROAD_SHIPMENT: { title: 'Road Shipments', onPageLoad: true },
  AIR_SHIPMENT: { title: 'Air Shipments', onPageLoad: true },
  ORDERS: { title: 'Orders', onPageLoad: true },
  SCHEDULES: { title: 'Schedules', onPageLoad: true },
  INBOUND_CONTAINER_PICKUP: { title: 'Inbound Container Pickup', onPageLoad: true },
  USER_SETTING: { title: 'User Setting', onPageLoad: true },
  COMPANY_SETTING: { title: 'Company Setting', onPageLoad: true },
  USERS: { title: 'Users', onPageLoad: true },
  INVOICE: { title: 'Invoice', onPageLoad: true },
  PRODUCT_MASTER: { title: 'Product Master', onPageLoad: true },
  SHARED_SHIPMENT: { title: 'Shared Shipment', onPageLoad: true },
};

export const RouteVsPageIdentifier = {
  '/welcome': PageIdentifier.WELCOME,
  '/add-shipments': PageIdentifier.ADD_SHIPMENT,
  '/dashboard': PageIdentifier.DASHBOARD,
  '/ocean-shipments': PageIdentifier.OCEAN_SHIPMENT,
  '/road-shipments': PageIdentifier.ROAD_SHIPMENT,
  '/air-shipments': PageIdentifier.AIR_SHIPMENT,
  '/orders': PageIdentifier.ORDERS,
  '/vessel-schedules': PageIdentifier.SCHEDULES,
  '/flight-schedules': PageIdentifier.SCHEDULES,
  '/container-pickup': PageIdentifier.INBOUND_CONTAINER_PICKUP,
  '/settings': PageIdentifier.USER_SETTING,
  '/company-settings': PageIdentifier.COMPANY_SETTING,
  '/users': PageIdentifier.USERS,
  '/invoices': PageIdentifier.INVOICE,
  '/product-master': PageIdentifier.PRODUCT_MASTER,
  '/_tracking': PageIdentifier.SHARED_SHIPMENT,
  '/_tracking_shipment': PageIdentifier.SHARED_SHIPMENT,
};
