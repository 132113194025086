import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { environment } from 'environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { StaticData } from './static-data.model';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  private staticDataUrl = environment.rootUrl + environment.urls.static_data_remote;
  dataKey = 'staticData';

  staticData: any = null;

  constructor(private http: HttpClient) {}

  getSettings(): Promise<any> {
    const promise = this.getStaticData()
      .toPromise()
      .then((result) => {
        //console.debug('getSettings!!!!');
        return result;
      });

    return promise;
  }

  getStaticDataDirect(): StaticData {
    return this.staticData;
  }

  //todo: make this private
  private getStaticData(): Observable<Object> {
    if (this.staticData !== null) {
      return of(this.staticData);
    } else {
      let staticData = (JSON.parse(localStorage.getItem(this.dataKey)) as Object) || {};
      if (staticData['staticData_date'] === environment.staticData_date) {
        this.staticData = staticData;
        return of(this.staticData);
      } else {
        return this.getStaticDataRemote();
      }
    }
  }

  private getStaticDataRemote(): Observable<Object> {
    return this.http.get<Object>(this.staticDataUrl).pipe(
      tap((data) => {
        data['currencies'] = Object.keys(data['currencies']).map((key) => {
          return { id: key, name: data['currencies'][key] };
        });
        data['staticData_date'] = environment.staticData_date;
        this.staticData = data;
        localStorage.setItem(this.dataKey, JSON.stringify(this.staticData));
        //sort data['user_questions'] by key
        //data['user_questions'] = Object.keys(data['user_questions']).reduce((a, c) => ((a[c] = data['user_questions'][c]), a), {});
        // this.cache.set(this.dataKey, data, 60);
      })
    );
  }
}
