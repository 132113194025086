import { Component, Input } from '@angular/core';
import { UDS_ICON_MAP } from 'app/image.constants';
import { RoadPayload } from 'app/shared/shared.model';

@Component({
  selector: 'dp-road-shipment-details',
  templateUrl: './road-shipment-details.component.html',
  styleUrls: ['./road-shipment-details.component.scss'],
})
export class RoadShipmentDetailsComponent {
  udsIconMap = UDS_ICON_MAP;
  @Input() payload: RoadPayload;
}
