import { Component, OnInit } from '@angular/core';
import { UsersService } from './users.service';
import { UIService } from 'app/shared';
import { LogService } from '@dp/services/log.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InviteUsersComponent } from '../invite-users/invite-users.component';
import { environment } from 'environments/environment';
import moment from 'moment';
import { User } from 'app/auth/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BasicSuccessResponse } from '@dp/types';

@Component({
  selector: 'dp-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

/**
 * deprecated
 */
export class UsersComponent implements OnInit {
  loadingUsers: boolean = false;
  loadingUser: boolean = false;

  // used to generate our list of users.
  allUsers: Array<User> = [];

  inviteUsersDialogRef: MatDialogRef<any> = null;
  removeUserConfirmDialogRef: MatDialogRef<any> = null;

  constructor(
    private usersService: UsersService,
    private log: LogService,
    private uiService: UIService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.getUsersInOrganization();
  }

  /**
   * called when you click the link of the users name, loads their profile page.
   * @param theUser
   */
  showUserProfile(theUser: User) {
    localStorage.setItem('currentlyEditingUser', JSON.stringify(theUser));
    this.router.navigateByUrl('/profile');
  }

  removeUserClicked(theUser: User, templateRef: any) {
    this.removeUserConfirmDialogRef = this.dialog.open(templateRef, {
      width: '100rem;',
      data: { user: theUser }
    });
  }

  // called when you confirm that you want to delete a user.
  removeYesClicked(user: User) {
    this.removeUserConfirmDialogRef.close();

    this.usersService.removeUser(user).subscribe(
      (data: BasicSuccessResponse) => {
        if (!data || !data.success) {
          this.log.logError('removeUser request failed ');
          this.uiService.showSnackbar("We can't remove users right now. Please try again later.", null, {
            duration: 8000,
            panelClass: 'warn'
          });
          return;
        }

        // remove the user from the visual list
        let index = this.allUsers.findIndex(x => x.id === user.id);
        if (index > -1) {
          this.allUsers.splice(index, 1);
        }

        this.uiService.showSnackbar('The user was removed successfully.', null, {
          duration: 8000,
          panelClass: 'accent'
        });
      },
      (error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error && error.error && error.error.errorCode && environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
          errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
        }

        this.log.logError('removeUser request failed error: ' + errorMessage);
        this.uiService.showSnackbar("We can't remove users right now. Please try again later.", null, {
          duration: 8000,
          panelClass: 'warn'
        });
      },
      () => {
        this.loadingUser = false;
      }
    );
  }

  removeNoClicked() {
    this.removeUserConfirmDialogRef.close();
  }

  inviteUsersClicked() {
    this.inviteUsersDialogRef = this.dialog.open(InviteUsersComponent, {
      width: '100rem;',
      data: { emails: [''] }
    });

    this.inviteUsersDialogRef.afterClosed().subscribe(result => {
      this.inviteUsers(result);
    });
  }

  getUser(userId: string) {
    this.loadingUser = true;
    this.usersService.getUser(userId).subscribe(
      (data: any) => {
        // check if the data isn't valid
        if (data === null || typeof data !== 'object') {
          // inform user there's a server problem
          this.log.logError('getUser; request failed.');
          this.uiService.showSnackbar('getUser request failed.', null, {
            duration: 8000,
            panelClass: 'warn'
          });
          return;
        }
      },
      (error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error && error.error && error.error.errorCode && environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
          errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
        }

        this.log.logError('getUser request failed error: ' + errorMessage);
        this.uiService.showSnackbar('getUser request failed. ', null, {
          duration: 8000,
          panelClass: 'warn'
        });
      },
      () => {
        this.loadingUser = false;
      }
    );
  }

  getUsersInOrganization() {
    this.loadingUsers = true;
    this.usersService.getUsersInOrganization().subscribe(
      (data: Array<User>) => {
        // check if the data isn't valid
        if (data === null || typeof data !== 'object') {
          // inform user there's a server problem
          this.log.logError('getUsersInOrganization; request failed.');
          this.uiService.showSnackbar('getUsersInOrganization request failed.', null, {
            duration: 8000,
            panelClass: 'warn'
          });
          return;
        }

        this.processUserData(data);

        this.allUsers = data;
      },
      (error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error && error.error && error.error.errorCode && environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
          errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
        }

        this.log.logError('getUsersInOrganization; request failed. ' + errorMessage);
        this.uiService.showSnackbar('getUsersInOrganization request failed. ', null, {
          duration: 8000,
          panelClass: 'warn'
        });
      },
      () => {
        this.loadingUsers = false;
      }
    );
  }

  processUserData(data: Array<User>) {
    for (let i = 0; i < data.length; i++) {
      let user: User = data[i];
      user.initials = '';

      // here we are generating the users initals that are used in the UI
      if (user.firstName && user.firstName.length > 0) {
        user.initials += user.firstName.charAt(0);
      }
      if (user.lastName && user.lastName.length > 0) {
        user.initials += user.lastName.charAt(0);
      }

      if (user.invitedAt) {
        let oneDayAfterMilliseconds = moment(user.invitedAt)
          .add(1, 'day')
          .toDate()
          .getTime();
        let nowMilliseconds = moment()
          .toDate()
          .getTime();

        ////console.log("user: " + user.userEmail + " timeDiff: " + (oneDayAfterMilliseconds - nowMilliseconds) / 3600000);
        if (oneDayAfterMilliseconds < nowMilliseconds) {
          user.moreThanDayOld = true;
        }
      }
    }
  }

  logOutput() {
    //console.log(data);
  }

  resendClicked(theUser: User) {
    this.inviteUsers([theUser.userEmail]);

    theUser.moreThanDayOld = false;
  }

  /**
   *  called when the user closes the invite users prompt, whether they entered emails or canceled
   */
  inviteUsers(userEmails: Array<string>) {
    // this will happen if the user cancels
    if (userEmails == null) {
      return;
    }

    // this.usersService.inviteUsers(userEmails).subscribe(
    //   (data: BasicSuccessResponse) => {
    //     //console.log("inviteUsers; callback data: " + JSON.stringify(data));

    //     // check if the data isn't valid
    //     if (!data || !data.success) {
    //       // inform user there's a server problem
    //       this.log.logError('inviteUsers; request failed.');
    //       this.uiService.showSnackbar("We can't invite users right now. Please try again later.", null, {
    //         duration: environment.snackBarDuration.error,
    //         panelClass: 'warn'
    //       });
    //       return;
    //     }

    //     this.uiService.showSnackbar('The invites were just sent!', null, {
    //       duration: environment.snackBarDuration.success,
    //       panelClass: 'accent'
    //     });
    //   },
    //   (error: HttpErrorResponse) => {
    //     let errorMessage = '';
    //     if (error && error.error && error.error.errorCode && environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
    //       errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
    //     }

    //     this.log.logError('inviteUsers; request failed. ' + errorMessage);
    //     this.uiService.showSnackbar("We can't invite users right now. Please try again later.", null, {
    //       duration: environment.snackBarDuration.error,
    //       panelClass: 'warn'
    //     });
    //   },
    //   () => { }
    // );
  }
}
