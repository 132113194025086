<mat-form-field appearance="{{ appearance }}" class="d-block">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select #selectElem [panelClass]="'dp ' + panelClass" [formControl]="formControl" [multiple]="multiple"
    [(ngModel)]="selectedValue" (selectionChange)="onSelectionChange($event)"
    (openedChange)="$event ? '' : searchInput.value = ''; filterItem('');">
    <div class="box-search">
      <!-- <mat-checkbox *ngIf="multiple" color="primary" class="box-select-all" [(ngModel)]="selectAllChecked"
        (change)="toggleSelectAll($event)"></mat-checkbox> -->
      <input #searchInput type="text" [ngClass]="{ 'pl-1': !multiple }" (input)="filterItem(searchInput.value)"
        (keydown)="$event.stopPropagation()" placeholder="Search..." />
      <div class="box-search-icon" (click)="filterItem(''); searchInput.value = ''">
        <button mat-icon-button class="search-button" [disabled]="!searchInput.value">
          <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
        </button>
      </div>
    </div>

    <mat-select-trigger>
      {{ onDisplayString() }}
    </mat-select-trigger>

    <div class="body" style="overflow-y: auto; width: 100%;height: 164px;">
      <mat-option *ngFor="let option of options; trackBy: trackByFn" [disabled]="option.disabled" [value]="option[value]"
        [style.display]="hideOption(option) ? 'none' : 'flex'">{{ option[display] }}
      </mat-option>
    </div>

    <div class="clear-all-section">
      <button mat-button (click)="clearSelectAll()" [disabled]="selectedValue.length === 0">Clear</button>
    </div>
  </mat-select>

</mat-form-field>