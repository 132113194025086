import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProgressService, SvgMap, UIService } from 'app/shared';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { dpAnimations } from '@dp/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanySettingService } from '../../settings/organization/company-setting/company-setting.service';
import { GenericHttpResponse } from '../../shared/shared.model';

@Component({
  selector: 'dp-user-invite-action',
  templateUrl: './user-invite-action.component.html',
  styleUrls: ['./user-invite-action.component.scss'],
  animations: dpAnimations,
})
export class UserInviteActionComponent implements OnInit {
  action: string;
  actionCode: string;
  hasResult = false;
  error: HttpErrorResponse = null;
  actionMap = { accept: 'accepted', decline: 'declined' };
  SvgMap = SvgMap;

  @ViewChild('contentZone') contentZone?: ElementRef;

  constructor(
    private progressService: ProgressService,
    private route: ActivatedRoute,
    private companySettingService: CompanySettingService,
    private uiService: UIService
  ) {}

  ngOnInit(): void {
    this.action = this.route.snapshot.paramMap.get('action');
    this.actionCode = this.route.snapshot.paramMap.get('actionCode');

    const progress = this.progressService.showProgress(this.contentZone);
    this.companySettingService
      .updatePartnerApprovalStatus(this.action, this.actionCode)
      .pipe(
        finalize(() => {
          this.progressService.detach(progress);
        })
      )
      .subscribe(
        (response: GenericHttpResponse) => {
          if (response?.message !== 'Invitation Expired') {
            this.hasResult = true;
          } else {
            this.error = new HttpErrorResponse({
              status: 402,
              statusText: 'Invitation is expired.',
            });
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  getTitle() {
    return this.action === 'accept' ? 'Accept' : 'Decline';
  }
  getError() {
    return this.error?.status === 500
      ? 'This action is not valid anymore!'
      : this.error.status === 402
      ? this.error.statusText
      : "We can't process this right now. Please try again later.";
  }
}
