import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  template: `
    <ng-container *ngFor="let message of data">
      <div>{{ message }}</div>
    </ng-container>
  `,
})
export class MultiLinesSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<MultiLinesSnackbarComponent>, 
    @Inject(MAT_SNACK_BAR_DATA) public data: string[]) {}
}
