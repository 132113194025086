<div class="intro-wrapper inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div class="intro-right-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" #contentZone>
    <div class="intro-right">

      <div *ngIf="!resetCode">
        <form [formGroup]="f" (ngSubmit)="submit()" *ngIf="!showResult">
          <div class="title">Forgot your password?</div>

          <br />
          <p class="dp-body-2">No need to worry. We are here to help!</p>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Your work email</mat-label>
              <input type="email" matInput formControlName="email" name="email" email required [ngModelOptions]="{standalone: true}">
              <mat-error *ngIf="f.controls['email'].invalid">Field must not be empty.</mat-error>
            </mat-form-field>
          </div>
          <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'" formControlName="recaptcha"></ngx-recaptcha2>

          <button type="submit" [dpBusy]="isFormBusy" mat-raised-button [disabled]="f.invalid || !f.dirty" class="submit-button primary-800">
            Send Me Instructions
          </button>
        </form>
        <div *ngIf="showResult">
          <div class="title">Instruction sent successfully!</div>
          <br />
          <p class="dp-body-2">Instructions for resetting your password have
            been sent to: </p>
          <p><strong>{{this.f.value.email}}</strong></p>
          <br />
          <p>You’ll receive this email within 3 minutes. Be sure to check your spam folder, too.</p>
          <br />
          <a class="link" [routerLink]="'/login'">Go to Login</a>
        </div>
      </div>
      <div *ngIf="resetCode">
        <form [formGroup]="f2" (ngSubmit)="submit2()" [hidden]="showResult2">
          <div class="title mb-40">Reset your password</div>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>New Password</mat-label>
            <input matInput type="password" formControlName="password" [errorStateMatcher]="errorMatcher" required>
            <mat-error *ngIf="f2.controls['password'].invalid">
              {{ getErrorMessage(f2.controls['password']) }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Confirm New Password</mat-label>
            <input matInput type="password" formControlName="confirmPassword" [errorStateMatcher]="errorMatcher" required>
            <mat-error *ngIf="f2.controls['confirmPassword'].invalid">
              {{ getErrorMessage(f2.controls['confirmPassword']) }}
            </mat-error>
            <mat-error *ngIf="f2.hasError('passwordsDoNotMatch')">
              Passwords do not match!
            </mat-error>

          </mat-form-field>
          <button type="submit" mat-raised-button [disabled]="f2.invalid" class="submit-button primary-800" [dpBusy]="isBusy">
            Save</button>

        </form>
        <div *ngIf="showResult2">
          <div class="title mb-40">Reset password successful!</div>
          <p>We have reset your password! Please use
            your new password to login.</p>
          <p>Go to <a class="link" [routerLink]="['/login']" [queryParams]="{email: resetEmail}">login.</a></p>
        </div>
      </div>
    </div>

  </div>
</div>
