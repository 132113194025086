import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Tag } from '@dp/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'app/navigation/header/notification/notification.service';
import { environment } from 'environments/environment';
import { UIService } from '../../../shared/ui.service';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProgressService } from 'app/shared';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { SharedApiService } from '../../../shared/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'dp-tagged-shipment',
  templateUrl: './tagged-shipment.component.html',
  styleUrls: ['./tagged-shipment.component.scss'],
})
export class TaggedShipmentComponent implements AfterViewInit {
  SvgMap = SvgMap;
  tags: Tag[] = [];
  tagToAdd: Tag = null;
  noTag = false;
  isBusy = false;
  addingMode = false;
  initializing = true;
  @Input() tagsSubscribed: Tag[];
  @Output() tagsSubscribedChange = new EventEmitter<Tag[]>();
  @ViewChild('contentZone') contentZone?: ElementRef;
  public tagFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  public filteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  @ViewChild('tagList') tagList: MatSelect;

  constructor(
    private sharedService: SharedApiService,
    private notificationService: NotificationService,
    private uiService: UIService,
    private progressService: ProgressService
  ) {}

  ngAfterViewInit(): void {
    let getTags$ = this.sharedService.getTagSuggestions();
    // this.isLoading = true;
    let progress = this.progressService.showProgress(this.contentZone);

    forkJoin([getTags$])
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          // this.isLoading = false;
          this.progressService.detach(progress);
          this.initializing = false;
        })
      )
      .subscribe(
        ([tags]) => {
          this.tags = tags;
          if (!tags || !tags.length) {
            this.noTag = true;
          }
          this.tagListSetup();
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
      this.tagFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterTags();
      });
  }
  protected filterTags() {
    // get the search keyword
    const search = this.tagFilterCtrl.value;
    const filteredTags = search
      ? this.tags.filter((tag) => tag.name?.search(new RegExp(search, 'i')) > -1)
      : this.tags;
    this.filteredTags.next(filteredTags);
  }

  tagListSetup() {
    this.tags = this.tags.filter((tag) => {
      return !this.tagsSubscribed?.find((item) => item.id === tag.id);
    });
  }

  setFirstItemSelected() {
    this.filteredTags.next(this.tags);
  }

  subscribedAllTags(): boolean {
    return this.tagsSubscribed && this.tagsSubscribed.length > 0 && (!this.tags || this.tags.length === 0);
  }

  removeTag(tag: Tag) {
    let progress = this.progressService.showProgress(this.contentZone);
    this.notificationService
      .removeTag(tag)
      .pipe(
        finalize(() => {
          this.progressService.detach(progress);
        })
      )
      .subscribe(
        (res) => {
          this.tagsSubscribed = this.tagsSubscribed.filter((item) => item.id !== tag.id);
          this.tagsSubscribedChange.emit(this.tagsSubscribed);
          if (!this.tags) this.tags = [];
          this.tags.push(tag);
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }
  addTag() {
    this.isBusy = true;
    this.notificationService
      .addTag(this.tagToAdd)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (res) => {
          if (!this.tagsSubscribed) this.tagsSubscribed = [];

          this.tagsSubscribed.push(this.tagToAdd);
          this.tagsSubscribedChange.emit(this.tagsSubscribed);
          this.tags = this.tags.filter((item) => item.id !== this.tagToAdd.id);
          this.setFirstItemSelected();
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  toggleAddMode() {
    this.addingMode = !this.addingMode;
  }

  onAddTag() {
    this.toggleAddMode();
    this.tagList.open();
  }

  onTagSelection() {
    this.addTag();
    this.toggleAddMode();
  }
}
