<span type="button" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<h1 mat-dialog-title>Add a new partner</h1>

<div mat-dialog-content>

  <!-- Not needed in current feature -->
  <!--<form>
    <p>Does your partner already use Cargoes Flow?</p>
    <mat-form-field class="filter-group full-width pb-0" appearance="fill">
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="No">No. Invite a partner to use Cargoes Flow.</mat-option>
        <mat-option value="Yes">Yes. My partner is already using Cargoes Flow</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="box p-12" *ngIf="getPartners() | async as partnersList">
      <ng-container *ngIf="partnersList">
        <dp-mat-table [headers]="['Company name', 'Company ID', 'Status']" [rows]="partnersList"></dp-mat-table>
      </ng-container>

    </div>
  </form>-->
  <form class="easy-form">
    <mat-form-field appearance="outline" class="w-400">
      <mat-label>Company Id</mat-label>
      <input matInput [(ngModel)]="companyId" value={{companyId}} />
    </mat-form-field>
  </form>

  <div class="my-12 text-right">
    <button mat-flat-button class="easy-button secondary medium mr-20" (click)="onDismiss()" type="button">Cancel</button>
    <button mat-flat-button class="easy-button medium" type="button" (click)="onConfirm()" [dpBusy]="confirmBtnBusy" [disabled]="!(companyId && companyId.length)">Send Request</button>
  </div>
</div>
