import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dp-mini-fab',
  templateUrl: './mini-fab.component.html',
  styleUrls: ['./mini-fab.component.scss'],
})
export class MiniFabComponent implements OnInit {
  @Input() total: number;
  @Input() bgColor: string = 'uds-primary-ocean';

  constructor() {}

  ngOnInit(): void {}
}
