import { Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Port } from '@dp/services/static-data.model';
import { StaticDataService } from '@dp/services/static-data.service';
import { Tag } from '@dp/types';
import { SelectAutocompleteComponent } from '@dp/components/select-autocomplete/select-autocomplete.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { dpAnimations } from '@dp/animations';

@Component({
  selector: 'dp-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
  host: { class: 'uds-page-host' },
  animations: dpAnimations,
})
export class PlaygroundComponent implements OnInit {
  SvgMap = SvgMap;
  //auto complete
  myControl = new UntypedFormControl();
  options1: Port[];
  filteredOptions: Observable<Port[]>;
  environment = environment;
  localTime: string;

  ngOnInit() {
    const local_tz = moment.tz.guess();
    this.localTime = moment(environment.timeStamp).tz(local_tz).format('YYYY-MM-DD HH:mm');
    const staticData = this.staticDataService.getStaticDataDirect();
    console.log('static data: ', staticData);
    this.options1 = staticData.ports;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string | Port): Port[] {
    let result = [];
    if (!value) return result;
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      // Port type
      filterValue = value.port.toLowerCase();
    }

    this.options1.forEach((item, i) => {
      if (result.length >= 20) return result;

      if (item.port.toLowerCase().includes(filterValue)) {
        result.push(item);
      }
    });
    return result;
    // return this.options.filter((item) => item.port.toLowerCase().includes(filterValue));
  }

  displayFn(item) {
    if (!item) return '';

    // reverse look up by location_code
    return `${item.port} , ${item.country_code} (${item.location_code})`;
  }

  tags1 = [];
  tags2: Tag[] = [{ name: 'tag1' }, { name: 'tag2' }];
  tagSuggestions1 = [{ name: 'tag1' }, { name: 'tag2' }, { name: 'tag3' }, { name: 'foo' }, { name: 'bar' }];
  tagSuggestions2 = [{ name: 'tag1' }, { name: 'tag2' }, { name: 'tag3' }, { name: 'foo' }, { name: 'bar' }];

  currencyCodes = [
    { name: 'USA', code: 'USD' },
    { name: 'Canada', code: 'CAD' },
    { name: 'India', code: 'INR' },
    { name: 'Europe', code: 'EUR' },
    { name: 'UAE (dubai)', code: 'AED' },
    { name: 'Japan', code: 'JPY' },
    { name: 'China', code: 'CNY' },
    { name: 'United Kingdom', code: 'GBP' },
  ];

  constructor(private staticDataService: StaticDataService) {}
  optionSelectedChange($event: MatAutocompleteSelectedEvent) {
    console.log('$event: ', $event);
  }

  /// mat-select-autocomplete

  // @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;
  // selectOptions = [
  //   {
  //     display: 'Origin',
  //     value: 'Origin',
  //   },
  //   {
  //     display: 'In POO',
  //     value: 'In POO',
  //   },
  //   {
  //     display: 'In Transit',
  //     value: 'In Transit',
  //   },
  //   {
  //     display: 'In POD',
  //     value: 'In POD',
  //   },
  //   {
  //     display: 'Destination',
  //     value: 'Destination',
  //   },
  // ];

  @ViewChild(SelectAutocompleteComponent)
  multiSelect: SelectAutocompleteComponent;

  options = [
    {
      display: 'One',
      value: '1',
    },
    {
      display: 'Two',
      value: '2',
    },
    {
      display: 'Three',
      value: '3',
    },
    {
      display: 'Four',
      value: '4',
    },
    {
      display: 'Five',
      value: '5',
    },
    {
      display: 'Six',
      value: '6',
    },
  ];
  selectedOptions = ['1'];

  selected = this.selectedOptions;
  showError = false;
  errorMessage = '';

  onToggleDropdown() {
    this.multiSelect.toggleDropdown();
  }

  getSelectedOptions(selected) {
    console.log('selected: ', selected);
    this.selected = selected;
  }

  onResetSelection() {
    this.selectedOptions = [];
  }
}
