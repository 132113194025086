import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ProgressRef, ProgressService } from 'app/shared';
import { NotificationTypes } from '../notification.model';
import { NotificationService } from '../notification.service';
import { Subscription } from 'rxjs';

const batchSize = 20;
@Component({
  selector: 'dp-notification-dlg',
  templateUrl: './notification-dlg.component.html',
  styleUrls: ['./notification-dlg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDlgComponent implements OnInit {
  NotificationTypes = NotificationTypes;
  countEvents = false;
  countExceptions = false;
  countSystems = false;
  tabIndex = -1;
  sub: Subscription = null;
  progress: ProgressRef;
  @ViewChild('contentZone') contentZone: ElementRef;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @Input() set opened(open: boolean) {
    // this._opened = open;
    if (open) {
      this.tabIndex = 0;
      if (this.tabGroup) this.tabGroup.selectedIndex = 0;
      this.init();
    } else {
      this.tabIndex = -1;
    }
  }
  @Output() dismiss = new EventEmitter<void>();
  constructor(private service: NotificationService, private progressService: ProgressService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    //this.setup();
    this.service.closeNotificationDlg$.subscribe((res) => {
      this.dismissIt();
    });
  }

  init(): void {
    if (this.sub) this.sub.unsubscribe();

    this.sub = this.service.getCounts().subscribe((counts) => {
      this.countEvents = !!counts.event;
      this.countExceptions = !!counts.exception;
      this.countSystems = !!counts.system;
      // console.log({ countEvent: this.countEvents, countExceptions: this.countExceptions });
      this.refresh();
    });
  }

  allRead(type: NotificationTypes) {
    if (type === NotificationTypes.EVENT) {
      this.countEvents = false;
    } else if (type === NotificationTypes.EXCEPTION) {
      this.countExceptions = false;
    } else if (type === NotificationTypes.SYSTEM) {
      this.countSystems = false;
    }
  }
  partialRead(type: NotificationTypes) {
    this.init();
  }

  dismissIt() {
    this.dismiss.emit();
  }

  refresh() {
    setTimeout(() => {
      this.changeDetector.detectChanges();
    });
  }

  selectedTabChange(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.init();
  }
}
