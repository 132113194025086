import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utility } from '@dp/utilities';
import { BoardConfigService } from 'app/board/board-config.service';
import { ACCESS_TYPE } from 'app/user-access.constants';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ACCOUNT_TYPES, User, UserPermissionInfo } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private permissibleData = new BehaviorSubject(Utility.getItemDecrypted(environment.storage.permissible));
  permissibleData$ = this.permissibleData.asObservable();
  tooltipText = 'Limited functionality, contact Admin for access.';

  constructor(private http: HttpClient, private boardConfigService: BoardConfigService) {}

  get currentPermissibleData() {
    return this.permissibleData.getValue();
  }

  updatePermissibleData(data) {
    Utility.setItemEncrypted(environment.storage.permissible, data);
    this.permissibleData.next(data);
  }

  getAccessType(key: string, isShared) {
    const org = isShared ? 'sharedPartnerOrg' : 'currentOrg';
    if (isShared && !this.currentPermissibleData?.sharedPartnerOrg) {
      return this.currentPermissibleData?.currentOrg?.[key];
    }
    return this.currentPermissibleData && this.currentPermissibleData[org] && this.currentPermissibleData[org][key];
  }

  anyOrgHasAccess(key: string) {
    const { currentOrg, sharedPartnerOrg } = this.currentPermissibleData || {};
    if (!currentOrg && !sharedPartnerOrg) {
      return true;
    }
    return (currentOrg && currentOrg[key] !== ACCESS_TYPE.HIDE) || (sharedPartnerOrg && sharedPartnerOrg[key] !== ACCESS_TYPE.HIDE);
  }

  hasAccess(key: string, isShared?, isAdmin?) {
    const accessType = this.getAccessType(key, isShared);
    if (accessType === undefined && isAdmin === false) {
      return false;
    } else if (accessType === undefined) {
      return true;
    }
    return accessType !== ACCESS_TYPE.VIEW && accessType !== ACCESS_TYPE.HIDE;
  }

  hasViewoOnlyAccess(key: string, isShared?) {
    return this.getAccessType(key, isShared) === ACCESS_TYPE.VIEW;
  }

  hasNoAccess(key: string, isShared?) {
    return this.getAccessType(key, isShared) === ACCESS_TYPE.HIDE;
  }

  hasSomeAccess(keys: string[], isShared?) {
    for (let i = 0; i < keys.length; i++) {
      if (this.getAccessType(keys[i], isShared) !== ACCESS_TYPE.HIDE) {
        return true;
      }
    }
    return false;
  }

  accessClass(key: string, isShared?) {
    const accessType = this.getAccessType(key, isShared);
    switch (accessType) {
      case ACCESS_TYPE.VIEW:
        return 'access-viewonly';
      case ACCESS_TYPE.HIDE:
        return 'access-denied';
      default:
        return '';
    }
  }

  fetchDataOnRefresh(userCode): Promise<UserPermissionInfo> {
    const promise = this.http
      .get(
        environment.rootUrl + environment.urls.users_in_organization + `/${userCode}?onlyInclude=["permissions","dashboard_configurations"]`
      )
      .toPromise()
      .then((result) => {
        if (result) {
          result['userPermissions'] && this.updatePermissibleData(result['userPermissions']);
          result['dashboardConfigurations'] && this.boardConfigService.updateBoardConfigData(result['dashboardConfigurations'], true);
        }
        return result;
      })
      .catch((err) => {
        localStorage.removeItem(environment.storage.currentUser);
        return null;
      });

    return promise;
  }

  getTooltip(key: string, isShared?) {
    if (this.hasViewoOnlyAccess(key, isShared)) {
      return this.tooltipText;
    }
    return null;
  }

  getTooltipForViewOnly(ele) {
    const { className } = ele || {};
    if (className && className.includes('access-viewonly')) {
      return this.tooltipText;
    }
    return null;
  }

  hasNewFilterDesignAccess(user: User): boolean {
    return user?.accountType !== ACCOUNT_TYPES.PLUS;
  }

  getOrgIdForQuickTrack(): number {
    return 'prod' === environment.environmentType
      ? 100005165
      : 'staging' === environment.environmentType
        ? 100005165  // org name - Ernst & Young
        : 0;
  }

  isVimaFoodsOrgId(user: User): boolean {
    return (environment.environmentType === 'prod' || environment.environmentType === 'staging')
    ? (user.organizationId === 100007366 || user.organizationId === 100000077)
    : user.organizationId === 100000000;
  }

  isIntermodalJourneyEnabled(user): boolean {
    return (environment.environmentType === 'prod' || environment.environmentType === 'staging')
    ? (user.organizationId === 100001394 || user.organizationId === 100000077)
    : user.organizationId === 100000000;
  }
}
