import { ITheme } from './theme.interface';

export const flowTheme: ITheme = {
  name: 'flow',
  properties: {
    '--primary-main': '#007ba8',
    '--primary-light': '#e3f2fd',
    '--primary-dark': '#076284',
    '--primary-contrastText': '#ffffff',
  },
};

export const eflTheme: ITheme = {
  name: 'efl',
  properties: {
    '--primary-main': '#a86b00',
    '--primary-light': '#fff6e5',
    '--primary-dark': '#835507',
    '--primary-contrastText': '#ffffff',
  },
};
