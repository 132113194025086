import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProgressRef, ProgressService } from 'app/shared';
import { finalize } from 'rxjs/operators';
import { NotificationEvent, NotificationTypes } from '../notification.model';
import { NotificationService } from '../notification.service';
import { UIService } from 'app/shared/ui.service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

@Component({
  selector: 'dp-event-tab',
  templateUrl: './event-tab.component.html',
  styleUrls: ['./event-tab.component.scss'],
})
export class EventTabComponent implements OnInit {
  SvgMap = SvgMap;
  throttle = 0;
  limit = 50;
  distance = 2;
  scrollThrottle = 50;
  loading = true;
  page: number;
  progress: ProgressRef;
  notificationEvents: NotificationEvent[] = [];

  @ViewChild('contentZone') contentZone: ElementRef;

  @Input() set opened(open: boolean) {
    if (open) {
      setTimeout(() => {
        this.init();
      });
    }
  }
  @Input() hasUnread: boolean;
  @Input() type: NotificationTypes;
  @Output() allRead = new EventEmitter<void>();
  @Output() partialRead = new EventEmitter<void>();

  constructor(
    private service: NotificationService,
    private progressService: ProgressService,
    private uiService: UIService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // if (this.type === NotificationTypes.exception) {
    //   console.log('xxxx, OnInit')
    //   this.init();
    // }
  }

  init(): void {
    let progress = this.progressService.showProgress(this.contentZone);
    this.loading = true;
    this.contentZone.nativeElement.scrollTop = 0;
    this.page = 1;
    this.service
      .getEvents(this.page, this.limit, this.type)
      .pipe(
        // delay(10000),
        finalize(() => {
          this.progressService.detach(progress);
          this.loading = false;
        })
      )
      .subscribe(
        (events) => {
          this.notificationEvents = events;
          this.refresh();
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  onScroll(): void {
    this.loading = true;
    this.service
      .getEvents(++this.page, this.limit, this.type)
      .pipe()
      .subscribe((items) => {
        // this.changeDetector.detach();
        this.notificationEvents.push(...items);
        this.loading = false;
        this.refresh();
        // this.appRef.tick();
        // console.log('xxx, count', this.notificationEvents.length, 'details', this.notificationEvents);
        // setTimeout(() => {
        //   this.changeDetector.detectChanges();
        // });
      });
  }
  onClick(event: NotificationEvent) {
    if (!event.isRead) {
      this.service.markRead(event.notificationIds).subscribe(
        () => {
          event.isRead = true;
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
    }
  }

  refresh() {
    setTimeout(() => {
      this.changeDetector.detectChanges();
    });
  }

  markAllRead() {
    let progress = this.progressService.showProgress(this.contentZone);
    this.service
      .markAllRead(this.type)
      .pipe(finalize(() => this.progressService.detach(progress)))
      .subscribe(
        () => {
          this.allRead.emit();
          this.notificationEvents.map((event) => (event.isRead = true));
          this.refresh();
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
    // this.notificationEvents.map(event => (event.isRead = true));
  }

  isRead(event: NotificationEvent) {
    event.isRead = true;
    this.partialRead.emit();
    this.refresh();
  }
}
