import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainersTracking, Pagination } from '@dp/types/dp-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IRequestToken, ITokenResult, ITrackingResponse, SharedTokenResponse } from './tracking.model';
import { IDocument } from '../shipments2/shipments2.model';
import { EntityType } from '@dp/types';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private trackingEventBaseUrl = environment.rootUrl + environment.urls.trackingEventService.baseUrl;
  private generateTokenUrl = environment.rootUrl + 'te/generateSharingUrlToken';
  private consumeTokenUrl = environment.rootUrl + 'new_container/containers/share'; //deprecated for old shipment page
  private consumeTruckTokenUrl = environment.rootUrl + 'shipmentV2/shipments/share';

  private consumeTokenUrlShipments = environment.rootUrl + 'shipmentV2/shipments/share';
  private consumeTokenDocumentsUrl = environment.rootUrl + 'shipmentGroup/sharedDocuments';
  private publicTrackingLogUrl = environment.rootUrl + 'public_tracking/logo';

  constructor(private http: HttpClient) {}

  // generate token
  // construct shared token uri, i.e host/baseUri/tracking/{token}
  // copy to clipboard
  // @return true for success
  generateSharingToken(requestToken: IRequestToken): Observable<string> {
    console.log('TrackingService -> requestToken', requestToken);

    //only for the old /shipments page
    let path = '/_tracking';

    if (requestToken.entityType === EntityType.TRACKING_SHIPMENT) {
      //ocean page, truck page
      path = '/_tracking_shipment';
    }
    return this.http.post(this.generateTokenUrl, requestToken).pipe(
      map((payload: ITokenResult) => {
        if (payload.token) {
          return location.origin + path + '?token=' + payload.token;
        }
        return null;
      })
    );
  }

  consumeToken(tokenId: string, containerId?: number) {
    let params = {
      params: {
        token: tokenId,
      },
    };
    if (containerId) {
      params.params['containerId'] = containerId;
    }
    return this.http.get(this.consumeTokenUrl, params);
  }

  consumeTokenShipments(tokenId: string, shipmentId?: number, pagination?: Pagination): Observable<ITrackingResponse> {
    const params = {
      token: tokenId,
    };
    if (shipmentId) {
      params['shipmentId'] = shipmentId;
    }
    if (pagination) {
      params['_page'] = pagination.currentPage;
      params['_limit'] = pagination.pageSize;
    }
    return this.http.get<any>(this.consumeTokenUrlShipments, { observe: 'response', params }).pipe(
      map((res) => {
        const result = res.body;
        if (Array.isArray(result['sharedInformation'])) {
          result['sharedInformation'].map((item) => (item.status = item.status === 'NEW' ? 'PENDING' : item.status));
        } else {
          result['sharedInformation']['status'] =
            result['sharedInformation']['status'] === 'NEW' ? 'PENDING' : result['sharedInformation']['status'];
        }
        return {
          totalCount: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
          data: result,
        };
      })
    );
  }

  consumeTokenDocuments(tokenId: string, pagination?: Pagination): Observable<ITrackingResponse> {
    let params = {
      token: tokenId,
      shipmentType: 'INTERMODAL_SHIPMENT',
    };
    if (pagination) {
      params['_page'] = pagination.currentPage;
      params['_limit'] = pagination.pageSize;
    }
    return this.http.get<any>(this.consumeTokenDocumentsUrl, { observe: 'response', params }).pipe(
      map((res) => ({
        totalCount: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
        documentData: res.body,
      }))
    );;
  }
  addLane(payload: {}): Observable<Object> {
    return this.http.put<Object>(environment.rootUrl + 'notification/subscribe/shipping-lane', payload);
  }

  deleteLane(id: number): Observable<any> {
    const payload = {
      subscribed: false,
      notificationSubscriptionIds: [id],
    };
    return this.http.put(environment.rootUrl + 'notification/subscribe/shipping-lane', payload);
  }

  // Consume token for Truck page
  // if shipmentId is provided, return a single sharedInformation
  // if shipmentId is not provided, return sharedInformation = []
  consumeTruckToken(tokenId: string, shipmentId?: string): Observable<SharedTokenResponse> {
    let params = {
      params: {
        token: tokenId,
      },
    };

    if (shipmentId) {
      params.params['shipmentId'] = shipmentId;
    }
    // console.log('TrackingService -> consumeToken with params: ', params);
    return this.http.get<SharedTokenResponse>(this.consumeTruckTokenUrl, params);
  }

  /**
   * Get container tracking events by container number
   * @param containerNumber
   */
  getContainerTrackingEvents(containerNumber: string): Observable<ContainersTracking> {
    const url = this.trackingEventBaseUrl + environment.urls.trackingEventService.tracking + '?containerNumber=';
    return this.http.get<ContainersTracking>(url + containerNumber);
  }

  getLandPorts(): Observable<string[]> {
    return this.http.get<string[]>(environment.rootUrl + 'shipmentV2/land_ports');
  }

  getOrgLogo(orgId: number): string {
    if (environment.environmentType === 'local' || environment.environmentType === 'qa') {
      let idLookup = {
        100000010: 100000076,
        100000011: 100000986,
        100000012: 100001021,
      };
      orgId = idLookup[orgId] || orgId;
    }
    let orgIds = environment.orgLogosOnTracking;
    return orgIds.includes(orgId) ? 'assets/images/logos/' + orgId + '.png' : '';
  }

  getOrgLogoUrl(tokenId: string): string {
    return this.publicTrackingLogUrl + `?token=${tokenId}`;
  }

  getOrgLogo2(tokenId: string): Observable<BlobPart> {
    return this.http.get(this.publicTrackingLogUrl + `?token=${tokenId}`, { responseType: 'blob' });
  }

  hideHeader(orgId: number): boolean {
    if (environment.environmentType === 'local' || environment.environmentType === 'qa') {
      let idLookup = {
        100000013: 100000780,
      };
      orgId = idLookup[orgId] || orgId;
    }

    let excludeIds = [100000007, 100000008, 100000780, ...environment.orgWithWideLogo];

    return excludeIds.includes(orgId) ? true : false;
  }
}
