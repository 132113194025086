import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Port } from '@dp/services/static-data.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'dp-port-select',
  templateUrl: './port-select.component.html',
})
export class PortSelectComponent implements OnInit {
  control: FormControl = new FormControl('');
  @Input() options: string[]; //if options.length is 0, it is vessel case
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() defaultValue: string = '';
  @Output() optionChanged = new EventEmitter<string>();
  filteredOptions: Observable<Port[]>;

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.control.setValue(this.defaultValue);
  }

  private _filter(value: string | Port): Port[] {
    let result = [];
    if (!value) {
      this.optionChanged.emit('');
      return result;
    }
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }

    this.options.forEach((item, i) => {
      if (result.length >= 20) return result;
      if (item.toLowerCase().includes(filterValue)) {
        result.push(item);
      }
    });
    if (result.length !== 1 || (result.length === 1 && result[0].toLowerCase() !== filterValue)) {
      this.optionChanged.emit('');
    }
    if (result.length === 1 && result[0].toLowerCase() === filterValue) {
      const portCode = this.getPortCode(result[0]);
      this.optionChanged.emit(portCode);
    }
    return result;
  }

  optionSelectedChange($event: MatAutocompleteSelectedEvent) {
    const portCode = this.getPortCode($event.option.value);
    this.optionChanged.emit(portCode);
  }

  getPortCode(selection: string): string {
    let start = selection.lastIndexOf('(');
    if (start) {
      return selection.substring(start + 1, selection.length - 1);
    }
    return '';
  }
}
