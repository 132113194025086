import { Component, OnInit, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EntityType } from '@dp/types';
import { ShipmentMap } from 'app/shipments2/shipments2.model';
import { dpAnimations } from '@dp/animations';
import { UDS_ICON_MAP } from 'app/image.constants';


@Component({
  selector: 'dp-tracking-result-road-transport',
  templateUrl: './_tracking-result-road-transport.component.html',
  styleUrls: ['./_tracking-result-road-transport.component.scss'],
  animations: dpAnimations,
})
export class TrackingResultRoadTransportComponent implements OnInit {
  udsIconMap = UDS_ICON_MAP;
  showReportError = false;
  EntityType = EntityType;
  @Input() payload;
  @Input() isBusy = false;
  @Input() errorText = '';
  @Input() showTemperature = true;
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  @Input() tabs: string[] = ['Tracking'];
  @Input() showDocumentsInSharedLinks: boolean;
  @Input() tokenId: string;
  exceptionCount = 0;
  @Input() showException = true;

  ngOnInit(): void {
    const payload = this.payload;
    if (payload) {
      if (payload && payload.mapsData) {
        this.shipmentMap = payload.mapsData;
      }
      if (payload && payload.transportJourneys) {
        this.transportJourneys = payload.transportJourneys;
      }

      if (payload && payload.error) {
        console.warn(payload.error);
        this.errorText = payload.error;
      }

      if (this.payload.exceptions?.steps) {
        this.payload.exceptions.steps.forEach((step) => {
          this.exceptionCount += step.exceptions.length;
        });
      }
    }
  }

  selectedTabIndex = 0;
  selectedTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }

  isEmpty(exception): boolean {
    return !exception || Object.keys(exception).length === 0;
  }

  reportError() {
    this.showReportError = true;
  }
}
