import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { INotificationItem } from '../../../app/navigation/header/notification/notification.model';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'dp-notification-slider',
  templateUrl: './notification-slider.component.html',
  styleUrls: ['./notification-slider.component.scss'],
})
export class NotificationSliderComponent implements OnInit {
  SvgMap = SvgMap;
  _item: INotificationItem = {};
  @Input() set item(data) {
    this._item = data;
  }
  @Input() set title(value) {
    this._item.title = value;
  }
  @Input() set subTitle(value) {
    this._item.subTitle = value;
  }
  @Input() set isEnabled(value) {
    this._item.isEnabled = value;
  }
  @Input() isSmall = false;
  @Input() category = '';

  @Output() onChange: EventEmitter<INotificationItem> = new EventEmitter();
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this._item.isBusy = false;
  }

  sliderChange() {
    this._item.isBusy = true;
    this.onChange.emit(this._item);
    this.valueChange.emit(this._item.isEnabled);

    const event = environment.gaEvents.names.toggle;
    const eventDetail = {
      eventCategory: this.category,
      eventAction: this._item.title,
      eventLabel: this._item.isEnabled ? 'On' : 'Off',
    };
    this.authService.logEvent(event, eventDetail);
  }
}
