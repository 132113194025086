import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ChoiceWithIndices } from 'app/shared/mentions';
import { Observable, Subject } from 'rxjs';
import { SvgMap } from '../svg/uds-svg-map';
import { Utility } from '@dp/utilities';
import { ShipmentNotesService } from './shipment-note.service';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared/ui.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorStateMatcher } from '@angular/material/core';
import { hasHTMLtagValidator } from '@dp/validators/no-html-tags.validator';

export interface NoteUser {
  id: number;
  firstName: string;
  lastName: string;
  initials?: string;
}
export interface Note {
  createdBy: number;
  id: number;
  createdAt: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  text: string;
  usersTagged: number[];
}

export class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid;
  }
}

@UntilDestroy()
@Component({
  selector: 'app-shipment-note',
  templateUrl: './shipment-note.component.html',
  styleUrls: ['./shipment-note.component.scss'],
})
export class ShipmentNoteComponent implements OnInit, OnDestroy {
  Utility = Utility;
  SvgMap = SvgMap;
  @Input() userNote: Note;
  @Input() isShipmentPage = true;
  @Input() currentUser: NoteUser;
  @Input() currentUserId: number;
  @Input() editingNoteId: number; // current note being Edit
  @Input() shipmentId: number;
  @Input() taggedUsersList: NoteUser[] = [];
  shipmentNotesService: any;

  userNoteSubject = new Subject<string>();
  form: FormGroup = null;
  matcher = new TouchedErrorStateMatcher();

  constructor(
    private fb: FormBuilder,
    private shipmentNotesApiService: ShipmentNotesService,
    private dialog: MatDialog,
    private uiService: UIService
  ) {
    this.form = this.fb.group({
      text: new FormControl('', [Validators.maxLength(500), hasHTMLtagValidator]),
      usersTagged: this.fb.array([]),
    });
    this.shipmentNotesService = this.shipmentNotesApiService;
  }

  ngOnInit() {
    if (this.userNote?.text) {
      this.textControl.setValue(this._processNoteText(this.userNote.text));
    }
    if (Array.isArray(this.userNote?.usersTagged) && this.userNote?.usersTagged.length) {
      const usersTaggedIds = this.userNote?.usersTagged;
      usersTaggedIds.forEach((userId: number) => this.usersTaggedControl.push(new FormControl(userId)));
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.shipmentNotesService.updateState({ editingNoteId: null });
  }

  get usersTaggedControl() {
    return this.form.controls['usersTagged'] as FormArray;
  }
  get textControl() {
    return this.form.controls['text'] as FormControl;
  }

  // https://flxng.codeeve.com/#/mentions
  loading = false;
  choices: NoteUser[] = [];
  mentions: ChoiceWithIndices[] = [];

  getChoiceLabel = (user: NoteUser): string => {
    return `@${user.firstName} ${user.lastName}`;
  };

  onSelectedChoicesChange(choices: ChoiceWithIndices[]): void {
    this.mentions = choices;
    // update usersTagged ids
    if (Array.isArray(this.mentions)) {
      this.usersTaggedControl.clear();
      this.mentions.forEach((item) => this.usersTaggedControl.push(new FormControl(item.choice.id)));
      this.form.updateValueAndValidity();
    }
  }

  onMenuShow(): void {
    console.log('Menu show!');
  }

  onMenuHide(): void {
    // console.log('Menu hide!');
    this.choices = [];
  }

  loadChoices(searchTerm: string): NoteUser[] {
    this.choices = this.taggedUsersList.filter((user) => {
      const alreadyExists = this.mentions.some((m) => m.choice.name === user.firstName);
      return (
        !alreadyExists &&
        ((user.firstName && user.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
          (user.lastName && user.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))
      );
    });
    return this.choices;
  }

  getUsers(): Observable<NoteUser[]> {
    this.loading = true;
    return this.shipmentNotesApiService.getUsersTaggingList(this.shipmentId, this.isShipmentPage);
  }

  openConfirmDeleteDialog(shipmentNoteId: number) {
    const message = `Are you sure you want to delete this note from shipment?`;
    const dialogData = new ConfirmDialogModel('Delete Note', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '450px',
      data: dialogData,
      panelClass: 'app-confirm-dialog',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.shipmentNotesService
          .deleteShipmentNote(this.shipmentId, shipmentNoteId, this.isShipmentPage)
          .pipe(untilDestroyed(this))
          .subscribe(
            (result) => {
              let currentNotes = this.shipmentNotesService.currentState?.notes;
              // remove deleted note from UI
              currentNotes = currentNotes.filter((note) => note.id !== shipmentNoteId);
              this.shipmentNotesService.updateState({ notes: currentNotes });
            },
            (error) => {
              this.uiService.showSnackbar('Failed to delete the note.', null, {
                duration: environment.snackBarDuration.warning,
                panelClass: 'warn',
              });
            }
          );
      }
    });
  }

  lookUpUserId(firstName, lastName) {
    const theUser = this.taggedUsersList.find((user) => {
      return user.firstName == firstName && user.lastName == lastName;
    });
    return theUser ? theUser.id : false;
  }

  saveNote(shipmentNoteId: number) {
    const pattern = '@\\w+ \\w+';
    const re = new RegExp(pattern, 'gi');
    const source = this.textControl.value;
    const matchedStrings = source.match(re);
    const noteId = this.isShipmentPage ? 'shipmentNoteId' : 'orderNoteId';
    if (matchedStrings) {
      // look up userId
      matchedStrings.forEach((user) => {
        const [firstName, lastName] = user.split(' ');
        const userId = this.lookUpUserId(firstName.substr(1), lastName);
        if (userId && this.usersTaggedControl.value.indexOf(userId) === -1) {
          this.usersTaggedControl.push(new FormControl(userId));
        }
      });
    }
    this.shipmentNotesService
      .editShipmentNote(this.shipmentId, { [noteId]: shipmentNoteId, ...this.form.value }, this.isShipmentPage)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          // success
          if (result) {
            this.shipmentNotesService.updateState({ editingNoteId: null });
          }
        },
        (error) => {
          this.uiService.showSnackbar('Failed to save the note.', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  copyFormValue = {};
  cancelEdit() {
    this.form.reset(this.copyFormValue);
    this.shipmentNotesService.updateState({ editingNoteId: null });
  }

  changeToEditMode(noteId) {
    this.copyFormValue = { ...this.form.value };
    this.shipmentNotesService.updateState({ editingNoteId: noteId });
    // Todo scroll 40px; ?
  }

  _processNoteText(noteInputText) {
    return noteInputText.replace(/\@\[([^\]]+)\]\(\d+\)/g, '@$1');
  }
}
