import { Pipe, PipeTransform } from '@angular/core';
import { NoteUser } from 'app/shared/components/shipment-note/shipment-note.component';

@Pipe({
  name: 'userMentionSearchHighlight',
})
export class UserMentionSearchHighlightPipe implements PipeTransform {
  addHighlightToSearchTerms(
    source: string,
    terms: string,
    taggedUsersList: NoteUser[] = [],
    patternStart = "<span class='user-tag'>",
    patternEnd = '</span>'
  ): string {
    const re = new RegExp(terms, 'gi');
    let matchedStrings = source.match(re) as string[];
    if (!matchedStrings) {
      return source;
    }
    const fullNameTaggedUsersList = taggedUsersList.map((user) => '@' + user.firstName + ' ' + user.lastName);
    matchedStrings = matchedStrings.filter((s) => fullNameTaggedUsersList.includes(s));
    let currentIndex = 0;
    return (
      matchedStrings.reduce((result, person) => {
        const index = currentIndex + source.slice(currentIndex).search(person);
        result += source.substring(currentIndex, index) + patternStart + person + patternEnd;
        currentIndex = index + person.length;
        return result;
      }, '') + source.substring(currentIndex)
    );
  }

  transform(value: string, search: string, taggedUsersList: NoteUser[]): string {
    if (!value) return '';
    if (!search) return value;

    const valueStr = value + ''; // Ensure numeric values are converted to strings

    // for performance reason, we ignore the case one word contains multiple search terms!!!
    return this.addHighlightToSearchTerms(valueStr, search, taggedUsersList);
  }
}
