import { Component, OnInit, Input } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { ShipmentDetail, ShipmentExceptions, Shipment, ExceptionSteps, ExceptionSeverity } from 'app/shipments2/shipments2.model';
// import moment from 'moment';
import { ShipmentType } from 'app/shared/shared.model';

@Component({
  selector: 'dp-os-exception',
  templateUrl: './os-exception.component.html',
  styleUrls: ['./os-exception.component.scss'],
})
export class OsExceptionComponent implements OnInit {
  SvgMap = SvgMap;
  // @Input() shipmentExceptions: ShipmentExceptions;
  @Input() shipmentDetail: ShipmentDetail;
  @Input() steps: Array<ExceptionSteps>;
  @Input() shipmentType?: ShipmentType;
  @Input() selectedShipmentId: number;
  @Input() shipments: Array<Shipment>;
  ShipmentType = ShipmentType;
  currentShipment: Shipment;

  // actually what we databind too for the vertical progress bar
  // steps: Array<ExceptionSteps> = [];

  // used by vertical progress bar
  heightPerStep: number = 200;

  // used by description section (right of vertical progress bar)
  heightPerStepDescription: number = 70;

  // databound height of vertical progress bar
  totalHeight: number = 0;

  noPendingExceptions: string = 'No pending exception';

  constructor() {}

  ngOnInit() {
    let padding = 35;
    let unitHeight = 55;
    this.totalHeight = this.calculateHeight(this.steps, padding, unitHeight);
    //this.totalHeight = this.heightPerStep * this.steps.length;
    // this.totalExceptions = this.countTotalExceptions(this.steps);
    // for (let i = 0; i < this.shipments.length; i++) {
    //   let shipment = this.shipments[i];
    //   if (shipment.id === this.selectedShipmentId) {
    //     this.currentShipment = shipment;
    //     break;
    //   }
    // }
  }

  /**
   * Generates data for displaying dynamic step heights.
   * @param steps
   * @param padding
   * @param unitHeight
   */
  calculateHeight(steps: ExceptionSteps[], padding: number, unitHeight: number) {
    let totalHeight = 0;

    if (steps) {
      for (let i = 0; i < steps.length; i++) {
        let step = steps[i];

        // everything gets 1 line
        let stepHeight = unitHeight;

        // account some space for steps that have no exceptions
        stepHeight += step.exceptions.length * unitHeight;

        // only scenario we don't need the extra height.
        if (step.exceptions[0] && step.exceptions[0].name === 'No pending exception') {
          stepHeight -= unitHeight;
        }

        step.heightSoFar = totalHeight;
        totalHeight += stepHeight;
        step.height = stepHeight;
        // console.log('step.height: ' + step.height);
      }

      totalHeight += padding * 2;
      // console.log('totalHeight: ' + totalHeight);
    }

    return totalHeight;
  }

  /**
   * We need to count exceptions so if we have 0 we can just show a simple message.
   * We also convert date formats here, should switch to a pipe.
   * @param currentSteps
   */
  countTotalExceptions(): number {
    let totalExceptions = 0;
    for (let i = 0; i < this.steps.length; i++) {
      let step = this.steps[i];
      totalExceptions += step.exceptions.length;

      // Should not parse Date
      // for (let j = 0; j < step.exceptions.length; j++) {
      //   let exception = step.exceptions[j];
      //   let date = moment(exception.date);
      //   exception.date = date.format('l');
      // }
    }
    return totalExceptions;
  }

  /**
   * maps an exception level to the color it's displayed as in the UI.
   * @param severity
   */
  getColor(severity: ExceptionSeverity) {
    if (severity === ExceptionSeverity.GREEN) {
      return '#00C853';
    } else if (severity === ExceptionSeverity.YELLOW) {
      return '#FFA000';
    } else {
      return '#AD2F33';
    }
  }

  getTitle(state: string) {
    if (state === 'ON_TIME') {
      return 'ATD';
    } else {
      return 'ETA';
    }
  }
}
