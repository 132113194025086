import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Team, TeamsState } from 'app/settings/settings.model';
import { TeamsFacade } from './teams.facade';
import { Pagination } from '@dp/types';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, Subscription, distinctUntilChanged, map } from 'rxjs';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { ConfirmDialogComponent, ConfirmDialogModel, ProgressRef, ProgressService, UIService } from 'app/shared';
import { MatDialog } from '@angular/material/dialog';
import { CreateEditTeamComponent } from './create-team/create-team.component';
import { AuthService } from 'app/auth/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'dp-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TeamsComponent implements AfterViewInit {
  SvgMap = SvgMap;

  displayedColumns = ['teamName', 'teamDescription', 'totalUsers', 'createdBy', 'updatedBy', 'actions'];
  expandedElements = new Set();
  pagination: Pagination;
  vm$: Observable<TeamsState> = this.facade.vm$;
  @ViewChild('contentZone') contentZone?: ElementRef;
  @ViewChild('tableZone', { read: ElementRef }) tableZone?: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('scrollable') scrollable: ElementRef;
  progress: ProgressRef;
  progressTable: ProgressRef;
  paginationSubscription: Subscription;

  constructor(
    private facade: TeamsFacade,
    private progressService: ProgressService,
    private dialog: MatDialog,
    private authService: AuthService,
    private uiService: UIService
  ) {
    this.facade.reset();
  }
  ngAfterViewInit(): void {
    this.expandedElements.clear();
    this.vm$
      .pipe(
        map((state) => state.loading),
        distinctUntilChanged()
      )
      .subscribe((loading) => {
        if (loading) {
          this.progress = this.progressService.showProgress(this.contentZone);
        } else {
          this.progressService.detach(this.progress);
          setTimeout(() => {
            if (this.paginator && !this.paginationSubscription) {
              this.paginationSubscription = this.paginator.page.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(() => {
                this.scrollTop();
              });
            }
          });
        }
      });
    this.vm$
      .pipe(
        map((state) => state.loadingTable),
        distinctUntilChanged()
      )
      .subscribe((loadingTable) => {
        // console.log(Utility.logTime(), 'xxx, loadingTable', loadingTable);
        if (loadingTable) {
          this.progressTable = this.progressService.showProgress(this.tableZone);
        } else {
          this.progressService.detach(this.progressTable);
        }
      });
  }

  scrollTop() {
    if (this.scrollable) {
      const scrollableElement = this.scrollable.nativeElement as HTMLElement;
      scrollableElement.scrollTop = 0;
    }
  }

  createOrEditTeam(team?: Team) {
    const dlg = this.dialog.open(CreateEditTeamComponent, {
      width: '500px',
      disableClose: true,
      data: team,
    });
    dlg.afterClosed().subscribe((updatedTeam?: Team) => {
      if (updatedTeam) {
        team.teamName = updatedTeam.teamName;
        team.teamDescription = updatedTeam.teamDescription;
        team.updatedAt = new Date().toISOString();
        team.updatedByUserEmail = this.authService.currentUserValue.userEmail;
      }
    });
  }
  deleteTeam(team: Team) {
    const dialogData = new ConfirmDialogModel(
      'Delete Team?',
      `On deletion, shipments accessible to only <b>${team.teamName}</b> will be made Private to you, please assign them to right groups. Other shipments - <b>${team.teamName}</b>'s members will not be able to access them afterwards.`,
      `You are about to delete team - <b>${team.teamName}</b>. Are you sure you want to continue? `,
      true
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: dialogData,
      panelClass: 'app-confirm-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // delete the record in the table
        this.facade.deleteTeam(team);
      }
    });
  }
  handlePageChange(event) {
    this.facade.updatePagination({
      currentPage: event.pageIndex,
      pageSize: event.pageSize,
    });
  }
  toggleMember(team: Team) {
    this.facade.toggleMember(team);
    // team.showingUsers = !team.showingUsers;
    // this.expandedElements.add(team);
  }
}
