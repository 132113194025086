import { Injectable } from '@angular/core';
import { DateAgoPipe } from '@dp/pipes/date-ago/date-ago.pipe';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { MapSpace } from 'app/shipments2/shipments3.model';

@Injectable({
  providedIn: 'root',
})
export class ShipmentMapService {
  stopSmall = {
    scaledSize: {
      width: 14,
      height: 14,
    },
    anchor: {
      x: 7,
      y: 7,
    },
  };
  stopSmallest = {
    scaledSize: {
      width: 2,
      height: 2,
    },
    anchor: {
      x: 1,
      y: 1,
    },
  };
  stopLarge = {
    scaledSize: {
      width: 20,
      height: 20,
    },
    anchor: {
      x: 10,
      y: 10,
    },
  };
  stopSmallComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopSmall,
  };
  stopSmallIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopSmall,
  };
  stopLargeComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopLarge,
  };
  stopLargeIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopLarge,
  };
  stopCurrent = {
    url: 'assets/svg/cityMarkerCurrent.svg',
    ...this.stopLarge,
  };
  currentLocation = {
    url: 'assets/svg/currentLocation.svg',
    ...this.stopLarge,
  };
  missingPath = {
    url: 'assets/svg/circle_missing.svg',
    ...this.stopSmallest,
  };

  constructor(private dateAgoPipe: DateAgoPipe) {}

  getStopIcon(journeyStop: MapSpace.JourneyStop) {
    const importantStops = [
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_PORT,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_PORT,
    ];
    const important = importantStops.includes(journeyStop.type);
    return journeyStop.isFuture
      ? important
        ? this.stopLargeIncomplete
        : this.stopSmallIncomplete
      : important
      ? this.stopLargeComplete
      : this.stopSmallComplete;
  }

  getLabelIcon(stop: MapSpace.JourneyStop, trackingType: string): string {
    return stop.type === MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN
      ? 'assets/svg/map/map-origin.svg'
      : stop.type === MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION
      ? 'assets/svg/map/map-destination.svg'
      : trackingType?.toLowerCase() === 'awb_number'
      ? SvgMap.airFreight
      : 'assets/svg/map/map-port.svg';
  }

  getCurrentLabelIcon(locationType) {
    return locationType === MapSpace.ShipmentLocationTypes.IN_PORT
    ? 'assets/svg/map/map-port.svg'
    : 'assets/svg/map/map-vessel.svg';
  }

  getLocationLabel(locationLabel: string, locationTimestamp: string) {
    let label = '';
    if (locationLabel) {
      label += locationLabel;
    }

    if (locationTimestamp) {
      label += ' (Last updated: ' + this.dateAgoPipe.transform(locationTimestamp) + ')';
    }
    return label;
  }
}
