import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedApiService } from 'app/shared/shared.service';
import { AWBCheckResult } from '../../app/shared/shared.model';

export class AWBValidator {
  static createValidator(sharedApiService: SharedApiService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value || control.value?.length < 11) {
        return of({ awb_invalid: true });
      }
      return sharedApiService.checkAWB(control.value).pipe(
        map((result: AWBCheckResult) => {
          const res = result.valid
            ? null
            : result.reason === 'AWB already exists in your account'
            ? { awb_exists: true }
            : { awb_invalid: true };
          const hint = result.valid? result.carrierName : '';
          sharedApiService.awbAirport[control.value] = hint;
          return res;
        })
      );
    };
  }
}
