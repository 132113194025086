<mat-selection-list class="org-list" [multiple]="false">
  <mat-list-option *ngFor="let org of organizations" [selected]="org.organizationId === organizationSelected?.organizationId" (click)="itemClicked(org)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <div mat-line class="w-152 ellipsis">{{org.organizationName}}</div>
        <div mat-line *ngIf="org.users?.length > 0">{{org.users.length}} members</div>
      </div>
      <div>
        <button type="button" mat-mini-fab tooltip="{{Utility.getFullName(user)}}" class="mat-tiny-fab m-4" *ngFor="let user of org.users | slice:0:3" [matTooltipShowDelay]=300>{{Utility.getInitials(user)}}</button>
        <span *ngIf="org.users?.length > 3">...</span>
      </div>
    </div>
  </mat-list-option>
</mat-selection-list>

<!-- <pre>{{ organizations | json}}</pre> -->
