import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { AuthService } from 'app/auth/auth.service';
import { filter } from 'rxjs';

@Component({
  selector: 'dp-users-teams',
  templateUrl: './users-teams.component.html',
  styleUrls: ['./users-teams.component.scss'],
  host: { class: 'uds-page-host' },
  animations: dpAnimations,
})
export class UsersTeamsComponent {
  activeLinkIndex = 0;
  navLinks = [
    {
      label: 'Manage Users',
      link: 'users',
      index: 0,
      display: true,
      disabled: false,
    },
    {
      label: 'Manage Teams',
      link: 'teams',
      index: 1,
      display: this.authService.isTeamFeatureEnabled? true : false,
      disabled: false,
    },
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      if (res.url === '/users-teams') {
        this.router.navigate(['users'], { relativeTo: this.activatedRoute });
      } else {
        const navLink = this.navLinks.find((navLink) => res.url.indexOf(navLink.link) > -1);
        this.activeLinkIndex = navLink?.index || this.activeLinkIndex;
      }
    });
  }
  getManageUsersTeamsLabel(): string {
    return this.authService.isTeamFeatureEnabled ? 'Manage Users & Teams' : 'Manage Users';
  }
}
