<section *ngIf="userNote.id !== editingNoteId" class="mb-8">
  <div class="d-flex align-items-start">
    <div style="width: 44px">
      <dp-mini-fab [total]="userNote.initials || Utility.getInitials(userNote)"></dp-mini-fab>
    </div>

    <div class="flex-grow-1">
      <p class="my-0 text-bold">{{ userNote.firstName }} {{ userNote.lastName }}</p>
      <label class="text-muted fs-12" style="color: #6A6A6D">{{
        userNote.createdAt | localizedDate:'local-date-time' }}</label>
    </div>

    <div class="text-right" *ngIf="currentUserId === userNote.created_by || currentUserId === userNote.createdBy && !editingNoteId">
      <button mat-icon-button [color]="'primary'" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

  </div>
  <div class="ml-44 pt-8 text-note" style="white-space: pre-wrap">
    <span [innerHTML]="form.value.text | userMentionSearchHighlight: '@\\w+ \\w+':taggedUsersList"></span>
  </div>
</section>

<section *ngIf="userNote.id === editingNoteId" class="mb-8 pr-8">
  <div class="d-flex align-items-start">
    <div style="width: 44px">
      <dp-mini-fab [total]="userNote.initials || Utility.getInitials(userNote)"></dp-mini-fab>
    </div>

    <form *ngIf="form" [formGroup]="form" autocomplete="off" class="flex-grow-1 notes-wrapper">
      <mat-form-field appearance="outline" class="flex-grow-1 relative-block-container full-width notes-field" #field>
        <mat-label *ngIf="field?._control?.focused || textControl?.value">Note</mat-label>
        <mat-label *ngIf="!field?._control?.focused && !textControl?.value">Add a note. Use @ to mention someone</mat-label>
        <textarea class="pb-12" matInput #textareaRef formControlName="text" [errorStateMatcher]="matcher"
          placeholder="Add a note. Use @ to mention someone"></textarea>

        <!-- <flx-text-input-autocomplete [textInputElement]="textareaRef" [textInputValue]="textControl?.value" [menuTemplate]="menuTemplate"
          [triggerCharacter]="'@'" [getChoiceLabel]="getChoiceLabel" [searchRegexp]="'^([-&.\\w]+ *){0,3}$'" (search)="loadChoices($event)"
          (selectedChoicesChange)="onSelectedChoicesChange($event)" (menuShow)="onMenuShow()" (menuHide)="onMenuHide()" menuPosition="bottom">
        </flx-text-input-autocomplete> -->

        <flx-mentions [textInputElement]="textareaRef" [textInputValue]="textControl?.value" [menuTemplate]="menuTemplate"
          [triggerCharacter]="'@'" [getChoiceLabel]="getChoiceLabel" [searchRegexp]="'^([-&.\\w]+ *){0,3}$'" (search)="loadChoices($event)"
          (selectedChoicesChange)="onSelectedChoicesChange($event)" (menuShow)="onMenuShow()" (menuHide)="onMenuHide()" position="top">
        </flx-mentions>

        <mat-error *ngIf="textControl?.errors && textControl?.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="textControl?.errors && textControl?.hasError('maxlength')">Maximum length for this field is 500 characters.
        </mat-error>
        <mat-error *ngIf="textControl?.errors && textControl?.hasError('hasHTMLtag')">Invalid symbols.
        </mat-error>
      </mat-form-field>
    </form>

  </div>
  <div class="text-right d-flex align-items-center">
    <a class="link ml-auto mr-24 text-bold fs-12" (click)="cancelEdit()">
      CANCEL
    </a>
    <button mat-flat-button class="easy-button secondary px-24 small" (click)="saveNote(userNote.id)"
      [disabled]="form.invalid || !form.dirty || !textControl.value.trim()">SAVE</button>
  </div>
</section>

<ng-template #menuTemplate let-selectChoice="selectChoice">
  <ul class="flx-selectable-list mat-elevation-z10" *ngIf="choices?.length > 0">
    <li *ngFor="let user of choices" class="flx-selectable-list-item" (click)="selectChoice(user)">
      <div style="width: 44px">
        <dp-mini-fab [total]="user.initials || Utility.getInitials(user)"></dp-mini-fab>
      </div>
      <span>{{user.firstName}} {{user.lastName}}</span>
    </li>
  </ul>
</ng-template>

<!-- Edit, Delete note -->
<mat-menu #menu="matMenu" style="width: 300px">
  <span>
    <button mat-menu-item (click)="changeToEditMode(userNote.id)">
      <dp-svg [src]="SvgMap.edit" w="22"></dp-svg>
      <span class="pl-8">Edit</span>
    </button>
  </span>

  <button color="primary" mat-menu-item (click)="openConfirmDeleteDialog(userNote.id)">
    <dp-svg [src]="SvgMap.delete" w="20"></dp-svg>
    <span class="pl-8">
      Delete
    </span>
  </button>
</mat-menu>