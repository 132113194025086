import { IFSColumnPreferences, IVSColumnPreferences } from '@dp/types';
import { UserStatus, Country, Capabilities, Partner } from 'app/settings/users/users.model';
import { ColumnSetting } from 'app/shared/shared.model';
import { IColumnPreferences } from '../orders-advanced/orders-advanced-models';
import { IResponseTheme } from 'app/theme/theme.interface';

export enum STATES {
  NEW = 'NEW',
  WITH_COMPANIES = 'WITH_COMPANIES',
  VERIFIED = 'VERIFIED',
  PRIVILEGED = 'PRIVILEGED',
  DISABLED = 'DISABLED',
}

export enum ACCOUNT_TYPES {
  BASIC = 'BASIC',
  ENTERPRISE = 'ENTERPRISE',
  PREMIUM = 'PREMIUM',
  TRIAL = 'TRIAL',
  PLUS = 'PLUS',
  PARTNER_EFL = 'PARTNER_EFL'
}

//delete
export enum EMAIL_SIGNUP_STATES {
  UNKNOWN,
  EXISTING_USER,
  SIGNED_UP,
  NEW_USER,
}

export interface SignupCompanyUser {
  fullName: string;
  initials: string;
}

export interface CompanySignupStatus {
  id: number;
  name: string;
  userCount: number;
  usersProcessed?: SignupCompanyUser[];
  users: string[];
}

export interface CreateEmailStatus {
  status: 'EXISTING' | 'NEW';
}

export enum LoginCompanyStatusType {
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  NEW = 'NEW',
}
export interface User {
  colorPreference?: string; // "LT"
  companyApplicationTime: string;
  companyId: number;
  companyName: string;
  companyStatus: LoginCompanyStatusType;
  country?: string;
  department?: string;
  firstName: string;
  id?: number;
  invitedAt?: string;
  isAdmin?: boolean;
  languages?: Array<string>; // TODO: find enum for all languages
  lastLoginTime?: string;
  lastName: string;
  locale?: string; // "en-GB"
  middleName?: string;
  orgCode?: string;
  organizationId: number;
  organizationName?: string;
  isQuickTrackingEnabled?: boolean;
  phone?: string;
  role?: any; // what is this?
  state?: STATES; // "VERIFIED"
  timezone?: string; // "America/Los_Angeles"
  userCode?: string; // 6d6a6b4064656d6f696d706f727465722e636f6d  // the id
  userEmail: string;
  //calculated property, setup right after the http get api
  fullName?: string;
  ageOfDays?: number;
  isCurrentUser?: boolean;

  // everything below are not returned by login api
  initials?: string; // generated and added to object on client side for user list.
  jobTitle?: string;
  moreThanDayOld?: boolean; // generated and added to object on client side to determine if resend button should show
  picture?: string; // url of profile picture
  status?: UserStatus;
  token?: string;
  isBusy?: boolean; //extra flag for container busy mode
  //dt trade user
  pendingUserEmail: string;
  showUserInformationConfirmationDialog?: boolean;
  source: string;
  //dt trade user end
  systemAdminPanelAccess: SystemAdminPanelAccessType;
  datePattern: string;
  timePattern: string;
  accountType?: ACCOUNT_TYPES;
  accountCapabilities?: Capabilities;
  organizationPartnerships?: Partner[];
  impersonated: boolean;
  enableEmailNotifications?: boolean;
  containerShipmentColumnPreferences?: ColumnSetting[];
  truckShipmentColumnPreferences?: ColumnSetting[];
  airShipmentColumnPreferences?: ColumnSetting[];
  outsourcedLogisticsShipmentColumnPreferences?: ColumnSetting[];
  userAcceptedTcs?: boolean;
  enableCustomNotifications?: boolean;
  enableNotificationsForSubscribedShipments?: boolean;
  enableShipmentNotifications?: boolean;
  hideLogistics?: number[];
  accessRole?: string;
  oldRole?: string;
  vesselScheduleColumnPreferences?: IVSColumnPreferences[];
  flightScheduleColumnPreferences?: IFSColumnPreferences[];
  ordersPageColumnPreferences?: IColumnPreferences[];
  userPermissions?: object;
  isAdvancedConsentForRoadTrackingEnabled?: boolean;
  isAdvancedMetabaseSubscribed?: boolean;
  dashboardConfigurations?: DashboardConfigurations;
  shipmentGroupPageColumnPreferences?: IColumnPreferences[];
  isPredictiveEtaEnabled?: boolean;
  isOutsourcedLogisticsShipmentTrackingAllowed?: boolean;
  teams?: string[];
  hasAiChatbotAccess: boolean;
  shipmentPageViewType?: 'CARD' | 'LIST';
  themeColors: IResponseTheme;
  organizationMetaLabels: string[];
  isMetaInfoEnabled: boolean;
  g2Banner: boolean;
}

export interface DashboardConfigurations {
  landing_page?: string;
  ocean_shipments?: boolean;
  air_shipments?: boolean;
  road_shipments?: boolean;
  summary_card?: boolean;
  updateDefault?: boolean;
  news_articles_setting?: {
    has_user_enabled_news_articles: boolean;
    is_news_articles_enabled: boolean;
  };
}

export interface UserSetting {
  oceanShipmentPanelWidth: number;
  truckShipmentPanelWidth: number;
}
export enum SystemAdminPanelAccessType {
  FULL = 'FULL',
  READ_ONLY = 'READ_ONLY',
}

export interface UserVerify extends User {
  password: string;
  passwordConfirm: string;
}

export interface SessionData {
  remindMeLater?: boolean;
}

export enum WebUpdateTypes {
  BANNER = 'BANNER',
  TUTORIAL = 'TUTORIAL',
}
export interface WebUpdate {
  id: number;
  content: string;
  isRemindMeLaterAvailable: boolean;
  remindMeLaterDurationInHours?: number;
  isDismissAvailable: boolean;
  expiryDate?: string;
  priority: number;
  type: WebUpdateTypes;
  createdAt: string;
  updatedAt: string;

  actionFinished?: boolean; // extra helper property to control action buttons enabled/disabled
}
export interface WebUpdatePayload {
  webUpdateId: number;
  isFinished?: boolean;
  isRemindMeLaterClicked?: boolean;
}

export interface UserPermissionInfo {
  userPermissions: Permissions;
}

export interface Permissions {
  currentOrg: object;
  sharedPartnerOrg: object;
}
