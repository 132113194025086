export const SideNavLabel = {
  WELCOME: 'Quick Track',
  DASHBOARD: 'Dashboard',
  SHIPMENT: 'Shipments',
  OCEAN_SHIPMENT: 'Ocean Shipments',
  ROAD_SHIPMENT: 'Road Shipments',
  AIR_SHIPMENT: 'Air Shipments',
  OUTSOURCED_LOGISTICS: 'Outsourced Logistics',
  ORDERS: 'Orders',
  INTEGRATED_ORDERS: 'Purchase Orders',
  SCHEDULES: 'Schedules',
  VESSEL_SCHEDULES: 'Vessel Schedules',
  FLIGHT_SCHEDULES: 'Flight Schedules',
  INBOUND_CONTAINER_PICKUP: 'Inbound Container Pickup',
  CALENDAR: 'Calendar',
  ANALYTICS: 'Analytics',
  LOCATIONS: 'Locations',
};

export const SideNavRouteVsLabel = {
  '/welcome': SideNavLabel.WELCOME,
  '/dashboard': SideNavLabel.DASHBOARD,
  '/ocean-shipments': SideNavLabel.OCEAN_SHIPMENT,
  '/road-shipments': SideNavLabel.ROAD_SHIPMENT,
  '/air-shipments': SideNavLabel.AIR_SHIPMENT,
  '/outsourced-logistics': SideNavLabel.OUTSOURCED_LOGISTICS,
  '/orders': SideNavLabel.ORDERS,
  '/purchase-orders': SideNavLabel.INTEGRATED_ORDERS,
  '/vessel-schedules': SideNavLabel.VESSEL_SCHEDULES,
  '/flight-schedules': SideNavLabel.FLIGHT_SCHEDULES,
  '/container-pickup': SideNavLabel.INBOUND_CONTAINER_PICKUP,
  '/calendar': SideNavLabel.CALENDAR,
  '/performance': SideNavLabel.ANALYTICS,
};
