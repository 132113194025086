import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserVerify, User, STATES, LoginCompanyStatusType } from '../user.model';
import { Subject, combineLatest, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { CrossFieldErrorMatcher } from '@dp/types';
import { StaticDataService } from '@dp/services/static-data.service';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { UsersService } from 'app/settings/users/users.service';
import { Utility } from '@dp/utilities';
import { Timezone, VerifyEmailRequest } from 'app/settings/users/users.model';
import { AuthService } from '../auth.service';
import { ProgressService, ProgressRef, UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { CountdownComponent } from 'ngx-countdown';
import { PolicyTypes } from 'app/documents/documents.model';
import { DateTimePattern } from '@dp/helper/DateTimePattern';
import { PasswordStrengthValidator } from '@dp/validators/password-strength.validator';
import { HttpResponse } from '@angular/common/http';
import { MilestoneService } from '@dp/services/milestone.service';
import { UserNameValidator } from '@dp/validators/user-name.validator';
@Component({
  selector: 'dp-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  animations: dpAnimations,
})
export class VerifyComponent extends DateTimePattern implements OnInit, OnDestroy {
  PolicyTypes = PolicyTypes;
  verificationCode: string;
  isBusy = false;
  redirecting = false;
  delay = 3000; //3 seconds before redirect
  verifyForm: UntypedFormGroup;
  errorMatcher = new CrossFieldErrorMatcher(['passwordsDoNotMatch']);
  progressRef: ProgressRef;
  user = <UserVerify>{};
  userRetrieved: User;
  allLanguages: Array<string> = [];
  timezones: Timezone[];
  isAlreadyVerified = false;
  // languageKeys: Array<string> = [];
  // countries: Country[];
  // timezoneKeys: Array<string> = [];
  //localeKeys: string[];
  staticData = null;
  error = '';

  public timezoneFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredTimezones: ReplaySubject<Timezone[]> = new ReplaySubject<Timezone[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // public countryFilterCtrl: FormControl = new FormControl();

  // public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

  @ViewChild('countrySelect', {
    static: true,
  })
  countrySelect: MatSelect;
  @ViewChild('pageZone', {
    static: true,
  })
  pageZone: ElementRef;

  @ViewChild('cd', { static: true }) countdown: CountdownComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private staticDataService: StaticDataService,
    private usersService: UsersService,
    private authService: AuthService,
    private uiService: UIService,
    private progressService: ProgressService,
    private milestoneService: MilestoneService
  ) {
    super(staticDataService);
  }

  ngOnInit() {
    this.progressRef = this.progressService.showProgress(this.pageZone);
    this.isBusy = true;
    this.staticData = this.staticDataService.getStaticDataDirect();
    this.timezones = this.staticData['timezones_v2'];
    this.filteredTimezones.next(this.timezones.slice());
    this.timezoneFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTimezone();
    });

    this.activatedRoute.params
      .pipe(
        switchMap((params) => {
          this.verificationCode = params['verificationCode'];
          return this.usersService.getUserByVerificationCode({
            verificationCode: this.verificationCode,
          });
        })
        // finalize won't be called due to aactivatedRoute.params will never be complete
        // finalize(() => {
        //   this.progressRef = this.progressService.detach(this.progressRef);
        //   this.isBusy = false;
        // })
      )
      .subscribe(
        (user) => {
          this.updateUser(user);
          this.progressRef = this.progressService.detach(this.progressRef);
          this.isBusy = false;
        },
        (error) => {
          if (error?.status === 403 && error?.error?.errorCode === '40310') {
            this.isAlreadyVerified = true;
          } else {
            this.error = error;
          }
          this.progressRef = this.progressService.detach(this.progressRef);
          this.isBusy = false;
        }
      );
  }
  protected filterTimezone() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    if (!search) {
      this.filteredTimezones.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTimezones.next(this.timezones.filter((timezone) => timezone.name.toLowerCase().indexOf(search) > -1));
  }

  updateUser(user: User) {
    this.userRetrieved = user;
    this.user = {
      ...this.user,
      ...this.userRetrieved,
    };
    if (this.user.state === STATES.NEW) {
      this.user.timezone = Utility.detectedTimezone(this.staticData);
      //this.user.locale = Utility.detectedLocale(this.staticData.locales);
    } else if (this.user.state === STATES.VERIFIED) {
      //redirect with email
      return this.redirectLogin();
    }

    this.verifyForm = this.formBuilder.group(
      {
        firstName: [this.user.firstName, [Validators.required, UserNameValidator]],
        middleName: [this.user.middleName],
        lastName: [this.user.lastName, [Validators.required, UserNameValidator]],
        phone: [this.user.phone, [Validators.required, Validators.pattern(/^\d{8,15}$/)]],
        datePattern: [this.user.datePattern, Validators.required],
        timePattern: [this.user.timePattern, Validators.required],
        timezone: [this.user.timezone, Validators.required],
        password: [this.user.password, [Validators.required, PasswordStrengthValidator]],
        confirmPassword: [this.user.passwordConfirm, Validators.required],
      },
      {
        validator: this.passwordValidator,
      }
    );
  }

  redirectLogin() {
    this.redirecting = true;
    // this.countdown.begin();

    // setTimeout(() => {
    //   this.router.navigate(['/login'], { queryParams: { email: this.user.userEmail } });
    // }, this.delay);
  }

  openLink(para: string) {
    window.open(`/documents/${para}`, '_blank');
  }

  countdownFinished(event) {
    if (event.action === 'done') {
      this.router.navigate(['/login'], { queryParams: { email: this.user.userEmail } });
    }
  }

  verifyUser() {
    this.isBusy = true;
    const phone = this.verifyForm.value.phone.replace(/[^0-9]/g, '');
    let dataToSend: Object = {
      verificationCode: this.verificationCode,
      password: this.verifyForm.value.password,
      userData: {
        firstName: this.verifyForm.value.firstName,
        middleName: this.verifyForm.value.middleName,
        lastName: this.verifyForm.value.lastName,
        phone,
        datePattern: this.verifyForm.value.datePattern,
        timePattern: this.verifyForm.value.timePattern,
        timezone: this.verifyForm.value.timezone,
      },
    };

    dataToSend = Utility.cleanupData(dataToSend);

    if (environment.SSO && environment.production) {
      this.usersService.verifyEmail(dataToSend as VerifyEmailRequest).subscribe(
        () => {
          // this.router.navigate(['/sso-login'])
          this.router.navigate(['/login']);
        },
        () => {
          this.isBusy = false;
          this.uiService.showSnackbar("We can't do this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
    } else {
      this.usersService
        .verifyEmail(dataToSend as VerifyEmailRequest)
        .pipe(
          switchMap((user) => {
            this.milestoneService.checkForEvent(environment.gaEvents.milestones.verifiedEmail);

            return this.authService.loginAfterVerify({
              email: user.userEmail,
              password: this.verifyForm.value.password,
            });
          }),
          finalize(() => (this.isBusy = false))
        )
        .subscribe(
          (response: HttpResponse<User>) => {
            let user = response.body as User;
            // console.log(user);
            if (user.companyStatus === LoginCompanyStatusType.NEW) {
              this.authService.setupNewUser(user, response);
              this.router.navigate(['/set-company']);
            } else if (user.companyStatus === LoginCompanyStatusType.DECLINED) {
              //todo: may not be possible here
              console.error('not implemented yet');
            } else if (user.companyStatus === LoginCompanyStatusType.PENDING) {
              //todo: may not be possible here
              console.error('not implemented yet');
            } else if (user.state === STATES.VERIFIED) {
              this.router.navigate(['/login'], { queryParams: { email: user.userEmail } });
            }
          },
          () => {
            this.uiService.showSnackbar("We can't do this right now. Please try again later.", null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        );
    }
  }

  mergeUserWithOrg() {
    this.authService.getOrganization().subscribe;
  }

  getNameOrEmail(): string {
    if (this.userRetrieved.firstName) {
      return Utility.getFullName(this.userRetrieved);
    } else {
      return this.userRetrieved.userEmail;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // private countrySelectInit() {
  //   this.filteredCountries.next(this.countries.slice());
  //   // listen for search field value changes
  //   this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
  //     this.filterCountries();
  //   });
  // }

  passwordValidator(form: UntypedFormGroup) {
    let result = {};
    if (!form.touched) {
      return result;
    }
    if (form.get('password').value !== form.get('confirmPassword').value) {
      result = {
        ...result,
        passwordsDoNotMatch: true,
      };
    }

    return result;
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = control.hasError('required')
      ? 'You must enter a value'
      : control.hasError('email')
      ? 'This is not a valid email address'
      : control.hasError('minlength')
      ? 'Password has to be at least 6 characters'
      : control.hasError('emailExists')
      ? 'This email has registered with another user'
      : control.hasError('passwordStrength')
      ? control.errors['passwordStrength']
      : control.hasError('pattern')
      ? 'This is not a valid phone number'
      : '';
    return msg;
  }

  asIsOrder() {
    // return a.value < b.value ? 1 : -1;
    return 1;
  }
}
