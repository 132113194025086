<span type="button" class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<h1 mat-dialog-title>
  <span class="warning-icon" [setSVGAttributes]="svgAttributes" [inlineSVG]="'assets/uds_icons/warning_black.svg'"></span> Warning!
</h1>

<div mat-dialog-content class="content">
  <div class="missing-carrier">Missing Carrier Name: {{ count }} Shipment</div>
  <div>Add Carrier Name for faster and enhanced tracking results</div>
</div>

<div class="mt-40" fxLayout fxLayoutAlign="space-between center">
  <button mat-flat-button class="tertiary easy-button mr-20" (click)="proceed()">Proceed anyway</button>
  <button mat-flat-button class="easy-button" [mat-dialog-close]="false">Add Carrier Name</button>
</div>
