<div class="mapContainer" *ngIf="shipmentDetail">

  <agm-map #gm [mapDraggable]="true" style="width:100%; height:590px;" [minZoom]=2 [maxZoom]="maxZoom" [disableDefaultUI]="false"
    [fitBounds]="true" [scrollwheel]="false" [restriction]="restriction">
    <ng-container *ngFor="let route of shipmentDetail.routes | keyvalue">
      <div *ngIf="isActualPathAvailable(); else expectedPath">
        <div *ngIf="route.key !== 'actualPath'; else actualPath">
          <ng-container *ngFor="let points of route.value['pastRoute']">
            <agm-polyline [strokeColor]="'#076284'">
              <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
                [agmFitBounds]="getFitBounds(route.key)">
              </agm-polyline-point>
              <agm-icon-sequence [strokeColor]="'#076284'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
                [path]="'M 0,-1 0,1'">
              </agm-icon-sequence>
            </agm-polyline>
          </ng-container>
        </div>
        <ng-template #actualPath>
          <div *ngIf="shipmentDetail.shipmentType !== 'TRUCK_SHIPMENT'">
            <ng-container *ngFor="let points of route.value['pastRoute']">
              <agm-polyline [strokeColor]="'#076284'">
                <agm-icon-sequence [fixedRotation]="false" [repeat]= "'250px'" offset="100px" [scale]="2" [path]="'FORWARD_OPEN_ARROW'">
                </agm-icon-sequence>
                <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
                  [agmFitBounds]="getFitBounds(route.key)">
                </agm-polyline-point>
              </agm-polyline>
            </ng-container>
          </div>
        </ng-template>
          <!-- futureRoute -->
        <ng-container *ngFor="let points of route.value['futureRoute']">
          <agm-polyline [strokeColor]="'#076284'">
            <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
              [agmFitBounds]="getFitBounds(route.key)">
            </agm-polyline-point>
            <agm-icon-sequence [strokeColor]="'#076284'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
              [path]="'M 0,-1 0,1'">
            </agm-icon-sequence>
          </agm-polyline>
        </ng-container>
      </div>
      <ng-template #expectedPath>
        <!-- pastRoute -->
        <ng-container *ngFor="let points of route.value['pastRoute']">
          <agm-polyline [strokeColor]="'#073964'">
            <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
              [agmFitBounds]="getFitBounds(route.key)">
            </agm-polyline-point>
          </agm-polyline>
        </ng-container>
        <!-- futureRoute -->
        <ng-container *ngFor="let points of route.value['futureRoute']">
          <agm-polyline [strokeColor]="'#1976d2'">
            <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
              [agmFitBounds]="getFitBounds(route.key)">
            </agm-polyline-point>
            <agm-icon-sequence [strokeColor]="'#1976d2'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
              [path]="'M 0,-1 0,1'">
            </agm-icon-sequence>
          </agm-polyline>
        </ng-container>
      </ng-template>
      <!-- possibleRoute -->
      <ng-container *ngFor="let points of route.value['missingRoute']">
        <!-- very tricky here, vessel point could be in the path, then points.length === 3 -->
        <ng-container *ngFor="let i of (points.length === 2? [0]: [0,1])">
          <ng-container *ngIf="{showMsg: false}; let cur">
            <agm-polyline [strokeColor]="'#FFA000'" (lineClick)="cur.showMsg=!cur.showMsg;">
              <agm-polyline-point *ngFor="let point of points.slice(i, i+2)" [latitude]="point.lat" [longitude]="point.lon"
                [agmFitBounds]="getFitBounds(route.key)">
              </agm-polyline-point>
              <agm-icon-sequence [strokeColor]="'#FFA000'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2"
                [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
              </agm-icon-sequence>
            </agm-polyline>
            <agm-marker [latitude]="getPossibleRouteMiddle(points.slice(i, i+2), 'lat')"
              [longitude]="getPossibleRouteMiddle(points.slice(i, i+2), 'lon')" [iconUrl]="missingPath">
              <agm-info-window #possiblePathWindow [isOpen]="cur.showMsg" (infoWindowClose)="cur.showMsg=false;">
                <span class="warning">This route hasn't been identified yet</span>
              </agm-info-window>
            </agm-marker>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Intermodal Case (manually linking) -->
    <ng-container *ngIf="isManualIntermodalLinkage">
      <!-- Case 1 - Connecting First mile destionation to Middle mile Origin -->
      <agm-polyline [strokeColor]="'#076284'">
        <agm-polyline-point *ngFor="let point of firstMileConnection" [latitude]="point?.lat" [longitude]="point?.lon"
          >
        </agm-polyline-point>
        <agm-icon-sequence [strokeColor]="'#076284'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
          [path]="'M 0,-1 0,1'">
        </agm-icon-sequence>
      </agm-polyline>

      <!-- Case 2 - Connecting Middle mile Destination to Last mile Origin -->
      <agm-polyline [strokeColor]="'#076284'">
        <agm-polyline-point *ngFor="let point of lastMileConnection" [latitude]="point?.lat" [longitude]="point?.lon"
          >
        </agm-polyline-point>
        <agm-icon-sequence [strokeColor]="'#076284'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
          [path]="'M 0,-1 0,1'">
        </agm-icon-sequence>
      </agm-polyline>
    </ng-container>

    <!-- markers -->
    <agm-marker *ngFor="let journeyStop of shipmentDetail.journeyStops; let i = index" [latitude]="journeyStop.lat"
      [longitude]="journeyStop.lon" [agmFitBounds]="getFitBoundsForStop(journeyStop)" [iconUrl]="shipmentMapService.getStopIcon(journeyStop)" [zIndex]="2"
      (mouseOver)="onMouseOver(infoWindow,gm, journeyStop.name)">
      <agm-info-window [disableAutoPan]="true" [isOpen]="journeyStop.displayNamePermanently" [zIndex]="i">
        <span class="svg" [inlineSVG]="shipmentMapService.getLabelIcon(journeyStop, trackingType)" [setSVGAttributes]="{width: 22, height: 22}"></span>
        <span class="infoLabel">{{journeyStop.name}}</span>
      </agm-info-window>
    </agm-marker>

    <ng-container *ngIf="isActualPathAvailable() && shipmentDetail.shipmentType === 'INTERMODAL_SHIPMENT' ">
      <ng-container *ngFor="let journeyStops of shipmentDetail.routes.actualPath['pastRoute']; let i = index">
        <ng-container *ngFor="let journeyStop of journeyStops">
          <agm-marker *ngIf="journeyStop.locationName" [latitude]="journeyStop.lat" [longitude]="journeyStop.lon" [iconUrl]="aisPings">
            <agm-info-window [disableAutoPan]="true" [zIndex]="i">
              <span class="svg" [inlineSVG]="shipmentMapService.getLabelIcon(journeyStop, trackingType)" 
                [setSVGAttributes]="{width: 22, height: 22}"></span>
              <span class="infoLabel">{{journeyStop.locationName}}</span>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Clustering of AIS pings for ocean shipment -->
    <ng-container *ngIf="isActualPathAvailable() && shipmentDetail.shipmentType === 'INTERMODAL_SHIPMENT'">
      <agm-marker-cluster [styles]="mapOptions.styles" [calculator]="mapStyles.calculator">
        <agm-marker *ngFor="let marker of markers; index as i" [latitude]="marker.lat" [longitude]="marker.lon" 
          [agmFitBounds]="true" [iconUrl]="aisPings" [zIndex]="5">
        </agm-marker>
      </agm-marker-cluster>
    </ng-container>

    <!-- DASHBOARD BEHAVIOUR FOR ROAD SHIPMENTS  -->
    <ng-container *ngIf="isActualPathAvailable() && shipmentDetail.shipmentType === 'TRUCK_SHIPMENT' ">
      <agm-marker-cluster [styles]="mapStyles.styles" [calculator]="mapStyles.calculator">
        <agm-marker *ngFor="let marker of markers; index as i" [latitude]="marker.coord.lat" [longitude]="marker.coord.lon" 
          [agmFitBounds]="true" [iconUrl]="gpsPings" [zIndex]="5">
          <agm-info-window>
            <span class="text-bold">
              Ping {{ marker?.count }} | {{ marker.coord?.name ? (marker.coord?.name + ' | ') : '' }} {{ showTimestamp(marker) }}
            </span>
          </agm-info-window>
        </agm-marker>
      </agm-marker-cluster>
    </ng-container>

    <!-- shipmentLocation -->
    <agm-marker *ngIf="shipmentCurrentLocation" [latitude]="shipmentCurrentLocation.lat"
      [longitude]="shipmentCurrentLocation.lon" [iconUrl]="currentLocation" [zIndex]="2"
      (mouseOver)="onMouseOver(infoWindow, gm, shipmentMapService.getLocationLabel(shipmentCurrentLocation.locationName, shipmentCurrentLocation.locationTimestamp))">
      <agm-info-window [disableAutoPan]="false" #infoWindow>
        <span *ngIf="shipmentDetail.shipmentType === 'INTERMODAL_SHIPMENT'" class="svg" [inlineSVG]="shipmentMapService.getCurrentLabelIcon(shipmentDetail.shipmentLocation.locationType)" [setSVGAttributes]="{width: 22, height: 22}">
        </span>
        <span>{{locationName}}</span>
      </agm-info-window>
    </agm-marker>
  </agm-map>

  <div *ngIf="isActualPathAvailable()" class="legend">
    <ng-container *ngFor="let items of legendItems">
      <div *ngIf="items.title === shipmentDetail.shipmentType" class="legend-item">
        <div *ngFor="let item of items.data">
          <dp-svg [src]="item.icon" class="mr-10" w="item.width"></dp-svg>
          <span class="itemTitle">{{ item.title }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  {{isGoogleMapAvailable()}}

  <ng-container *ngIf="showBingMaps && !noData()">
    <dp-shipment-bing-maps *ngIf="!showBingMapsFullScreen" [shipmentDetail]="shipmentDetail" [trackingType]="trackingType"></dp-shipment-bing-maps>
    <mat-icon class="full-screen-icon" (click)="fullScreen()">fullscreen</mat-icon>
  </ng-container>

  <div class="overlay centerChild" *ngIf="noData()">
    <div class="centerBox">The shipment routes haven't been identified yet</div>
  </div>

</div>