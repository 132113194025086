import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dp-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
  @Input() src: string;
  @Input() w = 18;
  @Input() h = 18;
  @Input() disabled = false;
  @Output() click = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  clicked(event:MouseEvent) {
    event.stopPropagation();
    if (!this.disabled) {
      this.click.emit();
    }
  }
}
