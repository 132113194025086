import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Keys } from '@dp/types';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Utility } from '@dp/utilities';

interface keyInfo {
  value: string;
  keyCode: Keys;
}
@Component({
  selector: 'dp-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class DpSearchBoxComponent implements OnInit {
  SvgMap = SvgMap;
  debounced: Subject<keyInfo> = new Subject<keyInfo>();

  @Input() placeholder: string;
  @Input() value: string = '';
  @Input() hasBorder: boolean;
  @Input() showFilter = false;
  @Input() disableFilter = false;
  @Input() filterCount = 0;

  @Output() valueChange = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() keyUp: EventEmitter<string> = new EventEmitter();
  @Output() cancelClicked = new EventEmitter<any>();
  @Output() filterOpen = new EventEmitter<any>();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.debounced.pipe(debounceTime(environment.debounceTime)).subscribe((info: keyInfo) => this.handleChange(info));
    iconRegistry.addSvgIcon('filter-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/uds_icons/filter1.svg'));
  }

  ngOnInit() {
    if (this.value) {
      this.search.emit(this.value);
    }
  }

  // for paste by Mouse
  onModelChange(newValue, oldValue) {
    this.value = newValue;
    this.valueChange.emit({ newValue, oldValue });
  }

  onKeyUp(event: KeyboardEvent) {
    let str = (event.target as HTMLInputElement).value;
    let printable_ASCII_only_str = str.replace(/[^(\x20-\x7F\p{Sc})]/u, '');
    this.debounced.next({
      value: printable_ASCII_only_str,
      keyCode: event.keyCode,
    });
  }

  handleChange(info: keyInfo) {
    this.value = info.value;
    if (info.keyCode === Keys.ENTER) {
      this.search.emit(this.value);
    }
    this.keyUp.emit(this.value);
    // this.valueChange.emit(this.value);
  }

  clear() {
    this.debounced.next({
      value: '',
      keyCode: null,
    });
    this.cancelClicked.emit();
  }

  openFilterPopup() {
    if (!this.disableFilter) {
      Utility.trackEvent({
        event: environment.gaEvents.names.groupFilter,
        eventDetail: {
          eventCategory: environment.gaEvents.categories.shipmentFilterIcons,
          eventAction: 'Filter Popup Open',
        },
      });
      this.filterOpen.emit();
    }
  }
}
