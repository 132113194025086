<section style="width:100%" *ngIf="steps.length > 0">
  <div class="headings flex text-label" style="margin-left: 25px;">
    <div style="width:50%;">
      <span class="text-bold">{{statusHeader}}</span>
    </div>
    <div style="width:35%">
      <span class="text-bold">Date & time</span>
    </div>
    <div style="width:15%; text-align: right">
      <span class="text-bold">Duration</span>
    </div>
  </div>
  <br>
  <ul class="ul-timeline" *ngFor="let step of steps; let first = first" style="color: #6A6A6D">
    <li class="line"
      [ngClass]="[step.isFuture || childrenHaveFuture(step.locationActions) ? 'line-dashed' : 'line-solid', step.isAssumed ? 'line-assumed' : '']">
      <div [ngClass]="getEventClass(step)">
        <div *ngIf="step.title === 'Rail Stations'; else existingMilestone">
          <span style.maxWidth="250px" style="font-size: 12px;">{{ step.title }} ({{ step.data.length }})</span>
          <a (click)="openRailStationDlg(step)" class="link">
            <span class="text-bold" style="font-size: 12px; padding-left: 8px;">VIEW ALL</span>
          </a>
        </div>
        <ng-template #existingMilestone>
          <dp-port class="text-bold text-dark text-label" [name]="step.address" [hasData]="step.dpwFlag" [maxWidth]="'250px'"
            style="position: relative; top:-5px">
          </dp-port>
          <span class="up" style="font-weight: normal" *ngIf="step.title">&nbsp;({{step.title}})</span>
        </ng-template>
      </div>
    </li>
    <!-- each event step -->
    <li class="line text-sm" *ngFor="let row of step.locationActions; let last = last;"
      [ngClass]="[row.isFuture ? 'line-dashed' : 'line-solid', row.isAssumed ? 'line-assumed' : '']">
      <!-- future events -->
      <div *ngIf="row.isFuture" class="future" style="padding: 0;" fxLayoutAlign="start center">
        <div [ngClass]="{'text-error': row.isAssumed, 'manual': row.isManualMilestone}" style="width: 50%; padding: 0;">
          {{row.name}}
        </div>
        <div class="item" style="width:35%">
          {{row.date}}
          <dp-svg *ngIf="(row == firstRow && last && context?.predictedEta) || (step?.predictedEta && last)"
            [src]="context?.hubAta ? SvgMap.predictedEta : SvgMap.arrivingPredictedEta" w="26" placement="bottom" class="predicted-icon"
            tooltip="{{getPredictedTitle()}} - {{context?.predictedEta || step?.predictedEta}} {{getPredictiveDaysDifference(context?.hubEtaDaysDifference)}}
              _________________________________
              Takes into account historical data, route forecast along with other key factors to provide an accurate final ocean port ETA. Currently in beta with limited coverage."></dp-svg>
        </div>
        <div *ngIf="row.duration" class="future-duration" style="width:15%">
          {{row.duration}}
        </div>
      </div>

      <!-- past events -->
      <div *ngIf="!row.isFuture" class="flex border-b" style="padding: 0 0 10px 0px;">
        <!-- <div style="width:50%" [ngClass]="[row.isAssumed ? 'item error-dark-text' : 'item']"> -->
        <div style="width:50%" class="item" [ngClass]="{'error-dark-text': row.isAssumed, 'manual': row.isManualMilestone}">
          {{row.name}}
        </div>

        <div class="item" style="width:35%">
          {{row.date}}
          <dp-svg *ngIf="row == firstRow && last && context?.predictedEta"
            [src]="context?.hubAta ? SvgMap.predictedEta : SvgMap.arrivingPredictedEta" w="26" placement="bottom" class="predicted-icon"
            tooltip="{{getPredictedTitle()}} - {{context?.predictedEta}} {{getPredictiveDaysDifference(context?.predictedHubEtaDifference)}}
              ____________________________________
              Takes into account historical data, route forecast along with other key factors to provide an accurate final ocean port ETA. Currently in beta with limited coverage."></dp-svg>
        </div>
        <div *ngIf="row.duration" class="duration" style="width:15%">
          {{row.duration}}
        </div>
      </div>
    </li>
  </ul>
</section>