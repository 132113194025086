import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MaterialModule } from 'app/material.module';
import { AuthRoutingModule } from './auth-routing.module';
import { RouterModule } from '@angular/router';
import { DpSharedModule } from '@dp/shared.module';
import { VerifyComponent } from './verify/verify.component';
import { OrgListComponent } from './signup/org-list/org-list.component';
// import { SignupComponent } from './signup/signup.component';
import { NewUserComponent } from './new-user/new-user.component';
import { Signup2Component } from './signup2/signup2.component';
import { IntroComponent } from './intro/intro.component';
import { SetCompanyComponent } from './set-company/set-company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { CountdownModule } from 'ngx-countdown';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { SharedCommonModule } from 'app/shared/shared.common.module';
import { LoginSsoComponent } from './login-sso/login-sso.component';
import { UserInviteActionComponent } from './user-invite-action/user-invite-action.component';

@NgModule({
  imports: [DpSharedModule, RouterModule, MaterialModule, DpPipesModule, CountdownModule, SharedCommonModule, BrowserModule, CommonModule],
  exports: [AuthRoutingModule],
  declarations: [
    LoginComponent,
    RegisterComponent,
    VerifyComponent,
    OrgListComponent,
    NewUserComponent,
    UserInviteActionComponent,
    Signup2Component,
    IntroComponent,
    SetCompanyComponent,
    ForgotPasswordComponent,
    ChangeEmailComponent,
    LoginSsoComponent,
  ],
  providers: [],
})
export class AuthModule {}
