<span type="button" class="close-button" [mat-dialog-close]="null">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<h1 mat-dialog-title style="margin: 0 0 8px;">
  <dp-svg *ngIf="showWarningIcon" [src]="SvgMap.exceptionWarning" class="ml-4 mr-8" w="24"></dp-svg> 
  {{title}}
</h1>

<div mat-dialog-content class="full-height">
  <p *ngIf="messageExtra" [innerHtml]="messageExtra"></p>
  <p [innerHtml]="message"></p>
</div>

<div class="text-right mt-20">
  <button mat-flat-button class="secondary easy-button mr-20" (click)="onDismiss()">Cancel</button>
  <button mat-flat-button class="easy-button" (click)="onConfirm()">Confirm</button>
</div>