import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Utility } from '@dp/utilities';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const foundToken = route.queryParams['token'];
    if (this.authService.isAuth() || foundToken) {
      return true;
    } else if (environment.baseUrl.includes('localhost')) {
      this.router.navigate(['/']);
    } else {
      Utility.redirectTo('cargoes/flow/welcome');
      return false;
    }
  }
}
