import { Injectable } from '@angular/core';
import { IResponseTheme, ITheme } from './theme.interface';
import { flowTheme } from './app.theme';
import { Utility } from '@dp/utilities';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private defaultTheme: ITheme = flowTheme;
  userId;
  changeTheme(theme: IResponseTheme, userId: number): void {
    this.userId = userId;
    this.applyActiveThemeToDom({ ...this.defaultTheme.properties, ...this.transformIntoThemeProperties(theme) });
  }

  private transformIntoThemeProperties(theme: IResponseTheme): Partial<ITheme['properties']> {
    if (Utility.isEmpty(theme)) {
      return null;
    }
    return this.transform(theme, null);
  }

  private transform(theme: object, key: string): Partial<ITheme['properties']> {
    let themeProperties: Partial<ITheme['properties']> = {};
    for (const rKey in theme) {
      let newKey = `${key ? `${key}-` : ''}${rKey}`;
      newKey = newKey.includes('--') ? newKey : `--${newKey}`;
      if (typeof theme[rKey] === 'string') {
        themeProperties[newKey] = theme[rKey];
      } else {
        themeProperties = { ...themeProperties, ...this.transform(theme[rKey], newKey) };
      }
    }
    return themeProperties;
  }

  private applyActiveThemeToDom(themeProperties: ITheme['properties']): void {
    Object.keys(themeProperties).forEach((key) => {
      document.documentElement.style.setProperty(key, themeProperties[key]);
    });
  }
}
