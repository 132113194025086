import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipmentType } from 'app/shared/shared.model';
import { ShipmentsBaseApiService } from 'app/shared/shipments-base-api.service';
import { Observable, of, throwError } from 'rxjs';
import { IMilestone } from 'app/new-ocean-shipment/ocean-tracking/ocean-tracking.model';

@Injectable({
  providedIn: 'root',
})
export class OceanShipmentsApiService extends ShipmentsBaseApiService {
  constructor(http: HttpClient) {
    super(http, ShipmentType.INTERMODAL_SHIPMENT);
  }

  addOrUpdateMilestones(shipmentId, payload): Observable<IMilestone[]> {
    const shipmentMilestonesURL = this.shipmentV2_API.getManualMilestonesURL(shipmentId);
    return this.http.put<IMilestone[]>(shipmentMilestonesURL, payload);
  }

  getManualMilestones(shipmentId): Observable<IMilestone[]> {
    const shipmentMilestonesURL = this.shipmentV2_API.getManualMilestonesURL(shipmentId);
    return this.http.get<IMilestone[]>(shipmentMilestonesURL);
  }

}
