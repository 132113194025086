import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';

import { DP_CONFIG } from '@dp/services/config.service';

@NgModule()
export class DpModule {
  constructor(@Optional() @SkipSelf() parentModule: DpModule) {
    if (parentModule) {
      throw new Error(
        'dpModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(config): ModuleWithProviders<DpModule> {
    return {
      ngModule: DpModule,
      providers: [
        {
          provide: DP_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
