<section class="notification-setting" style="min-height: 600px;padding: 0 0 12px 0">
  <div class="messageBox" *ngIf="currentUser.pendingUserEmail">
    <p>
      Please confirm your email to enable notification!
    </p>
  </div>

  <section class="notification-settings">
    <h2 class="setting-section">Manage Notifications</h2>
    <p class="body-regular">Manage your ocean, air and road shipment notifications.</p>
  </section>

  <div *ngIf="isLoading" fxLayoutAlign="center center" style="height: 300px">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>

  <div *ngIf="!isLoading">
    <div class="box-title">Shipment Notifications</div>
    <div class="box">
      <div class="enable-shipment">
        <dp-notification-slider [item]="data.enableShipmentNotificationSlide" (onChange)="enableShipmentNotificationChange($event)" [category]="'Shipment Notifications - Enable Notification'">
        </dp-notification-slider>
      </div>
      <ng-container *ngIf="data.enableShipmentNotificationSlide.isEnabled">
        <div class="enable-shipment-type">
          <mat-radio-group [(ngModel)]="currentUser.enableCustomNotifications" (change)="allNotificationOrOwnRule($event)">
            <mat-radio-button [value]="false" [checked]="!currentUser.enableCustomNotifications">
              Receive all notifications
            </mat-radio-button>
            <div class="box-subTitle">
              Get notified of all your shipments.
            </div>
            <mat-radio-button [value]="true" [checked]="currentUser.enableCustomNotifications">
              Create your own rules
            </mat-radio-button>
            <div class="box-subTitle">
              You will receive notifications for shipments that meet any of the rules you define below.
            </div>
          </mat-radio-group>

          <div class="own-rules" *ngIf="currentUser.enableCustomNotifications">
            <div class="components-table-heading" style="margin-bottom:-10px;">
              Subscribe shipments
            </div>
            <dp-notification-slider [item]="data.subscribeShipmentSlide" [isSmall]="true" (onChange)="subscribeShipmentChange($event)" [category]="'Create your own rules - Subscribe shipments'">
            </dp-notification-slider>
            <mat-divider></mat-divider>
            <dp-tagged-shipment [(tagsSubscribed)]="data.tagsSubscribed"></dp-tagged-shipment>
            <mat-divider></mat-divider>
            <dp-shipping-lane [(shippingLanesSubscribed)]="data.shippingLanesSubscribed"></dp-shipping-lane>
            <mat-divider></mat-divider>
            <dp-product [(productsSubscribed)]="data.productsSubscribed"></dp-product>
          </div>
        </div>
        <ng-container *ngIf="enableEmailNotifications && emailNotifications">
          <div class="notifications-settings">
            <div class="box-title">Shipping event and exception notifications settings</div>
            <div class="event-setting">
              <mat-accordion multi displayMode="flat" *ngFor="let setting of emailNotifications; let parentLast = last">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ setting.section | titlecase }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-container *ngFor="let subscription of setting.subscriptions; let last = last">
                    <div class="components-table-heading sub-category">{{ subscription.type ? subscription.type : '&nbsp;' }}</div>
                    <dp-notification-slider *ngFor="let item of subscription.content" [item]="item" [isSmall]="true"
                      (onChange)="saveSetting(item)" [category]="setting.section + ' - ' + (subscription.type ? subscription.type : '&nbsp;')">
                    </dp-notification-slider>
                    <mat-divider *ngIf="!last"></mat-divider>
                  </ng-container>
                </mat-expansion-panel>
                <mat-divider *ngIf="!parentLast"></mat-divider>
              </mat-accordion>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="box-title">Other Notifications</div>
    <div class="box">
      <ng-container *ngFor="let notification of data.otherNotifications; let i = index; ">
        <dp-notification-slider [category]="'Other Notifications'" [item]="notification" (onChange)="saveOtherNotifications(notification)">
        </dp-notification-slider>
        <mat-divider *ngIf="i !== (data.otherNotifications.length - 1)"></mat-divider>
      </ng-container>
      <!-- <dp-notification-slider [item]="data.sharedShipmentsSlide" (onChange)="sharedShipmentsNotificationSlideChange($event)">
      </dp-notification-slider>
      <mat-divider></mat-divider>
      <dp-notification-slider [item]="data.documentsSlide" (onChange)="sharedShipmentsNotificationSlideChange($event)">
      </dp-notification-slider>
      <mat-divider></mat-divider>
      <dp-notification-slider [item]="data.shipmentUpdatesSlide" (onChange)="sharedShipmentsNotificationSlideChange($event)">
      </dp-notification-slider> -->
    </div>
    <div class="box-title">Email Settings</div>
    <div class="box">
      <div fxLayout=" row" fxLayoutAlign="space-between center">
        <dp-notification-slider [category]="'Email Settings'" [item]="data.emailNotificationSlide" (onChange)="emailNotificationSlideChange($event)">
        </dp-notification-slider>
        <!-- <div class="box-slider-title" [class.disabled-text]="!enableEmailNotifications">
          Enable email notifications
        </div> -->
        <!-- <mat-slide-toggle [checked]="enableEmailNotifications" #emailNotificationToggle (change)="updateEmailNotification($event)">
          {{enableEmailNotifications ? 'On' : 'Off'}}
        </mat-slide-toggle>
        <div class="box-subTitle">
          Receive the notification on your registered email ID
        </div> -->

      </div>

    </div>


  </div>




</section>