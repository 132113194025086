<!-- <button mat-mini-fab class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button> -->

<h1 class="mb-0">
  Cargoes Flow Terms and Conditions Updated
</h1>

<p style="font-size: 1.1rem">The Cargoes Flow has updated the terms and conditions. The user needs to agree on the new terms and conditions
  to keep using the
  application.</p>

<div mat-dialog-content>
  <pdf-viewer [src]="pdfSrc" [autoresize]="true" [original-size]="false" [render-text]="true" [external-link-target]="'blank'"
    [show-borders]="true"></pdf-viewer>
</div>

<br>

<div class="mt-auto text-center">
  <button mat-flat-button class="submit-button easy-button" (click)="agreeToTcs(true)" [dpBusy]="isBusy">I agree</button>
</div>