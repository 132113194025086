import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { UsersService } from 'app/settings/users/users.service';
import { UIService } from 'app/shared';
import { finalize, debounceTime } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { InviteUserResult } from '@dp/types';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { dpAnimations } from '@dp/animations';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { USER_ROLES } from 'app/settings/user.constant';

@UntilDestroy()
@Component({
  selector: 'dp-invite-user-popover',
  templateUrl: './invite-user-popover.component.html',
  styleUrls: ['./invite-user-popover.component.scss'],
  animations: dpAnimations,
})
export class InviteUserPopoverComponent implements OnInit {
  SvgMap = SvgMap;
  emailForm: UntypedFormGroup;
  isBusy = false;
  roles = USER_ROLES;
  public validationMsgs = {
    email: [{ type: 'email', message: 'Enter a valid email' }],
  };

  @Output() inviteUserClose = new EventEmitter<Boolean>();
  constructor(private formBuilder: UntypedFormBuilder, private usersService: UsersService, private uiService: UIService) {}

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()]),
    });
  }

  get formEmails() {
    return this.emailForm.get('emails') as UntypedFormArray;
  }

  hasUser(): boolean {
    let users = (this.emailForm.get('emails') as UntypedFormArray).getRawValue();
    users = users.filter((user) => user.email.length > 0);
    return users.length > 0;
  }

  public addEmailFormGroup(event: MouseEvent) {
    event.preventDefault();
    const emails = this.emailForm.get('emails') as UntypedFormArray;
    emails.push(this.createEmailFormGroup());
  }

  public removeOrClearEmail(i: number) {
    const emails = this.emailForm.get('emails') as UntypedFormArray;
    if (emails.length > 1) {
      emails.removeAt(i);
    } else {
      emails.reset();
    }
  }

  public inviteUsers() {
    let users = (this.emailForm.get('emails') as UntypedFormArray).getRawValue();
    users = users.filter((user) => user.email.length > 0);
    let data = {
      users: users,
    };
    this.isBusy = true;

    this.usersService
      .inviteUsers(data)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (results) => {
          const failedResults = results.filter((result) => result.status === 'failed');
          const successResults = results.filter((result) => result.status === 'success');
          this.showSuccess(successResults, failedResults);
          this.reset();
          this.inviteUserClose.emit(true);
        },
        (error) => {
          this.showError();
          this.inviteUserClose.emit(false);
        }
      );
  }

  private reset() {
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()]),
    });
  }

  private showError() {
    this.uiService.showSnackbar("We can't invite users right now. Please try again later.", null, {
      duration: environment.snackBarDuration.warning,
      panelClass: 'warn',
    });
  }

  private showSuccess(successResults: InviteUserResult[], failedResults: InviteUserResult[]) {
    let msgs: string[] = [];
    let duration = failedResults.length === 0 ? environment.snackBarDuration.warning : environment.snackBarDuration.error;
    if (successResults.length > 1) {
      msgs.push(`${successResults.length} users are invited.`);
    } else if (successResults.length === 1) {
      msgs.push(`${successResults[0].email} is invited.`);
    }
    if (failedResults.length) {
      msgs.push(' The following users are in the system already!');
      failedResults.forEach((result) => msgs.push(result.email));
    }
    this.uiService.showMultiLinesSnackbar(msgs, duration, 'accent');
  }

  createEmailFormGroup(): UntypedFormGroup {
    const f = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.email),
      role: new UntypedFormControl(USER_ROLES[1].id),
    });

    f['that'] = this;
    // const email = f.get('email');
    // email.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(() => {
    //   this.emailFormAutoAppend(f);
    // });
    return f;
  }

  // emailFormAutoAppend(f: FormGroup) {
  //   const email = f.get('email');
  //   if (email.status !== 'INVALID' && email.value) {
  //     const parentArray = f.parent as FormArray;
  //     const lastF = parentArray.controls[parentArray.length - 1];
  //     if (lastF === f && parentArray.length < 10) {
  //       parentArray.push(f['that'].createEmailFormGroup());
  //     }
  //   }
  // }
}
