import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MilestoneService } from '@dp/services/milestone.service';
import { environment } from 'environments/environment';
import { LoginSsoService } from './login-sso.service';

@Component({
  selector: 'dp-login-sso',
  templateUrl: './login-sso.component.html'
})
export class LoginSsoComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private loginSsoService: LoginSsoService,
    private milestoneService: MilestoneService) { }

  redirected() {
    const sessionState = this.route.snapshot.queryParamMap.get('session_state');
    const code = this.route.snapshot.queryParamMap.get('code');
    if (sessionState && code) {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnInit() {

    if (this.redirected()) {

      this.milestoneService.checkForEvent(environment.gaEvents.milestones.login);

      this.loginSsoService.authorizeLogin();
    }
    else {
      this.loginSsoService.login()
    }
  }

}
