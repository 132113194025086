<mat-tab-group id="notificationDlg" #contentZone (selectedTabChange)="selectedTabChange($event)" #tabs *ngIf="tabIndex !== -1">
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tabLabel" [ngClass]="{'withDot': countEvents }">Events</div>
    </ng-template>
    <ng-template matTabContent>
      <dp-event-tab [type]="NotificationTypes.EVENT" fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="tabContainer"
        [opened]="tabIndex === 0" (allRead)="allRead(NotificationTypes.EVENT)" (partialRead)="partialRead(NotificationTypes.EVENT)"
        [hasUnread]="countEvents"></dp-event-tab>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tabLabel" [ngClass]="{'withDot': countExceptions }">Exceptions</div>
    </ng-template>
    <ng-template matTabContent>
      <dp-event-tab [type]="NotificationTypes.EXCEPTION" fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="tabContainer"
        [opened]="tabIndex === 1" (allRead)="allRead(NotificationTypes.EXCEPTION)" (partialRead)="partialRead(NotificationTypes.EXCEPTION)"
        [hasUnread]="countExceptions"></dp-event-tab>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tabLabel" [ngClass]="{'withDot': countSystems }">Others</div>
    </ng-template>
    <ng-template matTabContent>
      <dp-event-tab [type]="NotificationTypes.SYSTEM" fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="tabContainer"
        [opened]="tabIndex === 2" (allRead)="allRead(NotificationTypes.SYSTEM)" (partialRead)="partialRead(NotificationTypes.SYSTEM)"
        [hasUnread]="countSystems"></dp-event-tab>
    </ng-template>
  </mat-tab>
</mat-tab-group>