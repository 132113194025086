import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { User } from 'app/auth/user.model';
import { StaticDataService } from '@dp/services/static-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UsersService } from 'app/settings/users/users.service';
import { LogService } from '@dp/services/log.service';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { Timezone, UpdateUserRequest } from 'app/settings/users/users.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { finalize, takeUntil, take, map } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Country } from '@dp/services/static-data.model';
import { MatSelect } from '@angular/material/select';
import { CrossFieldErrorMatcher } from '@dp/types';
import { DateTimePattern } from '@dp/helper/DateTimePattern';
import { PasswordStrengthValidator } from '@dp/validators/password-strength.validator';
import { DpEmailValidator } from '@dp/validators/email.validator';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { EditPasswordDlg } from '../new-settings/edit-password-dlg/edit-password-dlg.component';
import { MatDialog } from '@angular/material/dialog';
import { UserNameValidator } from '@dp/validators/user-name.validator';

@Component({
  selector: 'dp-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends DateTimePattern implements OnInit, AfterViewInit, OnDestroy {
  SvgMap = SvgMap;
  isBusy = false;
  emailEditMode = false;
  passwordEditMode = false;
  errorMatcher = new CrossFieldErrorMatcher();

  newEmail = '';
  f: UntypedFormGroup;
  allLanguages: Array<string> = [];
  languageKeys: Array<string> = [];
  countries: Country[];
  timezones: Timezone[];
  localeKeys: string[];
  staticData = null;
  currentlyEditingUser: User = null;
  oldUserState: User = null;
  user: User;

  // public countryCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

  public timezoneFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTimezones: ReplaySubject<Timezone[]> = new ReplaySubject<Timezone[]>(1);

  @ViewChild('emailBlock') emailBlock: ElementRef;
  @ViewChild('restPropertyZone') restPropertyZone: ElementRef;
  @ViewChild('actionZone') actionZone: ElementRef;
  @ViewChild('emailZone') emailZone: ElementRef;
  @ViewChild('passwordZone') passwordZone: ElementRef;
  @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;
  // @Input() user: User;
  // @Output() userUpdated = new EventEmitter<User>();

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private staticDataService: StaticDataService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private log: LogService,
    private uiService: UIService,
    private dpEmailValidator: DpEmailValidator,
    public dialog: MatDialog,
    private progressService: ProgressService
  ) {
    super(staticDataService);
  }

  ngOnInit() {
    this.processStaticData(this.staticDataService.getStaticDataDirect());
    this.user = this.authService.currentUserValue;
    this.currentlyEditingUser = this.user;
    this.oldUserState = this.user;

    // this.countrySelectInit();

    this.f = this.formBuilder.group(
      {
        oldPassword: ['', Validators.required],
        password: ['', [Validators.required, PasswordStrengthValidator]],
        confirmPassword: ['', Validators.required],

        email: [this.user.userEmail, [Validators.required, Validators.email], this.dpEmailValidator.userValidator(this.user.userEmail)],
        firstName: [this.user.firstName, [Validators.required, UserNameValidator]],
        middleName: [this.user.middleName],
        lastName: [this.user.lastName, [Validators.required, UserNameValidator]],
        phone: [this.user.phone, [Validators.required, Validators.pattern(environment.validators.phone)]],
        // locale: [this.user.locale],
        // country: [this.user.country],
        // languages: [this.user.languages],
        datePattern: [this.user.datePattern, Validators.required],
        timePattern: [this.user.timePattern, Validators.required],
        timezone: [this.user.timezone],
        organization: [this.user.organizationName],
        department: [this.user.department],
        role: [this.user.role],
      },
      {
        validator: this.passwordValidator,
      }
    );

    this.passwordEdit(false);
    this.emailEdit(false);

    // this.openEditPasswordDlg();
  }

  passwordValidator(form: UntypedFormGroup) {
    let result = {};
    if (!form.touched || form.get('password').status === 'DISABLED') {
      return result;
    }
    if (
      form.get('password').value.length > 0 &&
      form.get('confirmPassword').value.length > 0 &&
      form.get('password').value !== form.get('confirmPassword').value
    ) {
      result = { ...result, passwordsDoNotMatch: true };
    }
    if (form.get('password').value === form.get('oldPassword').value) {
      result = { ...result, sameAsOld: true };
    }

    return result;
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  cancel() {}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  actionProgress: ProgressRef;
  restPropertyZoneProgress: ProgressRef;
  emailZoneProgress: ProgressRef;
  passwordZoneProgress: ProgressRef;

  passwordEdit(enable: boolean) {
    this.passwordEditMode = enable;

    if (enable) {
      this.f.get('oldPassword').enable();
      this.f.get('password').enable();
      this.f.get('confirmPassword').enable();
      this.setBusy();
    } else {
      this.f.get('oldPassword').reset('');
      this.f.get('password').reset('');
      this.f.get('confirmPassword').reset('');

      this.f.get('oldPassword').disable();
      this.f.get('password').disable();
      this.f.get('confirmPassword').disable();

      this.removeBusy();
    }
  }

  openEditPasswordDlg() {
    this.dialog.open(EditPasswordDlg, { panelClass: 'edit-pwd-dlg' });
  }

  emailEdit(enable: boolean) {
    this.emailEditMode = enable;

    if (enable) {
      this.f.get('email').enable();
      this.setBusy();
    } else {
      this.f.get('email').setValue(this.user.userEmail);
      this.f.get('email').disable();
      this.removeBusy();
    }
  }

  private removeBusy() {
    if (this.actionProgress) this.progressService.detach(this.actionProgress);
    if (this.restPropertyZoneProgress) this.progressService.detach(this.restPropertyZoneProgress);
    if (this.emailZoneProgress) this.progressService.detach(this.emailZoneProgress);
    if (this.passwordZoneProgress) this.progressService.detach(this.passwordZoneProgress);
  }

  private setBusy() {
    if (this.passwordEditMode) {
      this.emailZoneProgress = this.progressService.showProgress(this.emailZone, true);
    } else {
      this.passwordZoneProgress = this.progressService.showProgress(this.passwordZone, true);
    }
    this.actionProgress = this.progressService.showProgress(this.actionZone, true);
    this.restPropertyZoneProgress = this.progressService.showProgress(this.restPropertyZone, true);
  }

  processStaticData(staticData: any) {
    this.staticData = staticData;
    this.localeKeys = Object.keys(this.staticData.locales);

    this.timezones = staticData['timezones_v2'];
    this.filteredTimezones.next(this.timezones.slice());
    this.timezoneFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTimezone();
    });
  }
  protected filterTimezone() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    if (!search) {
      this.filteredTimezones.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTimezones.next(this.timezones.filter((timezone) => timezone.name.toLowerCase().indexOf(search) > -1));
  }

  getTimezoneName(): string {
    return this.timezones.find((timezone) => timezone.code === this.user.timezone)?.name || 'N/A';
  }

  getErrorMessage(control: UntypedFormControl) {
    let msg = control.hasError('required')
      ? 'You must enter a value'
      : control.hasError('email')
      ? 'This is not a valid email address'
      : control.hasError('pattern')
      ? 'This is not a valid phone number'
      : control.hasError('emailExists')
      ? 'This email has registered with another user'
      : control.hasError('passwordsDoNotMatch')
      ? 'Passwords do not match'
      : control.hasError('sameAsOld')
      ? 'This new password is the same as your old password'
      : control.hasError('passwordStrength')
      ? control.errors['passwordStrength']
      : '';
    return msg;
  }

  cancelEmail() {
    this.f.get('email').setValue(this.user.userEmail);
    this.emailEdit(false);
  }
  canUpdateEmail(): boolean {
    return this.f.controls['email'].valid && this.f.get('email').value !== this.user.userEmail;
  }

  changeEmail() {
    let progress = this.progressService.showProgress(this.emailBlock);
    let payload = { newEmail: this.f.get('email').value };

    this.usersService
      .updateUser(payload, this.user.userCode)
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          this.authService.setupNewUser(user, response);
          return user;
        }),
        finalize(() => this.progressService.detach(progress))
      )
      .subscribe(
        () => {
          this.uiService.showSnackbar('We have sent you an email to verify your email address', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });
          this.emailEdit(false);
          this.user.pendingUserEmail = this.f.get('email').value;
          // this.authService.userUpdated(user);
        },
        () => {
          this.uiService.showSnackbar('We can not do this right now. Please try again later.', null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }
  changePassword() {
    let progress = this.progressService.showProgress(this.emailBlock);
    let oldPassword = this.f.get('oldPassword').value;
    let newPassword = this.f.get('password').value;

    this.usersService
      .changePasswordRequest(oldPassword, newPassword, this.user.userCode)
      .pipe(
        finalize(() => {
          this.progressService.detach(progress);
          // this.passwordEdit(false);
        })
      )
      .subscribe(
        () => {
          this.uiService.showSnackbar('Your password has been updated.', null, {
            duration: 3000,
            panelClass: 'accent',
          });
          this.passwordEdit(false);
        },
        () => {
          this.uiService.showSnackbar('The current password you provided is not correct.', null, {
            duration: 3000,
            panelClass: 'warn',
          });
        }
      );
  }

  generateUpdateUserRequest(user: User): UpdateUserRequest {
    const phone = user.phone.replace(/[^0-9]/g, '');
    let request: UpdateUserRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      //locale: user.locale,
      timezone: user.timezone,
      role: user.role,
      phone,
      department: user.department,
      datePattern: user.datePattern,
      timePattern: user.timePattern,
    };
    return request;
  }

  saveProfile() {
    let formValue = this.f.value;

    let user: User = { ...this.oldUserState, ...formValue };

    let request: UpdateUserRequest = this.generateUpdateUserRequest(user);

    this.isBusy = true;

    this.usersService
      .editUser(request, user.userCode)
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          this.authService.setupNewUser(user, response);
          return user;
        }),
        finalize(() => (this.isBusy = false))
      )
      .subscribe(
        (user: User) => {
          // console.log('updated user: ', user);
          // check if the data isn't valid

          if (user === null || typeof user !== 'object') {
            // inform user there's a server problem
            this.log.logError('editUser; request failed.');
            this.uiService.showSnackbar('editUser request failed.', null, {
              duration: 8000,
              panelClass: 'warn',
            });
            return;
          }

          this.uiService.showSnackbar('Your profile has been saved!', null, {
            duration: 4000,
            panelClass: 'accent',
          });

          // change has been confirmed, we now have a new concept of oldUserState incase the user edits more
          this.oldUserState = user;
          this.f.markAsPristine();
        },
        (error: HttpErrorResponse) => {
          let errorMessage = '';
          let errorCode = '';
          if (error && error.error && error.error.errorCode) {
            errorCode = error.error.errorCode;
            if (environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
              errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
            }
          }

          this.log.logError('editUser; request failed: ' + errorCode + ' ' + errorMessage);
          this.uiService.showSnackbar("Somethings wrong, your profile can't be updated right now.", null, {
            duration: 3000,
            panelClass: 'warn',
          });
        },
        () => {}
      );
  }

  /**
   * Sets the initial value after the filteredCountries are loaded initially
   */
  protected setInitialValue() {
    this.filteredCountries.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control (i.e. _initializeSelection())
      // this needs to be done after the filteredCountries are loaded initially
      // and after the mat-option elements are available
      this.countrySelect.compareWith = (a: string, b: string) => {
        return a === b;
      };
    });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(this.countries.filter((country) => country.country_name.toLowerCase().indexOf(search) > -1));
  }

  asIsOrder() {
    return 1;
  }
}
