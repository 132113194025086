  <div class="intro" fxFlex fxHide fxShow.gt-xs>

    <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}" [routerLink]="'/'">
      <img src="assets/cargoflow-new.svg" style="width: 375px;">
    </div>

    <div class="container">
      <dp-cargoes-features fxFlex></dp-cargoes-features>
    </div>

  </div>