import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { UsersService } from 'app/settings/users/users.service';
import { UIService, ProgressService } from 'app/shared';
import { NotificationService } from '../../navigation/header/notification/notification.service';
import { forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { User } from 'app/auth/user.model';
import {
  MatLegacySlideToggle as MatSlideToggle,
  MatLegacySlideToggleChange as MatSlideToggleChange,
} from '@angular/material/legacy-slide-toggle';
import { environment } from 'environments/environment';
import { INotificationItem } from '../../navigation/header/notification/notification.model';
import { Tag, ShippingLane } from '@dp/types';
import { ProductSubscribed } from '@dp/types/dp-model';

interface UserNotificationPageData {
  // shipmentNotification?: boolean;
  enableShipmentNotificationSlide: INotificationItem;
  subscribeShipmentSlide: INotificationItem;
  tagsSubscribed?: Tag[];
  shippingLanesSubscribed?: ShippingLane[];
  productsSubscribed?: ProductSubscribed[];
  sharedShipmentsSlide?: INotificationItem;
  otherNotifications?: INotificationItem[];
  emailNotificationSlide?: INotificationItem;
}

@UntilDestroy()
@Component({
  selector: 'dp-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnInit {
  isLoading = true;
  data: UserNotificationPageData = null;
  emailNotifications;
  enableEmailNotifications = true; //TODO:
  enableShipmentUpload = true; //TODO: from data service
  enableDocuments = true; //TODO: from data service
  currentUser = this.authService.currentUserValue;
  @ViewChild('contentZone') contentZone?: ElementRef;
  @ViewChild('shipmentNotificationsToggle') shipmentNotificationsToggle: MatSlideToggle;
  @ViewChild('emailNotificationToggle') emailNotificationToggle: MatSlideToggle;
  @ViewChild('shipmentUploadToggle') shipmentUploadToggle: MatSlideToggle;
  @ViewChild('documentsToggle') documentsToggle: MatSlideToggle;
  constructor(
    private notificationService: NotificationService,
    private uiService: UIService,
    private progressService: ProgressService,
    public authService: AuthService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.data = {
      // shipmentNotification: false,
      enableShipmentNotificationSlide: {
        isEnabled: this.currentUser.enableShipmentNotifications,
        title: 'Enable shipment notifications',
      },
      subscribeShipmentSlide: {
        isEnabled: this.currentUser.enableNotificationsForSubscribedShipments,
        title: 'Get notifications of your subscribed shipments.',
      },
      tagsSubscribed: [],
      shippingLanesSubscribed: [],
      productsSubscribed: [],
      emailNotificationSlide: {
        isEnabled: this.currentUser.enableEmailNotifications,
        title: 'Enable email notifications',
        subTitle: 'Receive the notification on your registered email ID',
      },
    };

    let getEmailNotifications$ = this.notificationService.getNotificationSettings();
    let getShipmentNotifications$ = this.notificationService.getShipmentNotifications();

    this.isLoading = true;
    forkJoin([getEmailNotifications$, getShipmentNotifications$])
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        ([emailData, subscriptionData]) => {
          this.emailNotifications = emailData.filter((item) => item.section !== 'other notifications');
          this.data.emailNotificationSlide = {
            ...this.data.emailNotificationSlide,
            isEnabled: this.currentUser.enableEmailNotifications,
            isBusy: false,
          };
          const otherNotifications = emailData.find((item) => item.section === 'other notifications');
          if (otherNotifications?.subscriptions?.length === 1) {
            let notifications = otherNotifications.subscriptions[0].content;
            this.data.otherNotifications = notifications.map((notification) => {
              return {
                code: notification.code,
                title: notification.title,
                subTitle: notification.description,
                isEnabled: notification.isEnabled,
              };
            });
          }

          this.data.tagsSubscribed = subscriptionData?.['tagsSubscribed']
            ?.map((item) => {
              return { id: item.tagId, name: item.tagName, notificationSubscriptionId: item.notificationSubscriptionId };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          this.data.shippingLanesSubscribed = subscriptionData['shippingLanesSubscribed']?.sort((a, b) =>
            a.carrier > b.carrier || (a.carrier === b.carrier && a.origin > b.origin) ? 1 : -1
          );
          this.data.productsSubscribed = subscriptionData['productsSubscribed']
            ?.sort((a, b) => (a.productCode > b.productCode ? 1 : -1))
            .map((item) => {
              const renameKey = ({ productId: id, ...rest }) => {
                return { id, ...rest };
              };
              return renameKey(item) as ProductSubscribed;
            });

          this.data.sharedShipmentsSlide = {
            ...this.data.sharedShipmentsSlide,
            isEnabled: false,
          };
        },
        (err) => {
          this.uiService.showSnackbar('System error!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  saveOtherNotifications(notification: INotificationItem) {
    notification.isBusy = true;
    let payload = {};
    payload[notification.code] = {
      forAllShipments: true,
      isEnabled: !notification.isEnabled,
    };

    this.notificationService
      .saveOtherNotification(payload)
      .pipe(
        finalize(() => {
          untilDestroyed(this);
          notification.isBusy = false;
        })
      )
      .subscribe(
        (res) => {
          notification.isEnabled = !notification.isEnabled;
        },
        (err) => {}
      );
  }

  allNotificationOrOwnRule() {
    console.log(this.currentUser.enableCustomNotifications);
    const payload = { enableCustomNotifications: this.currentUser.enableCustomNotifications };

    this.usersService
      .editUserSingleProperty(payload, this.currentUser.userCode)
      .pipe(untilDestroyed(this))
      .subscribe(
        (user) => {
          this.authService.userUpdated(user);
        },
        (err) => {
          this.currentUser.enableCustomNotifications = !this.currentUser.enableCustomNotifications;
          this.uiService.showSnackbar(`We can't process this right now.Please try again later`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  subscribeShipmentChange(item: INotificationItem) {
    item.isBusy = true;
    item.isEnabled = !item.isEnabled;
    let payload = {
      enableNotificationsForSubscribedShipments: item.isEnabled,
    };
    this.usersService
      .editUserCleanVersion(payload, this.currentUser.userCode)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          item.isBusy = false;
        })
      )
      .subscribe(
        (user) => {
          this.authService.userUpdated(user);
        },
        (error: HttpErrorResponse) => {
          item.isEnabled = !item.isEnabled;
          this.uiService.showSnackbar(`We can't process this right now.Please try again later`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }
  emailNotificationSlideChange(item: INotificationItem) {
    item.isBusy = true;
    item.isEnabled = !item.isEnabled;
    let payload = {
      enableEmailNotifications: item.isEnabled,
    };

    this.usersService
      .editUserSingleProperty(payload, this.currentUser.userCode)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          item.isBusy = false;
        })
      )
      .subscribe(
        (user) => {
          this.currentUser = user;
          this.authService.userUpdated(user);
        },
        (error: HttpErrorResponse) => {
          item.isEnabled = !item.isEnabled;
          this.uiService.showSnackbar(`We can't process this right now.Please try again later`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }
  enableShipmentNotificationChange(item: INotificationItem) {
    item.isEnabled = !item.isEnabled;
    item.isBusy = true;
    let request = {
      enableShipmentNotifications: item.isEnabled,
    };

    this.usersService
      .editUserSingleProperty(request, this.currentUser.userCode)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          item.isBusy = false;
        })
      )
      .subscribe(
        (user) => {
          this.authService.userUpdated(user);
        },
        (error: HttpErrorResponse) => {
          item.isEnabled = !item.isEnabled;
          this.uiService.showSnackbar(`We can't process this right now.Please try again later`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  updateEmailNotification(event: MatSlideToggleChange) {
    let request = {
      enableEmailNotifications: event.checked,
      timePattern: this.currentUser.timePattern || 'hh:mm A',
      datePattern: this.currentUser.datePattern || 'MM/DD/YYYY',
    };
    this.enableEmailNotifications = event.checked;

    this.usersService
      .editUser(request, this.currentUser.userCode)
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;

          return user;
        })
      )
      .subscribe(
        (user: User) => {
          // update localStorage, handle page refresh
          this.authService.userUpdated(user);
        },
        (error: HttpErrorResponse) => {
          this.enableEmailNotifications = !this.enableEmailNotifications;
          this.emailNotificationToggle.toggle();
          const msg = 'Error: ' + error.statusText;
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        },
        () => {}
      );
  }

  saveSetting(item) {
    item.isEnabled = !item.isEnabled;
    // let body = this.getRequestBody();
    // for (let key in body) {
    //   delete body[key].forAllShipments;
    // }
    let requestBody = {
      [item.code]: {
        isEnabled: item.isEnabled,
        forAllShipments: item.forAllShipments,
      },
    };
    this.notificationService.updateNotificationSettings(requestBody).subscribe(
      (result) => {
        item.isBusy = false;
        if (!result) {
          item.isEnabled = !item.isEnabled;
          this.showError();
          return;
        }
        // this.showSuccess();
      },
      (error) => {
        item.isEnabled = !item.isEnabled;
        item.isBusy = false;
        this.showError();
      }
    );
  }

  errorMessage = 'Email notification could not be saved!';
  showError() {
    this.uiService.showSnackbar(this.errorMessage, null, {
      duration: environment.snackBarDuration.warning,
      panelClass: 'warn',
    });
  }
}
