<div class="components-table-heading">Tagged Shipments</div>
<div class="subTitle">Get notified of any shipments with selected tags.</div>

<label *ngIf="!noTag">Tag Name/s</label>

<div #contentZone class="contentZone">
  <div *ngIf="initializing"></div>

  <ng-container *ngIf="!initializing">
    <div *ngIf="noTag">
      <div class="flex">
        <div>
          <img style="margin: 20px auto; width: 30px; display: block" src="assets/images/svg/failed.svg" />
          <p>You haven't created any tag yet!</p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!noTag">
      <div class="data-list" *ngIf="tagsSubscribed && tagsSubscribed.length > 0">
        <div class="data-list-item" *ngFor="let tag of tagsSubscribed; let i = index">
          <p class="item-name">{{ i + 1 + '. ' + tag.name }}</p>
          <button mat-icon-button class="btnDelete" (click)="removeTag(tag)">
            <dp-svg [src]="SvgMap.delete"></dp-svg>
          </button>
        </div>
      </div>
      <div *ngIf="!tagsSubscribed || !tagsSubscribed.length" class="flex">
        <div>
          <img style="margin: 20px auto; width: 30px; display: block" src="assets/images/svg/failed.svg" />
          <div class="no-data">No tags subscribed yet!</div>
        </div>
      </div>
      <ng-container *ngIf="!subscribedAllTags()">
        <div class="mt-12" *ngIf="!addingMode">
          <button mat-flat-button class="easy-button secondary medium" (click)="onAddTag()">
            {{tagsSubscribed?.length ? 'Add another tag' : 'Add a tag'}}
          </button>
        </div>
        <div [hidden]="!addingMode || !tags.length" class="easy-list">
          <mat-form-field class="add-tag" appearance="outline">
            <mat-label>Tag Name</mat-label>
            <mat-select placeholder="Add a tag" #tagList [disableOptionCentering]="true" [(ngModel)]="tagToAdd" (selectionChange)="onTagSelection()" panelClass="no-change">
              <mat-option>
                <ngx-mat-select-search [formControl]="tagFilterCtrl" placeholderLabel="Search..." noEntriesFoundLabel="'no matching tag found'">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let tag of filteredTags | async" [value]="tag"> {{tag.name}} </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="btn-wrapper">
            <button mat-icon-button (click)="addingMode = false">
              <dp-svg [src]="SvgMap.delete"></dp-svg>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="subscribedAllTags()">
        <p>Great job, you have subscribed shipment notifications with all tags!</p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
